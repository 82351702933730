import findGoogleIframeMaxSize from "./findGoogleIframeMaxSize";

// Move to setupModule
export default function findMaxParentSize(parent){
    let maxSize = {width: window.innerWidth, height: window.innerHeight};
    // If not in a friendly iframe
    if (typeof parent === 'undefined' || parent === null) {
        return maxSize
    }
    // If inside a nested iframe
    else if (parent.ownerDocument.defaultView.frameElement) {
        parent = parent.ownerDocument.defaultView.frameElement;
    }

    try {
        maxSize = findGoogleIframeMaxSize(parent);
    } catch(e){
        console.error(e);
    }
    
    let parentWidth = parent.getBoundingClientRect().width;
    let parentHeight = parent.getBoundingClientRect().height;
    if (parentWidth > maxSize.width || parentHeight > maxSize.height){
        maxSize = {width: parentWidth, height: parentHeight};
    }

    return maxSize;
}

