export default function convertDataToBinary(base64Data,fileName) {
// 1. Convert base64 data to binary
const byteString = atob(base64Data.split(',')[1]); // Strip prefix (data:image/png;base64,)
const mimeType = base64Data.match(/data:(.*?);base64,/)[1]; // Get MIME type (e.g., image/png)

// Create an ArrayBuffer to store binary data
const buffer = new ArrayBuffer(byteString.length);
const uint8Array = new Uint8Array(buffer);

// Write binary data to ArrayBuffer
for (let i = 0; i < byteString.length; i++) {
  uint8Array[i] = byteString.charCodeAt(i);
}

// Create a Blob from ArrayBuffer
//const fileName = "image.png"; // Name of the file to send
const file = new File([uint8Array], fileName, { type: mimeType }); // Create as File instead of Blob

return file;
}