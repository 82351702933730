import * as React from 'react';

export default function SynchronizedTextarea(){


    return <textarea id="text-content" onChange={(e)=>{
        let value = e.currentTarget.value;
        cohoba.selection.object.textContent = value;
        
        if (cohoba.selection.layout && typeof cohoba.selection.cn[0] === "string") {
            cohoba.selection.cn[0] = value;
        }
        // Find element in layout
        // Change text in layout without refreshing render
    }} style={{opacity: 0, pointerEvents:"none", position: "absolute"}} />
}