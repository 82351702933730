import React from "react";
import FindReplaceComponent from "./FindReplaceComponent";

//Code Mirror Runtime Libraries -> Change to npm package and regular imports
import "codemirror/lib/codemirror.css";
//import 'script-loader!../lib/codemirror/lib/codemirror.js';
import CodeMirror from "./CodeMirror";
import "codemirror/addon/mode/overlay";
import "codemirror/mode/javascript/javascript";
// import "codemirror/addon/display/autorefresh";
import "codemirror/mode/xml/xml";
import "codemirror/mode/htmlmixed/htmlmixed";
import "codemirror/mode/css/css";
// import "codemirror/addon/search/searchcursor";
// import "codemirror/addon/dialog/dialog";
import "codemirror/addon/edit/matchbrackets";
import "codemirror/addon/edit/matchtags";
import "codemirror/addon/fold/xml-fold";

// Allows user to use cmd + / to toggle comments
import "codemirror/addon/comment/comment";

// Code Mirror Theme
import "codemirror/theme/material.css";

// Gives Sublime-like key-commands
import 'codemirror/keymap/sublime';

// Hinting
import "codemirror/addon/hint/html-hint";
import "codemirror/addon/hint/javascript-hint";
import "codemirror/addon/hint/css-hint";
import "codemirror/addon/hint/show-hint";
import "codemirror/addon/hint/show-hint.css";

//Linting
import "codemirror/addon/lint/lint";
import "codemirror/addon/lint/html-lint";
//import 'codemirror/addon/lint/htmlhint';
import "codemirror/addon/lint/javascript-lint";
//import 'codemirror/csslint';
import "codemirror/addon/lint/css-lint";
import "codemirror/addon/lint/lint.css";

import prettier from 'prettier/standalone';
import parserHTML from 'prettier/parser-html';

//import autoFormatRange from '../lib/codemirror/lib/formatting.js';
window.CodeMirror = CodeMirror;

export default class CodeEditor extends React.Component {
  constructor(props) {
    super(props);

    // var textArea = React.createElement("textarea", {});
    var textArea = (<textarea></textarea>);

    this.state = {
      setDirectly: false,
      textArea: textArea,
      code: this.props.code,
      loaded: false,
      onChange: this.props.onChange,
      outputField: document.createElement("textarea"),
    };

    this.CodeEditor = {};

    this.formatCode = this.formatCode.bind(this);
    this.editCode = this.editCode.bind(this);
    this.updateDirectly = this.updateDirectly.bind(this);
    this.domRef = React.createRef();
  }

  componentDidUpdate(prevProps, newProps) {
//    console.log("Code Editor Updatedddd", prevProps.code, newProps.code);
  //  this.updateDirectly(this.props.code);
   // console.log("Old props", prevProps);
  //  console.log("New props", this.props);
    if (typeof this.CodeEditor.refresh !== "undefined") {
    //   this.CodeEditor.refresh();
    }
    
    if(prevProps.code !== this.props.code){
    //  this.setState({code:this.props.code});
      this.updateDirectly(this.props.code);
      if(this.props.formatCode){this.formatCode();}
    }
    // else{
    //   this.updateDirectly(this.props.code ? props.code : "");
    //   if(this.props.formatCode){this.formatCode();}
    // }

    
  }

  formatCode(options) {
    options = {
      mode: "prod",
    };

    let CodeEditor = this.CodeEditor;

    let prettierCode = prettier.format(CodeEditor.getValue(), {parser:"html", plugins: [parserHTML]});

    // CodeMirror.commands["selectAll"](CodeEditor);
    // this.CodeEditor.autoFormatRange(
    //   CodeEditor.getCursor(true),
    //   CodeEditor.getCursor(false)
    // );
    // CodeMirror.commands["selectAll"](CodeEditor);
    CodeEditor.setValue(prettierCode);


  }

  updateDirectly(code) {
    if(!code){
      return
    }
    this.setDirectly = true;
    this.CodeEditor.setValue(code?.toString());
    this.CodeEditor.refresh();
  }

  componentDidMount() {
    if (typeof window.cohoba === "undefined") {
      window.cohoba = {};
    }
    window.cohoba.updateCodeEditor = this.updateDirectly;
    let editCode = this.editCode;

  
    

    if (typeof window.CodeMirror !== "undefined") {


      if (typeof window.CodeMirror == "undefined") {
        return;
      }

      let editorMode = this.props.mode ? this.props.mode : "text/html";

      window.CodeMirror.defineMode("htmlWithAssetHighlight", function(config, parserConfig) {
        var htmlMode = window.CodeMirror.getMode(config, "text/html");
        
        var assetOverlay = {
          token: function(stream, state) {
            if (stream.match(/{{ASSET_\d+}}/, true)) {
              return "asset-highlight"; // This will be the CSS class name for highlighting
            } else {
              stream.next(); // If no match, consume the character and continue
              return null;
            }
          }
        };
      
        return window.CodeMirror.overlayMode(htmlMode, assetOverlay);
      });

      this.CodeEditor = window.CodeMirror.fromTextArea(this.domRef.current, {
        lineNumbers: true,
        lineWrapping: true,
        tabSize: 2,
        indentUnit: 2,
        indentWithtabs: true,
        value: "<h1>Hello World</h1>",
        mode: editorMode,
        theme: "material",
        keyMap: "sublime",
        autoRefresh: true,
          dragDrop : true,
        lint: this.props.lint || true,
        matchBrackets: true,
        matchTags: true,
        extraKeys: { "Ctrl-Space": "autocomplete" },
        gutters: this.props.gutters || ["CodeMirror-lint-markers"],
      });

      this.CodeEditor.setOption("mode", editorMode);

      //this.CodeEditor.setOption("keyMap", "sublime");

      let toggleFindReplace = this.props.toggleFindReplace;

      this.CodeEditor.on("keydown", function (instance, event) {
        if (event.ctrlKey) {
          if (event.which === 49) {
            window.cohoba.CodeEditor.replaceSelection("%%%HEADING%%%");
            window.cohoba.CodeEditor.focus();
          }
          if (event.which === 50) {
            window.cohoba.CodeEditor.replaceSelection("%%%CAPTION%%%");
            window.cohoba.CodeEditor.focus();
          }
          if (event.which === 51) {
            window.cohoba.CodeEditor.replaceSelection("%%%ADVERTISER_NAME%%%");
            window.cohoba.CodeEditor.focus();
          }
          if (event.which === 52) {
            window.cohoba.CodeEditor.replaceSelection("%%%IMAGE_URL%%%");
            window.cohoba.CodeEditor.focus();
          }
          if (event.which === 53) {
            window.cohoba.CodeEditor.replaceSelection("%%%CLICKTHROUGH_URL%%%");
            window.cohoba.CodeEditor.focus();
          }
          if (event.which === 54) {
            window.cohoba.CodeEditor.replaceSelection("%%%LOGO_IMAGE_URL%%%");
            window.cohoba.CodeEditor.focus();
          }

          if (event.which === 55) {
            window.cohoba.CodeEditor.replaceSelection("%%%INV_CODE%%%");
            window.cohoba.CodeEditor.focus();
          }
          if (event.which === 56) {
            window.cohoba.CodeEditor.replaceSelection("%%%DISCLOSURE%%%");
            window.cohoba.CodeEditor.focus();
          }

          if (event.which === 57) {
            window.cohoba.CodeEditor.replaceSelection("%%%CTA%%%");
            window.cohoba.CodeEditor.focus();
          }

          //This shouldn't be here
          if (event.which === 70 && window.cohoba.CodeEditor.hasFocus()) {
            toggleFindReplace(true);
            let selection = document.getSelection();
            let text = selection.toString();
            document.querySelector("#code-module .replace-code").value = text;
            document.querySelector("#code-module .replace-code").focus();
          }
        }
      });

      
      this.CodeEditor.on("change", function () {
        editCode();
      });

      if(typeof window.cohoba.CodeEditor === "undefined"){
        window.cohoba.CodeEditor = this.CodeEditor;
      }
    } //CodeMirror Conditional
  }

  editCode(code) {
    //This can be replaced with shouldComponentUpdate
    if (this.setDirectly) {
      this.setDirectly = false;
      return;
    }
    code = this.CodeEditor.getValue();
    this.state.onChange(code);
  }

  

  render() {
    var overlayStyle = { display: "none" };
    if (this.props.findReplaceVisibility) {
      overlayStyle = { display: "block" };
    }

    

  /* ****************************************
    ********* The Return Statement *********
    *****************************************/

    return (
      <div className={"code-editor-container " + this.props.className} style={this.props.style}>
        <span style={overlayStyle}>
          <FindReplaceComponent />
        </span>
        <textarea
          // NOTE: the defaultValue loads first but DOES NOT update
          // code updates happen in the componentDidUpdate() on CodeWindow.js
          //  with this: this.CodeEditor.current.CodeEditor.setValue(this.props.workspace.code);
          style={{ height: "100%", width: "100%" }}
          // defaultValue={this.state.code}
          defaultValue={this.props.code}
          ref={this.domRef}
          id="cohoba-code-module-output"
        />
      </div>
    );
  }
}