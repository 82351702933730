import * as React from "react";
import NavigatorItem from "./NavigatorItem";

function MapLayoutObjects(children) {
  const childrenObjects = children.map(function (child, index) {
    if (typeof child !== 'string' && !child.fingerprint){
      child.fingerprint = Math.random().toString().split(".")[1].slice(0,8);
    }

    let tagName = child.component === 'frame' ? <i className="fas fa-vector-square"></i> : child.component;

    let identifier = child.component || child;

    if (typeof child === 'string' || child.component === 'text') {
      tagName = <i className="fas fa-quote-left"></i>;
      identifier = child === '' ? 'Empty' : child;
    }

    if (child.component === 'media') {
      tagName = <i className="fas fa-photo-video"></i>;
    }

    if (child.component === 'box') {
      tagName = <i className="fas fa-border-all"></i>;
    }

    if (typeof identifier === "undefined") {
      identifier = child.attributes?.className;
    }

    if (typeof identifier === "undefined") {
      identifier = child.component + " " + child.fingerprint;
    }
    

    if (child.tagName === "SCRIPT") {
     //  return null
    }
    if (child.tagName === "STYLE") {
     //  return null
    }

    if (typeof child === "string") {
      return <NavigatorItem
      key={index}
      identifier={identifier}
      component={'text'}
      tagName={tagName}
      object={child}
    >
    </NavigatorItem>
   }

   if (typeof identifier === "undefined") {
    identifier = child.component || child.name || tagName;
  } else {
   // identifier = "" + child.attributes?.id + " " + child.attributes?.className;
  }

    //  if(child.tagName === "LINK" || child.tagName === "SCRIPT" || child.tagName === "BR"){return}

    if (typeof child.cn !== "undefined" && child.cn.length > 0) {
      var grandchildren = child.cn;

    //   grandchildren = Array.from(grandchildren);

      var grandchildrenObjects = MapLayoutObjects(grandchildren);
  
      return <NavigatorItem
          key={index}
          toggled={true}
          identifier={identifier}
          component={child.component}
          tagName={tagName}
          object={child}
          fingerprint={child.fingerprint}
        >
        {grandchildrenObjects}
        </NavigatorItem>
    } else {
       
      return <NavigatorItem
      key={index}
      identifier={identifier}
      tagName={tagName}
      component={child.component}
      object={child}
      fingerprint={child.fingerprint}
    >
    </NavigatorItem>
    }
  });

  return childrenObjects;
}

export default MapLayoutObjects;
