import React from "react";
import ActionButton from "../components/ActionButton";
import styled from 'styled-components';

const StyledFindPlacement = styled.div`

      position: relative;
      display: flex;
      padding: 2px 6px 6px;

      & .replace-code {
        width: calc(50% - 25px) !important;
        display: inline-block;
      }

      & .replace-value {
        margin-left: 6px !important;
        margin-right: 6px !important;
        width: calc(50% - 25px) !important;
        display: inline-block;
      }

`


//===========================
// A Find and Replace set of fields
// used in the code editor
//===========================


export default class FindReplaceComponent extends React.Component {
  constructor(props) {
    super(props);

    // guard clause
    if (typeof this.props.styles == "undefined") {
      return
    }
  }

  componentDidUpdate() {}

  //We can move all this logic to internal states and PropertyInputs
  render() {


    return(
      <StyledFindPlacement>

       <input
        type="text"
        className="replace-code"
        placeholder="Find"/>
      <input
        type="text"
        className="replace-value"
        placeholder="Replace"/>

        <ActionButton
          action={function () {
            let replaceCode = document.querySelector("#code-module .replace-code").value.trim();
            let replaceValue = document.querySelector("#code-module .replace-value").value.trim();
            let replaceCodeRegex = new RegExp(replaceCode, "g");

            console.log("replaceCode, replaceValue:", replaceCode, replaceValue);

            if (window.cohoba.workspace.mode === "edit") {
              let template = window.cohoba.workspace.code.replace(
                replaceCodeRegex,
                replaceValue
              );
              window.cohoba.workspace.code = template;
            } else {
              let render = window.cohoba.workspace.preview.replace(
                replaceCodeRegex,
                replaceValue
              );
              window.cohoba.workspace.render = render;
            }

            window.cohoba.workspaceArray[window.cohoba.currentIndex] = window.cohoba.workspace;
            window.cohoba.updateWorkspace(window.cohoba.workspace);
          }}
        >
          <i className="fas fa-exchange-alt"></i>
        </ActionButton>
       </StyledFindPlacement>

    );
  }
}
