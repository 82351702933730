import React from "react";
import TypesettingProperties from "./TypesettingProperties";
import LayoutProperties from "./LayoutProperties";

export default function ComponentsModule(props) {

  return(

    <section>

      

      <TypesettingProperties 
      key="typesetting-properties"
        selection={props.selection?.layout ? props.selection.attributes?.style : props.selection} 
      />

      <LayoutProperties
     key="layout-properties"
        selection={props.selection?.layout ? props.selection.attributes?.style : props.selection} 
      />
      
     

    </section>

  )

};	