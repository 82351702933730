const selectProps = {
  display: { selectable: true },
  visibility: { selectable: true },
  "font-size": { selectable: true, computedProp: "fontSize" },
  "border-radius": { selectable: true, computedProp: "borderRadius" },
  width: { selectable: true },
  height: { selectable: true },
  "background-image:": { selectable: true, computedProp: "backgroundImage" },
  "background-color:": { selectable: true, computedProp: "backgroundColor" },
  "box-shadow:": { selectable: true, computedProp: "boxShadow" },
  "border-width:": { selectable: true, computedProp: "borderWidth" },
  "border-color:": { selectable: true, computedProp: "borderColor" },
  "border-style:": { selectable: true, computedProp: "borderStyle" },
  "border-width:": { selectable: true, computedProp: "borderWidth" },
  "font-family:": { selectable: true, computedProp: "fontFamily" },
  "font-weight:": { selectable: true, computedProp: "fontWeight" },
  "font-style:": { selectable: true, computedProp: "fontStyle" },
  "text-transform:": { selectable: true, computedProp: "textTransform" },
  "letter-spacing:": { selectable: true, computedProp: "letterSpacing" },
  "color:": { selectable: true, computedProp: "color" },
  "padding:": { selectable: true, computedProp: "padding" },
  "padding-top:": { selectable: true, computedProp: "paddingTop" },
  "padding-right:": { selectable: true, computedProp: "paddingRight" },
  "padding-bottom:": { selectable: true, computedProp: "paddingBottom" },
  "padding-left:": { selectable: true, computedProp: "paddingLeft" },
  "box-shadow:": { selectable: true, computedProp: "boxShadow" },
  "box-sizing:": { selectable: true, computedProp: "boxSizing" },
  "line-height:": { selectable: true, computedProp: "lineHeight" },
  "vertical-align:": { selectable: true, computedProp: "verticalAlign" },
  "font-stretch:": { selectable: true, computedProp: "fontStretch" },
  "text-align:": { selectable: true, computedProp: "textAlign" },
  "text-decoration:": { selectable: true, computedProp: "textDecoration" },
  "vertical-align:": { selectable: true, computedProp: "verticalAlign" },
  "opacity:": { selectable: true, computedProp: "opacity" },
  "mix-blend-mode:": { selectable: true, computedProp: "mixBlendMode" },
};
export default selectProps;
