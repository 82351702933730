import selector from "./selector";

function moveSelection(direction){

  let selection = null;
  if (!window.cohoba.selection) {
    return false;
  }
  switch(direction){
    case "out":
    if (window.cohoba.selection.object.parentNode != null) {
           selection = selector(window.cohoba.selection.object.parentNode)
          window.cohoba.setSelection(selection);
          
            }
    break;

    case "in":
      if (window.cohoba.selection.object.children.length >= 1 && window.cohoba.selection.object.firstElementChild) {
         selection = selector(window.cohoba.selection.object.firstElementChild);
        window.cohoba.setSelection(selection);
        
           
                } else {
                  selection = selector(window.cohoba.selection.object);
                  window.cohoba.setSelection(selection);
                  
                }
    break;

    case "prev":
      if (window.cohoba.selection.object.previousElementSibling) {

        selection = selector(window.cohoba.selection.object.previousElementSibling);
        window.cohoba.setSelection(selection);
        
      }
    break;

    case "next":
      if (window.cohoba.selection.object.nextElementSibling) {

        selection = selector(window.cohoba.selection.object.nextElementSibling);
        window.cohoba.setSelection(selection);
        
      }
    break;
    default:
  }
 
  
 }
 


export default moveSelection;