import checkViewabilityOptions from "../checkViewabilityOptions";
import MRAIDModule from "../../mraidModule";
import ErrorModule from "../../errorModule";
export default function checkViewability(){
        try{
          window.render_params.viewabilityTracking = checkViewabilityOptions();
          window.render_params.viewable = MRAIDModule.mraid ? MRAIDModule.mraid.isViewable() : false;
        }catch(e){
          window.render_params.viewabilityTracking = 'untrackable';
          window.render_params.viewable = MRAIDModule.mraid ? MRAIDModule.mraid.isViewable() : false;
          ErrorModule.capture({...e, stack:"checkViewability : "+e.stack})
        }
      }