import SetupModule from "../../setupModule";

const getAdSize = () => {
    try {
        const container = SetupModule.getAdContainer();
        const containerBox = container?.getBoundingClientRect();
        return {width: containerBox?.width, height: containerBox?.height}
    } catch(e){
        return {width: 'undefined', height: 'undefined'}
    }
}

export default getAdSize;