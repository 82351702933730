import createImpressionParameters from "../createImpressionParameters";
import renderPixel from "../../../utilities/renderPixel";
import renderTag from "../../../utilities/renderTag";
import SetupModule from "../../setupModule";
import VideoModule from "../../videoModule";
import isViewable from "../isViewable";

const renderImpressionTrackers = async (node) => {
  
    if (!node) {
        node = document.body;
    }
    
    let viewable;
    if (!VideoModule.isVideo()) {
      viewable = await isViewable();
    } else {
      window.render_params.viewabilityTracking = "video"
    }

    if (window.render_params) {
      window.render_params.viewable = viewable;
    } 

    //Seems redundant to wait for viewable to return if viewability is already 'untrackable'
    if (viewable === true 
      || viewable === "untrackable"
      || window.render_params.viewabilityTracking === 'video'
      || window.render_params.viewabilityTracking === "untrackable"
      ) {

  // Advertible Render Pixel
  if (SetupModule.getRenderUrls()) { 
    const renderUrls = SetupModule.getRenderUrls();
    renderUrls.forEach((url : string) => {
      if (url !== ''){

        const renderEventUrl = new URL(url);
        const extString = createImpressionParameters();
        renderEventUrl.searchParams.set("ext", extString);
        node.appendChild(renderPixel(renderEventUrl.href));
      }
    })
  }

  // Bid Eventtrackers Impressions
  if (render_params.native && render_params.eventtrackers && render_params.eventtrackers.length > 0) {
    render_params.eventtrackers.forEach((et) => {
        if (et.event === 1) {
          if (et.method === 1) {
            node.appendChild(renderPixel(et.url));
          } else if (et.method === 2) {
            node.appendChild(renderTag(et.url));
          }
        }
      });
    }
    }
}

export default renderImpressionTrackers;