import React from "react";
import { PropertyLabel } from "./PropertyLabel";
import checkUnit from "./checkUnit";
import getDecimalLength from "./getDecimalLength";
import styled from 'styled-components';

// =============================
// TODO: insert componet description here
// =============================

const StyledPropertyInput = styled.div`
  position: relative;
  display: block;
  line-height: 1;


&.column-2 {
  width:50%;
  box-sizing: border-box;
}

.property-label {
  font-weight: 600;
  font-size: 11px;
  text-align: left;
  padding-bottom: 4px;
  display: inline-block;
  text-overflow: ellipsis;
  overflow: hidden;
  vertical-align: middle;
  line-height: 1;
  margin-top: 4px;
}

.property-sublabel {
  color: #fff;
  font-size: 11px;
  padding: 0;
  display: block;
  text-overflow: ellipsis;
  text-align: right;
  overflow: hidden;
  vertical-align: middle;
  padding-bottom:10px;
  bottom:-28px;
  left:8px;
  opacity:.7;
  margin:4px 0px 0px 4px;
}

.property-unit {
  position: absolute;
  right: 0px;
  top:8px;
  width: 16px;
  text-align: center;
  font-size: 11px;
  padding: 0px 4px;
  display: inline-block;
  text-overflow: ellipsis;
  overflow: hidden;
  vertical-align: middle;
  width: auto;
  color: #999;
  pointer-events: none;
}


.property-value-change-arrows{
  position: absolute;
  top: 2px;
  left: 0px;
  height: 24px;
  width: 16px;
  text-align: center;
  color: var(--light-gray);
}
.property-value-increase-arrow{
  position: absolute;
  top:0px;
  left:0px;
  width: 100%;
}
.property-value-decrease-arrow{
  position: absolute;
  bottom:0px;
  left:0px;
  width: 100%;
}

.property-value-increase-arrow:hover,.property-value-decrease-arrow:hover{
  color: var(--dark-gray);
  cursor:pointer;
}

.property-value-increase-arrow:active,.property-value-decrease-arrow:active{
  color: var(--dark-blue);
  cursor:pointer;
}


& .text-input {
  color: #666;
  margin: 0;
  width: 100%;
  font-size: 12px;
  padding: 6px 15px 5px;
  box-sizing: border-box;
  border-radius: 3px;
  border: 0;
  text-align: left;
  font-family: inherit;
  background-color: #f8f8f8;
  border: 1px solid var(--light-gray);
  box-sizing: border-box;
  transition: all 0.25s ease-in-out;
}
& .text-input:focus  {
  outline: 1px solid var(--primaryColor);
 
  position: relative;
}

& > span {
  display: inline-block;
  position: relative;
  width: 100%;
}

.property-field {
  position: relative;

  line-height: 1;
  min-height: 30px;
  }
  .property-field input[type='text']{min-height: 30px;}

`


export default class PropertyInput extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      type: this.props.type,
      value: this.props.value ?? '',
      label: this.props.label,
      sublabel: this.props.sublabel,
      action: this.props.action,
      returnAction: this.props.returnAction,
      id: this.props.id,
      unit: this.props.unit,
      placeholder: this.props.placeholder,
      disabled: this.props.disabled,
      //dynamic : true
      shiftToggled: false,
      arrowsActive: false
    };

    if (this.props.type === "static") {
        this.state.dynamic = false;
    }
    if (typeof this.state.type === "undefined") {
      this.state.type = "dynamic";
      this.state.arrowsActive = true;
    }

    if (this.state.disabled) {
      this.state.type = "static";
    }

    if (typeof this.state.unit === "undefined") {
      this.state.unit = "";
    }

    if (
      typeof this.props.value !== "undefined" &&
      typeof this.props.value === "string" && this.state.type === "dynamic"
    ) {
   
      this.state.unit = checkUnit(this.props.value);
    }

    this.input = React.createRef();
    this.updateValue = this.updateValue.bind(this);
    this.returnAction = this.returnAction.bind(this);
    this.increaseValue = this.increaseValue.bind(this);
    this.decreaseValue = this.decreaseValue.bind(this);
    this.toggleShift = this.toggleShift.bind(this);
  }

  //Some of this mess can be avoided with shouldComponentUpdate()

  componentDidUpdate() {

    if (this.props.disabled !== this.state.disabled) {
      this.setState({disabled: this.props.disabled})
    }

    if (this.input.current) {
      this.input.current.updateValue = this.updateValue;
    }
    if (this.state.type === "static") {
      this.input.current.value = this.props.value ? this.props.value : this.input.current.value;
    //  console.log("Returning for static", this.props.value)
      return;
    }

    if (this.props.value && (this.input.current.value + this.state.unit) !== this.props.value.toString()) {
      //   this.input.current.value = this.props.value;

      // Check unit change
      if (isNaN(this.props.value) && this.state.type === "dynamic") {
        if (typeof this.props.value === "string") {
          var unit = checkUnit(this.props.value);

          if (unit === "") {
            this.input.current.value = this.props.value;
          } else {
            this.input.current.value = parseFloat(this.props.value);

            if (unit !== this.state.unit) {
              this.setState({ unit: unit });
            }
          }
        }
      }

      return;
    }

    // Check unit change
    if (this.props.value && isNaN(this.input.current.value) && this.state.type === "dynamic") {

      if (typeof this.props.value === "string") {
        unit = checkUnit(this.input.current.value);

        if (unit === "") {
          this.input.current.value = this.props.value;
       // } else if (unit !== checkUnit(this.props.value)) {
        } else if (unit !== this.state.unit) {
          this.input.current.value = parseFloat(this.props.value);

          if (unit !== "" && unit !== this.state.unit) {
            this.setState({ unit: unit });
          }
        }
      }
    }
  }

  toggleShift(value) {
    this.shiftToggled = !this.shiftToggled;
  }

  returnAction(value) {
    if (typeof this.state.returnAction !== "undefined") {
      this.state.returnAction(value);
    }
  }

  updateValue(event) {
    if (this.state.type === "static") {
      
      this.state.action(this.input.current.value);
      return;
    }

    var inputValue = this.input.current.value;

    var unit = checkUnit(inputValue);

    if(inputValue === "auto"){
      this.state.action(inputValue);
      this.setState({ unit: "" });
      return;
    }

    if (unit === "" && this.state.type !== "static") {
      this.input.current.value = inputValue;
      if (this.state.unit && this.state.unit !== "") {
        this.state.action(inputValue + this.state.unit);
      } else {
        this.state.action(inputValue);
      }
    } else if (unit !== "" && this.state.type !== "static") {
      this.input.current.value = parseFloat(inputValue);
      this.state.action(this.input.current.value + unit);
    }

    if (this.state.type !== "static") {
      if (unit !== "" && unit !== this.state.unit) {
        this.setState({ unit: unit });
      }
    }
  }

  increaseValue() {
    var value = this.input.current.value;

   
    var decimals = getDecimalLength(parseFloat(value));
    var increaseNumber = 1;
    //var newValue = parseFloat(value);

    if (decimals === 1) {
      increaseNumber = 0.1;
    }

    if(this.shiftToggled){
      increaseNumber *= 10;
    }

    if (decimals > 0) {
      var newValue = parseFloat(value) + increaseNumber;
      this.input.current.value = newValue.toFixed(1);
    } else {
      var newValue = parseInt(value) + increaseNumber;
      this.input.current.value = newValue;
    }
    this.updateValue();
  }

  decreaseValue() {
    var value = this.input.current.value;


    var decimals = getDecimalLength(parseFloat(value));
    var decreaseNumber = 1;
    //var newValue = parseFloat(value);

    if (decimals === 1) {
      decreaseNumber = 0.1;
    }

    if(this.shiftToggled){
      decreaseNumber *= 10;
    }
    if (decimals > 0) {
    var newValue = parseFloat(value) - decreaseNumber;
    this.input.current.value = newValue.toFixed(1);
    } else {
      var newValue = parseInt(value) - decreaseNumber;
      this.input.current.value = newValue;
    }
    this.updateValue();
  }

  render() {
    var properValue = this.state.value;
    var increaseValue = this.increaseValue;
    var decreaseValue = this.decreaseValue;
    var updateValue = this.updateValue;

    if (typeof this.state.unit !== "undefined" && this.state.unit !== "") {
      properValue = parseFloat(this.props.value);
      if (
        this.props.value === "" &&
        this.state.unit !== "" &&
        typeof this.state.unit !== "undefined"
      ) {
        properValue = 0;
      }
    } else {
      if (this.props.value === "") {
        properValue = "";
       // console.log("Setting to empty value 1");
      }
    }
    if (this.state.dynamic && isNaN(properValue)) {
      properValue = "";
     // console.log("Setting to empty value 2");
    }

    var returnAction = this.returnAction;
    var toggleShift = this.toggleShift;

    return(
      <StyledPropertyInput
       style={this.props.style}
       className={`${this.props.className ? this.props.className + " property-input" : "property-input"}`}
       >
        {this.state.label && <PropertyLabel className="property-label">{this.state.label}</PropertyLabel>}
        <span>
        <input
          ref={this.input}
          autoComplete="off"
          className="text-input"
          id={this.state.id}
          type={this.props.password ? 'password' : "text"}
          defaultValue={properValue ? properValue : ''}
         
          onChange={updateValue}
          placeholder={this.state.placeholder}
          readOnly={this.state.disabled}
          onBlur={this.props.onBlur ? this.props.onBlur : ()=>{}}
          onKeyDown={function (event) {
            if (event.keyCode === 38) {
              increaseValue();
              /* window.increaseInterval = setInterval(function(){
                                    increaseValue();
                                  },100);*/
            }
            if (event.keyCode === 40) {
              decreaseValue();
              /* window.increaseInterval = setInterval(function(){
                                    decreaseValue();
                                  },100);*/
            }

            if(event.keyCode === 16){
              toggleShift(true);
            }
          }}
          onKeyUp={function (event) {
            if(event.keyCode === 16){
              toggleShift(false);
            }

            if (event.keyCode === 13) {
              returnAction(event.currentTarget.value);
            }

            if (event.keyCode === 38) {
              // clearInterval(window.increaseInterval);
            }
            if (event.keyCode === 40) {
              //clearInterval(window.decreaseInterval);
            }
          }}
        />

         {this.state.sublabel  && <span
         key="sublabel"
         className="property-sublabel"
         >
          {this.state.sublabel}
        </span>}

        {this.state.unit &&  <span
         key="unit"
         className="property-unit"
         >
          {this.state.unit}
         </span>
        }

        {this.state.type === "dynamic" && this.state.disabled !== true && <div
         key="arrows"
         className="property-value-change-arrows"
         >
         <div
           className="property-value-increase-arrow"
            onClick={function () {
              increaseValue();
            }}
          >
          <i className="fas fa-caret-up"></i>
        </div>

        <div
          className="property-value-decrease-arrow"
          onClick={function () {
              decreaseValue();
            }}
         >
          <i className="fas fa-caret-down"></i>
        </div>
       </div>}

        </span>
     </StyledPropertyInput>
    );
  }
}
