const getColorMode = () => {
    try{
        const isDarkModePreferred = window.matchMedia('(prefers-color-scheme: dark)').matches;
        const isLightModePreferred = window.matchMedia('(prefers-color-scheme: light)').matches;
        if (isDarkModePreferred) {
            return 'dark'
        } else if (isLightModePreferred) {
            return 'light'
        } else {
            return 'unknown'
        }
    } catch(e){
        return 'unknown'   
    }
}

export default getColorMode;