
import React, {useRef} from "react";
import styled, {ThemeProvider, createGlobalStyle} from 'styled-components';
import nodePackage from '../../../package.json';
import cohoba from "../../config";
import {refreshWorkspace, getWorkspaces } from "../../network/workspace";
// Remove all these and use exclusively styled-components
import  "@fortawesome/fontawesome-free/js/all";
import shortcuts from "../shortcuts";
import {Flex, Box} from 'rebass';

//UI
import AppFrame from "./AppFrame";
import Header from "../../components/Header";
import WorkspaceContainer from "../../workspace/WorkspaceContainer";
import ContextualMenu from "./ContextualMenu";
import {ActionButton, Menu, Modal, StyledOverlay, Message, MessageContainer, PropertyInput} from "@components";

//Windows
import HomeScreen from './HomeScreen';
import ProjectWindow from "../../project/ProjectWindow";
import LibraryWindow from "../../library/LibraryWindow";
//import SettingsWindow from "../settings/SettingsWindow";
import CodeWindow from "../../code/CodeWindow";
import PreviewPanel from "./PreviewPanel";
import PropertiesWindow from "./PropertiesWindow";
import TemplateWindow from "../../template/TemplateWindow";

import {Route, Routes, useNavigate, useLocation} from 'react-router-dom';
import appTheme from "../cohobaTheme";
import { WorkspaceProvider } from './WorkspaceContext';
import { SelectionProvider } from "./SelectionContext";
import Selector from "./FrameContent/SelectorFrame";
import componentLibrary from "../../interactive/componentLibrary";

import CursorMode from "./CursorMode";
import SynchronizedTextarea from "./SynchronizedTextarea";
import Logo from "../../components/content/Logo";
import FrameActions from "./FrameActions";
import OverlayContainer from "./OverlayContainer";
import FrameContent from "./FrameContent/FrameContent";
import SidePanel from "./SidePanel";
import trackIntersections from "./trackIntersections";
import createImageFromClipboard from "../../workspace/createImageFromClipboard";
import WorkspaceActions from "./WorkspaceActions";
import updateMarkup from "../../workspace/updateMarkup";
import atabey from '../../images/icons/atabey.svg';
import {contactYaya} from '../../network/contactYaya';
const version = nodePackage.version;

const SvgCanvas = styled('svg')`
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 4;
  top: 0;
  left: 0;
  pointer-events: none;
  & * {
    stroke: red;
    fill: red;
  }
`;

const GlobalStyles = createGlobalStyle`
${props => props.theme.fontImport}
body * {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  margin: 0;
}
`;

const tag = document.currentScript;

function Platform(){
  const [user, setUser] = React.useState({
    config:{
      theme: "light",
      syncWithDatabase: false,
      keepSession: false,
      knobsHidden: true,
      menuHidden: true,
      experimentalFeatures: false,
      panelNavigation: false,
      labelsOff: false,
      frameHidden: false,
      workspaceHidden: true,
    },
    username: "Guest",
    id: null,
    profile_picture: appTheme.app.logo,
    token: null,
  });

  const [theme, setTheme] = React.useState(appTheme);
  const [frame, setFrame] = React.useState(0);
  const [cursor, setCursor] = React.useState('select');
  const [workspace, setWorkspace] = React.useState(null);
  const [stylesheets, setStylesheets] = React.useState([]);
  const [components, setComponents] = React.useState(componentLibrary);
  const [creatives, setCreatives] = React.useState([]);
  const [selection, setSelection] = React.useState(null);
  const [overlays, setOverlay] = React.useState(null);
  const [messages, setMessages] = React.useState([]);
  const [isPreview, setIsPreview] = React.useState(false);
  const [showHomeScreen, setShowHomeScreen] = React.useState(false);
  const [componentInjection, setComponentInjection] = React.useState(null);
  const [routes, setRoutes] = React.useState([]);
  const frameElement = React.useRef();
  const areaSelection = React.useRef();
  const WorkspaceContainerRef = React.useRef();
  const ProjectWindowRef = React.useRef();
  const TemplateWindowRef = React.useRef();
  const LibraryWindowRef = React.useRef();
  const DesignWindowRef = React.useRef();
  const CodeWindowRef = React.useRef();
  const overlayRef = React.useRef();
  const SvgCanvasRef = React.useRef();
  const location = useLocation();
  
  React.useEffect(()=> {

    //We may only need this for widget mode?
   if(typeof window.cohoba.container === "undefined"){
    window.cohoba.container = document.querySelector("div#cohoba-pwa,#root");
   }

  /*
  Prevent the browser from navigating away, move to Frame
  */
  window.addEventListener("beforeunload", function(event) {
    event.returnValue = "You're about to browse away.";
    return false;
  });

  // Move this to useState init
  let UserObject = window.localStorage.getItem("cohoba-user");
      if (UserObject) {
        UserObject = JSON.parse(UserObject);

        if (UserObject.token) {
          setUser(UserObject);

          // Validate token with server
          // fetch(`https://cohoba.co/simple-jwt-login/v1/auth/validate&JWT=${UserObject.token}`,{
          //   method:'POST',
          //   body: JSON.stringify({
          //     "JWT" : UserObject.token,
          //   })
          // }).then(r =>{
          //   console.log(r);

          //  setUser(UserObject);

          // // setShowHomeScreen(true);

          // }).catch(e => console.error);
        } else {
        //  setLoading(false);
          setShowHomeScreen(true);
        }
      }

      // let storedWorkspace = window.localStorage.getItem("cohoba-workspace");
      // if ((storedWorkspace)) {
      //   const parsedWorkspace = JSON.parse(storedWorkspace);
      //   if (parsedWorkspace.pub_id !== 'test' && parsedWorkspace.name !== 'demo'){
      //     setWorkspace(JSON.parse(storedWorkspace));
      //   }
      // }
      
        getWorkspaces().then(workspaces => {
          if(workspaces.length === 0) {
            refreshWorkspace(workspace);
          } else{
            // Tag Layout hack
         //   workspaces[0].layouts[0] = TagLayout(workspaces?.at(0).layouts.at(0));
            setWorkspace(workspaces?.at(0))
          }
        }).catch(e => console.error);
 
    /* APIs */
    window.cohoba.frame = 0;
    window.cohoba.selection = selection;
    window.cohoba.setSelection = setSelection;
    window.cohoba.setOverlay = setOverlay;
    window.cohoba.setFrame = setFrame;
    window.cohoba.newMessage = newMessage;
    window.cohoba.newModal = newModal;
    cohoba.container = frameElement.current;
    window.cohoba.areaSelection = areaSelection.current;
      
    //Attach shortcuts
   shortcuts();

   document.addEventListener('paste', createImageFromClipboard);

   window.workspaceRefresh = setInterval(()=>{
     if (window.cohoba?.workspace?.title){
      updateMarkup(window.cohoba.workspaceElement.innerHTML, window.cohoba.workspace.assets);
      
      refreshWorkspace(window.cohoba.workspace, (res)=>{
        if (res) {
          newMessage({type:'success',title:'Autosaved workspace', autoClose:true});
        } else {
          newMessage({type:'error',title:'Autosaved workspace'});
        }
      });
    }
   },180000);
}, []);

React.useEffect(() => {
  window.cohoba.selection = selection;
},[selection]);

React.useEffect(() => {
  refreshWorkspace(workspace);
},[workspace?.assets])

React.useEffect(() => {
      window.cohoba.user = user;
      window.cohoba.workspace = workspace;
      window.cohoba.componentInjection = componentInjection;
      window.cohoba.currentFrame = workspace?.layouts[frame];
      window.cohoba.frame = frame;
       //Save user config
       if(user){
        window.localStorage.setItem("cohoba-user", JSON.stringify({...user, config: user.config}));
      }
    

      if (showHomeScreen){
          document.querySelector(".home-screen-interactions").style.opacity = "1";
          document.querySelector(".home-screen-status").innerHTML = "Cohoba Platform v."+version+" <i className=\"fas fa-cog\" style='margin-left: 8px; font-size: 16px'></i>";
        }

        // // Re-draw SVG canvas
        // if (document.querySelector(".node-test")){
        //   setInterval(()=>{
        //     SvgCanvasRef.current.innerHTML = "";
        //       const nodeList = Array.from(document.querySelectorAll(".node-test"));
        //     nodeList.forEach(node => {
        //       const filteredNodeList = nodeList.filter(n => {
        //         // n !== node
                
        //       });
        //       const linkedNodes = node.dataset['name'] ? document.querySelectorAll(".node-test[data-name='"+node.dataset['linked']+"']") : [];
        //       linkedNodes.forEach(n =>{
        //         const {newX,newY} = calculateNewCoordinates(
        //           node.getBoundingClientRect().left + (node.getBoundingClientRect().width /2), 
        //         node.getBoundingClientRect().top + (node.getBoundingClientRect().height /2),
        //         n.getBoundingClientRect().left + (n.getBoundingClientRect().width /2) ,
        //         n.getBoundingClientRect().top + (n.getBoundingClientRect().height /2), 
        //         50)
        //         drawArrow(SvgCanvasRef.current, 
        //           node.getBoundingClientRect().left + (node.getBoundingClientRect().width /2), 
        //           node.getBoundingClientRect().top + (node.getBoundingClientRect().height /2),
        //           newX,
        //           newY,
        //         );
        //       })
            
        //     })
        //   },300);
        // }        
},[user, workspace, frame]);


const navigate = useNavigate();


React.useEffect(()=>{
        setRoutes([
          {
            name: "file",
            label: "ENV",
            icon: <i className="fas fa-warehouse"></i>,
            window: ProjectWindowRef.current,
            action: (toggled) => {
              document.querySelectorAll("#side-panel .window").forEach(w => {
                if (w.classList.contains('file')) {
                  w.classList.remove('hidden');
                } else{
                  w.classList.add('hidding');
                  setTimeout(()=>{
                    w.classList.add('hidden');
                    w.classList.remove('hidding');
                  },300)
                }
                  
              })},
          },
          {
            name: "library", 
            label:"LIB", 
            icon: <i className="fa-solid fa-icons"></i>, 
            window: LibraryWindowRef,
            action: (toggled) => {
              document.querySelectorAll("#side-panel .window").forEach(w => {
                if (w.classList.contains('library')) {
                  w.classList.remove('hidden');
                } else{
                  w.classList.add('hidding');
                  setTimeout(()=>{
                    w.classList.add('hidden');
                    w.classList.remove('hidding');
                  },300)
                }
                  
              })}},
          {
            name: "navigator",
            icon: <i className="fas fa-compass"></i>,
            label: "MAP",
            action: (toggled) => {
              document.querySelectorAll("#side-panel .window").forEach(w => {
                if (w.classList.contains('template')) {
                  w.classList.remove('hidden');
                } else{
                  w.classList.add('hidding');
                  setTimeout(()=>{
                    w.classList.add('hidden');
                    w.classList.remove('hidding');
                  },300)
                }
                  
              })},
            window: TemplateWindowRef
          },
          {
            name: "properties",
            icon: <i className="fas fa-pencil-ruler"></i>,
            label: "PROPS",
            action: (toggled) => {
              document.querySelectorAll("#side-panel .window").forEach(w => {
                if (w.classList.contains('design')) {
                  w.classList.remove('hidden');
                } else{
                  w.classList.add('hidding');
                  setTimeout(()=>{
                    w.classList.add('hidden');
                    w.classList.remove('hidding');
                  },300)
                }
                  
              })},
            window: DesignWindowRef
          },
      
          {name: "code", 
          label:"EDITOR", 
          icon: <i className="fas fa-code"/>,  
          action: (toggled) => {
            document.querySelectorAll("#side-panel .window").forEach(w => {
              if (w.classList.contains('code')) {
                w.classList.remove('hidden');
                } else{
                  w.classList.add('hidding');
                  setTimeout(()=>{
                    w.classList.add('hidden');
                    w.classList.remove('hidding');
                  },300)
                }
                
            })},
          window: CodeWindowRef},
          // {
          //   name: "action", 
          //   label:"ACTION", 
          //   icon: <i className="fas fa-hand-pointer"/>,  
          // //  window: PreviewWindowRef.current
          // },
          // {
          //   name: "timeline", 
          //   label:"TIME", 
          //   icon: <i className="fas fa-stopwatch"/>,  
          //   window: PreviewWindowRef.current
          // },
      ]);
      window.deviceFrameScale = 1;
      window.scaleInterval = setInterval(()=>{
        const frameContainer = document.querySelector("#preview-panel");
        const workspaceContainer = document.querySelector("#workspace-container");
      
        if (!workspaceContainer || !frameContainer) {
            clearInterval(window.scaleInterval);
            return;
        }

        const workspaceWidth = workspaceContainer.getBoundingClientRect().width;
       const containerWidth = frameContainer.getBoundingClientRect().width;
       const workspaceHeight = workspaceContainer.getBoundingClientRect().height;
       const containerHeight = frameContainer.getBoundingClientRect().height;

        // if (workspaceWidth > (containerWidth-10)){
        //     window.deviceFrameScale = window.deviceFrameScale * 0.99;
        //     const scaleFactor = window.deviceFrameScale;
        //     workspaceContainer.style.transform = `scale(${scaleFactor})`;
        // } else if ((workspaceWidth*1.01)+10 < (containerWidth)) {
        //     window.deviceFrameScale = window.deviceFrameScale * 1.01;
        //     const scaleFactor = window.deviceFrameScale;
        //     workspaceContainer.style.transform = `scale(${scaleFactor})`;
        // }

        

    //     if (workspaceHeight > (containerHeight-50)){
    //       window.deviceFrameScale = window.deviceFrameScale * 0.95;
    //       const scaleFactor = window.deviceFrameScale;
    //       workspaceContainer.style.transform = `scale(${scaleFactor})`;
    //   } else if ((workspaceHeight*1.05)+50 < (containerHeight)) {
    //       window.deviceFrameScale = window.deviceFrameScale * 1.05;
    //       const scaleFactor = window.deviceFrameScale;
    //       workspaceContainer.style.transform = `scale(${scaleFactor})`;
    //   }
      }, 100);
    
},[]);

// Modals and messages
const newModal = (modal) => {
  let ModalComponent = <Modal {... modal} />;
  let overlayComponents = <StyledOverlay>{ModalComponent}</StyledOverlay>
  setOverlay(overlayComponents)
}


const newMessage = (message : {type?: string; content: string; icon?: any; title?: string; acceptMessage?: string;}) => {

  if(typeof(message.type) === "undefined"){
    message.type = "info";
  }

  let messageComponent = <Message time={Date.now()} style={{position:"relative", left:"auto", top:"0", right:"0", marginBottom:"16px"}} setOverlay={setOverlay} {... message}>
    {message.content}
  </Message>;


if(messages){
    setMessages(msgs => ([...msgs, messageComponent]));
}
}

const areaSelectionMoveHandler = (e) => {
    window.cohoba.areaSelection = document.querySelector(".area-selection");
   // console.log(cohoba.areaSelection);
   if (cursor === 'select' && document.querySelector(".area-selection")) {
     let startPosition = JSON.parse(cohoba.areaSelection.dataset['startPosition']);
     let offsetX = e.clientX - startPosition.x;
     let offsetY = e.clientY - startPosition.y;
     if (offsetX < 0) {
       cohoba.areaSelection.style.left = e.clientX +'px';
       cohoba.areaSelection.style.width = offsetX*-1 +'px';
     } else {
       cohoba.areaSelection.style.width = offsetX +'px';
   //    cohoba.areaSelection.style.height = offsetY +'px';
     }
     if (offsetY < 0) {
       cohoba.areaSelection.style.top = e.clientY +'px';
       cohoba.areaSelection.style.height = offsetY*-1 +'px';
     } else {
      // cohoba.areaSelection.style.width = offsetX +'px';
       cohoba.areaSelection.style.height = offsetY +'px';
     }
     
     cohoba.areaSelection.dataset['endPosition'] = JSON.stringify({x: e.clientX, y: e.clientY});
   }
  
}

  return (<>
    <ThemeProvider theme={theme}>
    <GlobalStyles />
    <WorkspaceProvider workspace={workspace} setWorkspace={setWorkspace}>
    <SelectionProvider  selection={selection} setSelection={setSelection}>
    <AppFrame id="cohoba-frame" ref={frameElement} className={`${isPreview ? 'interactive-mode' : ''}`}>
    
      {showHomeScreen && <HomeScreen style={{display: user.token ? 'none' : 'block'}} user={user} setUser={setUser} 
      setShowHomeScreen={setShowHomeScreen}
      />}
      
      {overlays && <OverlayContainer ref={overlayRef}>
        {overlays}
      </OverlayContainer>}

     

      <Header workspace={workspace}>
        <Logo src={theme.app.logo}/>
      <img id="logo-icon" onClick={() => {
        setShowHomeScreen(true);
      }} src={theme.app.logo} style={{width: "24px", height:"24px", objectFit:"cover", objectPosition:"top left", margin: '0 0 0 4px'}}/>
     <Flex marginLeft={"auto"} flexGrow={1} alignItems="center" id="transform-actions">
    
      <WorkspaceActions />
     

                  
       
<ActionButton className="undo" action={()=>{
    // runWorkspace(workspace);
    window.cohoba.CodeEditor.undo();
   }}>

             <i className="fas fa-undo"></i>
         </ActionButton>

<ActionButton className="redo" action={()=>{
   // runWorkspace(workspace);
   window.cohoba.CodeEditor.redo();
}}>

             <i className="fas fa-redo"></i>
         </ActionButton>

        <Box  margin="0 auto 0 8px" id="cursor-mode">
          <CursorMode cursor={cursor} setCursor={setCursor}/>
       </Box>

       <Box>
         <FrameActions selection={selection} workspace={workspace} frame={frame} setFrame={setFrame}/>
       </Box>
          </Flex>
        
      </Header>
      
     <FrameContent id="frame-content" className="dots">
       <Flex id="atabey">
         <ActionButton><img src={atabey}/></ActionButton>
         <PropertyInput type='static' returnAction={(value)=>{
           //event.currentTarget.value = '';
           newMessage({title: window.cohoba.user.username, content:value, type:'success'});
           contactYaya(value);
         }} action={()=>{}} placeholder='How can Atabey help?'/>
       </Flex>
     {messages && <MessageContainer id="message-container">
        {messages}
      </MessageContainer>}
     <ContextualMenu 
        cursor={cursor}
        componentInjection={componentInjection}
        setComponentInjection={setComponentInjection}
        components={components}
        />
       <div ref={areaSelection} key="area-selection" className="area-selection" style={{position: 'fixed', top: 0, left: 0, width: '1px', height: '1px', background: '#49c1d157', border: '1px solid '+'#49c1d1', zIndex: '999', pointerEvents:'none', borderRadius:'6px'}}/>
          <Selector key="selector-frame" cursor={cursor}/>
          <SynchronizedTextarea key="text-area"/>

        <SvgCanvas ref={SvgCanvasRef} key={Math.random()} />

        <PreviewPanel key="preview-panel">
     
          <WorkspaceContainer
          key="workspace-contaner"
          ref={WorkspaceContainerRef}
          workspace={workspace}
          frame={frame}
          />
        </PreviewPanel>

    <SidePanel id="side-panel">
    <Menu menuArray={routes.length > 0 ? routes : []}/>

    <ProjectWindow 
      key="project-window"
      workspace={workspace}
      windowRef={ProjectWindowRef}
    />

    <CodeWindow
      key="code-window"
      user={user}
      windowRef={CodeWindowRef}
      workspace={workspace}
      frame={frame}
    />
    <TemplateWindow  
      key="template-window"
      windowRef={TemplateWindowRef}
    />
    <PropertiesWindow 
      key="design-window"
      workspace={workspace}
      selection={selection}
      setOverlay={setOverlay}
      windowRef={DesignWindowRef}
    />
 
<LibraryWindow
key="library-window"
workspace={workspace}
windowRef={LibraryWindowRef}
creatives={creatives}
user={user}
stylesheets={stylesheets}
/>



          
     
      </SidePanel>
          </FrameContent>
         

      
    </AppFrame>
    </SelectionProvider>
    </WorkspaceProvider>
    </ThemeProvider></>
  );
}


export default React.memo(Platform);