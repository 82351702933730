import React, { createContext, useContext, useState } from 'react';

// Create the context
const WorkspaceContext = createContext();

// Create a provider component
export const WorkspaceProvider = ({workspace, setWorkspace, children }) => {
  // You can initialize this state based on props, API data, or defaults
  //const [workspace, setWorkspace] = useState(null); // replace null with your initial workspace value
  const updateWorkspace = (newWorkspace) => {
    if (newWorkspace) {
      setWorkspace(workspace => ({...workspace, ...newWorkspace}));
    } else {
      setWorkspace(workspace => ({...workspace}));
    }
  }

  window.cohoba.updateWorkspace = updateWorkspace;

  return (
    <WorkspaceContext.Provider value={{ workspace, setWorkspace, updateWorkspace }}>
      {children}
    </WorkspaceContext.Provider>
  );
};

// Custom hook to use the workspace context
export const useWorkspace = () => {
  const context = useContext(WorkspaceContext);
  if (!context) {
    throw new Error('useWorkspace must be used within a WorkspaceProvider');
  }
  return context;
};


