/**
 * Finds a specific Data URL in a string and replaces it with another string.
 * 
 * @param {string} inputString - The string to search through for Data URLs.
 * @param {string} oldDataURL - The Data URL to find and replace.
 * @param {string} newString - The string to replace the old Data URL with.
 * @return {string} The modified string with the Data URL replaced.
 */
 export function replaceDataURL(inputString: string, oldDataURL: string, newString: string): string {
    // Ensure we're escaping any special regex characters in the oldDataURL
    const escapedDataURL = oldDataURL.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    
    // Create the regex pattern to match the entire Data URL
    const regex = new RegExp(escapedDataURL, 'g');

    // Replace all occurrences of the Data URL
    return inputString.replace(regex, newString);
}