import getMraid from "../getMraid";
export default function getPlacementType(){
      const mraid = getMraid();
      if (!mraid) {
        return 'unknown'
      } else if (typeof mraid.getPlacementType !== "undefined"){
        return mraid.getPlacementType(); 
      } else {
        return 'unknown'
      }
    }