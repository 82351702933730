import * as React from 'react';
import {styled} from 'goober';
import generateCSSFromObject from '../../interactive/setupModule/generateCSSFromObject';

const StyledBox = styled('div')`
${props => props.styles ? generateCSSFromObject(props.styles) : ''}
`;

const Box = (props) => {
    return <StyledBox  {...props} data-fp={props['data-fp']}>{props.children}</StyledBox>
}

export default Box;