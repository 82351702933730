       /**
 * Extracts all Data URLs from an HTML string.
 * 
 * @param {string} htmlString - The HTML content to search through.
 * @return {string[]} An array of Data URLs found in the HTML.
 */
export default function findAllDataURLs(htmlString) {
            // Regular expression to match data URIs
            const dataURLRegex = /data:([-\w]+\/[-+\w.]+)?(?:;charset=[-\w]+|;base64)?,(?:[\w;,@!?^=%&$#~+*-./:'\\]|(?:\s))+/g;
          
            // Find all matches in the htmlString
            const matches = htmlString.match(dataURLRegex) || [];
          
            return matches;
          }