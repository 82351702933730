import React from 'react';
import {styled} from 'styled-components';

const MockFrame = styled('div').attrs({
  className:'workspace-frame'
})`
position: absolute;
z-index: 99999;
pointer-events: none;
transform-origin: top left;

/*======================= */
/* frames.css            */
/*======================= */

&.iphone-5 {
  background: url('../images/device-frames/iphone-5.png') no-repeat center / contain;
  width: 369px;
  height: 910px;
  top: -159px;
  left: -24px;
  filter: brightness(0.95);
}

&.iphone-8 {
  background: url('../images/device-frames/iphone-8-light.png') no-repeat center / contain;
  width: 433px;
  height: 910px;
  top: -110px;
  left: -28px;
  filter: brightness(0.95);
}

&.iphone-x {
  background: url('../images/device-frames/iphone-x.png') no-repeat center / contain;
  width: 428px;
  height: 910px;
  top: -50px;
  left: -27px;
  filter: brightness(0.95);
}

&.iphone-8-plus {
  background: url('../images/device-frames/iphone-8-plus.png') no-repeat center / contain;
  width: 479px;
  height: 910px;
  top: -74px;
  left: -32px;
  filter: brightness(0.95);
}

&.iphone-xs {
  background: url('../images/device-frames/Light-Phone-Illustration.png') no-repeat center / contain;
  width: 500px;
  height: 910px;
  top: -84px;
  left: -62px;
  filter: brightness(0.95);
}

&.google-pixel {
  background: url('../images/device-frames/Light-Phone-Illustration.png') no-repeat center / contain;
  width: 500px;
  height: 910px;
  top: -84px;
  left: -62px;
  filter: brightness(0.95);
}

&.samsung-galaxy-s10 {
  background: url('../images/device-frames/samsung-galaxy-s10-frame.png') no-repeat center / contain;
  width: 544px;
  height: 910px;
  top: -25px;
  left: -59px;
}

&.ipad {
  background: url('../images/device-frames/Light-Tablet-Illustration.png') no-repeat center -66px / contain;
  width: 1075px;
  height: 1357px;
  top: -100px;
  left: -146px;
  filter: brightness(0.9);
}

&.laptop {
  background: url('../images/device-frames/Light-Laptop-Illustration.png') no-repeat center / contain;
  width: 1778px;
  height: 1290px;
  top: -26.5%;
  left: -14.9%;
}

&.phone {
  background: url('../images/device-frames/mobile_frame.png') no-repeat center / contain;
  width: 480px;
  height: 866px;
  left: -54px;
  top: -24px;
}
/* 
&.desktop {
  background: url('/images/device-frames/Light-Desktop-Illustration.png') no-repeat center / contain;
  width: 890px;
  height: 1290px;
} */

&.tv {
  background: url('../images/device-frames/Light-Tv-Illustration.png') no-repeat center / contain;
  width: 2629px;
  height: 1680px;
}

`;

function WorkspaceFrame (props) {
  return <MockFrame className={props.workspace?.target}/>
}

export default WorkspaceFrame;
