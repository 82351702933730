import React,{useState} from 'react';
import {Flex, Box} from 'rebass';
import { useWorkspace } from '../WorkspaceContext';
import {ActionButton, ActionList, Spinner, Accordion} from "@components";
import createWorkspace from "../../../entities/creators/createWorkspace";
import styled from 'styled-components';
import sailboat_html from '../../../workspace/hicotea_boats.html';
import renderToCanvas from '../../../project/renderToCanvas';
import { FileDrop, PropertyInput } from '../../../components';
import { getWorkspaces } from '../../../network/workspace';
const StyledAccordion = styled(Accordion)`
width: auto;
flex-grow: 0;
    margin: 0;
    * {
    transition: all .2s ease-out;
  }
   & .action-button{

    display: flex;
      min-height: 24px;
      line-height: 1;
      background: linear-gradient(to left, transparent, transparent);
      color: inherit;
      text-align: left;
      justify-content: space-between;
      margin: 4px -8px 4px 0;
      padding: 0 8px 0 0;
      align-items: center;
      position: relative;
      color: var(--textColor);
      svg {
        color: var(--textColor);
      }
      &:hover {
        background: linear-gradient(to left, var(--primaryColor), transparent);
       
        svg {
          color: var(--primaryColor);
        }

       .action-shortcut{
          color:#fff;
          opacity: 1;
        }
      }
    }

&.toggled {overflow: visible;}
& .accordion-title {
  font-weight: 600;
  font-size: 11px;

  svg {transform: none; font-size: 12px;}
}
  & .accordion-body {
    position: absolute;
    top: 38px;
    left: 0;
    background: linear-gradient(to bottom, hsl(187 20% 95% / 1), hsl(334 20% 95% / 1));
    text-align: left;
    color: var(--textColor);
    padding: 8px 8px 0 !important;
    z-index: var(--topIndex);
    min-width: 200px;
    border-radius: 0 0 8px 8px;
    box-shadow: 0 4px 8px -4px rgba(0,0,0,0.4);

  
  }

  .section-title{
    font-size: 11px;
    color: rgba(0,0,0,0.6);
    padding: 4px 0;
    margin: 0 0 4px;
    font-weight: 600;

  }
  .action-name{
    font-size:11px !important;
    padding-left: 32px;
    display: flex;
    align-items: center;
    margin: 0 !important;
    color:var(--textColor);
    svg{
      font-size: 13px;
      margin-right: 8px;
      position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left:0;
        color: var(--textColor);
    }
  }
  .action-shortcut{
    font-size:11px !important;
    opacity: 0.3;
    color: #000;
    margin: 0 !important;
  }
  
`;
// Move or refactor this asap
const saveWorkspace = (workspace) => {
     if (workspace.new) {
      delete workspace.new;
      fetch(process.env.API_ENDPOINT+"/workspace",{
        method:"POST",
        headers:{
         'Content-Type': 'application/json',
         'Authorization': 'Bearer '+ cohoba.user.token
        },
        body: JSON.stringify({
        //  id: workspace.id ||,
          title: workspace.title,
          content: workspace.render,
          status: 'publish',
          theme: workspace.stylesheet,
          assets: workspace.assets,
          frames: workspace.layouts,
          meta: {
            theme: JSON.stringify(workspace.stylesheet),
            assets: JSON.stringify(workspace.assets),
            frames: JSON.stringify(workspace.layouts),
         //   actions: JSON.stringify(workspace.actions),
         //   time: JSON.stringify(workspace.time),
          //  components: JSON.stringify(workspace.components),
          }
        })
      }).then(r => {
        // newMessage({
        //   title: "Created New Workspace",
        //   autoClose: true,
        //   type: "success"
        // });
        // setSaving(false);
        //cohoba.getStylesheets();
       return r.json();
      }).then(data => {
        if (!data.link){
        //   newMessage({
        //     title: "Failed at New Workspace",
        //     autoClose: true,
        //     type: "error",
        //     message:data.message,
        //     content:data.message,
        //   });
        } else {
         setWorkspace(workspace => ({...workspace, id: data.id}));
        //   newMessage({
        //     title: "Saved New Workspace",
        //     autoClose: true,
        //     type: "success"
        //   });
        }
  //  setSaving(false);
      }).catch(e => console.error);
      return;
    } 
      fetch(process.env.API_ENDPOINT+"/workspace"+"/"+ workspace.id,{
        method:"POST",
        headers:{
          'Authorization': 'Bearer '+cohoba.user.token,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          id: workspace.id,
          content: workspace.render,
          title: workspace.title,
          status: "publish",
          // theme: JSON.stringify(workspace.stylesheet),
          // assets: JSON.stringify(workspace.assets),
          // frames: JSON.stringify(workspace.layouts),
          meta: {
            theme: JSON.stringify(workspace.stylesheet),
            assets: JSON.stringify(workspace.assets),
            layouts: JSON.stringify(workspace.layouts),
          //  actions: JSON.stringify(workspace.actions),
          //  time: JSON.stringify(workspace.time),
          }
        })
      }).then(r => {
   //   setSaving(false); 
      return r.json()}).then(data => {
      }).catch(e => console.error);
    }
  


export default function WorkspaceActions(props) {
    const {workspace, setWorkspace} = useWorkspace();
    const [saving, setSaving] = useState(false);
    const [storedWorkspaces, setStoredWorkspaces] = useState([]);
    const [networkWorkspaces, setNetworkWorkspaces] = useState([]);

    React.useEffect(()=>{
      getWorkspaces().then((wksps)=>{
        setStoredWorkspaces(wksps);
      }).catch(console.error);

      fetch('https://cohoba.co/wp-json/wp/v2/workspace/').then(res => res.json()).then(data => {
        setNetworkWorkspaces(data);
      }).catch(console.error)
    },[])

 return  <> <StyledAccordion icon={workspace !== null ? <i className="fa-regular fa-folder-open"></i> : <i className="fa-solid fa-folder"></i>} title={''} id="file-actions" onToggle={(e, t)=>{
   const handleWindowClick = (e)=>{
    let accordionTitle = document.querySelector("#file-actions .accordion-title");
    if (!document.querySelector("#file-actions")?.contains(e.target)){
      setTimeout(()=>{
        accordionTitle.click();
      },10);
      window.removeEventListener('click', handleWindowClick, true)
    } else {
      
    }
   
  }

  if (t) { 
    window.addEventListener('click', handleWindowClick, true)
  } else {
    window.removeEventListener('click', handleWindowClick, true)
  }
 }}>

  <Flex className="section-title" justifyContent="space-between">Workspaces <Box><i className="fas fa-ellipsis-h"></i></Box></Flex>
  {storedWorkspaces.map(sw => <ActionButton key={sw.title} action={()=>{
    setWorkspace(sw);
  }}>{sw.title || sw.name}</ActionButton>)}
     <ActionButton action={()=>{
               //   alert("Trying to import sailboat");
                //  cohoba.currentFrame.markup = sailboat_html;
                //  cohoba.currentFrame.type = 'stack';
                  setWorkspace({...workspace,name:'Sailboat', size:[550,400], type:'fixed',layouts: [{type:'stack', name:'Sailboat',markup: sailboat_html}], assets:[], mode:'flow', render:sailboat_html});
                  document.querySelector("#file-actions .accordion-title").click();
                }}>⛵️  Sailboat</ActionButton>


                <Box height="1px" backgroundColor="#ddd" margin="4px -12px"/>

                <Flex  className="section-title" justifyContent="space-between">Project <Box><i className="fas fa-ellipsis-h"></i></Box></Flex>

                 <ActionButton action={()=>{
              const title = window.prompt("Title for new workspace:");
              if (title) {
                  const newWksp = createWorkspace(title, {new: true});
                  setWorkspace(newWksp);
                }
                
                document.querySelector("#file-actions .accordion-title").click();
            }
                }> 
                <Box className="action-name"><i className="fas fa-plus-circle"></i>  New</Box>
                <Box className="action-shortcut">Cmd + N</Box>
                </ActionButton>

              <ActionButton action={()=>{
                document.querySelector("#open-module").style.display='flex'

              }}>
                <Box className="action-name"><i className="fas fa-folder-open"></i> Open</Box>
                <Box className="action-shortcut">Cmd + O</Box>
              </ActionButton>
         

<ActionButton action={()=>{
            renderToCanvas(canvas => {
              canvas.style.position = 'absolute';
            //  cohoba.newModal(canvas);
            cohoba.setOverlay(<div id="klk"></div>)
             // document.querySelector("#overlay-container").style.display = 'flex';
              document.querySelector("#klk").appendChild(canvas);
               document.querySelector("#klk").onclick = (e)=>{if (e.target === e.currentTarget) e.currentTarget.style.display = 'none'}
             });
           
          }}>
            <Box className="action-name"><i className="fas fa-file-export"></i> Export</Box>
            <Box className="action-shortcut">Cmd + E</Box>
            </ActionButton>

 <ActionButton primary action={()=>{
            saveWorkspace(workspace);
            document.querySelector("#file-actions .accordion-title").click();
        }}>
          <Box className="action-name"><i className="fas fa-save"></i> Save {saving && <Spinner loading={saving}/>}</Box>
          <Box className="action-shortcut">Cmd + S</Box>
        </ActionButton>

          <ActionButton action={()=>{
            setWorkspace(null);
            document.querySelector("#file-actions .accordion-title").click();
          }}>
            <Box className="action-name"><i className="far fa-window-close"></i> Close</Box>
            <Box className="action-shortcut">Cmd + Q</Box>
          </ActionButton>
        
        
         <Flex id="open-module" style={{display:'none', flexDirection:'column', position:'absolute', top:0, left:0, width:'100%', height: '100%', background:'rgba(255,255,255,0.9)', padding:'16px'}}>
          <Flex className="section-title" justifyContent="space-between">File <i className="fas fa-hdd"/></Flex>
          <FileDrop />
          <Box height="1px" backgroundColor="#ddd" margin="4px -12px"/>
          <Flex className="section-title" justifyContent="space-between">Storage <i className="fas fa-archive"></i></Flex>
          <Box>
            {storedWorkspaces.map((sw,i) => <ActionButton key={i} action={()=>{
            setWorkspace(nw);
          }}><i className="far fa-folder"></i> {sw.title}</ActionButton>)}
          </Box>
          <Box height="1px" backgroundColor="#ddd" margin="4px -12px"/>
          <Flex className="section-title" justifyContent="space-between">Network <i className="fas fa-cloud"/></Flex>
          <PropertyInput placeholder="Workspace Id" action={()=>{}}/>
          <Flex flexDirection="column">
          {networkWorkspaces.map((nw,i) => <ActionButton key={i} action={()=>{
            setWorkspace(nw);
          }}><i className="fas fa-file"></i> {nw?.title?.rendered ? nw?.title?.rendered : nw?.title}</ActionButton>)}
          </Flex>
         </Flex>
        </StyledAccordion>
        
        <Box fontSize="11px" fontWeight="600">{workspace?.name ? workspace.title : 'Get Started'}</Box> </>
        }