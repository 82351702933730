import * as React from 'react';
import styled from 'styled-components';
import {Flex, Box} from 'rebass';
import { PropertyInput, ActionButton, ActionList } from '../../../components';
import DeviceOptions from '../../DeviceOptions';

const FrameActionsContainer = styled(Flex).attrs({id:'frame-actions'})`
& .action-button{
  min-height: 24px;
  line-height: 1;
  color: inherit;
  background: transparent;
  padding: 0 8px;
}
 & .text-input{

   border: 1px solid #314a61;
   padding: 5px 8px;
   font-size: 8px;
   line-height: 10px;
   background: transparent;
   font-family: monospace;
   color: #314a61;
   font-weight: 600;
   text-align: center;
   border-radius: 8px;
   background: transparent;
  }

  .play-interactive {
        font-size: 18px;
    color: #314a61;
    
    svg {

      filter: drop-shadow(0px 0px 2px #49c1d1) drop-shadow(0px 0px 2px #49c1d1);
    }
    .pause-icon {
      display: none;
    }
  }

  .stop-interactive {
    padding-left: 4px;
    margin: 0;
    color: var(--primaryColor);
    filter: none;
    font-size: 18px;
    color: #314a61;
  }

  #frame-manager {
    border: 1px solid var(--primaryColor);
    border-radius: 8px;
    margin-right: 8px;
    line-height: 1;
    &:hover{
      border-color: var(--secondaryColor);
    }
    .action-button {

      min-height: 20px;
    line-height: 1;
    color: #00000085;
    border-color: var(--primaryColor);
    background: transparent;
    padding: 0 8px;

    :hover{
      color: var(--primaryColor);
    }
    }
  }



   `;

   const FrameIndex = styled(Flex).attrs({id:'frame-index'})`
   & .frame-name {
    color: #666;
    border-color: currentColor;
   }
   & .frame-index {
    padding-left: 16px;
    width: 46px;

    .text-input input{
      background: #45c4d452;
      color: #666;
    }

    .property-value-change-arrows{
    left: -16px;
    top: 2px;
    height: 20px;
    font-size: 11px;
    }
   }
   `

const togglePreview = () => {
  if (!document.querySelector("#app-frame").classList.contains("interactive-mode")) {
    window.player = {
      time: 0,
      ticker : setInterval(()=>{
        window.player.time = window.player.time + 40;
        document.querySelector(".frame-index .text-input").value = `⏱ ${window.player.time/1000} s`;
      },40)
    }
    document.querySelector("#app-frame").classList.add("interactive-mode");
    window.addEventListener("dblclick", togglePreview, false);
    window.addEventListener("touchstart", togglePreview, true);
  } else {
    clearInterval(window.player.ticker);
    document.querySelector("#app-frame").classList.remove("interactive-mode");
    window.removeEventListener("dblclick", togglePreview, false);
    window.removeEventListener("touchstart", togglePreview, true);
  }
} 

export default function FrameActions({workspace, frame, selection, setFrame}){

    return <FrameActionsContainer>
       <Flex id="frame-manager">
        <ActionButton action={()=>{
              cohoba.workspace.layouts.splice(frame,1);
              cohoba.updateWorkspace({layouts: [...workspace.layouts]});
              setFrame(frame--);
           }}>
         <i className="far fa-trash-alt"></i>
         </ActionButton>

        <ActionButton action={()=>{
             
             const newLayoutFrame = {name: "Primary", id:"primary", markup:''};

             cohoba.updateWorkspace({layouts: [...workspace.layouts, newLayoutFrame]});
             setFrame(frame++);
           }}>
         <i className="far fa-clone"></i>
         </ActionButton>


          <ActionButton action={()=>{
             
              let clonedLayout = {...cohoba.currentFrame, id: Math.random()};
              console.log(clonedLayout);
              workspace.layouts.push(clonedLayout);
              console.log(workspace.layouts);

              cohoba.updateWorkspace({layouts: [...workspace.layouts]});
              setFrame(frame++);
            }}>
          <i className="fa-solid fa-clone"></i>
          </ActionButton>

          <ActionButton action={()=>{
            cohoba.updateWorkspace();
          }}>
          <i className="fa-solid fa-fan"></i>
          </ActionButton>
          </Flex>
          <ActionButton className="stop-interactive"><i className="fa fa-circle-stop"/></ActionButton>
          <FrameIndex>
            <PropertyInput className="frame-name" value={workspace?.layouts[frame]?.name} type="static" action={(value)=>{
              workspace.layouts[cohoba.frame].name = value;
            }}/> 

<PropertyInput className="frame-index" value={frame}  action={(value)=>{   
      console.log(value,isNaN(value));
                if(!isNaN(parseInt(value))) {
                  // Play range
                } else {
                  setFrame(parseInt(value));
                }
            }}/> 
        </FrameIndex>
          <ActionButton className="play-interactive" action={togglePreview}>
            <span key="pause-icon" className='pause-icon'>
              <i className="far fa-pause-circle"></i>
            </span>
            <span key='play-icon' className='play-icon'>
              <i className="fas fa-play-circle"></i>
            </span>
          </ActionButton>

       
        </FrameActionsContainer>
        }