import * as React from "react";
import styled from 'styled-components';
import { PropertyLabel } from './PropertyLabel';
import './optiongroup.css';


// ===============================
// We use this when we need to generate
// a set of soring buttons.
// For instance, it is used to sort creative
// formats by type, language & brand

// Used in:
// flexProperties.js
// typesettingComponent.js
// PlacementSettingsModule.js
// TemplateModule.js
// nativizerWindow.js
// userWindow.js
// etc....
// ===============================


// Styled-Components
const OptionGoupItem = styled.div`
  display: inline-block;
  margin: 2px;
  padding: 3px 6px;
  font-size: 12px;
  font-weight: 400;
  min-width: 35px;
  text-align: center;
  cursor: pointer;
  background-position: center 3px;
  background-size: 35px;
  background-repeat: no-repeat;
  border-radius: 6px;
  border: 1px solid #ccc;
  box-sizing: border-box;

  &:hover {
    background-color:${props => props.theme.primary || "var(--dark-blue)"};
    color:#fff;
  }

  & div:first-child {
    font-size:16px;
  }

  &.toggled {
    border-color: rgb(192, 104, 217);
    color: #fff;
    background-color:${props => props.theme.primary || "var(--dark-blue)"};
  }

  &.column-3 {
    width:calc(33% - 4px);
  }
`;





export default class OptionGroup extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      value: this.props.value,
      options: this.props.options,
      columns: this.props.columns,
      action: this.props.action,
    };

    this.selectOption = this.selectOption.bind(this);
    this.triggerAction = this.triggerAction.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {

    if(this.props.value === "" || (typeof this.props.value === "undefined" && this.state.value !== null)){
     // this.setState({ value: null });
      return;
    }

    if (this.state.value !== this.props.value && prevProps.value !== this.props.value) {
      for (var i = 0; i < this.props.options.length; i++) {
        if (this.props.options[i].value === this.props.value) {
      //    this.setState({ value: this.props.options[i].value });
        }
      }
    }
  }

  componentDidMount() {
    for (var i = 0; i < this.props.options.length; i++) {
      if (this.props.options[i].value === this.state.value) {
        this.setState({ value: this.props.options[i].value });
      }
    }
  }

  selectOption(selectedValue) {
    // this.props.value = selectedValue;
    this.setState({ value: selectedValue });
  }

  triggerAction(value) {
    this.state.action(value);
  }

  render() {
    let selectOption = this.selectOption;
    let action = this.triggerAction;
    const columns = this.state.columns;
    const value = this.state.value;

    // map over the "options" array, and return a div with that option
    const optionsArray = typeof this.props.options === "object" ? this.props.options.map(function (option, index) {
      let classes = "option-item";

      if (columns > 0) {
        classes += " column-" + columns;
      }

      if (value === option.value) {
        classes += " toggled";
      }

      if (typeof option.icon === "undefined") {
        option.icon = "";
      }

    return(
      <OptionGoupItem
        key={index}
        data-value={option.value}
        className={classes}
        onClick={ (event) => {
          if (option.value === value){
            selectOption(null);
          } else {
           selectOption(option.value);
           action(option.value);
         }
       }}
       >
          <div className="option-icon">
            {option.icon}
          </div>
          <div className="option-name">
            {option.name}
          </div>
        </OptionGoupItem>
    )


    }) : [];


       return (
         <div
          className={`option-group${this.props.name ? ' '+this.props.name : ''}${this.props.className ? ' '+this.props.className : ''}`}
          style={this.props.style}
        >
        {this.props.label && <PropertyLabel className="option-item-label">{this.props.label}</PropertyLabel>}

          {optionsArray}

        </div>
      );
    
  }
}
