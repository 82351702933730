import React from "react";
import styled from 'styled-components';
import Knob from "../../../components/Knob";
import { useSelection } from "../SelectionContext";
import selector from "../../../template/selector";
import updateMarkup from "../../../workspace/updateMarkup";
const SelectorContainer = styled.div`
pointer-events: all;
position: fixed;
opacity: 1;
top: 0px;
left: 0px;
width: 0px;
height: 0px;

z-index: var(--topIndex);
visibility: hidden;

&.selector-active{
  visibility: visible;
  box-shadow: 0 0 0px 10000vw rgba(0,0,0,0.0);
}

&:hover,&.hover{
 
}
&:hover .selector-size{
  opacity: 1;
}



 & .drag{
   
  position: absolute;
  top: 0 !important;
  left: 0 !important;

  width: 100%;
  height: 100%;
  transform: none;
  border-radius: 0;
  background: transparent;
  &:hover{

    background: rgb(73 193 209 / 49%);
  }
  &:active{
    background: transparent;
  }
 }



 ${props => props.hidden && `visibility: hidden;`}

`

const SelectorOutline = styled.div`
pointer-events: ${props => props.cursor === 'type' ? 'none' : 'none'};
outline: 1px solid ${props => props.theme.colors.primary};
position: absolute;
top: 0;
left:0;
& .selector-right{
  position: absolute;
  pointer-events: none;
  top: 0;
  right: 0;
  width: 1px;
  height: 1000vh;
  background: linear-gradient(to bottom, rgba(0,0,0,0),rgba(0,0,0,.2),rgba(0,0,0,0));
  background-size: 1px 10px;
  transform: translateY(-50%);
  }

& .selector-left{
  position: absolute;
  pointer-events: none;
  top: 0;
  left: 0;
  width: 1px;
  height: 1000vh;
  background: linear-gradient(to bottom, rgba(0,0,0,0),rgba(0,0,0,.2),rgba(0,0,0,0));
  background-size: 1px 10px;
  transform:translateY(-50%);
  }

& .selector-top{
  position: absolute;
  pointer-events: none;
  left: 0;
  top: 0;
  width: 1000vw;
  height: 1px;
  background: linear-gradient(to right, rgba(0,0,0,0),rgba(0,0,0,.2),rgba(0,0,0,0));
  background-size: 10px 1px;
  transform:translateX(-50%);
  }
& .selector-bottom{
  position: absolute;
  pointer-events: none;
  left: 0;
  bottom: 0%;
  width: 1000vw;
  height: 1px;
  background: linear-gradient(to right, rgba(0,0,0,0),rgba(0,0,0,.2),rgba(0,0,0,0));
  background-size: 10px 1px;
  transform:translateX(-50%);
  }

  & .selector-size{
    position: absolute;
    right: 50%;
    transform: translateX(50%);
    bottom: -20px;
    font-size: 10px;
    text-align: center;
    background: rgba(0,0,0,.5);
    color: #fff;
    border-radius: 4px;
    padding: 2px;
    opacity: 0;
    pointer-events: none;
    white-space: nowrap;
  }
`;

export default function Selector({cursor}){
  
  const [hidden, setHidden] = React.useState(false);
  const [position, setPosition] = React.useState({top: 0, left:0});
  const [size, setSize] = React.useState({width: 0, height:0});
  const selectorNode = React.useRef();
  const {selection} = useSelection();


  React.useEffect(()=>{
    cohoba.selectorFrame = selectorNode.current;
    selectorNode.current.addEventListener('mousedown', (e)=>{
      if (e.target.classList.contains('drag')){

        selectorNode.current.style.pointerEvents = 'none';
        let element = document.elementFromPoint(e.clientX, e.clientY);
        selectorNode.current.style.pointerEvents = 'all';
        
        if (element !== cohoba.selection?.object) {
          // if (e.target.classList.contains('knob')){return};
         // cohoba.updateSelector(element);
          cohoba.setSelection(selector(element));
        } else if(element === cohoba.selection?.object){
         // cohoba.setSelection(null);
        }
      }
    });
    // selectorNode.current.addEventListener('mouseup', (e)=>{
    //  if (selectorNode.current.classList.contains('interacting')){
    //   selectorNode.current.style.pointerEvents = 'none';
    //   let element = document.elementFromPoint(e.clientX, e.clientY);
    //   selectorNode.current.style.pointerEvents = 'all';
    //   if (element === cohoba.selection?.object) {
    //     cohoba.setSelection(null);
    //     selectorNode.current.classList.remove('interacting')
    //   }
    //  }
    // });
  },[])
  React.useEffect(()=>{
    console.log(selection);
    if (selection === null) {
     // setHidden(true);
      return;
    } else if (hidden){
      setHidden(false);
    }

    if (!selection?.object){
      return
    }

    setPosition({
      top: selection?.object?.getBoundingClientRect().top, 
      left: selection?.object?.getBoundingClientRect().left
    })
    setSize({
      width: selection?.object?.getBoundingClientRect().width, 
      height: selection?.object?.getBoundingClientRect().height
    });

    updateSelector(selection?.object);
    cohoba.selectorFrame = selectorNode.current;
  },[selection])

  const updateSelector = (element) => {
    if (element === null) {
      clearInterval(window.selectorTimer);
      return;
    }
    if(document.querySelector('.select-item')){
      document.querySelector('.select-item').classList.remove('select-item');
      element.classList.add('select-item');
    }

    if (selection === null) {
        setHidden(true);
        clearInterval(window.selectorTimer);
        return;
    } else if (!element && window.cohoba?.selection?.object){
      setPosition({
        top: selection.object.getBoundingClientRect().top, 
        left:selection.object.getBoundingClientRect().left
      })
      setSize({
        width: selection.object.getBoundingClientRect().width, 
        height: selection.object.getBoundingClientRect().height
      })
    } else if (element){
      setPosition({
        top: element.getBoundingClientRect().top, 
        left: element.getBoundingClientRect().left
      })
      setSize({
        width: element.getBoundingClientRect().width, 
        height: element.getBoundingClientRect().height
      })
    }

    clearInterval(window.selectorTimer);

    window.frameAnimation = true;
    const animateFrame = () => {
      if (!element && !selection) {
        window.frameAnimation = false;;
        return;
      }
      if (!selection) {
        window.frameAnimation = false;
        return;
      }
      if (!element || window.cohoba.selection === null || !window.cohoba.selection?.object) {
        window.frameAnimation = false;
        return;
      }

      if(element){
        setPosition({top:element.getBoundingClientRect().top, left: element.getBoundingClientRect().left});
        setSize({width:element.getBoundingClientRect().width, height: element.getBoundingClientRect().height});
      }
      if (window.frameAnimation) {
        window.requestAnimationFrame(animateFrame);
      }
    }
    window.requestAnimationFrame(animateFrame);
  }
  
  
  window.cohoba.updateSelector = updateSelector;
  
  return (<SelectorContainer className={`selector-box${selection !== null ? ' selector-active' : ''}`}
  ref={selectorNode} style={{
    top: position.top + "px",
    left: position.left + "px",
    width: 0,
    height: 0
  }}
  data-tag={selection?.object?.tagName}
>
  
    <Knob key="tl" name="tl" className="selector-knob"  freeMode={true} position={[
      position.left-1,
      position.top-1,
      ]} onTrigger={(p,k)=>{
        selection.object.classList.add('interacting');
        k.initialWidth = selection?.object.getBoundingClientRect().width;
        k.initialHeight = selection?.object.getBoundingClientRect().height;
        k.initialAttachedLeft = parseInt(selection?.object.style.left);
        k.initialAttachedTop = parseInt(selection?.object.style.top);

        if (!selection?.object.style.left && selection?.object.style.right) {
         
        }
      }} onMove={(p,k) => {
        console.log(p,k)
        let initialWidth = k.initialWidth;
        let initialHeight = k.initialHeight;
        let initialLeft = k.initialAttachedLeft;
        let initialTop = k.initialAttachedTop;
        console.log(initialWidth, initialHeight);

        selection.object.style.width = initialWidth - k.xOffset + 'px';
        selection.object.style.left = initialLeft + k.xOffset + 'px';

        selection.object.style.height = initialHeight - k.yOffset + 'px';
        selection.object.style.top = initialTop + k.yOffset + 'px';
      }} onClear={()=>{selection.object.classList.remove('interacting');  updateMarkup(cohoba.workspaceElement.innerHTML, cohoba.workspace.assets);}}/>
    <Knob key="tr" name="tr" className="selector-knob" freeMode={true} position={[
       position.left+size.width+1,
       position.top-1,
      ]} onTrigger={(p,k)=>{
        selection.object.classList.add('interacting');
        k.initialWidth = selection?.object.getBoundingClientRect().width;
        k.initialHeight = selection?.object.getBoundingClientRect().height;
        k.initialAttachedLeft = parseInt(selection?.object.style.left);
        k.initialAttachedTop = parseInt(selection?.object.style.top);
      }} onMove={(p,k) => {
        console.log(p,k)
        let initialWidth = k.initialWidth;
        let initialHeight = k.initialHeight;
        let initialLeft = k.initialAttachedLeft;
        let initialTop = k.initialAttachedTop;
        
        selection.object.style.left = initialLeft + 'px';
        selection.object.style.width = initialWidth + k.xOffset + 'px';

        selection.object.style.top = initialTop + k.yOffset + 'px';
        selection.object.style.height = initialHeight - k.yOffset + 'px';
      }} onClear={()=>{selection.object.classList.remove('interacting'); updateMarkup(cohoba.workspaceElement.innerHTML, cohoba.workspace.assets);}}/>
    <Knob key="bl" name="bl" className="selector-knob" position={[
      position.left-1,
      position.top + size.height+1,
      ]} onTrigger={(p,k)=>{
        selection.object.classList.add('interacting');
        k.initialWidth = selection?.object.getBoundingClientRect().width;
        k.initialHeight = selection?.object.getBoundingClientRect().height;
        k.initialAttachedLeft = parseInt(selection?.object.style.left);
        k.initialAttachedTop = parseInt(selection?.object.style.top);
      }} onMove={(p,k) => {
        selection.object.classList.add('interacting');
        let initialWidth = k.initialWidth;
        let initialHeight = k.initialHeight;
        let initialLeft = k.initialAttachedLeft;

        selection.object.style.width = initialWidth - k.xOffset + 'px';
        selection.object.style.left = initialLeft + k.xOffset + 'px';
        selection.object.style.height = initialHeight + k.yOffset + 'px';
      }} onClear={()=>{selection.object.classList.remove('interacting'); updateMarkup(cohoba.workspaceElement.innerHTML, cohoba.workspace.assets);}}/>
    <Knob key="br" name="br" className="selector-knob" position={[
      position.left+size.width+1,
      position.top + size.height+1,
      ]} onTrigger={(p,k)=>{
        selection.object.classList.add('interacting');
        k.initialWidth = selection?.object.getBoundingClientRect().width;
        k.initialHeight = selection?.object.getBoundingClientRect().height;
        k.initialAttachedLeft = parseInt(selection?.object.style.left);
        k.initialAttachedTop = parseInt(selection?.object.style.top);
      }} onMove={(p,k) => {
        console.log(p,k)
        let initialWidth = k.initialWidth;
        let initialHeight = k.initialHeight;
        
        if (cohoba.shortcuts.shift) {

        };
        selection.object.style.width = initialWidth + k.xOffset + 'px';
        selection.object.style.height = initialHeight + k.yOffset + 'px';
      }} onClear={()=>{selection.object.classList.remove('interacting'); updateMarkup(cohoba.workspaceElement.innerHTML, cohoba.workspace.assets);}}/>

<Knob key="top" name="top" className="selector-knob" position={[
      size.width/2, 0
      ]} onTrigger={(p,k)=>{
        selection.object.classList.add('interacting');
        k.initialWidth = selection?.object.getBoundingClientRect().width;
        k.initialHeight = selection?.object.getBoundingClientRect().height;
        k.initialAttachedLeft = parseInt(selection?.object.style.left);
        k.initialAttachedTop = parseInt(selection?.object.style.top);
      }} onMove={(p,k) => {
        console.log(p,k)
        let initialWidth = k.initialWidth;
        let initialHeight = k.initialHeight;
        
        if (cohoba.shortcuts.shift) {

        };
        selection.object.style.width = initialWidth + k.xOffset + 'px';
        selection.object.style.height = initialHeight + k.yOffset + 'px';
      }} onClear={()=>{selection.object.classList.remove('interacting'); updateMarkup(cohoba.workspaceElement.innerHTML, cohoba.workspace.assets);}}/>



<SelectorOutline cursor={cursor} style={{
    width: size.width + "px",
    height: size.height + "px"
  }}>
    
      <div className="selector-padding"/>
      <div
        className="selector-size"
      >
        <span style={{ fontWeight: "100" }}>w: <b>{size.width}</b></span>
        <span>x</span>
        <span style={{ fontWeight: "100" }}>h: <b>{size.height}</b></span>
        <span> px</span>
      </div>

      <div className="guide-line selector-right"/>
      <div className="guide-line selector-left"/>
      <div className="guide-line selector-top"/>
      <div className="guide-line selector-bottom"/>
      </SelectorOutline>
      
      {cursor === 'select' && <Knob key="drag" name="drag"  style={{
    // top: position.top + "px",
    // left: position.left + "px",
    width: size.width + "px",
    height: size.height + "px"
  }}  position={[
     0,0
      ]} onTrigger={(p,k)=>{
       
      //  alert("click works")
        selectorNode.current.classList.add('interacting');
        k.initialAttachedLeft = parseInt(selection.object.style.left);
        k.initialAttachedTop = parseInt(selection.object.style.top);
       
        if (isNaN(k.initialAttachedLeft)){
          k.initialAttachedLeft = selection.object.offsetLeft;
          k.initialAttachedTop = selection.object.offsetTop;
        }

      
      }} onMove={(p,k) => {
        let initialLeft = k.initialAttachedLeft;
        let initialTop = k.initialAttachedTop;

        if (cohoba.shortcuts.optionDown && !k.duplicated) {
            k.duplicated = true;
            let duplicate = selection?.object.cloneNode(true);
            cohoba.selection?.object.parentNode.appendChild(duplicate);
        }
        
        selection.object.style.left = initialLeft + k.xOffset + 'px';
        selection.object.style.top = initialTop + k.yOffset + 'px';
        selectorNode.current.classList.add('changed');


       // k.attachedTo.classList.remove('interacting');
      }} onClear={(p,k)=>{
        // selectorNode.current.style.pointerEvents = 'none';
        // let element = document.elementFromPoint(e.clientX, e.clientY);
        // selectorNode.current.style.pointerEvents = 'all';
        k.duplicated = false;
        if (!selectorNode.current.classList.contains('changed')) {
         cohoba.setSelection(null);
         cohoba.updateSelector(null);
       //  k.attachedTo.classList.remove('interacting');
         selectorNode.current.classList.remove('changed');
        }
     selectorNode.current.classList.remove('changed');
       // p.classList.remove('interacting');
       updateMarkup(cohoba.workspaceElement.innerHTML, cohoba.workspace.assets);
        }}/>}

        {/* 
          top knob
          bottom knob
          right knob
          left knob
          pivot knob

        */}

        {/* <Visualizer selection={selection}/> */}
    </SelectorContainer>
  );
}
