import ErrorModule from "../../errorModule";
import createBlobFromXML from "../../../utilities/createBlobFromXML";

enum AssetIds {
  link = 0,
  headline = 1,
  image = 2,
  logo = 3,
  advertiser = 4,
  caption = 5,
  cta = 6,
  rating = 7,
  price = 8,
  video = 9,
  disclosure = 10
}

export default function mapNativeToAssets(nativeBidResponse) {
    let assets = [
      {asset_id: AssetIds.image, name:"image", type: "img", data: nativeBidResponse.assets.find((a) => a.id?.Value === AssetIds.image)?.img?.url || null},
      {asset_id: AssetIds.logo, name:"logo", type:"image", data: nativeBidResponse.assets.find((a) => a.id?.Value === AssetIds.logo)?.img?.url || null},
      {asset_id: AssetIds.advertiser, name:"advertiser", type: "text", data: nativeBidResponse.assets.find((a) => a.id?.Value === AssetIds.advertiser)?.data?.value || null},
      {asset_id: AssetIds.link, name:"link", type: "link", data: nativeBidResponse.link?.url || null},
      {asset_id: AssetIds.headline, name:"headline",type: "text", data: nativeBidResponse.assets.find((a) => a.title)?.title?.text || null},
      {asset_id: AssetIds.caption, name:"caption", type: "text", data: nativeBidResponse.assets.find((a) => a.id?.Value === AssetIds.caption)?.data?.value || null},
      {asset_id: AssetIds.disclosure, name:"disclosure", type: "text", data: "Sponsored by"},
      {asset_id: AssetIds.cta, name:"cta",type: "text", data: nativeBidResponse.assets.find((a) => a.id?.Value === AssetIds.cta)?.data?.value || 'Learn More'},
      {asset_id: AssetIds.rating, name:"rating",type: "text", data: nativeBidResponse.assets.find((a) => a.id?.Value === AssetIds.rating)?.data?.value || 0},
      {asset_id: AssetIds.price, name:"price",type: "text", data: nativeBidResponse.assets.find((a) => a.id?.Value === AssetIds.price)?.data?.value || 0},
    ];


     const video = nativeBidResponse.assets.find((a) => a.video);
      if (video && video.video?.vasttag) {
         try {
         let vastUrl;
        //   const parser = new DOMParser();
        //   const xmlDoc = parser.parseFromString(nativeBidResponse.assets.find((a) => a.video).video.vasttag, 'text/xml');
        //   // Find VAST URL for mailonline library
        //   if (xmlDoc && !xmlDoc.querySelector('parsererror') && xmlDoc.querySelector('VASTAdTagURI')) {
        //     vastUrl = new URL(xmlDoc.querySelector('VASTAdTagURI')?.textContent?.trim());
        //     assets.push( {asset_id: 9, name:"video", type:"vasturl", data: vastUrl});
        //   } else {
            // Convert XML Vast tags to url blob
            const blob = createBlobFromXML(video.video?.vasttag);
            const blobUrl = URL.createObjectURL(blob);
            vastUrl = blobUrl;
            assets.push( {asset_id: 9, name:"video", type:"vasturl", data: vastUrl});
      //     }
        } catch(e){
          console.error(e);
          ErrorModule.capture({...e, stack:"Error Parsing XML : "+e.stack})
          assets.push( {asset_id: 9, name:"video", type:"vasttag", data: nativeBidResponse.assets.find((a) => a.video)?.video?.vasttag});
        }
      //  // assets.push( {asset_id: 9, name:"video", type:"vasttag", data: nativeBidResponse.assets.find((a) => a.video)?.video?.vasttag.replace(/"/g, '\\"')});
      // } else if (video && video.type === 'vasturl'){
      //   assets.push( {asset_id: 9, name:"video", type:"vasturl", data: video.data});
       }

    if (nativeBidResponse.eventtrackers) {
      assets.push({asset_id: 10, name:"eventtrackers", data: nativeBidResponse.eventtrackers})   
    }


    return assets;
  }