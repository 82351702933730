import escapeRegExp from './escapeRegExp.js';

function replaceRegex(htmlCode, workspace){

  if(typeof(workspace.creative.image_url) == "undefined"){console.log("Creative not found"); return htmlCode}
     
    let imageRegex = new RegExp(escapeRegExp(workspace.creative.image_url),"g");

  let headingRegex = new RegExp(escapeRegExp(workspace.creative.heading),"g");
  let captionRegex = new RegExp(escapeRegExp(workspace.creative.caption),"g");
  let clickRegex = new RegExp(escapeRegExp(workspace.creative.clickthrough_url),"g");
  let sponsorRegex = new RegExp(escapeRegExp(workspace.creative.advertiser_name),"g");

  let invCodeRegex;

  if(workspace.workspaceType === "template"){
    invCodeRegex = new RegExp(escapeRegExp(workspace.title.replace(/ /g,"_").replace(":", "-")),"g");
  }else{
    invCodeRegex = new RegExp(escapeRegExp(workspace.placement.inv_code),"g");
  }

  let disclosureRegex = new RegExp("Sponsored by","g");
  let ctaRegex = new RegExp("See More","g");

  htmlCode = htmlCode.replace(imageRegex, "%%%IMAGE_URL%%%");
  
  if(workspace.creative.logo_url !== ""){
    let logoRegex = new RegExp(escapeRegExp(workspace.creative.logo_url),"g");
   htmlCode = htmlCode.replace(logoRegex, "%%%LOGO_IMAGE_URL%%%");
  }




  htmlCode = htmlCode.replace(invCodeRegex, "%%%INV_CODE%%%");
  htmlCode = htmlCode.replace(headingRegex, "%%%HEADING%%%");
  htmlCode = htmlCode.replace(captionRegex, "%%%CAPTION%%%");
  htmlCode = htmlCode.replace(clickRegex, "%%%CLICKTHROUGH_URL%%%");
  htmlCode = htmlCode.replace(sponsorRegex, "%%%ADVERTISER_NAME%%%");
  htmlCode = htmlCode.replace(disclosureRegex, "%%%DISCLOSURE%%%");
  htmlCode = htmlCode.replace(ctaRegex, "%%%CTA%%%");

  
  return htmlCode;
  }
export default replaceRegex;