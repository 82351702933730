import React from "react";
import Accordion from "../../components/Accordion";
import PropertyInput from "../../components/PropertyInput";
import OptionGroup from "../../components/OptionGroup";
import styler from "../../design/styler";

function FlexProperties(props) {
  return (
    <>
      <OptionGroup
        options={[
          {icon: <i className="fas fa-long-arrow-alt-down"></i>, name: "", value: "column" },
          // {name:"COL-REV",  value:"column-reverse"},
          {icon: <i className="fas fa-long-arrow-alt-right"></i>, name: "", value: "row" },
          //  {name:"ROW-REV", value:"row-reverse"},
        ]}
        label="Direction"
        style={{ width: "calc(50% - 10px)", marginLeft: "10px" }}
        value={props.selection?.flexDirection}
        action={function (value) {
          styler("flex-direction", value);
        }}
      />

      <PropertyInput
        type="static"
        label="Wrap"
        style={{ width: "calc(50% - 10px)", marginLeft: "10px" }}
        value={props.selection?.flexWrap}
        action={function (value) {
          styler("flex-wrap", value);
        }}
      />

      <PropertyInput
        type="static"
        label="Flow"
        style={{ width: "calc(50% - 10px)", marginLeft: "10px" }}
        value={props.selection?.flexFlow}
        action={function (value) {
          styler("flex-flow", value);
        }}
      />

      <PropertyInput
        type="static"
        label="Justify"
        style={{ width: "calc(50% - 10px)", marginLeft: "10px" }}
        value={props.selection?.justifyContent}
        action={function (value) {
          styler("justify-content", value);
        }}
      />

      <PropertyInput
        type="static"
        label="Align Items"
        style={{ width: "calc(50% - 10px)", marginLeft: "10px" }}
        value={props.selection?.alignItems}
        action={function (value) {
          styler("align-items", value);
        }}
      />

      <PropertyInput
        type="static"
        label="Align Content"
        style={{ width: "calc(50% - 10px)", marginLeft: "10px" }}
        value={props.selection?.alignContent}
        action={function (value) {
          styler("align-content", value);
        }}
      />

      <PropertyInput
        type="static"
        label="Grow"
        style={{ width: "calc(50% - 10px)", marginLeft: "10px" }}
        value={props.selection?.flexGrow}
        action={function (value) {
          styler("flex-grow", value);
        }}
      />

      <PropertyInput
        type="static"
        label="order"
        style={{ width: "calc(50% - 10px)", marginLeft: "10px" }}
        value={props.selection?.order}
        action={function (value) {
          styler("order", value);
        }}
      />
    </>
  );
}

export default FlexProperties;
