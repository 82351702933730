import * as React from 'react';
import PropertyInput from '@components/PropertyInput';
import ActionButton from '@components/ActionButton';
import WindowFooter from '@components/WindowFooter';
import { ModalContainer } from '@components/Modal';
import { StyledOverlay } from "@components/Modal";
import FileDrop from '@components/FileDrop';
//import uploadScreenshot from '../../../network/assets/uploadScreenshot';
import styled from 'styled-components';

import uploadAsset from '../../network/assets/uploadAsset';
import { useWorkspace } from '../../views/Platform/WorkspaceContext';

const PreviewScreenshot = styled.img`
  width: 100%;
  max-height: 400px;
  object-fit: contain;
`;

const NewAssetModal = () => {
    
    const [screenshot, setScreenshot] = React.useState('');
    const [fileType, setFileType] = React.useState('');
    const [fileName, setFileName] = React.useState('');
    const {workspace, setWorkspace} = useWorkspace();
    return <><StyledOverlay id="overlay-background" style={{width:"100%", height:"100%"}} onClick={(e)=>{
      if (e.currentTarget.id === "overlay-background") {
       // cohoba.setOverlay(null);
      }
    }}ref={(e)=>{
      if (e) {
        e.parentNode.style.width = "100%";
        e.parentNode.style.height = "100%";
        e.parentNode.style.zIndex = "999999999999";
      }
    
    }}>
      <ModalContainer className="modal-confirm new-screenshot" style={{paddingBottom:"40px"}}>
        <PreviewScreenshot src={screenshot}/>
     <PropertyInput label="Screenshot URL" value={screenshot} action={value => {
        setScreenshot(value);
     }}/>
     {/* <PropertyInput label="File Type" value={fileType}/>
     <PropertyInput label="File Name" value={fileName}/> */}
    
     <FileDrop name="asset-file-drop" type="data" action={(source, type, name) => {
        setScreenshot(source);
        setFileType(type);
        setFileName(name);
     }}/>
    <WindowFooter style={{display:"flex", justifyContent:"space-between", alignItems:"center"}}>
      <ActionButton  name="cancel" knockedOut action={()=>{
          cohoba.setOverlay(null)
      }}>CANCEL</ActionButton>
    {fileType !== '' &&  <ActionButton name="upload-and-accept" action={()=>{      
     uploadAsset(document.querySelector(".asset-file-drop .file-drop-input")?.files[0], (data) => {
      console.log(data);
      setWorkspace(workspace => ({...workspace, assets: [...workspace.assets, {
        data: data.source_url,
        type: 'image',
        format: 'png',
        name: 'image',
        id: 0
      }]}))
      cohoba.setOverlay(null)
    })
   
    }}>UPLOAD AND ACCEPT</ActionButton> || <ActionButton name="accept" action={()=>{
     
      uploadAsset(document.querySelector(".asset-file-drop .file-drop-input")?.files[0], (data) => {
        console.log(data);
        setWorkspace(workspace => ({...workspace, assets: [...workspace.assets, {
          data: data.source_url,
          type: 'image',
          format: 'png',
          name: 'image',
          id: 0
        }]}))
        cohoba.setOverlay(null)
      })
    
  }}>ACCEPT</ActionButton>}
    </WindowFooter></ModalContainer>
    </StyledOverlay> </>
    }

    export default NewAssetModal;