import * as React from 'react';
import {styled} from 'goober';

const ScrubberBar = styled('div')`
  position: absolute;
  transform: translateZ(1px);
  z-index: 1;
  bottom: 0;
  width: 0%;
  height: 4px;
  background: ${props => props.playerStyle?.scrubberBackground ? props.playerStyle?.scrubberBackground : 'rgb(192, 104, 217)'};
  ${(props) => ['320x50', '320x100'].includes(props.template?.size)
    && `
      height: 2px;
    `}
  // min-width: calc(${props => props.progress}%);

  ${props => props.progressLabel === true && `
  border: 1px solid;

 

  &:after{
    content: "${props => props.progress}%";
    position: absolute;
    right: 0px;
    font-size: 12px;
    bottom: -16px;
    transform: translate(50%,0);
  }
  `}
`;

const ScrubberContainer = styled('div')`
  position: relative;
  width: 100%;
  height: 4px;
  background: #ccc;
  z-index: 1;
`;

export default function Scrubber({progress, style, playerStyle}){
    return <ScrubberContainer data-component='scrubber' key="scrubber-container" style={style}>
        <ScrubberBar key="scrubber-bar" style={{width: progress+"%"}}/>
    </ScrubberContainer>
}