import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const RulerContainer = styled.div`
  position: relative;
  width: 15px;
  height: 100%;
  overflow: hidden;
  background: rgba(255,255,255,0.8);
  user-select: none;
  top: 0;
  left: 0;
`;

const RulerMark = styled.div`
  position: absolute;
  left: 0;
  width: 6px;
  border-top: 1px solid #000;
  height: 1px;
  font-size: 6px;
  text-align: right;
  color: #000;
  border-color: rgba(0,0,0,0.1);

  &.major {
    width: 50%;
    font-size: 0px;
    padding-right: 6px;
    border-color: rgba(0,0,0,0.2);
  }
  
  .canvas-indicator &{
    border-color: rgba(0,0,0,0.4);
  }

  .canvas-indicator &.major {
    font-size: 8px;
    width: 100%;
    border-color: rgba(0,0,0,0.5);
  }

  span {
    position: absolute;
    right: 0;
    bottom: 2px;
    width: 20px;
    text-align: right;
    transform: rotate(-90deg);
  }
`;

const CanvasIndicator = styled.div`
  position: absolute;
  width: 16px;
  background-color: var(--primaryColor);
  pointer-events: none;
`;

const MouseIndicator = styled.div`
  position: absolute;
  left: 0;
  width: 16px;
  height: 1px;
  background-color: var(--accentColor);
  pointer-events: none;
`;

const MarksContainer = styled.div``;

const VerticalRuler = ({style, height, scale = 1, canvasHeight = 400, indicator = false}) => {
  const [mouseY, setMouseY] = useState(0);

  useEffect(() => {
    if (!indicator) {
      const handleMouseMove = (e) => {
        const rect = e.currentTarget.getBoundingClientRect();
        setMouseY((e.clientY - rect.top) * scale);
      };
      
      const rulerElement = document.querySelector('#frame-content');
      if (rulerElement) {
        rulerElement.addEventListener('mousemove', handleMouseMove);
        return () => rulerElement.removeEventListener('mousemove', handleMouseMove);
      }
    }
  }, [scale]);

  const marks = [];

  for (let i = 0; i < height * scale; i += 5) {
    let isMajor = i % 100 === 0;
    marks.push(
      <RulerMark 
        key={i} 
        style={{ top: `${i}px` }} 
        className={isMajor ? 'major' : ''}
      >
        {isMajor && <span>{i}</span>}
      </RulerMark>
    );
  }

  const canvasStart = (height * scale - canvasHeight * scale) / 2;
  const canvasEnd = canvasStart + canvasHeight * scale;

  return (
    <RulerContainer className="frame-content-ruler" style={{ transform: `scaleY(${1/scale})` }} style={style}>
      <MarksContainer className="marks-container">
        {marks}
        {canvasHeight !== 0 && <CanvasIndicator className='canvas-indicator' style={{ top: `${canvasStart-1}px`, height: `${canvasHeight * scale}px` }}>
          <VerticalRuler indicator={true} height={canvasHeight} canvasHeight={0} />
        </CanvasIndicator>}
       {!indicator && <MouseIndicator style={{ top: `${mouseY}px` }} />}
      </MarksContainer>
      
      {!indicator && <div style={{ position: 'absolute', left: '100%', bottom: `${canvasEnd-1}px`, fontSize:'8px', borderTop:"1px solid var(--primaryColor)", width:"100%", lineHeight:'22px', textAlign:"right"}}>{canvasHeight * scale}</div>}
      {!indicator && <div style={{ position: 'absolute', left: '100%', bottom: `${canvasStart-1}px`, fontSize:'8px', borderTop:"1px solid var(--primaryColor)", width:"100%", lineHeight:'22px', textAlign:"right"}}></div>}
    </RulerContainer>
  );
};

export default VerticalRuler;