import updateMarkup from "./updateMarkup";

export default function (event) {

  // use event.originalEvent.clipboard for newer chrome versions
  var items = (event.clipboardData  || event.originalEvent.clipboardData).items;
  // find pasted image among pasted items
  var blob = null;

  console.log(items);

  for (var i = 0; i < items.length; i++) {
    if (items[i].type.indexOf("image") === 0) {
      blob = items[i].getAsFile();
    }else if(items[i].type.indexOf("text/html") === 0 && 
        window.cohoba.selection && 
        window.cohoba.selection.object &&
     //   !window.cohoba.CodeEditor.hasFocus() && 
        document.activeElement.tagName !== "TEXTAREA" && 
        document.activeElement.type !== "text")
    {
      let textHtml = event.clipboardData.getData('text/html');
      let htmlContainer = document.createElement("div");
      htmlContainer.innerHTML = textHtml;
      let scrub = htmlContainer.querySelectorAll("meta, link");
      for(var x=0;x<scrub.length;x++){
        scrub[x].remove();
      }
   
    //  window.cohoba.workspaceElement.innerHTML = htmlContainer.innerHTML;
      //updateSource();
    //  window.cohoba.workspace.inspect = inspect();
      return;
    }
  }
  // load image if there is a pasted image
  if (blob !== null) {
    var reader = new FileReader();
    reader.onload = function(event) {

      let dataUrl = event.target?.result;
      let assetId = Math.random().toString().split(".")[1].slice(0,8);
      let assetName = "Clipboard #"+assetId;
      let assetObject = {
        id: assetId,
        name: assetName, 
        data: dataUrl, 
        type: 'image', 
        mimeType: 'image/png', 
        fileName: assetName+'.png'
      };

      let layoutElement = {
        component: "img",
        attributes: {
          src: `{{ASSET_${assetName.toUpperCase()}}}`
        }
      };

      if (window.cohoba.currentFrame?.cn) {
        if (window.cohoba.selection?.layout) {

          window.cohoba.selection.cn.push(layoutElement);
          window.cohoba.updateWorkspace({
            layouts:  window.cohoba.workspace.layouts,
            assets: [assetObject, ...window.cohoba.workspace.assets]
          });
        } else {
        window.cohoba.workspace.layouts[window.cohoba.frame].cn.push(layoutElement);
        window.cohoba.updateWorkspace({
          layouts:  window.cohoba.workspace.layouts,
          assets: [assetObject, ...window.cohoba.workspace.assets]
        });
      }
    }else {
      const img = new Image();
            img.className = "pasted-image";
        
            img.style.top = '50px';
            img.style.left = '50px';
            img.style.position = 'absolute';
            img.style.objectFit = 'cover';
           // img.dataset.src = `{{ASSET_${assetId}}}`;
            img.dataset.src = `ASSET_${assetId}`;
            img.style.maxWidth = '100%';
           // img.src = '{{'+img.dataset.src+'}}';
            img.src = dataUrl;


         
           
            img.addEventListener('load', (e)=>{
              let img = e.currentTarget;
              let width = img.naturalWidth/2;
              let height = img.naturalHeight/2;
              let ratio = width/height;
              
              if (width > cohoba.workspace.size.at(0)) {
                width = cohoba.workspace.size.at(0);
                height = width * ratio;
              }

              img.style.width = width+'px';
              img.style.height = height+'px';
              img.style.maxWidth = '';
              img.src = '{{'+img.dataset.src+'}}';

              updateMarkup(window.cohoba.workspaceElement.innerHTML, window.cohoba.workspace.assets);

              window.cohoba.updateWorkspace({
            //    render:  window.cohoba.workspaceElement.innerHTML,
                assets: [assetObject, ...window.cohoba.workspace.assets],
                layouts: [...window.cohoba.workspace.layouts]
              });
            });

            window.cohoba.workspaceElement.appendChild(img);

          }
        }
        reader.readAsDataURL(blob);

      }
}