import ErrorModule from "../../errorModule";
import layoutLibrary from "../../layoutLibrary";

/*
  - Find layout from list
  - Find stylesheet from library
  - Render default layout with orientation prop
*/

export function compareSizes(parentBox): {
  biggestSize: string;
  matchingSizes: string[];
} {

  const fitThreshold = 25;

  const matchingSizes = Object.values(layoutLibrary).filter((layout) => {
    if (layout.name !== 'interstitial' && layout.name !== 'infeed') {
      const { width, height } = layout.attributes;
      const fitsWidth = parentBox.width+fitThreshold >= width;
      const fitsHeight = parentBox.height+fitThreshold >= height;
      return fitsWidth && fitsHeight;
    }

    return false;
  });

  // matchingSizes.sort((a, b) => {
  //   const aSize = parseSize(a);
  //   const bSize = parseSize(b);
  //   return bSize.width - aSize.width || bSize.height - aSize.height;
  // });


  matchingSizes.sort((a, b) => {
    const aSize = {width: a.attributes.width, height: a.attributes.height};
    const bSize = {width: b.attributes.width, height: b.attributes.height};
  
    const areaFactorA = aSize.width * aSize.height;
    const areaFactorB = bSize.width * bSize.height;
  
    // Compare by area factor first
    if (areaFactorB !== areaFactorA) {
      return areaFactorB - areaFactorA;
    }
  
    // If area factors are equal, then compare by width and height
    return bSize.width - aSize.width || bSize.height - aSize.height;
  });

  

  const biggestSize = matchingSizes.at(0);

  let biggestArea = {layout: "320x50", area: 0};

  matchingSizes.forEach(layout => {
    const {width, height} = layout.attributes;
    let area = width*height;

    if (biggestArea.area < area) {
      biggestArea.area = area;
      biggestArea.layout = layout;
    }
  })


  return { biggestSize, matchingSizes, biggestArea };
}

function findMaxAreaObject(obj1, obj2, obj3) {

  // Extract area values from each object
  const area1 = obj1?.area || 0;
  const area2 = obj2?.area || 0;
  const area3 = obj3?.area || 0;

  // Find the maximum area among the three
  const maxArea = Math.max(area1, area2, area3);


  // Determine which object has the maximum area
  if (maxArea === area1) {
    return obj1;
  } else if (maxArea === area2) {
    return obj2;
  } else {
    return obj3;
  }
}

/**
 * Finds the most suitable ad size based on the dimensions of the parent container and the current viewport.
 * @returns {string} The most suitable ad size. It will return in the format "widthxheight".
 */
export function getLayoutSize(width, height, parentContainer: Element | null): object {
  if (!width) { width = 0}
  if (!height) { height = 0}

  const viewportSize = !parentContainer ? { width: window.innerWidth, height: window.innerHeight } : { width: 0, height: 0};
  let frameBiggestSize, viewportBiggestSize, parentBiggestSize;

  try {
    frameBiggestSize = compareSizes({width:width, height: height});
  }catch(e){
    ErrorModule.capture({...e, stack: "getLayoutSize.frameBiggestSize : "+e.stack})
  }

  try{
    viewportBiggestSize = compareSizes(viewportSize);
  }catch(e){
    ErrorModule.capture({...e, stack: "getLayoutSize.viewportBiggestSize : "+e.stack})
  }

  if (!parentContainer) {
    parentContainer = (document.currentScript?.parentNode as Element) || window.document.body;
  } 

  if (parentContainer?.clientHeight === 0) {
    parentContainer = document.body;
  }

  // Find parent container
  if (window.frameElement) {
    // We're in a friendly iframe
    parentContainer = window.frameElement as Element;
  }

  // if (viewportSize.width === 1 && viewportSize.height === 1) {
  //   return '1x1';
  // }

  // Using a bunch of conditionals while we build better logic to find IAB sizes vs flex sizes
  try{
    if (parentContainer) {
      const parentBox = parentContainer.getBoundingClientRect();
      // parentBox.width = viewportSize.width;
      // parentBox.height = viewportSize.height;
      
      // if (parentBox.width <= 1 && parentBox.height <= 1) {
        //   return '1x1';
        // }
        
        parentBiggestSize = compareSizes(parentBox);
        // return biggestSize;
      }
    }catch(e){
      ErrorModule.capture({...e, stack: "getLayoutSize.compareParentSizes "+e.stack})
    }
      try{
        const biggestLayout = findMaxAreaObject(viewportBiggestSize.biggestArea, frameBiggestSize.biggestArea, parentBiggestSize.biggestArea);
        return biggestLayout.layout;
      }catch(e){
        ErrorModule.capture({...e, stack: "findMaxAreaObject : "+e.stack});
        // Temporary
        return layoutLibrary['300x250'];
      }
  
}

export default getLayoutSize;
