
async function updateUserMeta(userId, metaUpdates, token) {
    const apiUrl = `https://rallyfrontera.do/wp-json/wp/v2/users/${userId}`; // Replace with your site's URL
    const authToken = token; // Replace with your actual auth token or nonce

    const data = {
        meta: metaUpdates // Here we use the object directly as meta data
    };

    try {
        const response = await fetch(apiUrl, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${authToken}` // or 'X-WP-Nonce': authToken for nonce
            },
            body: JSON.stringify(data)
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const jsonResponse = await response.json();
        console.log('User meta updated:', jsonResponse);
        return jsonResponse;
    } catch (error) {
        console.error('There was an error updating the user meta:', error);
    }
}

export default updateUserMeta;