import * as React from "react";
import ActionButton from "../components/ActionButton";
import PropertyInput from "../components/PropertyInput";
import {Box, Flex} from 'rebass';
import {Checkbox} from '@rebass/forms'

export default function LoginComponent({user, setUser}) {
  

const [loggedIn, setLoggedIn] = React.useState(false);
const [username, setUsername] = React.useState('');
const [password, setPassword] = React.useState('');


  const userLogin = () => {
   
   fetch(process.env.API_ENDPOINT+'/?rest_route=/simple-jwt-login/v1/auth',
   {
    method:"POST",
    headers:{
     // 'Authorization': 'Bearer '+ token,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      username: username,
      password: password
    })}).then(r => r.json()).then(data => {
     setUser(user => ({...user, token: data.data.jwt, username: username}));
    }).catch(e =>{
      console.error(e);
    })
    
    }


    return <section>

       {loggedIn &&
  
      
      <ActionButton
      className="log-out"
      action={ () => {
        logout();
      }}
      >
        Log-Out
      </ActionButton>
      }
      
      {!loggedIn &&
     <> <PropertyInput
        key="username"
        type="static"
        label="User"
        name="username"

        placeholder="User"
        action={(value) =>{
          console.log(value, username)
          setUsername(value);
        }}
        />

      <PropertyInput
        type="static"
        key="password"
        password
        label="Password"
        name="password"
        placeholder="Password"
        style={{margin:"0 0 16px"}}
        returnAction={userLogin}
        action={(value) =>{
          setPassword(value);
        }}
        />

        <Flex margin="0 0 16px" fontSize="12px" alignItems="center">

        <Checkbox></Checkbox> Keep session
        </Flex>
        <Flex alignItems="center" margin="0 0 16px" justifyContent="center">
        <ActionButton 
        style={{marginRight:"16px", minWidth: "100px", lineHeight:"35px", background:"#973ab2", fontWeight:"600", color:"#fff", boxShadow:"0 0 8px rgba(0 0 0 0.4)"}}
          className="submit" 
          action={ () => {
            userLogin();
          }}
          >
          Submit
        </ActionButton>
        <Box fontSize="12px" textAlign="right">Reset Password</Box>
        </Flex>

        <Flex justifyContent="center" fontSize="12px" textDecoration="underline">
          <Box padding="0 8px">Terms of Service</Box>
          <Box padding="0 8px">Privacy Policy</Box>
        </Flex>

  
   
        </>
      
}
    

     </section>
    
  
}