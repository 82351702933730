import React from "react";
import Accordion from "../../components/Accordion";
import PropertyInput from "../../components/PropertyInput";
import OptionGroup from "../../components/OptionGroup";
import styler from "../../design/styler";
import {Flex} from 'rebass';
import {ActionButton} from '@components';
function AttributesModule({workspace, selection}) {
    const setName = (name) => {
        window.cohoba.selection.object.setAttribute('name', name) ;
    }
    const setId = (id) => {
        window.cohoba.selection.id = id;
    }

  return (
    <><Accordion name="attributes" icon={<i class="far fa-list-alt"></i>} title={<Flex className="accordion-title-label">Identifiers<ActionButton action={e => {
      e.currentTarget.classList.toggle('toggled');
    }}name="maximize-button"><i className="far fa-square"></i><i className="fas fa-square"></i></ActionButton></Flex>}>
        <PropertyInput label="component" value={selection?.component} action={setName}/>
        <PropertyInput label="type" value={selection?.type} action={(value)=>{cohoba.selection.object.setAttribute('type',value)}}/>
        <PropertyInput label="Name" value={selection?.name} action={setName}/>

      <PropertyInput label="Id" value={selection?.id} action={(value)=>{
        cohoba.selection.object.setAttribute('id',value);
        cohoba.workspace.render = cohoba.workspaceElement.innerHTML;
      
      }
    }/>
      <PropertyInput label="Classes" value={selection?.className} action={(value)=>{cohoba.selection.setAttribute('className',value)}}/>
      <PropertyInput label="fingerprint" value={selection?.fingerprint} action={(value)=>{cohoba.selection.setAttribute('fingerprint',value)}}/>
      <PropertyInput label="Index" value={selection?.index} action={(value)=>{cohoba.selection.setAttribute('index',value)}}/>
      <PropertyInput label="Parent" value={selection?.parent} action={(value)=>{cohoba.selection.setAttribute('parent',value)}}/>
    </Accordion></>
  );
}

export default AttributesModule;
