// Move to setupModule

import React from 'react';
import componentLibrary from '../../componentLibrary';
//import patternLibrary from '../../patternLibrary';

const formatOnLoad = (e) => {
  if (window.render_params){
    window.render_params.finishTime = Date.now();
  }
}

export default function MapChildNodes(layout, identifier, components){
  if (!identifier) {
    identifier = 'cohoba';
  }
  if (!components) {
    components = componentLibrary;
  }
      return layout.cn.map((child)=>{
           if(typeof child === "string"){
             return child;
           }
           let componentId = child.component || 'box';

           const nodeId = Math.random().toString().split(".")[1].slice(0,6);
   
           //Create the children subtree for this element if it has children
           if(child.cn){
               let children = MapChildNodes(child, identifier, components);
              return React.createElement(components[componentId], {...child.attributes, identifier: identifier, className: child.attributes?.className, key: Math.random(), 'data-fp': child.fingerprint}, children);
           } else {
             
            return React.createElement(components[componentId], {...child.attributes, identifier: identifier, className: child.attributes?.className, key: Math.random(), 'data-fp': child.fingerprint});
           }
       })
   }