import * as React from 'react';
import styled from 'styled-components';
import {Flex, Box} from 'rebass';
import {Window, WindowHeader, WindowBody, WindowFooter, OptionList, ActionButton} from '@components';

import creativeLibrary from './creativeLibrary.json';
//import CreativeWizard from './creative-wizard/CreativeWizard';
import CreativeOption from './creatives-module/CreativeOption';

import { getCreatives } from '../network/creative/getCreatives';
 import { useWorkspace } from '../views/Platform/WorkspaceContext';
 import NewAssetModal from './assets-module/NewAssetModal.jsx';
import AssetItem from './assets-module/AssetItem';
import uploadAsset from '../network/assets/uploadAsset';
import convertDataToBinary from '../utilities/convertDataToBinary';
import emojis from './emojis.json';
import { Accordion } from '../components';
import RegistrationView from './components/RegistrationView';
import createRenderRoot from '../interactive/setupModule/createRenderRoot';

const StyledWindow = styled(Window)`
// top: 46px !important;
// transform: none !important;
// left: 46px !important;
// border-radius: 0 !important;
// bottom: 0 !important;
// height: auto !important;
// max-height: unset !important;
// width: 240px !important;
// min-width: 240px !important;
`

const CreativeList = styled(Flex)`
	flex-wrap: wrap;
`;

const CreativeListItem = styled.div`
	width: 25%;
	padding: 0 8px;
	box-sizing: border-box;
`;

const LibraryWindow = (props) => {
	
	//const window = React.useRef();

	//const workspace = props.workspace;
	const {workspace, setWorkspace} = useWorkspace();

		let updateStyles = props.updateStyles;

		const creativeOptions = Object.keys(creativeLibrary).map(key => {
			
			return <CreativeListItem><CreativeOption key={key} assets={creativeLibrary[key]} onClick={(e)=>{
	
				//window.cohoba.assets = creativeLibrary[key];
				cohoba.updateWorkspace({assets: creativeLibrary[key]})
			}}/></CreativeListItem>
		});

		const creativeList = props.creatives.map(creative => {
			const assets = [
				{asset_id: 9, name:"cta", data: creative.cta},
				{asset_id: 1, name:"image", data: creative.image},
				{asset_id: 2, name:"headline", data: creative.title},
				{asset_id: 3, name:"caption", data: creative.description},
				{asset_id: 4, name:"logo", data: creative.icon},
				{asset_id: 5, name:"link", data: creative.link},
				{asset_id: 6, name:"advertiser", data: creative.sponsored}
			]
			

			return <CreativeListItem><CreativeOption key={creative.crid} crid={creative.crid} assets={assets} onClick={(e)=>{
				//window.cohoba.assets = assets;
				cohoba.updateWorkspace({assets: assets})
			}}/></CreativeListItem>
		
		});


		return <StyledWindow    
		name="library"
        ref= {props.windowRef}
 
		type={"panel"}
        size= {[360, 480]}
		>
			<WindowHeader style={{justifyContent:"space-between"}}>
				<Box fontWeight="bold" fontSize="14px">Assets</Box>
				<OptionList options={[{name:"Sort", value:"0"}]}/>
			</WindowHeader>
			<WindowBody style={{padding:"0 8px"}}>
			<Flex style={{display: 'none', padding:"8px 0", width:"100%", alignItems:"center", paddingBottom:"12px"}}>
      <Box style={{flexGrow: 1, fontWeight: "900"}}>Assets <ActionButton style={{margin:"0 0 0 16px"}} action={()=>{
					// cohoba.setOverlay(<CreativeWizard creatives={props.creatives} workspace={props.workspace} setCreatives={props.setCreatives} stylesheets={props.stylesheets} user={props.user} />)
				}}><i className="fas fa-plus"></i>NEW</ActionButton></Box>
	  {/* <PropertyInput type="static" style={{flexGrow: 1, padding:"0 8px"}} placeholder={"Search creatives..."} value={""} action={(value)=>{
	   setPubQuery(value);
	  }}/> */}
	  <Flex alignItems="center">
	  <Box paddingRight="16px">Sort by: </Box>
	  <OptionList style={{minWidth:"100px", background:"transparent"}}  name="Sort By" value={"date"} action={(value)=>{
		  setSortBy(value);
		}} options={[
			{name: "Date Modified", value: "date"},
			{name: "Scored", value: "native-score"},
			{name: "Creation", value: "creation-date"},
			{name: "DT Impressions", value: "dt-impressions"},
			{name: "Issue Count", value: "issue-count"},
		]}/>
		</Flex>
	  </Flex>
				{/* <CreativeList>

			{creativeOptions}
			{creativeList}
				</CreativeList> */}
				<Box className="asset-list">

				{workspace?.assets && workspace.assets.map((ast, index) => {
					return <AssetItem asset={ast} index={index} onClick={(e)=>{
						e.currentTarget.classList.toggle('selected')
					}} onDblClick={(e) => {
						alert("Clicked twice");
					}}/>
				})}
				</Box>

				<Accordion title="EMOJI">

				<Flex flexWrap="wrap" fontSize="24px">
					{emojis.map(e => <Box draggable="true" onDragStart={(e)=>{e.dataTransfer.setData("emoji", e.currentTarget.textContent);
          console.log(e);
		}} onDragEnd={(e)=>{}}>{e}</Box>)}
				</Flex>
		</Accordion>
		<Accordion title="COMPONENTS">
			<ActionButton action={()=>{
				let anchor = document.createElement('span');
				cohoba.workspaceElement.appendChild(anchor);
				let root = createRenderRoot(anchor);
				root.render(<RegistrationView />)
				// A bit of a hack to wait for rendering to complete
setTimeout(() => {
    const node = anchor.firstChild;
	cohoba.workspaceElement.appendChild(node?.cloneNode(true));
	anchor.remove();
}, 100);
				
			}}>Registration View</ActionButton>
			</Accordion>
			</WindowBody>
			<WindowFooter>
			<ActionButton action={() => {
				// Find highlighted assets
				let pastedAssets = workspace.assets.filter(a => a.data.includes('data:image'));
				let selectedAssets = document.querySelectorAll(".asset-list .selected");
					let filterIndexes = [];
					selectedAssets.forEach(a => {
						let i = parseInt(a.getAttribute("data-index"));
						filterIndexes.push(i);
					});

				if (pastedAssets.length > 0) {
					pastedAssets.forEach(a => {
						// Convert base64 to binary for upload to api
						let binaryData = convertDataToBinary(a.data, a.fileName);
						// Upload all clipboard data to server (for images)
						uploadAsset(binaryData, (result) => {
						workspace.assets.find(asset => asset.name === a.name).data = result.source_url;
						setWorkspace(workspace);
						});
     			 });
				}
				}}>UPLOAD</ActionButton>

				<ActionButton action={() => {
					cohoba.setOverlay(<NewAssetModal />)
				}}>NEW</ActionButton>
				<ActionButton action={() => {
					cohoba.setOverlay(<NewAssetModal />)
				}}>CLONE</ActionButton>
				<ActionButton action={() => {
					cohoba.setOverlay(<NewAssetModal />)
				}}>DOWNLOAD</ActionButton>
				<ActionButton action={(e) => {
					let selectedAssets = document.querySelectorAll(".asset-list .selected");
					let filteredAssets = [...workspace.assets];
					let filterIndexes = [];
					selectedAssets.forEach(a => {
						let i = parseInt(a.getAttribute("data-index"));
						filterIndexes.push(i);
						console.log(i);
					});
					filteredAssets = filteredAssets.filter((as, ai) => {
						return filterIndexes.includes(ai) ? false : true;
					})
				
					
				//	setWorkspace(window.cohoba.workspace);
					window.cohoba.updateWorkspace({assets: filteredAssets})
				}}>DELETE</ActionButton>
			</WindowFooter>
		</StyledWindow>
	
}
export default LibraryWindow;