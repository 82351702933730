import * as React from 'react';
import styled from 'styled-components';
import {Box, Flex} from 'rebass';
import ActionList from '../../components/ActionList';
//import { deleteCreative } from '../creative-wizard/CreativeWizard';
import Modal from '../../components/Modal';

const MediaThumbnail = styled(Box)`
width: 100%;
min-width: 80px !important;
height: 200px;
min-height: 80px !important;
	border-radius: 8px;
	border-: 1px solid #dedede;
	background: url('${props => props.image}') no-repeat center / cover;
	margin-bottom: 12px !important;
	border: 1px solid rgba(255,255,255,0.25);
`;

const CreativeOptionContainer = styled(Flex)`
flex-direction: column;
background: rgba(0,0,0,0.25);
font-size: 12px;
width: 100%;
padding: 8px;
border-radius: 4px;
`;

const CreativeId = styled(Box)`
	width: 100%;

`;
const CreativeLogo = styled(Box)`
	width: 45px;
	height: 45px;
	background: #fff url('${props => props.logo}') no-repeat center / contain;
`;
const CreativeLink = styled(Box)`flex-grow: 1; font-size: 11px;`;
const AdvertiserName = styled(Box)`padding-left: 16px;`;
const CreativeHeadline = styled(Box)`font-size: 16px;`;
const CreativeCaption = styled(Box)`padding: 12px 0;`;
const CreativeCallToAction = styled(Box)`background: #666; color: #fff; padding: 8px; border-radius: 4px;
	text-align: center; font-weight: bold;
`;

const CreativeMenu = styled(ActionList)`
& .action-list-button{
	background-color: transparent;
	padding: 0;
	border: none;
 }
`;


const CreativeOption = ({crid, assets, onClick}) => {

	//const crid = assets.find(a => a.name === "image")?.data;
	const link = assets.find(a => a.name === "link")?.data;
	const image = assets.find(a => a.name === "image")?.data;
	const logo = assets.find(a => a.name === "logo")?.data;
	const disclosure = assets.find(a => a.name === "disclosure")?.data;
	const advertiser = assets.find(a => a.name === "advertiser")?.data;
	const headline = assets.find(a => a.name === "headline")?.data;
	const caption = assets.find(a => a.name === "caption")?.data;
	const cta = assets.find(a => a.name === "cta")?.data;

	return <CreativeOptionContainer onClick={onClick}>
		<Flex alignItems="center" paddingBottom="8px">
		<CreativeId>CRID: {crid}</CreativeId>
		<CreativeMenu icon={<div/>} label={<i className="fas fa-ellipsis-h"/>} actions={[
			{name: "Preview Tag", action:()=>{
				let iframe = document.createElement("iframe");
				iframe.style = "width: 300px; height: 250px;";
				iframe.srcdoc = `<script src="https://cdn.4dvertible.com/native/adtg.js?crid=${crid}"><\/script>`;
				cohoba.setOverlay(<Modal onConfirm={()=>{cohoba.setOverlay(null)}}><iframe style={{width: "300px", height:"250px"}} srcDoc={`<div><script src="https://cdn.4dvertible.com/native/adtg.js?crid=${crid}"><\/script><\/div>`}/></Modal>);
			}},
		//	{name: "View", action:()=>{}},
			{name: "Edit", action:()=>{}},
			{name: "Clone", action:()=>{}},
			{name: "Delete", action:()=>{
				const deleteConfirm = confirm("You sure?");
				if (deleteConfirm){
					const confirmAgain = confirm("Like, super sure? This can't be undone.");
					if (confirmAgain){
					//	deleteCreative({crid: crid}, (data)=> {console.log(data)});
					}
				} else {
					return false;
				}
			}}
		]}/>
</Flex>
		<MediaThumbnail image={image}/>
		<Box flexGrow={1}>
			<Flex alignItems="center" padding="8px 0">
			<CreativeLogo logo={logo}/>
			<AdvertiserName>{advertiser}</AdvertiserName>
			</Flex>

			<CreativeHeadline>{headline}</CreativeHeadline>
			<CreativeCaption>{caption}</CreativeCaption>
			<Flex alignItems="center">
			<CreativeLink>{link}</CreativeLink>
			<CreativeCallToAction>{cta}</CreativeCallToAction>
			</Flex>

			{/* <Box>{disclosure}</Box> */}
		</Box>
	</CreativeOptionContainer>
}

export default CreativeOption;