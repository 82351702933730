import Layout0x0 from '../layouts/base/0x0.json';
import Layout160x600 from '../layouts/base/160x600.json';
import Layout300x250 from '../layouts/base/300x250.json';
import Layout360x300 from '../layouts/base/360x300.json';
import Layout300x600 from '../layouts/base/300x600.json';
import Layout320x50 from '../layouts/base/320x50.json';
import Layout320x100 from '../layouts/base/320x100.json';
import Layout320x480 from '../layouts/base/320x480.json';
import Layout728x90 from '../layouts/base/728x90.json';
import Layout970x250 from '../layouts/base/970x250.json';
import Layout640x480 from '../layouts/base/640x480.json';
import Infeed from '../layouts/base/infeed.json';

 import Layout250x250 from '../layouts/base/250x250.json';
// import Layout200x200 from '../layouts/base/200x200.json';
// import Layout336x280 from '../layouts/base/336x280.json';
// import Layout120x600 from '../layouts/base/120x600.json';
// import Layout970x90 from '../layouts/base/970x90.json';
// import Layout300x100 from '../layouts/base/300x100.json';
// import Layout180x150 from '../layouts/base/180x150.json';
// import Layout970x66 from '../layouts/base/970x66.json';
// import Layout970x415 from '../layouts/base/970x415.json';
// import Layout300x50 from '../layouts/base/300x50.json';
// import Layout250x360 from '../layouts/base/250x360.json';
// import Layout580x400 from '../layouts/base/580x400.json';
// import Layout468x60 from '../layouts/base/468x60.json';

import LayoutInterstitial from '../layouts/base/interstitial.json';

//import LinkedInInsterstitial from '../layouts/linkedin_interstitial.json';
//import MusiInsterstitial from '../layouts/musi_interstitial.json';


export const layoutLibrary = {
 'social' : {
   name: 'SocialPost',
   attributes:{width:1024, height:1024},
   cn: [
     {component:'box', cn:[
       {component:'box', cn:[''], attributes:{style:{width:'100px', height:'100px', position:'absolute', top:0, left:0, background: 'red'}}},
       {component:'box', cn:[''], attributes:{style:{width:'100px', height:'100px', position:'absolute', top:'100px', left:'100px', background: 'blue'}}},
       {component:'box', cn:[''], attributes:{style:{width:'100px', height:'100px', position:'absolute', top:0, left:'100px', background: 'green'}}},
       {component:'box', cn:[''], attributes:{style:{width:'100px', height:'100px', position:'absolute', top:'100px', left:0, background: 'yellow'}}}
     ]}
   ]
 }
}

export default layoutLibrary;
