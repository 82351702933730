function extractHtmlFromGrok(response) {
    const htmlRegex = /```html([\s\S]*?)```/;
    const match = response.match(htmlRegex);

    if (match && match[1]) {
        // Trim any leading or trailing whitespace
        let htmlContent = match[1].trim();
        
        // Remove leading newlines or carriage returns
        htmlContent = htmlContent.replace(/^\s*\n/g, '');
        
        // Optionally, you might want to clean up any excess whitespace or formatting
        // For example, this would remove leading/trailing whitespace from each line
        htmlContent = htmlContent.split('\n').map(line => line.trim()).join('\n');
        
        return htmlContent;
    } else {
        return null; // No HTML code snippet found
    }
}


export const contactYaya = (message) => {
   
    fetch('http://192.168.1.154:3000/yaya',{
        method: 'POST',
        body: JSON.stringify({
            //model: 'grok-vision-beta',
            message: message,
            // "max_tokens": 5000,
            // "temperature": 0.5
        }),
        headers: {
            'Content-Type': 'application/json'
        }
    }).then(r => r.json()).then(d => {
        let content = d.result?.choices?.at(0)?.message?.content;
        let html = content.includes('```html') ? extractHtmlFromGrok(content) : '';
        if (html !== '') {
            cohoba.workspaceElement.innerHTML = html;
        }
        cohoba.newMessage({title:"Yaya", content: content});
       console.log(content)
    }).catch(console.error)
}