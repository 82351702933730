import React from "react";
import styler from "../../design/styler";
import {Flex} from 'rebass';
import {ActionButton, PropertyInput, ColorMixer, OptionList, OptionGroup, Accordion} from "@components";
import { BorderShape, BorderStyle, BoxShadow, FillColor, FontList, TextShadow } from "../quickStylers/QuickStylers";


export default class TypesettingComponent extends React.Component {
  render() {

    return (
      <Accordion icon={<i className="fas fa-paragraph"></i>} title={<Flex className="accordion-title-label">Typesetting<ActionButton action={e => {
        e.currentTarget.classList.toggle('toggled');
      }}name="maximize-button"><i className="far fa-square"></i><i className="fas fa-square"></i></ActionButton></Flex>}>

     <FontList value={this.props.selection?.fontFamily} fontFamilies={[
      {family:'Lexend', category:'sans-serif', weights:[], src:'https://fonts.googleapis.com/css?family='},
      {family:'Merriweather', category:'serif', weights:[], src:'https://fonts.googleapis.com/css?family='},
      {family:'Poppins', category:'sans-serif', weights:[], src:'https://fonts.googleapis.com/css?family='},
      {family:'Nunito', category:'sans-serif', weights:[], src:'https://fonts.googleapis.com/css?family='},
    
      {family:'Roboto', category:'sans-serif', weights:[], src:'https://fonts.googleapis.com/css?family='},
      {family:'Recoleta', category:'sans-serif', weights:[], src:'https://fonts.googleapis.com/css?family='},
     
      {family:'monospace', category:'sans-serif', weights:[], src:'https://fonts.googleapis.com/css?family='},

      {
        "family": "Roboto",
        "category": "sans-serif",
        "weights":[100, 300, 400, 500, 700, 900]
      },
      {
        "family": "Open Sans",
        "category": "sans-serif",
        "weights":[300, 400, 600, 700, 800]
      },
      {
        "family": "Lato",
        "category": "sans-serif",
        "weights":[100, 300, 400, 700, 900]
      },
      {
        "family": "Montserrat",
        "category": "sans-serif",
        "weights":[100, 200, 300, 400, 500, 600, 700, 800, 900]
      },
      {
        "family": "Merriweather",
        "category": "serif",
        "weights":[300, 400, 700, 900]
      },
      {
        "family": "Source Sans Pro",
        "category": "sans-serif",
        "weights":[200, 300, 400, 600, 700, 900]
      },
      {
        "family": "Oswald",
        "category": "sans-serif",
        "weights":[200, 300, 400, 500, 600, 700]
      },
      {
        "family": "Noto Sans",
        "category": "sans-serif",
        "weights":[100, 200, 300, 400, 500, 600, 700, 800, 900]
      },
      {
        "family": "Raleway",
        "category": "sans-serif",
        "weights":[100, 200, 300, 400, 500, 600, 700, 800, 900]
      },
      {
        "family": "Poppins",
        "category": "sans-serif",
        "weights":[100, 200, 300, 400, 500, 600, 700, 800, 900]
      },
        {
          "family": "Arial",
          "category": "sans-serif",
          "weights":[100, 200, 300, 400, 500, 600, 700, 800, 900]
        },
        {
          "family": "Helvetica",
          "category": "sans-serif",
          "weights":[100, 200, 300, 400, 500, 600, 700, 800, 900]
        },
        {
          "family": "Times New Roman",
          "category": "serif",
          "weights":[100, 200, 300, 400, 500, 600, 700, 800, 900]
        },
        {
          "family": "Courier New",
          "category": "monospace",
          "weights":[400, 700]
        },
        {
          "family": "Georgia",
          "category": "serif",
          "weights":[100, 200, 300, 400, 500, 600, 700, 800, 900]
        },
        {
          "family": "Verdana",
          "category": "sans-serif",
          "weights":[100, 200, 300, 400, 500, 600, 700, 800, 900]
        },
        {
          "family": "Trebuchet MS",
          "category": "sans-serif",
          "weights":[400, 700]
        },
        {
          "family": "Tahoma",
          "category": "sans-serif",
          "weights":[400, 700]
        },
        {
          "family": "Palatino",
          "category": "serif",
          "weights":[400, 700]
        },
        {
          "family": "Garamond",
          "category": "serif",
          "weights":[400, 700]
        },
        {
          "family": "Bookman Old Style",
          "category": "serif",
          "weights":[400, 700]
        },
        {
          "family": "Comic Sans MS",
          "category": "cursive",
          "weights":[400, 700]
        },
        {
          "family": "Impact",
          "category": "sans-serif",
          "weights":[400, 700]
        },
        {
          "family": "Lucida Console",
          "category": "monospace",
          "weights":[400]
        },
        {
          "family": "Monaco",
          "category": "monospace",
          "weights":[400]
        }
      
      ]} onFontSelect={(value)=>{styler('font-family',value)}}/>
     {/* <BoxShadow value={this.props.selection?.boxShadow} action={(value)=>{styler('box-shadow',value)}}></BoxShadow>
     <TextShadow value={this.props.selection?.textShadow} action={(value)=>{styler('text-shadow',value)}}></TextShadow> */}
      {/* <FillColor value={this.props.selection?.background} action={(value)=>{styler('background',value)}}/> */}
      {/* <BorderShape value={this.props.selection?.borderRadius} action={(value)=>{styler('border-radius',value)}}/>
      <BorderStyle value={this.props.selection?.border} action={(value)=>{styler('border',value)}}/> */}

        <div
          style={{
            display: "inline-block",
            verticalAlign: "bottom",
            width: "calc(32% - 10px)",
            marginLeft: "10px"
          }}
        >
          <OptionList
            label="Weight"
            style={{ width: "100%" , display:"inline-block"}}
            value={this.props.selection?.fontWeight}
            options={[
              { name: "100", value: "100", icon: "Lighter" },
              { name: "200", value: "200", icon: "Lighter" },
              { name: "300", value: "300", icon: "Light" },
              { name: "400", value: "400", icon: "Regular" },
              { name: "500", value: "500", icon: "Medium" },
              { name: "600", value: "600", icon: "Bold" },
              { name: "700", value: "700", icon: "Bolder" },
              { name: "900", value: "900", icon: "Black" },
            ]}
            action={function (value) {
              styler("font-weight", value);
              //  updateValue({fontWeight : parseInt(value)});
            }}
          />
        </div>

        <PropertyInput
          value={this.props.selection?.fontSize}
          label="Size"
          style={{ width: "33%" , display:"inline-block"}}
          id="font-size"
          unit="px"
          action={function (value) {
            styler("font-size", value);
            // value = value.split("px")[0];
            //updateValue({fontSize : value});
          }}
        />

<OptionGroup
          style={{ width: "calc(40%)", display: "inline-block" }}
          label="Align"
          value={this.props.selection?.textAlign}
          columns={4}
          options={[
            {
              name: "",
              value: "left",
              icon: <i className="fas fa-align-left"></i>,
            },
            {
              name: "",
              value: "center",
              icon: <i className="fas fa-align-center"></i>,
            },
            {
              name: "",
              value: "right",
              icon: <i className="fas fa-align-right"></i>,
            },
          ]}
          action={function (value) {
            styler("text-align", value);

            //  updateValue({textAlign : value});
          }}
        />

        <PropertyInput
          style={{ width: "calc(33% - 4px)", marginLeft: "10px" , display:"inline-block"}}
          id="line-height"
          label="Leading"
          unit="em"
          action={function (value) {
            styler("line-height", value);
            value = value.split("px")[0];
            value = value.split("%")[0];
            value = value.split("em")[0];
            value = value.split("rem")[0];
            // updateValue({lineHeight : value});
          }}
          value={this.props.selection?.lineHeight}
        />

        <OptionList
          label="Style"
          style={{
            width: "calc(32% - 10px)",
            verticalAlign: "bottom",
            marginLeft: "10px", display:"inline-block"
          }}
          value={this.props.selection?.fontWeight}
          options={[
            {
              name: "Normal",
              value: "normal",
              icon: '<i class="fas fa-bold"></i>',
            },
            {
              name: "Italic",
              value: "italic",
              icon: '<i class="fas fa-italic"></i>',
            },
            { name: "oblique", value: "oblique" },
            { name: "initial", value: "initial" },
            { name: "inherit", value: "inherit" },
            { name: "unset", value: "unset" },
          ]}
          action={function (value) {
            styler("font-style", value);
            // updateValue({fontWeight : parseInt(value)});
          }}
        />

        <PropertyInput
          style={{ width: "calc(50% - 5px)" , display:"inline-block"}}
          id="letter-spacing"
          label="Letter"
          unit="px"
          action={function (value) {
            styler("letter-spacing", value);
            value = value.split("px")[0];

            //updateValue({letterSpacing : value});
          }}
          value={this.props.selection?.letterSpacing}
        />

        <PropertyInput
          style={{ width: "calc(50% - 5px)", marginLeft: "10px", display:"inline-block" }}
          id="word-spacing"
          label="Word"
          unit="px"
          action={function (value) {
            styler("word-spacing", value);
            value = value.split("px")[0];

            //updateValue({wordSpacing : value});
          }}
          value={this.props.selection?.wordSpacing}
        />

        <PropertyInput
          value={this.props.selection?.webkitLineClamp}
          label="Line-clamp"
          action={function (value) {
            styler("-webkit-box-orient", "vertical");

            styler("overflow", "hidden");

            styler("-webkit-line-clamp", parseInt(value));
          }}
        />

        <div>
          <div className="property-label" style={{ display: "block" }}>
            Text Decoration
          </div>

          <OptionList
            style={{ width: "calc(25% - 3px)", verticalAlign: "bottom",display:"inline-block" }}
            value={this.props.selection?.textDecorationLine}
            label="Line"
            options={[
              { name: "None", value: "none" },
              { name: "Underline", value: "underline" },
              { name: "Overline", value: "overline" },
              { name: "line-through", value: "line-through" },
            ]}
            action={function (value) {
              styler("text-decoration-line", value);
              // updateValue({textDecorationLine : value});
            }}
          />
          <OptionList
            style={{
              marginLeft: "5px",
              width: "calc(25% - 3px)",
              verticalAlign: "bottom",
              display:"inline-block"
            }}
            label="Style"
            value={this.props.selection?.textDecorationStyle}
            options={[
              { name: "None", value: "none" },
              { name: "Solid", value: "solid" },
              { name: "Wavy", value: "wavy" },
              { name: "Double", value: "double" },
            ]}
            action={function (value) {
              if (value === "none") {
                styler("text-decoration", "none");
                return;
              }
              styler("text-decoration-style", value);
              // updateValue({textDecorationStyle : value});
            }}
          />
          <ColorMixer
            style={{
              label: "Color",
              marginLeft: "5px",
              width: "calc(50% - 5px)",
              verticalAlign: "bottom",
              display:"inline-block"
            }}
            value={this.props.selection?.textDecorationColor}
            action={function (value) {
              styler("text-decoration-color", value);
              //updateValue({textDecorationColor : value});
            }}
          />

          <OptionList
            style={{ width: "calc(50% - 5px)", display: "inline-block" }}
            label="Transform"
            value={this.props.selection?.textTransform}
            options={[
              { name: "none", value: "none", label: "None" },
              { name: "uppercase", value: "uppercase", label: "UPPERCASE" },
              { name: "capitalize", value: "capitalize", label: "Capitalize" },
              { name: "lowercase", value: "lowercase", label: "lowercase" },
              { name: "inherit", value: "inherit", label: "inherit" },
            ]}
            action={function (value) {
              styler("text-transform", value);
              // updateValue({textTransform : value});
            }}
          />
     

          <PropertyInput
            type="static"
            label="Shadow"
            value={this.props.selection?.textShadow}
            action={function (value) {
              styler("text-shadow", value);
            }}
          />

          <PropertyInput
            unit="px"
            label="Indent"
            value={this.props.selection?.textIndent}
            action={function (value) {
              styler("text-indent", value);
            }}
          />
        </div>
        </Accordion>
    );
  }
}
