import styled from 'styled-components';
//import console from '../../components/interactive/debugModule';

const AppFrame = styled.div.attrs({id: 'app-frame'})`

--background: ${props => props.theme.frame?.background || '#fff'};
--color: ${props => props.theme.fontColor || '#000'};
--fontFamily: ${props => props.theme.fontFamily || 'sans-serif'};
--fontSize: ${props => props.theme.fontSize || '14px'};
--primaryColor: ${props => props.theme.colors.primary || '#000'};
--secondaryColor: ${props => props.theme.colors.seconary || '#000'};
--accentColor: ${props => props.theme.colors.accent || 'red'};
--complementaryColor: ${props => props.theme.colors.complimentary || '#000'};
--textColor: ${props => props.theme.colors.text || '#314a61'};

/* Indexes */
--topIndex: 999;

display: flex;
flex-direction: column;
position: fixed;

background-position: bottom right;
background: var(--background, '#fff');
background-repeat: repeat;
background-size: 100px;
color: ${props => props.theme?.frame?.color || "#222"};
width: 100vw;
height: 100vh;
top: 0;
left: 0;
perspective: 15cm;
perspective-origin: 50% 50%;
font-family: sans-serif;
font-weight: 400;
margin: 0;
padding: 0;
-webkit-font-smoothing: antialiased;

z-index: 99999;

&.graphBackground {
  width: 100%;
  height: 100%;
}

& .window textarea::-webkit-scrollbar-track
{
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
}

& .window textarea::-webkit-scrollbar
{
  width: 6px;
  background-color: #F5F5F5;
  cursor: pointer;
}

& .window textarea::-webkit-scrollbar-thumb
{
  background-color: #888;
}

& #cohoba-workspace {
  background: rgba(255, 255, 255, .8);
}

&.preview-mode {
  #creative-wizard-window, #preview-selection {
    display: none;
  }
  #frame-content{
    padding: 0 !important;
  }
  .preview-mode-close-button{
    opacity: 1;
  }
  #preview-container{
    max-width: 95%;
  }
}

 & .interacting {
  transition: none !important;
}

.selected-element {
  outline: 1px solid var(--primaryColor);
  box-shadow: inset 0 0 16px -8px var(--primaryColor);
}
.stop-interactive {
  display: none;
}

&.interactive-mode {

  #logo-icon, #cursor-mode, #file-actions, #frame-manager{
      display: none;
  }

  .workspace-scroller {
    overflow: hidden;
  }

  .stop-interactive {
      padding-left: 4px;
      margin: 0;
      color: var(--primaryColor);
      filter: none;
      display: flex;
  }

  .play-interactive .pause-icon{
     display: block;
  }
  .play-interactive .play-icon{
    display: none;
 }

  .frame-index {
    .text-input{

      text-shadow: 0 0 4px #fff;
      border-color: var(--primaryColor);
      background: #45c4d452;
    }
      svg {
          filter: none;
      }
  }
}

#atabey{
  position: absolute;
  bottom: 24px;
  left: 24px;
  z-index: 999999;
  .action-button {
    width: 46px;
    height: 46px;
    border-radius: 50%;
    background: var(--primaryColor);
    padding: 8px;

    img {
      width: 100%;
    }
  }
}
`;

export default AppFrame;