import SetupModule from "../setupModule";

import getAdRatio from "./getAdRatio";
import calculateMediaAvailableSpace from "./calculateMediaAvailableSpace";
import transformLayout from "./transformLayout";
import transformAdContent from "./transformAdContent";

const getLayoutOrientation = () => {
    return SetupModule.getAdContainer()?.getAttribute('orientation');
}

// Standardize these attributes between layout and this function
const getFormatContainer = () => {
    return SetupModule.getAdContainer()?.querySelector("[data-component='format']")
}

const getMediaContainer = () => {
    return SetupModule.getAdContainer()?.querySelector(".advertible_media")
}

const getAdContent = () => {
    return SetupModule.getAdContainer()?.querySelector(".advertible_content")
}

const getFooter = () => {
    return SetupModule.getAdContainer()?.querySelector("#footer")
}

const getCaption = () => {
    return SetupModule.getAdContainer()?.querySelector("#caption")
}

const getCta = () => {
    return SetupModule.getAdContainer()?.querySelector("#cta")
}

const getLoadScreen = () => {
    return SetupModule.getAdContainer()?.querySelector("[data-component='load-screen']")
}

const getVideoContainer = () => {
    return SetupModule.getAdContainer()?.querySelector("[data-component='video-container']")
}

const getVideoBackground = () => {
    return SetupModule.getAdContainer()?.querySelector("[data-component='video-background']")
}

const getVideoEndScreen = () => {
    return SetupModule.getAdContainer()?.querySelector("[data-component='end-screen']")
}



const calculateMaxHeightBasedOnTextLines = (element, lines) => {
    const elementLineHeight = parseFloat(window.getComputedStyle(element).lineHeight);
    const maxHeight = lines * elementLineHeight;
    return maxHeight;
}

const calculateElementMaxLines = (element, availableSpace) => {

}

const truncateElementByLines = (element, lines) => {

}

const LayoutModule = (function () {
    return {
        getAdRatio: getAdRatio,
        getLayoutOrientation: getLayoutOrientation,
        getFormatContainer: getFormatContainer,
        getMediaContainer: getMediaContainer,
        getAdContent: getAdContent,
        getFooter: getFooter,
        getCaption: getCaption,
        getCta: getCta,
        getLoadScreen: getLoadScreen,
        getVideoContainer: getVideoContainer,
        getVideoBackground: getVideoBackground,
        getVideoEndScreen: getVideoEndScreen,
        calculateMediaAvailableSpace: calculateMediaAvailableSpace,
        transformLayout: transformLayout,
        transformAdContent: transformAdContent
    }
})();

export default LayoutModule;