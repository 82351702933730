import * as React from 'react';
import {styled} from 'goober';
import NewVideoPlayer from './VideoPlayer/Player';

const StyledFormat = styled('img')`
    width: 100%;
    max-width: 100%;
    object-fit: contain;
    object-position: center;
    position: relative;
    height: 100%;
    z-index: 1;
`;

const FormatBackground = styled('div')`
background-image: url('${props =>  props.src}');
background-repeat: no-repeat;
background-size: cover;
background-position: center;
filter: blur(3px);
position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100%;
`;

const FormatContainer = styled('div')`
position: relative;
height: 100%;
max-height: 100%;
overflow: hidden;
`;


const Format = (props) => {
    return <FormatContainer   data-fp={props['data-fp']} style={props.style} data-component="format">
    <StyledFormat data-component="image" src={props.src} draggable="false" onLoad={props.onLoad} data-onload={props.onLoad ? 'true': 'false'}/>
    <FormatBackground src={props.src}/>
    {props.video && <NewVideoPlayer src={props.src} video={props.video} style={{position: 'absolute', top:0, left: 0, width:'100%', height: '100%'}}/>}
    </FormatContainer>
}

export default Format;