export function ReplaceMacrosInLayout(layout, assets, identifier){

    if (typeof identifier === "undefined") {
        identifier = "cohoba";
    }
    let stringifiedLayout = '';
    if (layout.markup) {
      stringifiedLayout = layout.markup
    } else if(layout.cn){
       stringifiedLayout = JSON.stringify(layout);
    }
  
    stringifiedLayout = stringifiedLayout.replace(/{{IDENTIFIER}}/g, identifier);
    
    if (assets) {
      
      // Add Default Assets
      // if (!assets.find(a => a.name === "disclosure")) {
      //   assets.push({asset_id: 0, name:"disclosure", data:"Sponsored by"})
      // }
      // if (!assets.find(a => a.name === "cta")) {
      //   assets.push({asset_id: 1, name:"cta", data:"View More"})
      // }

      // if (!assets.find(a => a.name === "store_icon")) {
      //   assets.push({asset_id: 0, name:"store_icon", data: AppStoreIcon})
      // }
          // Hack to enable video for now
      if (assets.find(a => a.name === "video" && a.type === 'vasttag')) {
          stringifiedLayout = stringifiedLayout.replace(/"src":\s*"\{\{ASSET_IMAGE\}\}"/g, `"src": "{{ASSET_IMAGE}}", "video":{"layoutWidth":"${layout.attributes?.width}","layoutHeight":"${layout.attributes?.height}", "cta_text":"{{ASSET_CTA}}", "vasttag":"{{ASSET_VIDEO}}"}`);
      } else if (assets.find(a => a.name === "video" && a.type === 'vasturl')) {
        stringifiedLayout = stringifiedLayout.replace(/"src":\s*"\{\{ASSET_IMAGE\}\}"/g, `"src": "{{ASSET_IMAGE}}", "video":{"layoutWidth":"${layout.attributes?.width}","layoutHeight":"${layout.attributes?.height}", "cta_text":"{{ASSET_CTA}}", "vasturl":"{{ASSET_VIDEO}}"}`);
      } else if (assets.find(a => a.name === "video" && a.type === 'video/mp4')) {
        stringifiedLayout = stringifiedLayout.replace(/"src":\s*"\{\{ASSET_IMAGE\}\}"/g, `"src": "{{ASSET_IMAGE}}", "video":{"layoutWidth":"${layout.attributes?.width}","layoutHeight":"${layout.attributes?.height}", "cta_text":"{{ASSET_CTA}}", "url":"{{ASSET_VIDEO}}"}`);
      }

      assets.forEach(a => {
        if (a.name) {
          let data = a.data;


          // // Old Id Number Based
          let regex = new RegExp("{{ASSET_"+a.id+"}}","g");
          stringifiedLayout = stringifiedLayout.replace(regex, data);

          // New Name Based
          let regex2 = new RegExp("{{ASSET_"+a.name.toUpperCase()+"}}","g");

          if (a.name === "icon") {
            data = data.replace(/\"/g,`\\"`);

          } else if (!assets.find(a => a.name === "icon")) {
            let regex4 = new RegExp("{{ASSET_ICON}}","g");
            stringifiedLayout = stringifiedLayout.replace(regex4, "");
          }

          if (typeof data === "object") {
            let regex3 = new RegExp("\"{{ASSET_"+a.name.toUpperCase()+"}}\"","g");
            stringifiedLayout = stringifiedLayout.replace(regex3, JSON.stringify(data));
          } else if (typeof data === "string"){
            stringifiedLayout = stringifiedLayout.replace(regex2, data);
         
          }
    
        }
      });

      // Hack to insert svg arrow
      stringifiedLayout = stringifiedLayout.replace(/{{SVG_ARROW}}/g, 'https://cdn.4dvertible.com/native/static/media/svg-arrow.svg');
      
    }
    if (layout.cn) {
      layout = JSON.parse(stringifiedLayout);
    } else {
      layout = stringifiedLayout;
    }

    return layout;
  }

export default ReplaceMacrosInLayout;