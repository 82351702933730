import React from "react";
import styled from 'styled-components';
import {Flex} from 'rebass';

const WindowCardContainer = styled(Flex)`
  display: block;
  margin-bottom: 18px;
  flex-direction: column;
`  


const WindowCardTitle = styled(Flex)`
  font-size: 16px;
  color: #fff;
  line-height: 1;
  margin-bottom: 8px;
  font-weight: bold;
`;

export default class WindowCard extends React.Component {
  render() {
    return (
      <WindowCardContainer
        {...this.props}
        className={" window-card window-" + this.props.name + "-card " + this.props.className}
      >
        {this.props.title && <WindowCardTitle>{this.props.title}</WindowCardTitle>}
        {this.props.children}
      </WindowCardContainer>
    );
  }
}
