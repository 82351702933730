import * as React from 'react';
import {styled} from 'goober';
import generateCSSFromObject from '../../interactive/setupModule/generateCSSFromObject';

const StyledBox = styled('div')`
display: flex;
${props => props.styles ? generateCSSFromObject(props.styles) : ''}
`;

const Box = (props) => {
    return <StyledBox   data-fp={props['data-fp']} className={props.className} styles={props.style}>{props.children}</StyledBox>
}

export default Box;