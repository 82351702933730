import * as React from 'react';
import {styled} from 'goober';
import generateCSSFromObject from '../../interactive/setupModule/generateCSSFromObject';

const StyledIcon = styled('span')`
${props => props.styles ? generateCSSFromObject(props.styles) : ''}

margin-right: 1em;
vertical-align: middle;
`;

const Icon = (props) => {
 
    if (props.children?.at(0) === "" || !props.children) {
        return null
    }
    return <StyledIcon id="icon"   data-fp={props['data-fp']} src={props.src} className={props.className} styles={props.style} dangerouslySetInnerHTML={{__html:props.children}}></StyledIcon>
}

export default Icon;