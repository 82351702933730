export interface VideoCreative {
  videoUrl?: string | any;
  trackingEvents?: object[];
  mediaFiles?: object[];
  clickThrough?: string | any;
  adParameters?: string | any;
}

export default function parseVastTag(vastString: string) {
  console.log(vastString);
  const parser = new DOMParser();
  const vastDocument = parser.parseFromString(vastString, 'text/xml');
  const videoCreative: VideoCreative = {
    videoUrl: vastDocument.querySelector("MediaFile[type='video/mp4'], Mezzanine")?.textContent.trim(),
  };

  videoCreative.adParameters = vastDocument.querySelector('AdParameters')?.textContent;
  videoCreative.clickThrough = vastDocument.querySelector('VideoClick ClickThrough')?.textContent;
  const trackers = Array.from(vastDocument.querySelectorAll('TrackingEvents Tracking'));
  const impressionPixels = Array.from(vastDocument.querySelectorAll('Impression'));
  const mediaFiles = Array.from(vastDocument.querySelectorAll('MediaFiles MediaFile, MediaFiles Mezzanine'));

  videoCreative.trackingEvents = trackers.map((t) => ({
    event: t.getAttribute('event'),
    url: t.textContent,
  }));

  impressionPixels.forEach((ip) => {
    videoCreative.trackingEvents?.push({ event: 'impression', url: ip.textContent });
  });

  videoCreative.mediaFiles = mediaFiles.map((mf) => {
    if (mf.tagName === 'MediaFile') {
      return {
        id: mf.getAttribute('id'),
        delivery: mf.getAttribute('delivery'),
        width: mf.getAttribute('width'),
        height: mf.getAttribute('height'),
        type: mf.getAttribute('type'),
        bitrate: mf.getAttribute('bitrate'),
        scalable: mf.getAttribute('scalable'),
        maintainAspectRatio: mf.getAttribute('maintainAspectRatio'),
        url: mf.textContent,
      };
    }
    if (mf.tagName === 'Mezzanine') {
      return {
        id: 'OUTSTREAM',
        url: mf.textContent,
      };
    }
  });

  console.log(videoCreative);
  return videoCreative;
}
