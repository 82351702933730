import resizeAdContainer from "../resizeAdContainer";

export default function resizeContainer(node){

        if (!node || node.querySelector(".cohoba_container") === null) {
          return;
        }
        let renderWidth = node.querySelector(".cohoba_container").getBoundingClientRect().width;
        let renderHeight = node.querySelector(".cohoba_container").getBoundingClientRect().height;
        
        if (renderWidth < window.innerWidth) {
          node.querySelector(".cohoba_container").style.width = window.innerWidth+"px";
        } else {
          resizeAdContainer(renderWidth, node.querySelector(".cohoba_container").getBoundingClientRect().height)
        }
        
        if (renderHeight < window.innerHeight) {
          node.querySelector(".cohoba_container").style.height = window.innerHeight+"px";
          if (node.querySelector(".cohoba_container").getAttribute("orientation") === "landscape"){
            node.querySelector(".cohoba_container .cohoba_media").style.height = window.innerHeight+"px";
          }
        } else if (renderHeight > window.innerHeight){
          if (node.querySelector(".cohoba_container").getAttribute("orientation") === "portrait"){
            resizeAdContainer(renderWidth, node.querySelector(".cohoba_container").getBoundingClientRect().height)
            //  node.querySelector(".cohoba_container .cohoba_media").style.height = window.innerHeight+"px";
          }
          if (node.querySelector(".cohoba_container").getAttribute("orientation") === "landscape"){
            resizeAdContainer(renderWidth, node.querySelector(".cohoba_container").getBoundingClientRect().height)
            //  node.querySelector(".cohoba_container .cohoba_media").style.height = window.innerHeight+"px";
          }
        }
        
        
}