/**
 * Extracts the MIME type from a Data URL string.
 * 
 * @param {string} dataURL - The Data URL to extract the MIME type from.
 * @return {string|null} The MIME type if found, otherwise null.
 */
export default function extractMimeTypeFromDataURL(dataURL) {
    // Regular expression to match the Data URL format and capture the MIME type
    const mimeRegex = /^data:([^;,]+)/i;
    const match = dataURL.match(mimeRegex);
    
    // If a match is found, return the MIME type, otherwise return null
    return match ? match[1] : null;
}
