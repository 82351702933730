import React from "react";
import Switch from "../components/Switch";
import right_alignment from '../../../images/icons/right-alignment.png';
import left_alignment from '../../../images/icons/left-alignment.png';
import bottom_alignment from '../../../images/icons/bottom-alignment.png';
import top_alignment from '../../../images/icons/top-alignment.png';
import middle_alignment from '../../../images/icons/vertical-alignment.png';
import center_alignment from '../../../images/icons/center-alignment.png';
import { ActionButton } from "../../../components";

const WorkspaceAlign = (props) => {
  const positionWorkspace = (position) => {
    switch (position) {
      case "left":
        window.cohoba.selection.object.style.left = '0px';
      break;
      case "center":
        window.cohoba.selection.object.style.left = (window.cohoba.workspace.size.at(0)/2) - (window.cohoba.selection.object.getBoundingClientRect().width/2) + 'px';
      break;
      case "right":
        window.cohoba.selection.object.style.left = (window.cohoba.workspace.size.at(0)) - (window.cohoba.selection.object.getBoundingClientRect().width) + 'px';
      break;
      case "top":
        window.cohoba.selection.object.style.top = '0px';
      break;
      case "middle":
        window.cohoba.selection.object.style.top = (window.cohoba.workspace.size.at(1)/2) - (window.cohoba.selection.object.getBoundingClientRect().height/2) + 'px';
      break;
      case "bottom":
        window.cohoba.selection.object.style.top = (window.cohoba.workspace.size.at(1)) - (window.cohoba.selection.object.getBoundingClientRect().height) + 'px';
      break;
      default:
        console.log(position);
    }
  };

  return (
    <div id="workspace-align">
      <ActionButton style={{background:`url(${left_alignment}) no-repeat center / contain`}} action={()=>{
        positionWorkspace('left')
      }}/>
      <ActionButton style={{background:`url(${center_alignment}) no-repeat center / contain`}} action={()=>{
        positionWorkspace('center')
      }}/>
      <ActionButton style={{background:`url(${right_alignment}) no-repeat center / contain`}} action={()=>{
        positionWorkspace('right')
      }}/>
      <ActionButton style={{background:`url(${top_alignment}) no-repeat center / contain`}} action={()=>{
        positionWorkspace('top')
      }}/>
      <ActionButton style={{background:`url(${middle_alignment}) no-repeat center / contain`}} action={()=>{
        positionWorkspace('middle')
      }}/>
      <ActionButton style={{background:`url(${bottom_alignment}) no-repeat center / contain`}} action={()=>{
        positionWorkspace('bottom')
      }}/>
    </div>
  );
}

export default WorkspaceAlign;