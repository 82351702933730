import getState from "../getState";
import getMraid from "../getMraid";

export default async function isReady(){
      return new Promise((resolve, reject) => {
        const mraid = getMraid();
        if (mraid) {
          if (getState() === 'loading') {
            mraid.addEventListener("ready", ()=>{
              resolve(true)
            });

          } else if (getState() === 'default'){
            resolve(true);
          }
          
        } else{

        }

        const mraidStateInterval = setInterval(()=>{
          const mraid = getMraid();
          if (mraid && mraid.getState && mraid.getState() !== 'loading'){
            clearInterval(mraidStateInterval);
            resolve(true);
          } else if (!mraid){
            resolve(false);
          }

        }, 50);
      });
    }