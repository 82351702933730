export function removeChildFromLayout(layout, fingerprint) {
  if (!Array.isArray(layout.cn)) return layout;

  layout.cn = layout.cn.filter(child => {
    // If child is a string, we don't want to delete it, so it's kept by default
    if (typeof child === 'string') return true;
    
    // Check if this child should be deleted
    if (child.attributes?.delete || child.fingerprint === fingerprint) {
      return false;
    }

    // Recursively check children
    if (child.cn) {
      removeChildFromLayout(child, fingerprint);
    }
    return true;
  });

  return layout;
}
export default function() {
  window.cohoba = window.cohoba || {};
  window.cohoba.shortcuts = {
    optionDown: false,
    controlDown: false,
    shiftDown: false,
    commandDown: false,
    actions: {
      '27': () => {
        const closeButton = document.querySelector("#app-frame .close-button");
        if (closeButton) closeButton.click();
        const interactiveFrame = document.querySelector("#app-frame.interactive-mode");
        if (interactiveFrame) {
          clearInterval(window.cohoba.player?.ticker);
          interactiveFrame.classList.remove('interactive-mode');
        }
      },
      '8': () => {
       
        if (window.cohoba.selection && window.cohoba.selection.layout && document.activeElement.tagName !== "TEXTAREA" && document.activeElement.type !== "text" && !document.activeElement.getAttribute('contenteditable')) {
       
          let layout = removeChildFromLayout(window.cohoba.currentFrame, window.cohoba.selection.fingerprint);
          window.cohoba.setSelection(null);
          window.cohoba.updateWorkspace({layouts: window.cohoba.workspace.layouts});
        } else if (window.cohoba.selection?.object && document.activeElement.tagName !== "TEXTAREA" && document.activeElement.type !== "text" && !document.activeElement.getAttribute('contenteditable')){
          window.cohoba.selection.object.remove();
        
          window.cohoba.workspace.layouts[cohoba.frame].markup = cohoba.workspaceElement.innerHTML;
         // window.cohoba.updateWorkspace({render: cohoba.workspaceElement.innerHTML,code: cohoba.workspaceElement.innerHTML});
        }
      },
      // ... other existing shortcuts ...
    },
    // New shortcuts object for menu navigation
    menuShortcuts: {
      'KeyF': '.menu-item.file',
      'KeyA': '.menu-item.assets',
      'KeyM': '.menu-item.templates',
      'KeyP': '.menu-item.properties',
      'KeyE': '.menu-item.code'
    }
  };

  const keyHandler = (event, keyDown) => {
    const { shortcuts } = window.cohoba;
console.log(event.keyCode);
    // Key state management
    shortcuts.optionDown = keyDown && event.altKey;
    shortcuts.controlDown = keyDown && event.ctrlKey;
    shortcuts.shiftDown = keyDown && event.shiftKey;
    shortcuts.commandDown = keyDown && (event.metaKey || event.key === "OS");

    // Execute action if defined
    if (shortcuts.actions[event.keyCode] && keyDown) {
      shortcuts.actions[event.keyCode]();
    }

    // Handle Option/Alt key combinations for menu navigation
    if (shortcuts.optionDown && keyDown) {
      const selector = shortcuts.menuShortcuts[event.code];
      if (selector) {
       // event.preventDefault();
        const menuItem = document.querySelector(selector);
        if (menuItem) menuItem.click();
      }
    }

    // Handle other combination keys
    if (shortcuts.optionDown) {
      if (event.keyCode === 68){
        document.querySelector('.window.template .action-button.duplicate').click();
      }
      // Add other Option key combinations here...
    }

    if (shortcuts.commandDown && keyDown) {
      if (event.keyCode === 90){
        document.querySelector('.action-button.undo').click();
      }
    }

    if (shortcuts.commandDown && shortcuts.shiftDown && keyDown) {
      if (event.keyCode === 90){
        document.querySelector('.action-button.redo').click();
      }
    }

    // Prevent default action for certain keys
    if (keyDown && (shortcuts.optionDown || shortcuts.controlDown || shortcuts.commandDown || shortcuts.shiftDown)) {
     // event.preventDefault();
    }
  };

  // Keydown event listener
  window.addEventListener("keydown", (event) => {keyHandler(event, true);   });

  // Keyup event listener for modifier keys
  window.addEventListener("keyup", (event) => {
    keyHandler(event, false);
  });

  // Modal handling (unchanged from your previous setup)
  const modalHandler = (event) => {
    if (event.keyCode === 13 && document.querySelector("#overlay-container .modal-overlay .accept")) {
      event.preventDefault();
      document.querySelector("#overlay-container .modal-overlay .accept").click();
    } else if (event.keyCode === 27 && document.querySelector("#overlay-container .modal-overlay .cancel")) {
      event.preventDefault();
      document.querySelector("#overlay-container .modal-overlay .cancel").click();
    } else if (document.querySelector("#overlay-container .modal-confirm.define-component")) {
      const componentMap = {
        49: 2, 50: 3, 51: 4, 52: 5, 53: 6, 54: 7, 55: 8
      };
      if (componentMap[event.keyCode]) {
        document.querySelector(`#overlay-container .modal-confirm .option-group .option-item:nth-child(${componentMap[event.keyCode]})`).click();
      }   
    }
  };

  window.addEventListener("keydown", modalHandler);
}