import * as React from 'react';
import { ActionButton, Accordion, PropertyInput, OptionList, Flex } from "../../components";
import { useWorkspace } from '../../views/Platform/WorkspaceContext';
export default function BackgroundProperties({selection}){

  const {workspace} = useWorkspace();

return <Accordion
 icon={<i className="fas fa-paragraph"></i>} title={<Flex className="accordion-title-label">Background<ActionButton action={e => {
  e.currentTarget.classList.toggle('toggled');
}}name="maximize-button"><i className="far fa-square"></i><i className="fas fa-square"></i></ActionButton></Flex>}
name="background-properties"
>
<PropertyInput
  label="Background"
  action={function (value) {
    styler("background", value);
  }}
  value={selection?.background}
/>

<PropertyInput
  type="static"
  label="Image"
  action={function (value) {
    styler("background-image", value);
  }}
  value={selection?.backgroundImage}
/>
<OptionList action={(value) => {
  styler("background-image", `url('{{ASSET_${value}}}')`);
}} options={workspace?.assets?.filter(a => a.type === 'image')?.map(a => {
  return {name: a.name, value: a.id}
}) || []}/>
<PropertyInput
  type="static"
  label="Size"
  action={function (value) {
    styler("background-size", value);
  }}
  value={selection?.backgroundSize}
/>
<PropertyInput
  type="static"
  label="Position"
  action={function (value) {
    styler("background-position", value);
  }}
  value={selection?.backgroundPosition}
/>
<PropertyInput
  type="static"
  label="Attachment"
  action={function (value) {
    styler("background-attachment", value);
  }}
  value={selection?.backgroundAttachment}
/>

<PropertyInput
  type="static"
  label="Transform"
  action={function (value) {
    styler("transform", value);
  }}
  value={selection?.transform}
/>
</Accordion>
}