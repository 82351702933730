import getAdSize from "../getAdSize";
import getViewportSize from "../getViewportSize";
import getTrackingFramework from "../getTrackingFramework";
import getViewableState from "../getViewableState";
import getRenderVersion from "../getRenderVersion";
import getLayoutName from "../getLayoutName";
import getDeviceScreenSize from "../getDeviceScreenSize";
import getDeviceOrientation from "../getDeviceOrientation";
import getMraidState from "../getMraidState";
import getOmidState from "../getOmidState";
import getColorMode from "../getColorMode";
import getCurrentPosition from "../getCurrentPosition";
import getStylesheetId from "../getStylesheetId";
import getLoadTime from "../getLoadTime";
import getRenderTime from "../getRenderTime";
import getFinishTime from "../getFinishTime";
import getViewTime from "../getViewTime";
import isNestedIframe from "../isNestedIframe";
import getPlacementType from "../getPlacementType";
import { createVideoTrackingParameters } from "../../videoModule";
import { impressionParameters } from "../../../entities/advertibleInterfaces";
import isTouchDevice from "../isTouchDevice";
import { isGoogleIframe } from "../isGoogleIframe";

const getSandboxAttributes = () =>{
    if (window.frameElement) {
        const sandboxAttribues = window.frameElement.getAttribute('sandbox');
        return sandboxAttribues;
    }
}

const createImpressionParameters = () => {
    let params:impressionParameters = {
     //   fw: window.render_params?.frame?.width,
     //   fh: window.render_params?.frame?.height,
        aw: getAdSize()?.width,
        ah: getAdSize()?.height,
        vw: getViewportSize().w,
        vh: getViewportSize().h,
        cm: getColorMode(),
        px: getCurrentPosition()?.x,
        py: getCurrentPosition()?.y,
        sw: getDeviceScreenSize().width,
        sh: getDeviceScreenSize().height,
        mraid: getMraidState(),
      //  omid: getOmidState(),
        ss: getStylesheetId(),
        layout: getLayoutName(),
        orient: getDeviceOrientation(),
        view: getViewableState(),
        track: getTrackingFramework(),
        lt: getLoadTime(),
        rt: getRenderTime(),
        ft: getFinishTime(),
        vt: getViewTime(),
        nested: isNestedIframe(),
     //   sb: getSandboxAttributes(),
        ver: getRenderVersion(),
        pt: getPlacementType(),
        touch: isTouchDevice()
    }

    // POC: Video Tracking
    if (window.render_params?.assets?.find(a => a.name === "video")) {
        const videoParameters = createVideoTrackingParameters();
        params = {...params, ...videoParameters}
    }

    // Temp: Report SSP for Lemma Campaign
    if (window.renderInfo){
        params.ssp = window.renderInfo.ssp;
    }

    if (getTrackingFramework() === "friendly" && !isNestedIframe()){
        params.gi = isGoogleIframe(window.frameElement);
    }

    const parametersArray = Object.keys(params).map(key => {
        return `${key}=${params[key]}`;
    })

    return parametersArray.join('|');
}

export default createImpressionParameters;