function findRule(stylesheet, selector){
				   
   var rules = []
   
   for(var i=0; i < stylesheet.cssRules.length; i ++){	   
	   if(stylesheet.cssRules[i].selectorText === selector){
		   	rules.push(stylesheet.cssRules[i]);
		    return stylesheet.cssRules[i]
	   }	   
   }
   return null  
}

export default findRule;