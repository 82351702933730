
function gettargetSettings(workspace) {
  let containerStyle = {};
  if (!workspace || !workspace?.size || !workspace.target) {
    return containerStyle;
  }
switch (workspace.target) {
    case "none":
      containerStyle.width = workspace.size[0] + "px";
      containerStyle.height = workspace.size[1] + "px";
   
      break;

    case "Google Pixel 3 XL":
      containerStyle.width = "412px";
      containerStyle.height = "847px";
      containerStyle.borderRadius = "5px";
      break;

    case "Google Pixel 3":
      containerStyle.width = "412px";
      containerStyle.height = "824px";
      containerStyle.borderRadius = "5px";
      break;

    case "Google Pixel 2 XL":
      containerStyle.width = "412px";
      containerStyle.height = "732px";
      containerStyle.borderRadius = "5px";
      break;

    case "Samsung Galaxy S9+":
      containerStyle.width = "412px";
      containerStyle.height = "732px";
      containerStyle.borderRadius = "5px";
      break;

    case "samsung-galaxy-tab":
      containerStyle.width = "412px";
      containerStyle.height = "732px";
      containerStyle.borderRadius = "5px";
      break;

    case "oneplus-6t":
      containerStyle.width = "480px";
      containerStyle.height = "853px";
      containerStyle.borderRadius = "5px";
      break;

    case "nexus-9":
      containerStyle.width = "768px";
      containerStyle.height = "1024px";
      containerStyle.borderRadius = "5px";
      break;

    case "nexus-7":
      containerStyle.width = "600px";
      containerStyle.height = "960px";
      containerStyle.borderRadius = "5px";
      break;

    case "iphone-xr":
      containerStyle.width = "414px";
      containerStyle.height = "896px";
      containerStyle.borderRadius = "5px";
      break;

    case "iphone-xs":
      containerStyle.width = "375px";
      containerStyle.height = "812px";
      containerStyle.borderRadius = "5px";
      break;

    case "iphone-xsmax":
      containerStyle.width = "414px";
      containerStyle.height = "896px";
      containerStyle.borderRadius = "5px";
      break;

    case "iphone-5":
      containerStyle.width = "320px";
      containerStyle.height = "568px";
      containerStyle.borderRadius = "5px";
      break;

    case "iphone-8":
      containerStyle.width = "375px";
      containerStyle.height = "667px";

      if (window.cohoba.workspaceContainer.querySelector("#cohoba-app-demo")) {
        window.cohoba.workspaceContainer.querySelector(
          "#cohoba-app-demo"
        ).style.borderRadius = "4px";
      }
      break;

    case "iphone-x":
      containerStyle.width = "375px";
      containerStyle.height = "812px";
      containerStyle.borderRadius = "5px";

      break;

    case "iphone-8-plus":
      containerStyle.width = "414px";
      containerStyle.height = "736px";
      containerStyle.borderRadius = "5px";

      break;


    case "samsung-galaxy-s10":
      containerStyle.width = "412px";
      containerStyle.height = "869px";
      containerStyle.height = "859px";
      containerStyle.borderRadius = "32px";
      break;

    case "ipad":
      containerStyle.width = "768px";
      containerStyle.height = "1024px";
      break;

    case "ipad-pro-9":
      containerStyle.width = "1024px";
      containerStyle.height = "1366px";
      break;

    case "ipad-pro-10":
      containerStyle.width = "834px";
      containerStyle.height = "1112px";
      break;

    case 'Google Pixel C"':
      containerStyle.width = "1280px";
      containerStyle.height = "900px";
      break;

    case 'Apple Watch 44mm"':
      containerStyle.width = "224px";
      containerStyle.height = "184px";
      break;

    case "apple-watch":
      containerStyle.width = "390px";
      containerStyle.height = "312px";
      break;

    case 'Apple Watch 40mm"':
      containerStyle.width = "197px";
      containerStyle.height = "162px";
      break;

    case 'Huawei Watch GT"':
      containerStyle.width = "454px";
      containerStyle.height = "454px";
      break;

    case 'Asus Zenwatch 3"':
      containerStyle.width = "400px";
      containerStyle.height = "400px";
      break;

    case 'Huawei Watch Magic"':
      containerStyle.width = "390px";
      containerStyle.height = "390px";
      break;

    case 'Samsgung Galaxy Watch"':
      containerStyle.width = "360px";
      containerStyle.height = "360px";
      break;

    case "chromebook-pixel":
      containerStyle.width = "1280px";
      containerStyle.height = "850px";
      break;

    case "laptop":
      containerStyle.width = "1366px";
      containerStyle.height = "817px";
      break;

    case "desktop":
      containerStyle.width = "1440px";
      containerStyle.height = "960px";
      break;

    case "tv":
      containerStyle.width = "1920px";
      containerStyle.height = "1110px";
      break;

    case "isi":
      containerStyle.width = "320px";
      containerStyle.height = "150px";
      break;

      case "modern-android":
        containerStyle.width = "375px";
        containerStyle.height = "817px";
    
        break;

    default:
      containerStyle.width = workspace.size[0] + "px";
      containerStyle.height = workspace.size[1] + "px";

      if (workspace.layout) {
        let layoutContainer = workspace.layout.cn[0];
        let width = layoutContainer.attributes.width;
        let height = layoutContainer.attributes.height;
        containerStyle.width = width + "px";
        containerStyle.height = height + "px";
      }
  }

  return containerStyle;
}

  export default gettargetSettings;