import * as React from 'react';
import {styled} from 'goober';
import Box from './Box';
const StyledLogo = styled('div')`
//background-image: url("${props => props.src}");
background-repeat: no-repeat;
background-size: contain;
background-position: center;
background-color: #fff;
z-index: 4;
`;

const Logo = (props) => {
    if (props.src === '' || props.src === null) {
        return null;
    }
  
    return <StyledLogo   data-fp={props['data-fp']} data-component="logo" className={`${props.identifier}_logo${props.className ? " "+props.className : ''}`} style={{...props.style, backgroundImage: "url('"+props.src+"')"}} src={props.src}/>
}

export default Logo;