import ErrorModule from "../../errorModule";
import MRAIDModule from "../../mraidModule";
import renderClickTrackers from "../renderClickTrackers";
import SetupModule from "../../setupModule";
import LayoutModule from "../../layoutModule";
import VideoModule from "../../videoModule";

export default function(node: HTMLElement) {
            let link_url = render_params.assets.find(a=> a.name === "link")?.data;
            if (node) {
                node.addEventListener("click", (event) => {
                    if (event.target.getAttribute('data-component') === 'mute-button'
                    || event.target.getAttribute('data-component') === 'replay-button'
                    || event.target.getAttribute('data-component') === 'play-button'
                    ) {
                        return false;
                    }
                    try{
                        const adContainer = SetupModule.getAdContainer();
                        renderClickTrackers(event, adContainer);
                        if (MRAIDModule.mraid) {
                            // We should handle all mraid functionality through module methods
                            MRAIDModule.mraid.open(link_url);
                        } else {
                            window.open(link_url, "_blank");
                        }
                        // Should turn this into a isVideoRender? function to use across the codebase
                        if (window.render_params?.assets?.find(a => a.name === 'video')){
                            const videoElement = VideoModule.getVideoElement();
                            const videoBackground = LayoutModule.getVideoBackground();
                            const endScreen = LayoutModule.getVideoEndScreen();
                           // videoElement.currentTime = 0;
                           // videoBackground.currentTime = 0;
                           
                            videoElement.pause();
                            videoBackground.pause();
                            endScreen?.classList.remove("hidden");
                            
                        }
                    }catch(e){
                        ErrorModule.capture({...e, stack:"AnalysticModule : "+e.stack});
                    }
                }, false);
            }
        }