// Move to setupModule

export default function ReplacePatternsInLayout(layout, layoutsArray){
        if (!layoutsArray || !layoutsArray.find(l => l.id ? true : false)) {
          return layout;
        };

        let newLayout = {...layout};

        if (newLayout.cn) {
          newLayout.cn = newLayout.cn.map(child => {
            return ReplacePatternsInLayout(child, layoutsArray);
          });
        } else if (newLayout.pattern && layoutsArray.find(l => l.id === newLayout.pattern)){
              let pattern = layoutsArray.find(l => l.id === newLayout.pattern);
              let element = {...pattern};
              delete element.id;
              return element;
            }

      return newLayout;
   }