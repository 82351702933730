import * as React from 'react';
import styled from 'styled-components';
import { WindowModule } from '../../components';
import {Flex} from 'rebass';
import { ColorMixer } from '../../components';
import styler from '../styler';
const ColorOption = styled(Flex)`
  width: 12px;
  height: 12px;
  color: ${props => props.color};
  outline: 1px solid rgba(255,255,255,1);
`;
const ColorSwatches = styled(Flex)``;

export default function ColorProperties({selection, theme}){

        return <WindowModule name="quick-color">
         
        {/*Text Color*/}
        <ColorMixer
          label={<i className="fas fa-font"></i>}
          action={function (value) {
            styler("color", value);
          }}
          value={selection?.color}
        />

        {/*Background Color*/}
        <ColorMixer
          label={<i className="fas fa-brush"></i>}
          action={function (value) {
            styler("background-color", value);
          }}
          value={selection?.backgroundColor}
        />

         {/*Border Color*/}
         <ColorMixer
          label={<i className="fas fa-border-style"></i>}
          value={selection?.borderColor}
          action={function (value) {
            styler("border-color", value);
            //  updateValue({borderColor : value})
          }}
        />
        <ColorSwatches>
          {theme?.colors && Object.keys(theme.colors).map(key => {return <ColorOption color={theme.colors[key]}/>})}
        </ColorSwatches>
        </WindowModule>
        }