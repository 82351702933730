import {createRoot} from 'react-dom/client';

export default function(node : HTMLOrSVGElement|null){
        let container = document.createElement('div');
        if (node?.parentNode?.tagName === 'DIV') {
          container = node.parentNode;
        }
        container.classList.add('advertible-render');
        if (node && node.parentNode && node.parentNode !== container) {
          node.parentNode.insertBefore(container, node);
        } else {
          // document.body.appendChild(container);
        }

        if (window.render_params) {
          window.render_params.container = container;
        }

        const root = createRoot(container);
        return root;
}
