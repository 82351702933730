import renderPixel from "../../utilities/renderPixel";
import SetupModule from "../setupModule";
enum VIDEO_EVENTS {
    start = '0',
    firstQuartile = '25',
    midpoint = '50',
    thirdQuartile = '75',
    end = '100'
}

export const getVideoEventUrl = () => {
    let video_url;
    if (window.cohoba_impression?.video_url) {
        video_url = window.cohoba_impression?.video_url;
    }
    return video_url;
}

export const generateVideoTrackingUrl = (eventType) => {
    const url = new URL(getVideoEventUrl());
    url.searchParams.set('event', VIDEO_EVENTS[eventType]);
    return url.href;
}

const getVideoElement = () => {
    const adContainer = SetupModule.getAdContainer();
    const videoElement = adContainer?.querySelector(".video-element");
    return videoElement;
}

const getVideoStartTime = () => {
    return (window.render_params.videoStartTime - window.render_params.renderTime)/1000;
}

const getVideoLoadTime = () => {
    if (!window.render_params.videoLoadTime) {
        return -1
    }
    return (window.render_params.videoLoadTime - window.render_params.renderTime)/1000;
}

const getVideoWidth = () => {
    return window.render_params.videoWidth || getVideoElement()?.getBoundingClientRect()?.width;
}

const getVideoHeight = () => {
    return window.render_params.videoHeight || getVideoElement()?.getBoundingClientRect()?.height;
}

export const createVideoTrackingParameters = () => {
    /*
    videoLoadTime
    videoBitRate
    videoPlayerWidth
    videoPlayerHeight
    */
   return {
    vidst: getVideoStartTime(),
    vidlt: getVideoLoadTime(),
    vidw: getVideoWidth(),
    vidh: getVideoHeight(),
   }
}

const addVideoTrackingParameters = () => {
    if (window.render_params) {
      window.render_params.videoLoadTime = Date.now();
      window.render_params.videoWidth = getVideoWidth();
      window.render_params.videoHeight = getVideoHeight();
    }
  }

const getVideoRatio = () => {
    const videoElement = getVideoElement();
    return videoElement.videoWidth / videoElement.videoHeight;
}

const renderVideoImpressionTrackers = (xmlDoc) => {
    const impressionPixels = xmlDoc.querySelectorAll('Impression');

    if (impressionPixels.length > 0) {
        impressionPixels.forEach((px) => {
        renderPixel(px.textContent);
      });
    }
}

const VideoModule = (function () {
    return {
        getVideoElement: getVideoElement,
        getVideoEventUrl: getVideoEventUrl,
        generateVideoTrackingUrl: generateVideoTrackingUrl,
        getVideoStartTime: getVideoStartTime,
        getVideoLoadTime: getVideoLoadTime,
        getVideoWidth: getVideoWidth,
        getVideoHeight: getVideoHeight,
        createVideoTrackingParameters: createVideoTrackingParameters,
        addVideoTrackingParameters: addVideoTrackingParameters,
        getVideoRatio: getVideoRatio,
        renderVideoImpressionTrackers: renderVideoImpressionTrackers,
        isVideo: function(){
            return (window.render_params && window.render_params.assets?.find(a => a.name === 'video')) ? true : false
        }
    }
})();

export default VideoModule;