export default function findGoogleIframeMaxSize(container) {
    //Check for Google Iframes
    const googleRegex = /^google_ads_iframe_\//;
    let maxSize = {
        width: container.getBoundingClientRect().width, 
        height: container.getBoundingClientRect().height
    }
 
    if (googleRegex.test(container.id)){
        if (googleRegex.test(container.parentNode?.id)){
            container = container.parentNode;
        }
        let parentWidth = container.getBoundingClientRect().width;
        let parentHeight = container.getBoundingClientRect().height;
        maxSize = {width: parentWidth, height: parentHeight};
        if (container.parentNode.getAttribute("data-google-query-id")){
            // Root Google Ad Container
            container = container.parentNode;
           let rootContainerWidth = container.parentNode.getBoundingClientRect().width;
           let rootContainerHeight = container.parentNode.getBoundingClientRect().height;
           if (parentWidth < rootContainerWidth) {
              maxSize.width = rootContainerWidth;
            }
            if (parentHeight < rootContainerHeight) {
                maxSize.height = rootContainerHeight;
            }
            
            // Hack: Check up in the DOM tree until we find a container that's bigger than the container, then compare that
            let checkParents = true;
            while (container.getBoundingClientRect().width <= rootContainerWidth && checkParents) {
                if (container.parentNode instanceof Document || container.parentNode.tagName === "body" || typeof container.parentNode.getBoundingClientRect === "undefined" || container.parentNode.getBoundingClientRect().width > rootContainerWidth){
                    checkParents = false;
                    break;
                }
                container = container.parentNode;
                rootContainerWidth = container.getBoundingClientRect().width;
            }

            if (rootContainerWidth > maxSize.width){
                maxSize.width = rootContainerWidth;
                maxSize.height = rootContainerHeight;
            }
            
        } 
    }
    return maxSize;
}