import app_logo from '../images/cohoba_logo.svg';

const appTheme = {
  name: "Default",
  white: "#fff",
  black: "#000",
  lighterGray: "#fff",
  lightGray: "#f1f1f1",
  mediumGray: "#ededed",
  darkGray: "#333",
  darkerGray: "#444",
  darkestGray: "#111",
  lightBlue: "#a72fc8",
  mediumBlue: "#77218f",
  darkBlue: "#471456",
  fontFamily: "Lexend, sans-serif",
  fontImport: `@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@100..900&display=swap')`,
  fontSize: "12px",
  primaryColor: "rgb(49 74 97)",
  primary: "rgb(49 74 97)",

  colors: {
    primary: "#49c1d1",
    accent: "#ea237c",
    complimentary: "#f7bd0e",
    key: "#45c4d4",
    text: "rgb(49 74 97)",
    titles: "#000",
  },

  menu: {
    background: "linear-gradient(to bottom, hsl(187 20% 95% / 1), hsl(334 20% 95% / 1))",
    color: "#fff",
    icon_color: "#45c4d4",
    label_color: "#000",
    active_icon_color: ""
  },

  header: {
    color: "rgb(49 74 97)",
    background: 'linear-gradient(to right, hsl(187 20% 95% / 1), hsl(334 20% 95% / 1))',
  },

  frame: {
    background: 'linear-gradient(to top, hsl(187 20% 95% / 1), hsl(334 20% 95% / 1))'
  },

  panel: {
    background: '#fff',
    color: "#666"
  },
 
  app: {
    title: "Cohoba.co",
    logo: app_logo,
    icon: app_logo,
  }
}

export default appTheme;