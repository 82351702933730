import * as React from "react";
import styled from 'styled-components';
import {Box, Flex} from 'rebass';
import Workspace from "./Workspace";
import WorkspaceFrame from "./WorkspaceFrame";
import getDeviceSettings from "./getDeviceSettings";
import Format from "../components/content/Player";

const WorkspaceTitle = styled.div`
  opacity: 0.5;
  padding: 8px 0;
  display: none;
`;
const WorkspaceSize = styled(Box)`

  font-size: 12px;
  text-align: right;
  transition: all .25s ease-in-out;
  margin-top: 5px;
  color: #000;
  transform: scale(0);


.sizeLabelOn &{
  transform: scale(1);
}

.workspaceHidden & {
  transform: scale(0) !important;
}
`;

const StyledWorkspaceContainer = styled('div').attrs({id: 'workspace-container'})`
position: relative;

width: auto;
height: auto;
transform-origin: center center;






${props => props.background}
`;

const WorkspaceScroller = styled('div')`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: visible;
  top: 0;
  left: 0;
  outline: 1px solid rgb(0,0,0, 0%);
  box-shadow: 0 4px 4px 0px rgb(0 0 0 / 00%);
  &::-webkit-scrollbar {
    display: none;
  }
  transition: all .3s ease-in-out;
  .interactive-mode & {
    outline: 1px solid rgb(0,0,0, 10%);
    box-shadow: 0 4px 4px 0px rgb(0 0 0 / 30%);
  }

`;

const WorkspaceBackground = styled(Format)``;

const WorkspaceDetails = styled.div`
  display: flex;   
  top: -25px;
  font-size: 13px;
  position: absolute;
  left: 0;
  color: #000;
  transition: all .25s ease-in-out;
  transform: scale(0);
  .nameLabelOn &{
    transform: scale(1);
  }
`;
const WorkspaceInfo = styled.div``;


export const WorkspaceContainer =  React.memo(function ({workspace, frame, className, onMouseDown}) {
  const containerRef = React.useRef();
  const workspaceRef = React.useRef();
  const frameRef = React.useRef();

  React.useEffect(()=>{
    window.cohoba.workspaceContainer = containerRef.current;
  },[]);

  const positionWorkspaceContainer = (position) => {
    var windowWidth = window.innerWidth;
    var windowHeight = window.innerHeight;

    var workspaceWidth = containerRef.current.getBoundingClientRect()
      .width;
    var workspaceHeight = containerRef.current.getBoundingClientRect()
      .height;

    if (position === "center" || typeof position == "undefined") {
      var workspaceTop = (windowHeight - workspaceHeight) / 2;
      var workspaceLeft = (windowWidth - workspaceWidth) / 2 + 70;

      //Offset for menu
      if(window.innerWidth > 550){
        workspaceLeft = workspaceLeft - (document.querySelector("#frame #menu").getBoundingClientRect().width/2)
      }

      if(window.innerWidth < 550){
        workspaceTop = workspaceTop - (document.querySelector("#frame #menu").getBoundingClientRect().height/2)
      }

      containerRef.current.style.top = workspaceTop + "px";
      containerRef.current.style.left = workspaceLeft + "px";
      containerRef.current.style.top = workspaceTop + "px";
      containerRef.current.style.left = workspaceLeft + "px";
    } else if (position === "left") {
      containerRef.current.style.left = "0px";
    } else if (position === "right") {
      containerRef.current.style.left =
        windowWidth - workspaceWidth + "px";
    } else if (position === "top") {
      containerRef.current.style.top = "0px";
    } else if (position === "bottom") {
      containerRef.current.style.top =
        windowHeight - workspaceHeight + "px";
    }
  }
  
  if (!workspace) {
    return null;
  }

      let containerStyle = {...getDeviceSettings(workspace),
        position: workspace.type === 'fixed' ? 'relative' : 'relative',
        width : workspace.type === 'fixed' ? workspace.size?.at(0) + "px" : '100%',
        height : workspace.type === 'fixed' ? workspace.size?.at(1) + "px" : '100%',
        left : workspace.position?.at(0) ? workspace.position?.at(0) + "px" : 0,
        top : workspace.position?.at(1) ? workspace.position?.at(2) + "px" : 0,
        right : workspace.position?.at(2) ? workspace.position?.at(3) + "px" : 0,
        bottom : workspace.position?.at(3) ? workspace.position?.at(4) + "px" : 0,
        transform: `translate(0,0) rotate(0deg) scale(${workspace.scale})`,
      };
      console.log(containerStyle);
    return <StyledWorkspaceContainer
        onMouseDown={onMouseDown}
        className={"shadow outline " + workspace.target + " " + className +" "+ workspace.target}
        style={containerStyle}
        ref={containerRef}
        key="workspace-container"
      >
      <WorkspaceTitle className="workspace-name" key="workspace-name-label">
      {workspace.title}
        </WorkspaceTitle>

        <WorkspaceScroller
          key="workspace-scroller"
          className="workspace-scroller"
         
        >
         {workspace ? <Workspace
      key="workspace"
        workspace={workspace}
        frame={frame}
        ref={workspaceRef}
      /> : null}
   <WorkspaceBackground background={workspace.background} style={{display:'none',position:"absolute", width:"100%", height:"100%", objectFit:"contain"}}/>
        </WorkspaceScroller>
        <WorkspaceDetails>
        <WorkspaceInfo className="workspace-target" key="workspace-target-label">
            {workspace.size?.at(0) + "x" + workspace.size?.at(1)}
           </WorkspaceInfo>
           <WorkspaceInfo className="workspace-package" key="workspace-package-label">
            {workspace.size?.at(0) + "x" + workspace.size?.at(1)}
           </WorkspaceInfo>
        <WorkspaceInfo className="workspace-size" key="workspace-size-label">
            {workspace.size?.at(0) + "x" + workspace.size?.at(1)}
           </WorkspaceInfo>
           <WorkspaceInfo className="workspace-time" key="workspace-time-label">
            {workspace.size?.at(0) + "x" + workspace.size?.at(1)}
           </WorkspaceInfo>
           <WorkspaceInfo className="workspace-weight" key="workspace-weight-label">
            {workspace.size?.at(0) + "x" + workspace.size?.at(1)}
           </WorkspaceInfo>
           </WorkspaceDetails>
           
          
         
        <WorkspaceFrame
        ref={frameRef}
        workspace={workspace}
      />

      </StyledWorkspaceContainer>
}
);

export default WorkspaceContainer