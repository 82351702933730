  import * as React from "react";
  import {Box, Flex} from 'rebass';
  import loadText from "../workspace/loadText";
  import loadData from "../workspace/loadData";
  import styled from 'styled-components';
  import { PropertyLabel } from "./PropertyLabel";
import ActionButton from "./ActionButton";

  const StyledFileDrop = styled(Flex)`
  position: relative;
  width: 100%;
  max-width: 100%;
  min-width: 100px;
  width: 100%;
  cursor: pointer;
  min-height: 45px;
  height: auto;
  align-items: center;
  border: 2px dashed #fff;
  border-radius: 4px;
  color: #fff;
  box-shadow: inset 0 -2px 8px var(--primaryColor);
  max-width: 100%;
  flex-grow: 1;
  align-items: flex-end;
  border: 1px dashed var(--primaryColor);

    & .file-drop-label {
      position: absolute;
      width: 100%;
      height: 100%;
        display: flex;
        font-weight: 500;

        font-Size: 12px;
        color: #fff;
        background: none;
        text-overflow: ellipsis;
        overflow: hidden;
        min-height: 28px;
        align-items: center;
        justify-content: center;
        line-height: 1;
        pointer-events: none;
        z-index: 1;
        padding: 0px 10px;
        border-radius: 3px;
        cursor: pointer;
        align-items: center;
        max-width: 100%;
    }

    & .file-clear-button {
      position: absolute;
      top: 0;
      right: 0;
      font-size: 32px;
      padding: 4px;
      position: relative;
      background: rgba(0,0,0,0.5);
      color: #fff;
      font-weight: 400;
      line-height: 20px;
      z-index: 3;
    }

    & input {
      cursor: pointer;
      height: 30px;
      width: 100%;
      opacity: 0;
      position: absolute;
      width: 100%;
      height: 100%;
      top:0;
      left:0;
    }


 

  `

  const ImagePreview = styled(Box)`
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: #fff url('${props => props.src}') no-repeat center top / contain;
  `;
  const VideoPreview = styled.video`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  object-fit: contain;
  z-index: 2;
  `;


  export default function FileDrop(props){

      const [filesLoaded, setFilesLoaded] = React.useState(false);
      const [fileName, setFileName] = React.useState('Choose File');
      const [fileType, setFileType] = React.useState(null);
      const [previewData, setPreview] = React.useState('');
      let action = props.action;
      let type = props.type;


      return(
        <Flex flexDirection="column" style={props.style} className={props.className+" file-drop-container"}>
        {props.label && <PropertyLabel>{props.label}</PropertyLabel>}
        <StyledFileDrop className={`file-drop ${props.name}`}>
          {props.preview && filesLoaded && fileType === 'image' && <ImagePreview src={previewData} className="image-preview"></ImagePreview>}
          {props.preview && filesLoaded && fileType === 'video' && <VideoPreview src={previewData} autoPlay={false} muted={true} playsInline={true} controls={true} className="video-preview"></VideoPreview>}
  
          <Flex className="file-drop-label">{fileName}</Flex>
          {filesLoaded && <ActionButton className="file-clear-button" action={() =>{
            setFilesLoaded(false);
            setFileName('Choose File');
            action('', '', '', '');
          }}>×</ActionButton>}
         
          <input
          className= {`file-drop-input`}
          onChange= {function(event) {

            let files = event.currentTarget.files;

            if (files.length > 0) {
              setFilesLoaded(true);
            } else {
              setFilesLoaded(false);
            }
            const labelComponent = event.currentTarget.parentNode.parentNode.querySelector(".file-drop-label");
            labelComponent.textContent = "";

            for (let i=0; i<files.length;i++) {
              if (type === "text") {
                loadText(files[i], function(data) {
                  action(data.source, data.type, data.name, data.extension);
                });
              } else if (type === "data") {
                loadData(files[i], function(data) {
                  //This could prob return an object
                  action(data.source, data.type, data.name, data.extension);
                  if (props.preview && ['jpg', 'jpeg', 'gif', 'svg', 'png', 'webp'].includes(data.extension)){
                    setPreview(data.source);
                    setFileType('image');
                  } else if (props.preview && ['mov', 'mp4',].includes(data.extension)) {
                    setPreview(data.source);
                    setFileType('video');
                  }
                });
              } else if (type === "json") {

              }
        
              setFileName(files[i].name+" ");
             
     
            }
            //event.currentTarget.value = "";
          }}
          type= "file"
          multiple="multiple"
        />
       
        </StyledFileDrop>
        </Flex>
        )
    
  };