import { clickParameters } from "../../../entities/advertibleInterfaces";
import getComponentId from "../getComponentId";

const createClickParameters = (event:MouseEvent|TouchEvent) => {
    const params:clickParameters = {
        component: getComponentId(event)
    }

    const parametersArray = Object.keys(params).map(key => {
        return `${key}=${params[key]}`;
    })

    return parametersArray.join('|');
}

export default createClickParameters;