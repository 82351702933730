import React from 'react';
import styled from 'styled-components';
import { OptionGroup } from "@components";

const StyledOptionGroup = styled(OptionGroup)`
  background: transparent;
  border-radius: 0;
  padding: 0;

  .option-item {
    position: relative;
    color: ${props => props.theme?.colors?.primary || '#000'};
    border: 1px solid rgba(0, 0, 0, 0.1);
    padding: 4px;
    background: #fff;
    box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.14);
    transition: all 0.3s ease-in-out;
    min-width: 32px;

    .option-name {
      font-size: 7px;
      font-weight: 600;
      position: absolute;
      bottom: -28px;
      left: 50%;
      transform: translateX(-50%) translateY(-12px);
      color: #49c1d1;
      background: #fff;
      padding: 4px;
      border-radius: 4px;
      letter-spacing: 1px;
      box-shadow: 0 0 4px -1px rgba(0, 0, 0, 0.6);
      border-top: 1px solid rgba(0, 0, 0, 0.2);
      transition: all 0.3s ease-out;
      opacity: 0;
      pointer-events: none;
    }

    &.toggled {
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.2);
      border-color: currentColor;
      background: rgba(69, 196, 212, 0.26);
      border-color: rgba(0, 0, 0, 0.11);

      svg {
        color: ${props => props.theme?.colors?.key || '#333'};
        filter: drop-shadow(0 1px 0 rgba(0,0,0,0.25)) drop-shadow(0 0 2px #fff);
      }
    }

    &:hover {
      border-color: ${props => props.theme.colors.accent};
      .option-name {
        border-color: ${props => props.theme.colors.primary};
        opacity: 1;
        transform: translateX(-50%) translateY(0);
      }
    }
  }
`;

export default function CursorMode({cursor, setCursor}) {
  const options = [
  //  {icon: <i className="fas fa-swatchbook"></i>, name: "SWATCHES", value: 'spray'},
  //  {icon: <i className="fas fa-mitten"></i>, name: "PAN", value: 'pan'},
    {icon: <i className="fa-solid fa-arrow-pointer"></i>, name: "SELECT", value: 'select'},
    {icon: <i className="fa-solid fa-i-cursor"></i>, name: "TYPE", value: 'type'},
    {icon: <i className="fas fa-vector-square"></i>, name: "ADD", value: 'insert'},
  //  {icon: <i className="fas fa-cube"></i>, name: "TRANSFORM", value: 'transform'},
    {icon: <i className="fas fa-bolt"></i>, name: "ACTION", value: 'action'},
  //  {icon: <i className="fas fa-search-plus"></i>, name: "ZOOM", value: 'zoom'},
  ];

  const keyMap = {
    '1': 'spray',  // QWERTY: 1, AZERTY: &, Dvorak: '1'
    '2': 'pan',    // QWERTY: 2, AZERTY: é, Dvorak: '2'
    '3': 'select', // QWERTY: 3, AZERTY: ", Dvorak: '3'
    '4': 'type',   // QWERTY: 4, AZERTY: ', Dvorak: '4'
    '5': 'insert', // QWERTY: 5, AZERTY: (, Dvorak: '5'
    '6': 'transform', // QWERTY: 6, AZERTY: -, Dvorak: '6'
    '7': 'action',   // QWERTY: 7, AZERTY: è, Dvorak: '7'
    '8': 'zoom'    // QWERTY: 8, AZERTY: _, Dvorak: '8'
  };

  React.useEffect(() => {
    const handleKeydown = (event) => {
      const mode = keyMap[event.key];
     
      if (mode) {
        // Optionally, you could toggle the class here if necessary
        document.querySelectorAll(".contextual-menu .contextual-item").forEach((item, index) => {
          if (options[index] && options[index].value === mode) {
            window.cohoba.cursor = mode;
         //   action(mode);
            item.classList.add('toggled');
          } else {
            item.classList.remove('toggled');
          }
        });
  
      }
    };
  
    document.addEventListener('keydown', handleKeydown);
  
    // Cleanup the event listener on component unmount or when dependencies change
    return () => {
      document.removeEventListener('keydown', handleKeydown);
    };
  }, []); // Include any other dependencies here if needed

  const handleAction = (value) => {
    document.querySelectorAll(".contextual-menu .contextual-item").forEach((item, index) => {
      if (item.classList.contains(value)) {
        item.classList.add('toggled');
      } else {
        item.classList.remove('toggled');
      }
    });
    window.cohoba.cursor = value;
    setCursor(value);

    if (value === 'type') {
      document.querySelectorAll("#workspace *").forEach(c => {c.setAttribute("contenteditable", true)})
     
    } else {
      document.querySelectorAll("#workspace *").forEach(c => {c.removeAttribute("contenteditable")})
    }
  };

  return <StyledOptionGroup options={options} value={cursor} action={handleAction} />;
}