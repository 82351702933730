import * as React from "react";
import {Box} from 'rebass';
import { PropertyLabel, ColorMixer, FaderInput, PropertyInput, FileDrop, WindowCard, OptionList, OptionGroup, Switch,Accordion } from "@components";
//import WorkspaceAlign from "./WorkspaceAlign";
import DeviceOptions from '../views/DeviceOptions';
import styles from '../workspace/textures.css';
export default class WorkspaceSettings extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      autoCenter: this.props.workspace?.autoCenter || false,
      x: this.props.workspace?.x || 0,
      y: this.props.workspace?.y || 0,
      textureEnabled: false,
    };

    this.updatePosition = this.updatePosition.bind(this);
    this.updateDevice = this.updateDevice.bind(this);
    this.updateType = this.updateType.bind(this);
    this.updateScale = this.updateScale.bind(this);
    this.updateWidth = this.updateWidth.bind(this);
    this.updateHeight = this.updateHeight.bind(this);
    this.toggleAutoCenter = this.toggleAutoCenter.bind(this);

    this.setTextureState = this.setTextureState.bind(this);

    this.WorkspaceAlign = React.createRef();
    this.window = React.createRef();
  }

  updatePosition(value) {
    let workspace = this.props.workspace;
    workspace.x = parseInt(value[0]);
    workspace.y = parseInt(value[1]);
    window.cohoba.updateWorkspace(workspace);
    console.log("updating position - parseInt(value[0]):", parseInt(value[0]), "parseInt(value[1]):", parseInt(value[1]))
    console.log("updating position - workspace.x:", workspace.x, "workspace.y:", workspace.y)
  }

  setTextureState(state) {
    this.setState({textureEnabled: state})
  }

  updateRotation(value) {}

  updateWidth(value) {
    if (
      this.props.workspace.target !== "none" &&
      typeof this.props.workspace.target !== "undefined"
    ) {
      // window.cohoba.workspaceElement.style.width = parseInt(value[0])+"px";
      //  window.cohoba.workspaceElement.style.height = parseInt(value[1])+"px";
      // return
    }
    let workspace = this.props.workspace;
    let width = parseInt(value);
    workspace.size[0] = width;
    window.cohoba.updateWorkspace(workspace);
    // window.cohoba.workspaceArray[window.cohoba.currentIndex].size[0] = width;
  }

  updateHeight(value) {
    if (
      this.props.workspace.target !== "none" &&
      typeof this.props.workspace.target !== "undefined"
    ) {
      // window.cohoba.workspaceElement.style.width = parseInt(value[0])+"px";
      // window.cohoba.workspaceElement.style.height = parseInt(value[1])+"px";
      // return
    }
    let workspace = this.props.workspace;
    let height = parseInt(value);
    workspace.size[1] = height;
    window.cohoba.updateWorkspace(workspace);
    // window.cohoba.workspaceArray[window.cohoba.currentIndex].size[1] = height;
  }

  updateType(value) {
    let workspace = this.props.workspace;
    workspace.type = value;
    window.cohoba.updateWorkspace(workspace);
  }

  updateDevice(value) {
    // centers the workspace upon loading a device
    this.props.workspace.autoCenter = true;
    let workspace = this.props.workspace;
    workspace.target = value;
    window.cohoba.updateWorkspace(workspace);
  }

  updateScale(value) {
    this.props.workspace.scale = value;
    window.cohoba.updateWorkspace(this.props.workspace);
  }

  toggleAutoCenter() {
    let workspace = this.props.workspace;
    if (this.state.autoCenter) {
      workspace.autoCenter = false;
      window.cohoba.updateWorkspace(workspace);
      // this.setState({ autoCenter: false });
    } else {
      workspace.autoCenter = true;
      window.cohoba.updateWorkspace(workspace);
      this.WorkspaceAlign.current.positionWorkspace("center");
      //  this.setState({ autoCenter: true });
    }
  }

  componentDidUpdate() {
    // window.cohoba.workspace.autoCenter = this.state.autoCenter;
  }

  render() {
    if (typeof this.props.workspace == "undefined") {
      return null;
    }
    let updatePosition = this.updatePosition;
    let updateWidth = this.updateWidth;
    let updateHeight = this.updateHeight;
    let updateType = this.updateType;
    let updateDevice = this.updateDevice;
    let updateScale = this.updateScale;
    let setTextureState = this.setTextureState;
   

    let workspace = this.props.workspace;
 

    function loadReference(source, type) {
      if (type === "png" || type === "jpg" || type === "jpeg" || type === "gif") {
        let referenceImage = document.createElement("img");
        referenceImage.style.width = "100%";
        referenceImage.style.position = "relative";
        referenceImage.style.top = "0px";
        referenceImage.style.left = "0px";
        referenceImage.src = source;

        let referenceCanvas = document.createElement("canvas");
        referenceCanvas.id = "reference-canvas";
        referenceCanvas.style.width = "100%";

        let canvasContext = referenceCanvas.getContext("2d");
        let img = new Image();
        img.src = source;

        window.cohoba.workspaceContainer.querySelector(
          ".cohoba-workspace-scroller"
        ).appendChild(referenceCanvas);

        img.onload = () =>{
          let ratio = img.naturalWidth/img.naturalHeight;
          let width = referenceCanvas.getBoundingClientRect().width;
          let height = width/ratio;
          console.log(width, height)
          //referenceCanvas.width = "100%";
          referenceCanvas.width = width;
          referenceCanvas.height = height;
          referenceCanvas.style.height = height + 'px';
          canvasContext.drawImage(img, 0, 0, width, height);

          cohoba.updateWorkspace({...cohoba.workspace, size: [cohoba.workspace.size[0], cohoba.workspace.size[0]/ratio]})
        }


        // window.cohoba.workspaceContainer.querySelector(
        //   ".cohoba-workspace-scroller"
        // ).appendChild(referenceImage);

        if (typeof window.cohoba.workspace.background_images == "undefined") {
          window.cohoba.workspace.background_images = [];
          window.cohoba.workspace.background_images.push(source);
        } else {
          window.cohoba.workspace.background_images.push(source);
        }
      } else if (type === "mp4") {
        let referenceImage = document.createElement("video");
        referenceImage.style.width = "100%";
        referenceImage.style.position = "relative";
        referenceImage.style.top = "0px";
        referenceImage.style.left = "0px";
        referenceImage.src = source;
        referenceImage.setAttribute("autoplay", true);
        referenceImage.setAttribute("loop", true);
        referenceImage.setAttribute("mute", true);
        referenceImage.setAttribute("playsinline", true);

        window.cohoba.workspaceContainer.querySelector(
          ".cohoba-workspace-scroller"
        ).appendChild(referenceImage);
      }

      window.cohoba.workspaceContainer.classList.add("backgroundLoaded");
    }

    let placementWidth = 0 + "px";
    let placementHeight = 0 + "px";

    /* If there are no workspace coordinates,
    give them default values of 0 */
    if (workspace && !workspace?.x && !workspace?.y) {
      this.props.workspace.x = 0;
      this.props.workspace.y = 0;
    }

    let xPosition = Math.round(workspace?.x);
    let yPosition = Math.round(workspace?.y);

    if (workspace?.size) {
      placementWidth = workspace.size[0] + "px";
      placementHeight = workspace.size[1] + "px";
    }

    let backgroundImages = null;

    const textureOptions = [
      {name: "dots", value: "dots"},
      {name: "triangles", value: "triangles"},
      {name: "texture", value: "texture"},
      {name: "Graph", value: "graphBackground"},
      {name: "architect", value: "architect"},
      {name: "puzzle", value: "puzzle"},
      {name: "hideout", value: "hideout"},
      {name: "waves", value: "waves"},
      {name: "tiny-checkers", value: "tiny-checkers"},
      {name: "plus", value: "plus"},
      {name: "plus-2", value: "plus-2"},
      {name: "parkay", value: "parkay"},
      {name: "zig-zag", value: "zig-zag"},
      {name: "tic-tac-toe", value: "tic-tac-toe"},
      {name: "ilusion", value: "ilusion"},
      {name: "houndstooth", value: "houndstooth"},
      {name: "signal", value: "signal"},
      {name: "sigpolka-dotsnal", value: "polka-dots"},
      {name: "diagonal-stripes", value: "diagonal-stripes"},
      {name: "diagonal-lines", value: "diagonal-lines"},
      
    ];

    return (
      <div>

        {this.props.workspace?.id &&  <PropertyInput type='static' label="Id" value={this.props.workspace?.id} action={(value)=>{
          cohoba.updateWorkspace({id: value});
        }}/>}

        <PropertyInput type='static' label="Title" value={this.props.workspace?.title} action={(value)=>{
          cohoba.updateWorkspace({title: value});
        }}/>

        <DeviceOptions />

        <WindowCard>
          <PropertyLabel>Workspace Size</PropertyLabel>
          
          <div
            className="cohoba-size-component experimental"
            style={{
              display: "flex",
       
            }}
          >
            <PropertyInput
              sublabel="W"
              value={this.props.workspace?.size.at(0)}
              unit="px"
              
              //value={placementWidth}
              action={function (value) {
                // placementHeight = window.cohoba.workspaceElement.style.width

                updateWidth(value);
              }}
            />

            <PropertyInput
              sublabel="H"
              value={this.props.workspace?.size.at(1)}
              unit="px"
              action={function (value) {
                updateHeight(value);
              }}
            />
          </div>

        </WindowCard>
        <Box>
          <OptionGroup
            label="Mode"
            // name="cohoba-workspace-type"
            options={[
              { name: "stack", value: "stack" },
              { name: "flow", value: "flow" },
             
            ]}
            style={{ display: "inline-block", width: "50%" }}
            value={this.props.workspace?.type}
            action={(value)=>{
              window.cohoba.updateWorkspace({mode:value})
            }}
          />
</Box>
<OptionGroup
            label="Type"
            style={{ display: "inline-block", width: "50%" }}
            options={[
              { name: "Interactive", value: "relative" },
              { name: "Static", value: "fixed" },
              { name: "Template", value: "relative" },
              { name: "Widget", value: "fixed" },       
              { name: "Editor", value: "fixed" },       
            ]}
            action={function (value) {
              window.cohoba.updateWorkspace({type:value})
            }}
            value={this.props.workspace?.positionType}
          />


        <WindowCard>
       
          
          <Accordion>
            <ColorMixer preview={true} label="Frame Background" action={(value => {
              document.querySelector("#frame-content").style.backgroundColor = value;
            })}/>

            <Switch label="pattern" value={this.state.textureEnabled} action={(value)=>{
              setTextureState(!value)
       
            }} />

            {this.state.textureEnabled && <OptionGroup options={textureOptions} action={(value) => {
              textureOptions.forEach(option => {
                document.querySelector("#frame-content").classList.remove(option.value);
              });
              document.querySelector("#frame-content").classList.add(value);
              console.log(styles);
            }}/>}
        
            <PropertyInput label="Pattern size" value="8px" unit="px" action={(value)=>{
              document.querySelector("#frame-content").style.backgroundSize = value;
            }}/>
          </Accordion>

          <Accordion title="Workspace Background">
           
              <ColorMixer
                label="Background Color"
                value="#fff"
                preview={true}
                input={true}
                action={function (value) {
                  if (typeof window.cohoba.workspaceContainer !== "undefined") {
                    window.cohoba.workspaceContainer.style.background = value;
                    window.cohoba.workspaceContainer.classList.add("backgroundLoaded");

                    if (value === "") {
                      window.cohoba.workspaceContainer.classList.remove(
                        "backgroundLoaded"
                      );
                    }
                  }
                }}
              />
            
            <FileDrop
              type="data"
              className="workspace-background"
              style={{
                position: "relative",
                visibility: "visible",
                width: "100%",
                height: "80px",
                opacity: "1",
                margin: "0px auto",
                zIndex: "999",
              }}
              action={loadReference}
            />
          </Accordion>

        </WindowCard>

       


        

          <Switch
            action={function () {
              if (
                window.cohoba.workspaceContainer.classList.contains("sizeLabelOn")
              ) {
                window.cohoba.workspaceContainer.classList.remove("sizeLabelOn");
              } else {
                window.cohoba.workspaceContainer.classList.add("sizeLabelOn");
              }
            }}
            label="Size Label"
          />
          <Switch
            action={function () {
              if (
                window.cohoba.workspaceContainer.classList.contains("nameLabelOn")
              ) {
                window.cohoba.workspaceContainer.classList.remove("nameLabelOn");
              } else {
                window.cohoba.workspaceContainer.classList.add("nameLabelOn");
              }
            }}
            label="Title Label"
          />

<Switch
            action={function () {
              if (
                window.cohoba.workspaceContainer.classList.contains("nameLabelOn")
              ) {
                window.cohoba.workspaceContainer.classList.remove("nameLabelOn");
              } else {
                window.cohoba.workspaceContainer.classList.add("nameLabelOn");
              }
            }}
            label="Shadow"
          />

<Switch
            action={function () {
              if (
                window.cohoba.workspaceContainer.classList.contains("nameLabelOn")
              ) {
                window.cohoba.workspaceContainer.classList.remove("nameLabelOn");
              } else {
                window.cohoba.workspaceContainer.classList.add("nameLabelOn");
              }
            }}
            label="Outline"
          />
       

      </div>
    );
  }
}
