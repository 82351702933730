import "./code.css";
import React from "react";
import copyStringToClipboard from "../components/copyStringToClipboard";
import snippets from "./snippets";
import downloadFile from "./downloadFile";
import CodeEditor from "./CodeEditor";
import ActionButton from "../components/ActionButton";
import ActionList from "../components/ActionList";
import Window from "../components/Window";
import WindowHeader from "../components/WindowHeader";
import WindowBody from "../components/WindowBody";
import WindowFooter from "../components/WindowFooter";
import inspect from "../workspace/inspect";
import audit from "../workspace/audit";
import replaceCreative from "../workspace/replaceMacros";
import attach from "../template/attach";

import styled from 'styled-components';
import Switch from "../components/Switch";
//import styleTags from './styleTags.json';
import updateSource from './updateSource.js';
import { useWorkspace } from "../views/Platform/WorkspaceContext";
import { convertComponentToJsx, convertLayoutToJsx } from "./generateView";
import extractMimeTypeFromDataURL from "../utilities/extractMimeTypeFromDataURL";
import { replaceDataURL } from "../utilities/replaceDataURL";
import findAllDataURLs from "../utilities/findAllDataURLs";
import ReplaceMacrosInLayout from "../interactive/setupModule/replaceMacros";
// styled-components
const StyledCodeWindow = styled(Window)`



  & .code-editor-container{
    width: 100%;
  
    position: relative;}
    & .CodeMirror {
    height: 100%;
    width: 100%;
    font-size: 12px;
   }

   & .CodeMirror-scroll{
     -webkit-overflow-scrolling : touch !important;
  }

  & .cm-s-material div.CodeMirror-selected{
    background-color: rgba(255, 255, 255, 0.25) !important;
  }
  


`


export default function CodeWindow ({windowRef, frame}) {

  const {workspace, setWorkspace} = useWorkspace();
  const [code, setCode] = React.useState(workspace?.render);
  

    const state = {
      workspace: workspace,
      findReplaceVisibility: false,
      position:[200,200],
      size:[600,400]
    };


    const codeEditorRef = React.useRef();
  

  React.useEffect(()=>{
    if (codeEditorRef.current) {
      if (codeEditorRef.current.CodeEditor.refresh) {
        
        codeEditorRef.current.CodeEditor.refresh();
      }
    }
    codeEditorRef.current.CodeEditor.refresh();
  },[])

  React.useEffect(()=>{
    if (code !== workspace?.layouts[frame].markup) {
      console.log('workspace changed and code doesnt match')
     // setCode(workspace?.layouts[frame].markup);
       codeEditorRef.current.updateDirectly(workspace?.layouts[frame].markup)
    }
  },[workspace])

  React.useEffect(()=>{

    if (!workspace) {
      return;
    }

    if (typeof window.cohoba.CodeEditor !== "undefined" && codeEditorRef.current) {
      if (workspace.type === "flow") {
      // this updates the editor
      codeEditorRef.current.CodeEditor.setValue(workspace.render || "");
      } 
      // Clear the editor if there's no workspace
    }

    codeEditorRef.current.CodeEditor.refresh();

    // let CodeWindow = document.querySelector("#code-module");
    // let top = parseInt(CodeWindow.style.top);
    // let left = parseInt(CodeWindow.style.left);
    // let width = parseInt(CodeWindow.style.width);
    // let height = parseInt(CodeWindow.style.height);
    // let CodeWindowConfig = {position:[top,left], size:[width,height]};
    // // console.log("Storing window info",state);
    // window.localStorage.setItem("cohoba-code-window-config", JSON.stringify(CodeWindowConfig));
  
  },[])

  const toggleFindReplace = () => {
    // console.log("Toggle find replace");
    if (state.findReplaceVisibility) {
      setState({ findReplaceVisibility: false });
      document.querySelector("#find-and-replace-button").style.backgroundColor =
        "unset";
      document.querySelector("#find-and-replace-button").style.color = "unset";
    } else {
      setState({ findReplaceVisibility: true });
      document.querySelector("#find-and-replace-button").style.backgroundColor =
        "#888";
      document.querySelector("#find-and-replace-button").style.color = "#fff";
    }
  }

  //This functions is probably not updating the template/render code correctly and we need to do it without refreshing the whole frame.

  const editCode = (code) => {
    console.log('editCode')
    cohoba.workspace.layouts[cohoba.frame].markup = code;

    cohoba.workspaceElement.innerHTML = ReplaceMacrosInLayout(cohoba.workspace?.layouts[cohoba.frame], cohoba.workspace?.assets);
    // This will reset the code editor...must find workaround
   // setWorkspace(workspace => ({...cohoba.workspace, code: code}));

   // cohoba.workspaceElement.innerHTML = code;
  }

  const insertSnippet = (code) => {
    codeEditorRef.current.CodeEditor.replaceSelection(code);
    codeEditorRef.current.CodeEditor.focus(code);
    window.cohoba.workspace.inspect = inspect(
    // window.cohoba.workspace.preview
    );
    window.cohoba.trackEvent("user", "insertSnippet", "inserted", code);
  }



  const createSnippetsArray = (array) => {
    let actionArray = [];

    for (let i = 0; i < array.length; i++) {
      let insertFunction = function (event) {
        let index = event.target.dataset["index"];

        let code = snippets[index].code;
        if (snippets[index].snippet === "universal-template") {
          code = code.replace(
            /universal_template/g,
            window.cohoba.workspace.title
          );
        } else if (snippets[index].snippet === "custom-js") {
          code = code.replace(
            /PUT_INV_CODE_HERE/g,
            window.cohoba.workspace.title
          );
        }

        insertSnippet(code);
        window.cohoba.trackEvent(
          "user",
          "insertSnippet",
          snippets[index].snippet,
          true
        );
      };

      actionArray.push({ name: array[i].name, action: insertFunction });
    }
    return actionArray;
  }


  const formatCode = () => {
    codeEditorRef.current.formatCode();
  }

    function copyRender() {
      copyStringToClipboard(window.cohoba.workspaceElement.innerHTML);
    }

    

    let toggledValue = false;
    if (workspace) {
      if (workspace.mode === "edit") {
        toggledValue = true;
      }
    }

    const buildLayout = () => {
      let jsx = convertLayoutToJsx(workspace.layouts[0]);
      jsx = `
        import * as React from 'react';
        import styled from 'styled-components';
        
        export default function ${workspace.title}(props) {

          React.useEffect(() => {
            /* On Mount */
          }, []);

          React.useEffect(() => {
            /* On Render */
          }, [props]);

          return (${jsx});
        }
      `;
      setCode(jsx);
    }
    
    let styledComponent = `
      const ComponentName = styled('componentBase')\`\`
    `;
    /*
    Defining styled components
    - Base
    - Styles

    Defining custom component
    - Base
    - Styles
    - Children/Layout
    */


    return (
      <StyledCodeWindow ref={windowRef}
        name="code"
        type="panel"
        position={state.position}
        size={state.size}
        onOpen={e=>{
        //  setTimeout(()=>{window.cohoba.CodeEditor.refresh();},50);
          }}
      >
        <WindowHeader>
        
        {/* <ActionButton
            action={buildLayout}
            >
            BUILD LAYOUT  <i className="fas fa-copy" />
          </ActionButton> */}

          <ActionButton
            // transparent is a styled-component prop
            transparent
            label="RENDER"
            action={copyRender}
            >
              <i className="fas fa-copy" />
          </ActionButton>



          

          <ActionButton
            // transparent is a styled-component prop
            transparent
            id="find-and-replace-button"
            action={function () {
              toggleFindReplace();
            }}
          >
            <i className="fas fa-search"></i>
          </ActionButton>



          <ActionList
            style={{margin:"0"}}
            actions={[
              {name : "DATAURLs TO ASSETS", action:()=>{
         
const dataURLs = findAllDataURLs(cohoba.workspace.layouts[cohoba.frame].markup);
dataURLs.forEach(du => {
  const mimeType = extractMimeTypeFromDataURL(du);
  const assetId = Math.random().toString().split(".")[1].slice(0,8);
  const assetName = "Asset #"+assetId;
  const assetObject = {
    id: assetId,
    name: assetName, 
    data: du, 
    type: mimeType?.split('/')?.at(0), 
    mimeType: mimeType, 
    fileName: assetName+'.png'
  };
  cohoba.workspace.layouts[cohoba.frame].markup = replaceDataURL(cohoba.workspace.layouts[cohoba.frame].markup, du, `{{ASSET_${assetId}}}`);
  cohoba.workspace.assets.push(assetObject);
})
cohoba.updateWorkspace({assets:[...cohoba.workspace.assets], layouts:[...cohoba.workspace.layouts]});

              }, value:"Test"},
              {name : "CLEAN", action:()=>{
                window.CodeMirror.commands["selectAll"](window.cohoba.CodeEditor);
                window.cohoba.CodeEditor.autoFormatRange(
                  window.cohoba.CodeEditor.getCursor(true),
                  window.cohoba.CodeEditor.getCursor(false)
                );
                window.CodeMirror.commands["selectAll"](window.cohoba.CodeEditor);
              }, value:"Test"},
             
              {
                name: "DOWNLOAD",
                action: function () {
                  let fileName = prompt(
                    "File name?",
                    window.cohoba.workspace.title
                  );
                  downloadFile(fileName, window.cohoba.workspace.code);
                },
              },



             

            ]}
            icon={<i className="fas fa-ellipsis-v"></i>}
            />
        </WindowHeader>

        <WindowBody
          style={{
            height: "100%",
            padding: "0",
            maxHeight:"calc(100% - 90px)"

          }}
        >
          <CodeEditor
          mode="text/html"
            findReplaceVisibility={state.findReplaceVisibility}
            ref={codeEditorRef}
            onChange={editCode}
            formatCode={true}
            toggleFindReplace={toggleFindReplace}
            code={code}
            workspace={workspace}
          />
        </WindowBody>


      </StyledCodeWindow>
    );
  
}