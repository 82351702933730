import html2canvas from "html2canvas";

export default function renderToCanvas(callback){
            const renderElement = window.cohoba.workspaceElement;
            //renderElement.innerHTML = html;
            const canvas = document.createElement("canvas");
            canvas.id = "render-canvas";
            canvas.width = renderElement.clientWidth*2;
            canvas.height = renderElement.clientHeight*2;
            const clonedRenderElement = renderElement?.cloneNode(true);
            // clonedRenderElement.style.width = renderElement?.clientWidth+"px";
            // clonedRenderElement.style.height = renderElement?.scrollHeight+"px";
            clonedRenderElement.style.margin = "0";
          //  clonedRenderElement.style.maxHeight = 'unset';
            clonedRenderElement.style.position = 'relative';
            clonedRenderElement.style.overflow = 'hidden';
            clonedRenderElement.transformOrigin = "top left";
       //     clonedRenderElement.style.transform = "scale(1.25)";

//clonedRenderElement.style.visibility = "hidden";
document.body.appendChild(clonedRenderElement);
        //     const images = Array.from(document.querySelectorAll(".cohoba-workspace-scroller img"));
        //    document.querySelector("#preview-panel").appendChild(RenderImagesToCanvas(images));
        html2canvas(clonedRenderElement, { canvas: canvas,  allowTaint: true,useCORS: true})
        .then((canvas) => {
          // Append the canvas to the document or use it as needed
      //    document.querySelector("#preview-panel").appendChild(canvas);
      clonedRenderElement?.remove();
      callback(canvas);
          //document.body.appendChild(canvas);
        })
        .catch((error) => {
          console.error('Error rendering HTML to canvas:', error);
        });
        }