import MRAIDModule from "../mraidModule"
import renderImpressionTrackers from "./renderImpressionTrackers"
import renderClickTrackers from "./renderClickTrackers"
import ErrorModule from "../errorModule";
import OMIDModule from "../omidModule";
import VideoModule, { createVideoTrackingParameters } from "../videoModule";
import isViewable from "./isViewable";
import SetupModule from "../setupModule";
import LayoutModule from "../layoutModule";

import createImpressionParameters from "./createImpressionParameters";
import createClickParameters from "./createClickParameters";
import attachListeners from "./attachListeners";
import getPlacementType from "./getPlacementType";
import getLoadTime from "./getLoadTime";
import getRenderTime from "./getRenderTime";
import getFinishTime from "./getFinishTime";

import isTouchDevice from "./isTouchDevice";

const AnalyticsModule = (function () {
    return {
        getLoadTime: getLoadTime,
        getRenderTime: getRenderTime,
        getFinishTime: getFinishTime,
        getPlacementType: getPlacementType,
        isViewable: isViewable,
        createClickParameters: createClickParameters,
        createImpressionParameters: createImpressionParameters,
        attachListeners: attachListeners,
        renderImpressionTrackers: renderImpressionTrackers,
        renderClickTrackers: renderClickTrackers,
        isTouchDevice: isTouchDevice,
        initialize: () => {
            try {
                const adContainer = SetupModule.getAdContainer();
                if (!VideoModule.isVideo()){
                    AnalyticsModule.renderImpressionTrackers(adContainer);
                }
                AnalyticsModule.attachListeners(adContainer);
            } catch(e){
                ErrorModule.capture({...e, stack:"AnalyticsModule : "+e.stack});
            }
        }
    }
})();

export default AnalyticsModule;