import React from "react";
import PropertyInput from "../../components/PropertyInput";
import OptionList from "../../components/OptionList";
import Accordion from "../../components/Accordion";
import FlexProperties from "./FlexProperties";
import styler from "../../design/styler";
import { BorderStyle } from "../quickStylers/QuickStylers";
import OptionGroup from "../../components/OptionGroup";
import {Flex} from 'rebass';
import { ActionButton } from "../../components";

export default class LayoutProperties extends React.Component {
  constructor(props) {
    super(props);

    this.updateRender = this.updateRender.bind(this);
  }

  updateRender(selection) {
    this.setState(selection);
  }

  render() {
   
    if (
      typeof this.props.selection == "undefined" ||
      this.props.selection == null
    ) {
     // return "Select Item Undefined";
    } else {
    }
    return (
     
      <Accordion icon={<i class="fas fa-ruler-combined"></i>} title={<Flex className="accordion-title-label">Layout<ActionButton action={e => {
        e.currentTarget.classList.toggle('toggled');
      }}name="maximize-button"><i className="far fa-square"></i><i className="fas fa-square"></i></ActionButton></Flex>}><div
          
          className="margin-properties"
        >
        <FlexProperties selection={this.props.selection} />

          <PropertyInput
            sublabel="T"
            style={{
              display: "inline-block",
              width: "50%",
              marginBottom: "25px",
            }}
            value={this.props.selection?.marginTop}
            action={function (value) {
              styler("margin-top", value);
              //  updateValue({marginTop : value})
            }}
          />

          <PropertyInput
            sublabel="R"
            style={{ display: "inline-block", width: "50%" }}
            value={this.props.selection?.marginRight}
            action={function (value) {
              styler("margin-right", value);
              //  updateValue({marginRight : value})
            }}
          />

          <PropertyInput
            sublabel="B"
            style={{
              display: "inline-block",
              width: "50%",
              marginBottom: "25px",
            }}
            value={this.props.selection?.marginBottom}
            action={function (value) {
              styler("margin-bottom", value);
              //   updateValue({marginBottom : value})
            }}
          />

          <PropertyInput
            sublabel="L"
            style={{ display: "inline-block", width: "50%" }}
            value={this.props.selection?.marginLeft}
            action={function (value) {
              styler("margin-left", value);
              // updateValue({marginLeft : value})
            }}
          />
        </div>

        <div
          className="padding-properties"
          style={{ display: "inline-block", width: "50%" }}
        >
          <div className="property-label" style={{ display: "block" }}>
            Padding
          </div>

          <PropertyInput
            sublabel="T"
            style={{
              display: "inline-block",
              width: "50%",
              marginBottom: "25px",
            }}
            value={this.props.selection?.paddingTop}
            action={function (value) {
              styler("padding-top", value);

              // updateValue({paddingTop : value})
            }}
          />

          <PropertyInput
            sublabel="R"
            style={{ display: "inline-block", width: "50%" }}
            value={this.props.selection?.paddingRight}
            action={function (value) {
              styler("padding-right", value);

              // updateValue({paddingRight : value})
            }}
          />

          <PropertyInput
            sublabel="B"
            style={{
              display: "inline-block",
              width: "50%",
              marginBottom: "25px",
            }}
            value={this.props.selection?.paddingBottom}
            action={function (value) {
              styler("padding-bottom", value);

              // updateValue({paddingBottom : value})
            }}
          />

          <PropertyInput
            sublabel="L"
            style={{ display: "inline-block", width: "50%" }}
            value={this.props.selection?.paddingLeft}
            action={function (value) {
              styler("padding-left", value);

              // updateValue({paddingLeft : value})
            }}
          />
        </div>
        
       

       

        <PropertyInput
          label="Z-index"
          value={this.props.selection?.zIndex}
          action={function (value) {
            styler("z-index", value);
          }}
        />

       

<PropertyInput
            style={{ marginBottom: "30px" }}
            sublabel="Opacity"
            value={this.props.selection?.opacity * 100}
            action={function (value) {
              value = value / 100;

              styler("opacity", value);
            }}
          />

        

        <OptionList
          value={this.props.selection?.verticalAlign}
          label="Vertical Align"
          options={[
            { name: "Top", value: "top" },
            { name: "Middle", value: "middle" },
            { name: "Bottom", value: "bottom" },
            { name: "Baseline", value: "baseline" },
          ]}
          action={function (value) {
            styler("vertical-align", value);
            // updateValue({verticalAlign : value});
          }}
        />

   
          <OptionList
            id="float"
            label="Float"
            options={[
              { name: "Left", value: "left" },
              { name: "right", value: "right" },
              { name: "none", value: "none" },
            ]}
            action={function (value) {
              styler("float", value);
              //     updateValue({float : value})
            }}
            value={this.props.selection?.float}
          />
          </Accordion> 
    );
  }
}
