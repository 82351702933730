const getComponentId = (event:MouseEvent|TouchEvent) => {
    let componentId = event.target?.dataset['component'];
      if (!componentId) {
          componentId = event.target?.getAttribute("id");
      }
      if (!componentId) {
          try {
          const regex = /\badvertible_(\w+)/;
          const classnames = `${event.target?.getAttribute("class")}`;
          const match = classnames.match(regex);
          if (match) {
          componentId = match[1];
          }
      }catch(e){
         console.error(e)
      }
      }
    return componentId;
}

export default getComponentId;