//import cohoba from '../..';

const uploadAsset = (file, callback) => {
    if (!window.cohoba.user?.token) {return}
    const formData = new FormData();
    formData.append('file', file); // Append the file from the input

    fetch(process.env.API_ENDPOINT+'/media', {
        method: "POST",
        headers: {
       //    'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${window.cohoba.user?.token}`
        },
        body: formData
    }).then(response => response.json())
    .then(data => {console.log('Uploaded image:', data); callback(data)})
    .catch(error => console.error('Error uploading image:', error));
};

export default uploadAsset;