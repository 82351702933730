import styled from 'styled-components';
import React, {useState} from 'react';
import {Flex} from 'rebass';

const StyledAccordion = styled.div`
  max-height: 40px;
  overflow: hidden;
  transition: all 0.25s ease-out;
  margin: 0 auto;
  min-height: 40px;
  position: relative;
  box-sizing: border-box;

  border-bottom: 1px solid whitesmoke;

    max-height: 28px;
    overflow: hidden;
    transition: all 0.25s ease-out;
    margin: 0 auto;
    min-height: 28px;
    position: relative;
    box-sizing: border-box;
    align-items: center;
    flex-grow: 1;
    /* display: flex; */
    justify-content: space-between;
    width: 100%;

  &.toggled {
    max-height: 1080px;
    overflow-y: auto;
    overflow-x: hidden;
  }

  & .accordion-title {
    position: relative;
    height: auto;
    height: 40px;
  }

  & .accordion-title {
    display: flex;
    align-items: center;
    position: relative;
    padding: 0 4px;
    margin: 0;
    border-top: 1px solid ${props => props.theme.lightGray || "var(--light-gray)"};
    border-width: 1px 0;
    transition: all 0.25s ease-out;
    width: 100%;
    font-size: 14px;
    font-weight: 400;
    left: 0;
    z-index: 9;
    box-sizing: border-box;
    cursor: pointer;
  }

  & .accordion-title:hover {
    background: rgba(0, 0, 0, 0.055);
  }


  & .accordion-title span {
    margin-left: 5px;
  }

  &.toggled .accordion-title {
    transition: 0.1s all ease-in-out;
    border-bottom:none;
  }

  &.toggled .accordion-title:hover {
    background: ${props => props.theme.lightGray || "var(--light-gray)"};
    color:${props => props.theme.darkestGray || "var(--darkest-gray)"};
  }


  & .accordion-title .chevron-indicator {
    display: inline-block;
    transform: ${props => props.icon ? 'rotate(0deg);' : 'rotate(-90deg)'};
    transition: all 0.25s ease-out;
  }

  &.toggled .accordion-title .chevron-indicator {
    transform: ${props => props.icon ? 'rotate(0deg);' : 'rotate(0deg)'};
  }

  & .accordion-body {
    max-height: 0;
    overflow: auto;
    transition: all 0.25s ease-out;
    padding: 0;
    opacity: 0;
  }

  &.toggled .accordion-body {
    max-height: 1080px;
    overflow-y: auto;
    padding: 10px;
    opacity: 1;
    border-bottom: 1px solid ${props => props.theme.lightGray || "var(--light-gray)"};
  }

  &.accordionSection-container {
   background: ${props => props.theme.mediumGray || "var(--medium-gray)"};
   flex: 0 0 100%;
  }

  &.top-open-container {
   max-height: 100%;
  }

  & .accordion-title {
    flex-grow: 1;
    justify-content: space-between;
    padding: 0 8px;
    font-size: 11px;
    text-transform: capitalize;
    font-weight: 400;
    line-height: 1;
    height: fit-content;
    color: var(--textColor);

    padding: 8px 8px;
    line-height: 1;
    border-bottom: 1px solid whitesmoke;

    > svg {
      color: #314a61;
    }
    > div {
      flex-grow: 1;
    justify-content: space-between;
    padding: 0 0 0 8px;
    }

    .accordion-title-label {
      align-items: center;
    flex-grow: 1;
    justify-content: space-between;
    margin-left: 8px;
    font-size: 11px;
    vertical-align: middle;
    align-content: center;
    line-height: 1;
    }
  }
  & .maximize-button {
    font-size: 6px;
    color: #47c3d4;
    transform: rotate(45deg);
    border: none;
    border-style: none;
    border-radius: 4px;
    padding: 0;
    height: 12px;
    width: 12px;
    min-height: 0;
    margin-left: 8px;



    .toggled {
      /* border: 1px solid #deeef0; */
    }

    svg {

      
      :first-child {
        display: none;
      }
      :last-child{
        display: block;
      }
    color: inherit;
    
    line-height: 1;
    margin: 0;
    }
    svg:first-child{
      display: none;
    }
    .toggled svg:first-child{
      display: none;
    }
    .toggled svg:last-child{
      display: block;
    }
    
    .toggled svg {
      

      
     
      color: inherit;
      /* transform: rotate(45deg); */
      filter: drop-shadow(0px 0px 1px currentColor);
      line-height: 1;
      margin: 0;
    }
    
  }


`




export default function Accordion(props){


  //const[expanding, setExpanding] = useState(true);
  const[toggled, setToggled] = useState(props.toggled);


  const toggleSection = (event)=>{
      event.currentTarget.parentNode.classList.toggle("toggled");
      if (props.onToggle){
        props.onToggle(event, !toggled);
      }
      setToggled(!toggled);
     
  }

  let className = toggled ? "accordion-section toggled" : "accordion-section";

  //className = props.title ? (className += " "+props.title) : false;

    return(

      <StyledAccordion
      {... props}
      key={props.key || props.title || Math.random()}
      className={className + " "+props.name +" "+props.className}>

        <Flex
          className={"accordion-title"}
          onClick={toggleSection}
          >

         {props.icon || <span className='chevron-indicator'><i className="far fa-caret-square-down"></i></span>}

           {props.title}
        </Flex>

        <Flex className="accordion-body" flexDirection={"column"}>
           {props.children}
        </Flex>

     </StyledAccordion>

    )

}