import * as React from 'react';
import styled from 'styled-components';
import Header from './Header';

const WindowHeaderContainer = styled(Header)`
  display: flex;
  height:auto;
  border-radius: 4px 4px 0 0;
  width: 100%;
  max-width: 100%;
  white-space: break-spaces;
  z-index: 999;
  box-sizing: border-box;
  z-index: 9999999;

  .window & {
    box-shadow: none;
    display: flex;
    justify-content: space-around;
    position: relative;
    border-width: 2px;
  
  }

  & .action-button {
    min-height: 24px;
    background: transparent;
  }
`;

export default function WindowHeader (props){
    return(

    	<WindowHeaderContainer
    	  style={props.style}
    	  className={props.className+" window-header"}
    	  >
         {props.children}
      </WindowHeaderContainer>

      );
};