import React from "react";
import ActionButton from "./ActionButton";
import {styled} from 'goober';

import Window from './Window';
import WindowHeader from "./WindowHeader";
import WindowBody from "./WindowBody";
import WindowFooter from "./WindowFooter";

const ModalStyle = styled('div')`



& .ModalStyle-footer {
  display:flex;
}


`

export const ModalContainer = styled('div')`
  width: 800px;
  max-width: calc(100% - 60px);
  height: 500px;
  min-height: fit-content;
  max-height: calc(100% - 70px);
  background: linear-gradient(to top, rgb(41, 12, 50), rgb(71, 20, 86));
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  color:#fff;

  & .window-footer {padding-right: 8px; padding-left: 8px;}
`;

export const StyledOverlay = styled('div')`
position: absolute;
width:100%;
height:100%;
background:rgba(0,0,0,0.5);
`;

const ModalTitle = styled('div')`
  font-size: 18px;
  font-weight: bold;
`

export default function Modal({icon, title, onConfirm, confirmLabel, cancelLabel, children, style, className}) {
  const [isOpen, setIsOpen] = React.useState(true);

  if (!isOpen) {
    return null;
  }

  return (
    <StyledOverlay className="modal-overlay" onClick={(e) => {if (e.currentTarget.classList.contains('.modal-overlay')){setIsOpen(false); if (onConfirm){onConfirm(false)}}}}>
      <Window style={style} className={className}>
    <WindowHeader>
        <ModalTitle>
          {icon ? icon : <i
            className="fas fa-exclamation-circle"
            style={{ marginRight: "16px" }}
          ></i>}
        {title}
        </ModalTitle>
        </WindowHeader>
        <WindowBody>
        {children}
        </WindowBody>
    
      <WindowFooter>
       

        <ActionButton
          name="cancel"
          style={{ background: "transparent", color: "#666" }}
          action={() => {
            setIsOpen(false);
            onConfirm(false);
          }}
        >
          CANCEL
        </ActionButton>

        <ActionButton
        primary
        name="accept"
          action={() => {
            setIsOpen(false);
            onConfirm(true);
          }}
        >
          {confirmLabel ? confirmLabel : 'ACCEPT'}
        </ActionButton>
      </WindowFooter>
    </Window></StyledOverlay>
  );
}
