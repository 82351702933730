import ErrorModule from "../../errorModule";
import MRAIDModule from "../../mraidModule";

const getCurrentPosition = () => {
    try {

   
    if (MRAIDModule.mraid && MRAIDModule.mraid?.getCurrentPosition) {
        return MRAIDModule.mraid?.getCurrentPosition() || {x: 0, y: 0};
    } else if (window.$sf) {
        // This needs to be async
        window.$sf.ext.getPosition(function(position) {
            // The position object contains information about the ad position
            console.log('Ad Position:', position);
          });
    } 
    else if (window.frameElement) {
        // Calculate position based off page
    }
} catch(e){
        ErrorModule.capture({...e, stack:"AnalysticModule : "+e.stack});
}
}

export default getCurrentPosition;