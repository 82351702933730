import * as React from 'react';
import styled from "styled-components";
import { Knob } from "../../components";

const StyledPanel = styled.div.attrs({id: 'side-panel'})`
    position: absolute;
    top: 0px;
    bottom: 0;
    right: 0;
    overflow: hidden;
    width: 250px;
    max-width: 100%;
    z-index: 999999999999999;
    transform-origin: right center;
    transition: all .3s ease-in-out;
    padding-left: 36px;
    box-shadow: 0 0 12px 4px rgb(0 0 0 / 10%);
    & #menu {
        position: absolute;
        top: 0;
        left: 0;
        padding: 0;

    }

    & .window {
        top: 0 !important;
        left: 36px !important;
        width: calc(100% - 36px) !important;
        height: 100% !important;
        position: absolute;
       
    }

    .interactive-mode &&& {
        transform: scaleX(0)
    }

    .panel-resize-knob.panel-resize-knob.panel-resize-knob {
        top: 0 !important;
        left: 0 !important;
        width: 24px;
        height: 24px;
        position: absolute;
    }
`;

export default function SidePanel(props){
    return <StyledPanel>
        {props.children}
        <Knob className={'panel-resize-knob'} freeMode={true} style={{position: "absolute", top: 0, left: '0', zIndex: 9, }} onMove={(offset, knob, etc)=>{
            console.log(offset, etc);
            document.querySelector('#side-panel').style.width = window.innerWidth-offset.x + 'px';
        }}/>
        </StyledPanel>
};