import * as React from 'react';
import styled, { useTheme } from 'styled-components';
import {Box} from 'rebass';
import { PropertyInput, ActionButton,FaderInput } from '@components';

const ZoomControlFader = styled(FaderInput)`
margin: 0px;
    display: flex;

left: 0;
width: fit-content;
transform-origin: left center;


    input[type='range'] {
      min-width: 150px;
      margin: 0 12px 0 8px;
      display: flex;
    }

    .text-input {
      font-size: 11px;
      width: 56px;
    }
`;

const ZoomControlContainer = styled(Box)`
 
`;

export default function ZoomControl({workspace, updateWorkspace}){

  const theme = useTheme();
  if (!workspace) {}

return <ZoomControlContainer> 
  <ZoomControlFader
  unit="%"
  value={workspace?.scale*100}
  minValue={25}
  step={5}
  maxValue={500}
  style={{margin: 0}}
  textInput={true}
  action={function (value) {
    // let zoomValue = ((value/100)* 2);
    let zoomValue = value / 100;
    updateWorkspace({scale: zoomValue});
  }}
  />
</ZoomControlContainer>

          }