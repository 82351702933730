export function useCohoba(){
    if (window.cohoba) {
        return window.cohoba;
    } else {
        window.cohoba = {
            //container
            //frame
        };
    }
}

let config = window.cohoba;

if (!config) {
    window.cohoba = {};
    config = window.cohoba;
}

export default config;