import MRAIDModule from "../../mraidModule";

const getTrackingFramework = () => {
    let track = window.render_params?.track ? window.render_params.track : 'none';
    if (MRAIDModule.mraid) {
        track = 'mraid'
    } else if (window.$sf) {
        track = 'safeframe'
    } else if (window.frameElement && window.top) {
        track = 'friendly'
    }
    return track;
}

export default getTrackingFramework;