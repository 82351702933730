import * as React from 'react';
import styled from 'styled-components';
import {Box, Flex} from 'rebass';
import { ActionButton } from '../../components';

const AssetContainer = styled(Flex)`

margin-bottom: 16px;
padding: 4px 0;
flex-direction: column;


`;
const AssetHeader = styled(Flex)`
    font-size: 8px;
    text-transform: uppercase;
    font-weight: bold;
    color: #ccc;
    padding: 0 0 4px;
    justify-content: space-between;
    align-items: center;
`;
const AssetType = styled('div')`

`;
const AssetUsage = styled(Box)``;
const AssetContent = styled(Flex)`
border-radius: 4px;
border: 1px solid rgba(0,0,0,0.25);
padding: 4px;
box-shadow: 0 4px 8px -4px rgba(0,0,0,0.25);
.selected &{
    border: 2px solid ${props => props.theme.colors.accent};
}
`;
const AssetPreview = styled('div')`
background: #ccc no-repeat center / cover; width: 60px; height: 60px; min-width: 60px; border-radius: 4px; margin-right: 8px;`;
const AssetDescription = styled(Flex)`flex-direction: column; justify-content: space-evenly;`;

const AssetName = styled(Box)`
color: #222;
 font-size: 12px; 
 font-weight: 600;
 margin: 0 0 8px;`;
const AssetId = styled(Box)`font-size: 11px;
color: #666;`;
const AssetData = styled(Box)`font-size: 10px;
white-space: nowrap;
text-overflow: ellipsis;
overflow: hidden;
max-width: 100%;`;
const AssetFooter = styled(Box)``;

/*

// Draggable Element
document.getElementById('draggable').addEventListener('dragstart', drag);

function drag(ev) {
    ev.dataTransfer.setData("text", ev.target.id);
    // Here you can set other data if needed
    // ev.dataTransfer.setData("text/html", "<p>Here's some HTML</p>");
}

// Droppable Element
document.getElementById('droppable').addEventListener('dragover', allowDrop);
document.getElementById('droppable').addEventListener('drop', drop);

function allowDrop(ev) {
    ev.preventDefault(); // Necessary to allow dropping
}

function drop(ev) {
    ev.preventDefault();
    var data = ev.dataTransfer.getData("text");
    ev.target.appendChild(document.getElementById(data));
}

*/

export default function AssetItem({asset, onClick, index}) {

            const usage = 0;
            const assetPreview = asset.type === 'image' ? asset.data : '';

          return (<AssetContainer key={Math.random()} className="asset-container" data-index={index} onClick={onClick} draggable="true" onDragStart={(e)=>{e.dataTransfer.setData("text", e.currentTarget.querySelector(".asset-preview").outerHTML);
          console.log(e);
          }} onDragEnd={(e)=>{}}>
              <AssetHeader className="asset-header">
                  <AssetType className="asset-type">{asset.type ? asset.type : 'TEXT/HTML'}</AssetType>
                  <AssetUsage className="asset-use">{asset.id || '000'}</AssetUsage>
                  
              </AssetHeader>
              <AssetContent className="asset-content">
                  <AssetPreview className="asset-preview" style={{backgroundImage:`url('${assetPreview}')`}}>
                      {!asset.type === 'image' && <i className="fas fa-file-alt"></i>}
                      </AssetPreview>
                  <AssetDescription className="asset-description">
                      <AssetName className="asset-name">{asset.name}</AssetName>
                      <AssetId className="asset-id">{asset.id || '000'}</AssetId>
                      <AssetData className="asset-data">{asset.data}</AssetData>
                  </AssetDescription>
              </AssetContent>
              <AssetFooter className="asset-footer">
            
                  </AssetFooter>
          </AssetContainer>);
        }
      