import * as React from 'react';
import * as goober from 'goober';
import { extractCss } from 'goober';
import { shouldForwardProp } from 'goober/should-forward-prop';
import { prefix } from 'goober/prefixer';
//import Parent from '../content/Parent';

// import {
//     ActionButton,
//     ActionList,
//     Accordion,
//     Knob,
//     Message,
//     MessageContainer,
//     Modal,
//     OptionGroup,
//     OptionList,
//     Spinner,
//     StyledOverlay,
//     Window,
//     WindowHeader,
//     WindowBody,
//     WindowFooter,
//     WindowModule,
//     WindowCard,
//     WindowNavButton,
//     PropertyLabel,
//     PropertyInput,
//     Switch,
//     ColorMixer,
//     FileDrop,
//     FaderInput,
//     Menu,
//     MenuIcon,
//     MenuLabel
// } from '@components';
// import { AppBar, StatusBar, createFeedWireframe } from '../components/preview-components/Wireframe';
import Container from '../components/content/Container';
// import Footer from '../content/Footer';
// import Content from '../content/Content';
// import Headline from '../content/Headline';
// import Caption from '../content/Caption';
import Format from '../components/content/Player';
// import Cta from '../content/Cta';
import Icon from '../components/content/Icon';
// import Logo from '../content/Logo';
//import Brand from '../content/Brand';
// import Disclosure from '../content/Disclosure';
//import VideoPlayer from '../content/VideoPlayer';
import NewVideoPlayer from '../components/content/VideoPlayer/Player';
import Box from '../components/content/Box';
import Flex from '../components/content/Flex';

const target = document.getElementById("render-styles");
const css = goober.css.bind({ target: target });
const styled = goober.styled.bind({ target: target });

goober.setup(React.createElement, prefix, undefined,  shouldForwardProp((prop) => {
    return !['styles','inline'].includes(prop);
}));


const componentLibrary = {
   // "parent": Parent,
    "container": Container,
    // "content": Content,
    // "headline": Headline,
    // "caption": Caption,
    // "cta" : Cta,
    // "logo" : Logo,
    // "brand" : Box,
    // "disclosure": Disclosure,
    "format": Format,
    "video": NewVideoPlayer,
    "div" : Box,
    "box" : Box,
    "frame" : Box,
    "flex" : Flex,
    "img": "img",
    "span": "span",
    // "OptionGroup": OptionGroup,
    "icon": Icon,
    // "footer": Footer,
    // 'ActionButton': ActionButton,
    // 'ActionList':ActionList,
    // 'Accordion':Accordion,
    // 'Knob':Knob,
    // 'Message':Message,
    // 'MessageContainer':MessageContainer,
    // 'Modal':Modal,
    // 'OptionGroup':OptionGroup,
    // 'OptionList':OptionList,
    // 'Spinner':Spinner,
    // 'StyledOverlay':StyledOverlay,
    // 'Window':Window,
    // 'WindowHeader':WindowHeader,
    // 'WindowBody':WindowBody,
    // 'WindowFooter':WindowFooter,
    // 'WindowModule':WindowModule,
    // 'WindowCard':WindowCard,
    // 'WindowNavButton':WindowNavButton,
    // 'PropertyLabel':PropertyLabel,
    // 'PropertyInput':PropertyInput,
    // 'Switch':Switch,
    // 'ColorMixer':ColorMixer,
    // 'FileDrop':FileDrop,
    // 'FaderInput':FaderInput,
    // 'Menu':Menu,
    // 'MenuIcon':MenuIcon,
    // 'MenuLabel':MenuLabel,
    // 'AppBar' : AppBar
}

window.componentCss = extractCss();

export default componentLibrary;