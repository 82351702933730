

function checkUnit(string){
 	var unit = "";
 	
  if(string.split("%").length === 2){
     unit = "%";
  }else if(string.split("px").length === 2){
     unit = "px";
  }else if(string.split("em").length === 2){
     unit = "em";
  }else if(string.split("rem").length === 2){
     unit = "rem";
  }
  return unit;
}

export default checkUnit;