import React from "react";
import styler from "./styler";
import updateSource from "../code/updateSource";
import inspect from "../workspace/inspect";
import ActionButton from "../components/ActionButton";
import OptionList from "../components/OptionList";
import OptionGroup from "../components/OptionGroup";
import PropertyInput from "../components/PropertyInput";
import Accordion from "../components/Accordion";
import FileDrop from "../components/FileDrop";

export default class ComponentProperties extends React.Component {
  constructor(props) {
    super(props);
    this.updateValue = this.updateValue.bind(this);
  }

  updateValue(value) {
    if (isNaN(value[Object.keys(value)[0]])) {
      value[Object.keys(value)[0]] = 0;
    }
    this.setState(value);
  }

  componentDidUpdate() {}

  render() {
    let updateValue = this.updateValue;
    let updateStyles = this.props.updateStyles;
    let selection = this.props.selection;

    if (typeof this.props.selection == "undefined") {
      return "Select Item Undefined";
    }

    let imageProperties = null;

    let videoProperties = null;

    if (typeof this.props.selection.object !== "undefined") {
      if (this.props.selection.object?.tagName == "VIDEO") {
        videoProperties = (
          <Accordion title="VIDEO">
            <PropertyInput
              value={this.props.selection.object.src}
              type="static"
              action={function (value) {
                cohoba.selection.object.setAttribute("src", value);
              }}
            />
          </Accordion>
        );
      }

      if (this.props.selection.object?.tagName == "IMG") {
        let imageRatio =
          this.props.selection.object.naturalWidth /
          this.props.selection.object.naturalHeight;

        imageRatio = imageRatio + ":1";

        if (
          parseFloat(imageRatio, 2) == 1.78 ||
          parseFloat(imageRatio, 2) == 1.79
        ) {
          imageRatio = "16:9";
        }

        imageProperties = (
          <Accordion title="IMAGE">
            <FileDrop
              name="image-swap"
              type="data"
              action={function (data, type) {
                window.cohoba.selection.object.src = data;
                window.cohoba.selection.object.removeAttribute("srcset");
              }}
            />

            <PropertyInput
              label="Ratio"
              action={function (value) {
                console.log(value);
              }}
              value={imageRatio}
            />

            <ActionButton
              action={function () {
                styler("width", window.cohoba.selection.box.height + "px");
              }}
            >
              1:1
            </ActionButton>

            <ActionButton
              action={function () {
                styler(
                  "width",
                  window.cohoba.selection.box.height * 2 + "px"
                );
              }}
            >
              2:1
            </ActionButton>

            <ActionButton
              action={function () {
                styler(
                  "width",
                  (4 / 3) * window.cohoba.selection.box.height + "px"
                );
              }}
            >
              4:3
            </ActionButton>

            <ActionButton
              action={function () {
                styler(
                  "width",
                  (16 / 9) * window.cohoba.selection.box.height + "px"
                );
              }}
            >
              16:9
            </ActionButton>

            <ActionButton
              action={function () {
                styler(
                  "width",
                  window.cohoba.selection.object.naturalWidth + "px"
                );
              }}
            >
              Max Width
            </ActionButton>

            <ActionButton
              action={function () {
                styler(
                  "height",
                  window.cohoba.selection.object.naturalHeight + "px"
                );
              }}
            >
              Max Height
            </ActionButton>

            <ActionButton
              action={function () {
                styler(
                  "width",
                  window.cohoba.selection.object.naturalWidth + "px"
                );
                styler(
                  "height",
                  window.cohoba.selection.object.naturalHeight + "px"
                );
              }}
            >
              Max All
            </ActionButton>

            <PropertyInput
              label="FIT"
              value={this.props.selection.objectFit}
              type="static"
              action={function (value) {
                cohoba.selection.object.style.objectFit = value;
              }}
            />

            <PropertyInput
              label="POSITION"
              value={this.props.selection.objectPosition}
              type="static"
              action={function (value) {
                cohoba.selection.object.style.objectPosition = value;
              }}
            />
          </Accordion>
        );
      }
    }

    let anchorProperties = null;

    if (typeof this.props.selection.object !== "undefined") {
      if (this.props.selection.object?.tagName == "A") {
        anchorProperties = (
          <Accordion title="ANCHOR (LINK)">
            <PropertyInput
              value={this.props.selection.href}
              type="static"
              label="HREF"
              action={function (value) {
                window.cohoba.selection.object.setAttribute("href", value);
                updateSource();
              }}
            />

            <PropertyInput
              value={this.props.selection.target}
              type="static"
              label="TARGET"
              action={function (value) {
                window.cohoba.selection.object.setAttribute("target", value);
                updateSource();
              }}
            />
          </Accordion>
        );
      }
    }

    return (
      <div>
        <PropertyInput
          unit=""
          type="static"
          value={this.props.selection.textContent}
          label="Text"
          action={function (value) {
            cohoba.selection.object.textContent = value;
          }}
        />

        <OptionList
          value={this.props.selection.object?.tagName}
          label="Tag"
          action={function (value) {
            let object = window.cohoba.selection.object;
            let newObject = document.createElement(value);

            for (let i = 0; i < object.attributes.length; i++) {
              newObject.setAttribute(
                object.attributes.item(i).nodeName,
                object.attributes.item(i).nodeValue
              );
            }

            newObject.innerHTML = object.innerHTML;

            object.parentNode.replaceChild(newObject, object);

            updateSource();
            window.cohoba.workspace.inspect = inspect();
          }}
          options={[
            { name: "P", value: "P" },
            { name: "DIV", value: "DIV" },
            { name: "A", value: "A" },
            { name: "SPAN", value: "SPAN" },
          ]}
        />

        <PropertyInput
          value={this.props.selection.id}
          label="Id"
          action={function (value) {
            window.cohoba.selection.object.setAttribute("id", value);
          }}
        
        />

        <PropertyInput
          value={this.props.selection.className}
          label="Class"
          action={function (value) {
            window.cohoba.selection.object.setAttribute("class", value);
          }}
        
        />

        <OptionGroup
          label="Component"
          options={[
            { name: "Container", value: "container" },
            { name: "Headline", value: "headline" },
            { name: "Caption", value: "caption" },
            { name: "CTA", value: "cta" },
            { name: "Brand", value: "brand" },
            { name: "Brand Name", value: "brand_name" },
            { name: "Disclosure", value: "disclosure" },
          ]}
          action={function (value) {
            selection.component = value;
            cohoba.selection.component = value;
            if (selection.object) {
              selection.object.dataset["component"] = value;
              updateSource();
            }

            if (selection.libraryIndex) {
              let styles = window.cohoba.styles;
              styles[selection.libraryIndex].component = value;
             updateStyles(styles);
            }
          }}
          value={this.props.selection.component}
        />

        <PropertyInput
          label="XPath"
          value={this.props.selection.zIndex}
          action={function (value) {
            styler("z-index", value);
          }}
        />

        {videoProperties}
        {imageProperties}
        {anchorProperties}

        <Accordion title="EVENTS">
          <PropertyInput
            label="onClick"
            value={this.props.selection.object?.onClick}
            type="static"
            action={function (value) {
              cohoba.selection.object.setAttribute("onclick", value);
              updateSource();
            }}
          />
        </Accordion>
      </div>
    );
  }
};


