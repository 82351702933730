import * as React from "react";
import styled from 'styled-components';
import {Box, Flex} from 'rebass';
import selectProps from "../template/selectProps";
import Knob from "../components/Knob";
import ActionButton from "../components/ActionButton";
import Switch from "../components/Switch";
import copyStringToClipboard from "../components/copyStringToClipboard";
import styler from "../design/styler";
import {PropertyInput} from "@components";
import selector from "./selector";
import ColorMixer from "../design/styler-module/ColorProperties";
const VisualizerContainer = styled(Box)`
display: block;
width: 100%;
background: #fff;
padding: 0px;
text-align: center;
max-height: 480px;
min-height: 70px;
line-height: 60px;
overflow: hidden;
max-width: 100%;
min-width: 100%;
border-radius: 0px;
border: 1px solid var(--light-blue);
z-index: 999;
min-height: 36px;

* {
  font-size: inherit;
}

.property-input span {
  padding: 2px;
    font-size: inherit;
    flex-direction: row-reverse;
    display: flex;
    align-items: center;
    /* font-size: 6px; */
    color: #666;
    
}

&&& {
display: flex;
    /* align-items: baseline; */
    /* justify-content: unset; */
    position: relative;
    height: 36px;
    padding-left: 4px;
    padding-right: 24px;

    .maximize-button {
      transform: rotate(45deg);
      font-size: 8px;
      position: absolute;
      right: 8px;
      top: 11px;
      border: 1px solid currentColor;
      border-radius: 4px;
      padding: 1px;
      width: 12px;
      height: 12px;
      box-sizing: border-box;
      /* margin-left: 6px; */
    }

.window-header & {

  border-radius: 4px;
  border: none;
  background: whitesmoke;
  /* margin: 4px; */
  padding: 4px;
  width: calc(100% - 4px);
  margin: 2px auto;
  font-size: 8px;
  margin: 0;
    padding: 4px 0 0;
    background: none;
    justify-content: space-between;
}

&&& .minimal .property-sublabel {
  margin: 0;
    padding: 0;
    text-align: center;
    padding: 4px 0 0;
    color: #314a61;
    width: 2em;
    font-size: inherit;
    padding: 0;
    font-weight: 900;
}
&&& .minimal .text-input {
  text-align: center;
  min-width: 30px;
  padding: 4px 1em;
  font-size: 11px;
  font-family: monospace;
  border: none;
  background: none;
  box-shadow: none;
  border-bottom: 1px solid #8b8b8b;
  border-radius: 0;
  font-size: inherit;
  padding: 0;
  font-size: inherit;
  /* font-weight: bold; */
  border: none;
  color: #333333;
  min-width: 2em;
  /* min-width: fit-content; */
  flex-grow: 1;
  min-width: 2em;
    max-width: 4.5em;
    flex-grow: 1;
    border-width: 0 0 1px;
    text-align: center;
    width: fit-content;
}

&&& .position-size {

    font-size: inherit;
    align-items: stretch;
    min-width: 64px;
    /* max-width: fit-content;
    border-left: 1px solid #00000012;
    /* padding-left: 4px; */
    margin-left: 0px;
}

&&& .minimal .property-value-change-arrows{
  display: none;
}

&&& .minimal .property-input {
  position: relative;
    display: block;
    flex-direction: column;
    line-height: 1;
    font-size: inherit;
    /* flex-grow: 1;

    .text-input:focus ~ property-sublabel {
      color: #49c1d1;
      font-weight: 600;
    }
}

&&& .minimal .property-input span {
  padding: 2px;
    font-size: inherit;
    flex-direction: row-reverse;
    display: flex;
    align-items: center;
    /* font-size: 6px; */
    color: #666;

}

&&& .width-height, .left-top {
  display: flex;
    flex-direction: column;
    font-size: inherit;
    justify-content: space-between;
    padding: 0 4px 0 0px;
}

.visualizer-preview-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgb(255 255 255);
  flex-grow: 1;
  border-radius: 4px;
  max-height: 100%;
  overflow: hidden;
  margin-right: 2px;
  margin-right: -20px;
}

& .maximize-button {
  font-size: 6px;
  color: #47c3d4;
  transform: rotate(45deg);
  border: #f5f5f5 1px inset;
  border-style: inset;
  border-radius: 4px;
  padding: 0;
  height: 12px;
  width: 12px;
  min-height: 0;
  margin-left: 8px;
  border: 1px solid currentColor;
  border: 1px solid currentColor;
  position: relative;
  top: 8px;
  color: #a2e1e9;
  right: 4px;

  .toggled {
    /* border: 1px solid #deeef0; */
  }

  svg {

    
    :first-child {
      display: none;
    }
    :last-child{
      display: block;
    }
  color: inherit;
  
  line-height: 1;
  margin: 0;
  }
  svg:first-child{
    display: none;
  }
  .toggled svg:first-child{
    display: none;
  }
  .toggled svg:last-child{
    display: block;
  }
  
  .toggled svg {
    

    
   
    color: inherit;
    /* transform: rotate(45deg); */
    filter: drop-shadow(0px 0px 1px currentColor);
    line-height: 1;
    margin: 0;
  }
  
}
`;
const VisualizerPreview = styled.span`
background: transparent;
max-width: 100% !important;
background: rgba(255, 255, 255, .1);
display: inline-block;
position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);

`;

export default function Visualizer ({selection, visibility, style, theme}) {
  
  const [previewStyles, setPreviewStyles] = React.useState({});
  const visualizerRef = React.useRef();
  const previewRef = React.useRef();

  React.useEffect(()=>{
    console.log(selection);
    let element = selection?.object;
    if (!element) {
      return;
    }
    let elementSelection = selector(element);

    let componentPreviewStyles = {};
    if (elementSelection) {
      for (var prop in selectProps) {
        if (selectProps[prop].selectable) {
          if (typeof selectProps[prop].computedProp !== "undefined") {
            componentPreviewStyles[selectProps[prop].computedProp] = elementSelection[
              selectProps[prop].computedProp
            ];
          } else {
            componentPreviewStyles[prop] = elementSelection[prop];
          }
        }
      }
      componentPreviewStyles.display = "inline-block";
      componentPreviewStyles.width = "auto";
      componentPreviewStyles.height = "auto";
      setPreviewStyles(componentPreviewStyles);
    }
    
  },[selection]);  



    let toggleStatus = false;

    if (visibility === "visible") {
      toggleStatus = true;
    }

    var textString = selection?.textContent;
    if (typeof textString !== "undefined") {
      textString = textString.slice(0, 15);
    }


    return (
      <VisualizerContainer
        ref={visualizerRef}
        id="visualizer"
        key="visualizer-container"
        style={style}
      >
           <Flex padding="0 4px 4px" justifyContent="space-between">
{/* <OptionList
          label="Display"
          id="display"
          options={[
            { icon: "B", name: "Block", value: "block" },
            { icon: "IB", name: "Inline-block", value: "inline-block" },
            { icon: "I", name: "inline", value: "inline" },
            { icon: "F", name: "Flex", value: "flex" },
            { icon: "N", name: "None", value: "none" },
            { icon: "-WB-", name: "-webkit-box", value: "-webkit-box" },
          ]}
          action={function (value) {
            styler("display", value);
          }}
          value={selection?.display}
          style={{ display: "inline-block", width: "50%" }}
        />
         <OptionList
          label="Position"
          style={{ display: "inline-block", width: "50%" }}
          options={[
            { name: "Relative", value: "relative" },
            { name: "Absolute", value: "absolute" },
            { name: "Fixed", value: "fixed" },
            { name: "Static", value: "static" },
          ]}
          action={function (value) {
            styler("position", value);
          }}
          value={selection?.position}
        /> */}
{/*         
        <Switch icon={<i className="fas fa-eye"></i>}/> */}
       
         <ColorMixer theme={theme} selection={selection || cohoba.selection || document.querySelector("#select-item")}/> 
         
         <Flex className="position-size minimal">
            <Flex className="width-height">
              <PropertyInput
                sublabel="W"
                value={selection?.width}
                action={function (value) {
                  styler("width", value);
                }}
              />

              <PropertyInput
                sublabel="H"
             
                value={selection?.height}
                action={function (value) {
                  styler("height", value);
                }}
              />
          </Flex>

          </Flex>
         

          <Flex  className="min-max-size" style={{display:'none'}}>
            

          <PropertyInput
            label="Min"
            style={{ display: "inline-block", width: "50%" }}
            value={selection?.minWidth}
            action={function (value) {
              styler("min-width", value);
            }}
            />

          <PropertyInput
            label="Min"
            style={{ display: "inline-block", width: "50%" }}
            value={selection?.minHeight}
            action={function (value) {
              styler("min-height", value);
            }}
            />

          <PropertyInput
            label="Max"
            style={{ display: "inline-block", width: "50%" }}
            value={selection?.maxWidth}
            action={function (value) {
              styler("max-width", value);
            }}
            />

          <PropertyInput
            label="Max"
            style={{ display: "inline-block", width: "50%" }}
            value={selection?.maxHeight}
            action={function (value) {
              styler("max-height", value);
            }}
            />
            </Flex>
          
          <Flex  className="bounding-box minimal">
              <Flex  className="left-top">

          <PropertyInput
            sublabel="X"
            value={selection?.left}
            action={function (value) {
              styler("left", value);
            }}
            />

          <PropertyInput
            sublabel="Y"
            value={selection?.top}
            action={function (value) {
              styler("top", value);
            }}
            />

            </Flex>
            <Flex  className="bottom-right" style={{display: 'none'}}>

          <PropertyInput
            sublabel="R"
            style={{ display: "inline-block", width: "50%" }}
            value={selection?.right}
            action={function (value) {
              styler("right", value);
            }}
            />

          <PropertyInput
            sublabel="B"
            style={{ display: "inline-block", width: "50%" }}
            value={selection?.bottom}
            action={function (value) {
              styler("bottom", value);
            }}
            />
          </Flex>
          </Flex>
          </Flex>

        <div className="visualizer-preview-container">

        {/* <VisualizerPreview
          key="visualizer"
          ref={previewRef}
          className="visualizer-preview"
          data-style={JSON.stringify(previewStyles)}
          style={{...previewStyles}}
          className="component-preview"
          >
         
        </VisualizerPreview> */}
          </div>
          {/* <Switch insideLabel={true} label={ <i className="fas fa-thumbtack"></i>}/> */}
       
     
        {/* <ActionButton action={e => {
      e.currentTarget.classList.toggle('toggled');
    }}name="maximize-button"><i className="far fa-square"></i><i className="far fa-square"></i></ActionButton> */}
      </VisualizerContainer>
    );
}
