const renderPixel = (url: string) => {
  const pixel: HTMLImageElement = document.createElement('img');
  const srcUrl = new URL(url);
  srcUrl.searchParams.set("cb", Math.random().toString().slice(2,18));
  pixel.setAttribute('style', 'width: 1px; height: 1px; visibility: hidden; position: absolute; top:0; left:0;');
  pixel.setAttribute('src', srcUrl.href);
  pixel.addEventListener('load', () => {
   
  });

  return pixel;
};

export default renderPixel;
