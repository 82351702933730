import React from "react";
import Knob from "./Knob";
import styled, {css} from 'styled-components';
import {Flex} from 'rebass';

const WindowContainer = styled(Flex)`
  position: absolute;
  flex-direction: column;
  width: ${props => props.size ? props.size[0]+"px" : 'auto'};
  height: ${props => props.size ? props.size[1]+"px" : 'auto'};
  min-width: 240px;
  max-width: 100%;
  max-width: 1080px;
  overflow: visible;
  background: ${props => props.theme?.panel?.background || 'linear-gradient(to top, hsl(187 20% 95% / 1), hsl(334 20% 95% / 1))'};
  border-radius: 4px;
  left: ${props => props.position ? props.position[0] +"px" : '50%'};
  top: ${props => props.position ? props.position[1] +"px" : '50%'};
  text-align: left;
  color: ${props => props.theme?.colors?.text || "#333"};;
  padding: 0;
  margin: 0;
  z-index: 3;
  font-family: ${props => props.theme.fontFamily || "sans-serif"};
  border-left: 1px solid rgba(0, 0, 0, 0.025);
  border-right: 1px solid rgba(0, 0, 0, 0.025);
  border-bottom: 2px solid rgba(0, 0, 0, 0.13);
  box-shadow: 0 0px 8px -4px rgba(0, 0, 0, 0.5);
  opacity: 1;
  transition: 0.3s all ease-in-out;
  transform-origin: center right;

  &.active {
    z-index: 4;
  }

  &.moving,
  &.expanding {
    transition: none;
  }

  &.hidden {
    transform: translateX(100%) rotateY(90deg) !important;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
  }

  &.hidding {
    transform: translateX(-100%) rotateY(-90deg) !important;
    opacity: 1;
    pointer-events: none;
    z-index: 0;
  }

  & .window-header ~ .window-body {
    max-height: calc(100% - 30px);
  }

  & .window-footer ~ .window-body {
    padding-bottom: 30px !important;
  }

  ${props => props.type === 'modal' && css`
  height: calc(100% - 65px);
  max-height: 800px;
  left: calc(50% + 35px);
  top: calc(50% + 35px);
  transform: translate(-50%,-50%);
  `}

  ${props => props.type === 'panel' && css`
  position: relative;
  `}


`

export const WindowTitle = styled(Flex)`
  font-size: 11px;
  font-weight: bold;
  justify-content: space-between;
  align-items: center;
`;


export default class Window extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      hidden: this.props.hidden ? this.props.hidden : false,
      position: this.props.position ? this.props.position : [0, 0, 0, 0],
      size: this.props.size ? this.props.size : [300, 250],
      move: this.props.move ? this.props.move : false,
      scale: this.props.scale ? this.props.scale : false,
      maximize: this.props.maximize ? this.props.maximize : false,
      active : false,
      type: this.props.type ? this.props.type : 'window'
    };

    this.window = React.createRef();
    this.moveKnobRef = React.createRef();
    this.scaleKnobRef = React.createRef();
    this.maximizeKnobRef = React.createRef();

    this.onMove = this.onMove.bind(this);
    this.toggle = this.toggle.bind(this);
  }

  toggle(visible) {
    if (!this.state.hidden){
      let activeWindow = document.querySelector(".window.active");
      if (activeWindow !== null) {
        activeWindow.classList.remove("active");
      }
      this.window.current.classList.add("active");
    }
    
    if (typeof visible !== 'undefined') {
      this.setState({ hidden: !visible});
    } else {
      this.setState({ hidden: !this.state.hidden});
    }
    

    if (this.state.hidden && this.props.onOpen) {
      this.props.onOpen();
    } else if (this.props.onClose){
      this.props.onClose();
    }
  }

  onMove(){
    if(this.props.onMove){
      this.props.onMove();
    }
    this.window.current.moved = true;
  }

  componentDidUpdate() {
    if (this.moveKnobRef.current !== null) {
      this.moveKnobRef.current.positionKnob();
    }
    if (this.scaleKnobRef.current !== null) {
      this.scaleKnobRef.current.positionKnob();
    }
    if (this.maximizeKnobRef.current !== null) {
      this.maximizeKnobRef.current.positionKnob();
    }
    if (typeof this.props.hidden !== "undefined") {
      if (this.props.hidden !== this.state.hidden) {
        this.positionWindow();
        this.setState({ hidden: this.props.hidden});
      }
    }
  }

  render() {

  return(
    <WindowContainer {...this.props} className={`window ${this.props.className ? this.props.className : ''}${this.props.name ? " "+this.props.name : ''}${this.state.hidden ? " hidden" : ''}${this.state.active ? " active" : ''}`} ref={this.window} style={this.props.style} position={this.state.position} size={this.state.size}>
      {this.props.children}
    </WindowContainer>

    )
  }
}
