import findRule from "./findRule";
import buildCSS from "./buildCSS";
import updateCSS from "../workspace/updateCSS";
import updateMarkup from '../workspace/updateMarkup';
function styler(prop:string, value:string|number, object:HTMLObjectElement, stylesheet:object) {
		
		/* STARTING HERE WE SHOULD CLEAN THIS OUT */
		if(typeof(object) === "undefined"){
            if(document.contains(window.cohoba.selection?.object) ){
                 object = window.cohoba.selection.object;
            }else if (!window.cohoba.selection?.layout && window.cohoba.workspace.stylesheet && Object.keys(window.cohoba.workspace.stylesheet).find(key => key === window.cohoba.selection?.component)){
              object = window.cohoba.workspace.stylesheet[window.cohoba.selection?.component];
              console.log("Editing library item", object)
            }else if (window.cohoba.selection?.layout){
              object = window.cohoba.selection;
            }else{
                 object = document.querySelector(".cohoba-selector");
            }
	 	}

        if(object === null){
            window.cohoba.selection[prop] = value.replace(/"/g, "'");
            console.log("Edited prop and returned...")
            // Do code workspace update here
           return
		}

		/* ENDING HERE WE SHOULD CLEAN THIS OUT */

		let rule = null;
		let element = null;
		
    // Don't need this anymore....
	 	
	 	if(object instanceof Element && object.getAttribute("id") !== null && stylesheet !== null && typeof(stylesheet) !== "undefined"){
				 rule = findRule(stylesheet, object.tagName.toLowerCase()+"#"+object.id);
				if(rule == null){
					rule = findRule(stylesheet, "#"+object.id);
				}
		}else if(object instanceof Element && object.className[0] && stylesheet !== null && typeof(stylesheet) !== "undefined"){
				 rule = findRule(stylesheet, "."+object.classList[0]);
			 }

			//  //This could happen on select and can be stored to each selection (stylesheet)
			// 	const documentStyleSheets = Array.from(document.styleSheets).filter(
			// 		(styleSheet) => !styleSheet.href || styleSheet.href.startsWith(window.location.origin)
			// 	);


			// for(let i=0; i<documentStyleSheets.length; i++){

			// 	if(documentStyleSheets[i].cssRules){
			// 		object.classList.remove("cohoba-selector");
			// 		let checkRule = findRule(documentStyleSheets[i], "."+object.className);

			// 	if(checkRule){console.log(checkRule); rule = checkRule;}
			// 	}
				
			// }

		if(rule !== null){
				 element = rule;
				value = value.replace(/"/g, "'");
	   }else{

				 element = object;

				if(typeof(value) == "number"){
					value = value.toString();
				}
				value = value.replace(/"/g, "'");
              
			 }
			

		
		 if(typeof value == "string"){
			value = value.replace(/"/g, "'");
		 }


		 // Hack to get Advertible Style Library working with edits
		 let elementStyleObject = object.attributes?.style ? {...object.attributes.style} : {};

     if (!element || element === null) {
      element = {style:{}};
     } else if (!element.style) {
      element = {style:{}};
     }

     if (object?.layout) {
      if (!object.attributes) {
        object.attributes = {};
      }
      object = object.attributes;
      element = object.attributes?.style ? object.attributes.style : {style:{}};
     }
		 
		 //We can assign value directly and call out the exceptions instead of this switch

       switch(prop){
	       case "id":
	         object.id = value;
	       break;
	       
	       case "classList":
	         object.className = value;
	       break;
	       
	       case "text":
	         object.textContent = value;
	       break;
	       
	       case "title":
	         object.title = value;
	       break;
	       
	       case "position":
    element.style.position = value;
    elementStyleObject.position = value;
    break;

  case "z-index":
    element.style.zIndex = value;
    elementStyleObject.zIndex = value;
    break;

  case "display":
    element.style.display = value;
    elementStyleObject.display = value;
    break;

  case "visibility":
    element.style.visibility = value;
    elementStyleObject.visibility = value;
    break;

  case "overflow":
    element.style.overflow = value;
    elementStyleObject.overflow = value;
    break;

  case "top":
    element.style.top = value;
    elementStyleObject.top = value;
    break;

  case "right":
    element.style.right = value;
    elementStyleObject.right = value;
    break;

  case "bottom":
    element.style.bottom = value;
    elementStyleObject.bottom = value;
    break;

  case "left":
    element.style.left = value;
    elementStyleObject.left = value;
    break;

  case "width":
    element.style.width = value;
    elementStyleObject.width = value;
    break;

  case "height":
    element.style.height = value;
    elementStyleObject.height = value;
    break;

  case "max-width":
    element.style.maxWidth = value;
    elementStyleObject.maxWidth = value;
    break;

  case "min-width":
    element.style.minWidth = value;
    elementStyleObject.minWidth = value;
    break;

  case "max-height":
    element.style.maxHeight = value;
    elementStyleObject.maxHeight = value;
    break;

  case "min-height":
    element.style.minHeight = value;
    elementStyleObject.minHeight = value;
    break;

  case "flex-direction":
    element.style["flex-direction"] = value;
    elementStyleObject.flexDirection = value;
    break;

  case "flex-flow":
    element.style["flex-flow"] = value;
    elementStyleObject.flexFlow = value;
    break;

  case "flex-wrap":
    element.style["flex-wrap"] = value;
    elementStyleObject.flexWrap = value;
    break;

  case "flex-grow":
    element.style["flex-grow"] = value;
    elementStyleObject.flexGrow = value;
    break;

  case "align-items":
    element.style["align-items"] = value;
    elementStyleObject.alignItems = value;
    break;

  case "align-content":
    element.style["align-content"] = value;
    elementStyleObject.alignContent = value;
    break;

  case "justify-content":
    element.style["justify-content"] = value;
    elementStyleObject.justifyContent = value;
    break;

  case "order":
    element.style["order"] = value;
    elementStyleObject.order = value;
    break;

  case "float":
    element.style.float = value;
    elementStyleObject.float = value;
    break;

  case "margin":
    element.style.margin = value;
    elementStyleObject.margin = value;
    break;

  case "margin-top":
    element.style.marginTop = value;
    elementStyleObject.marginTop = value;
    break;

  case "margin-right":
    element.style.marginRight = value;
    elementStyleObject.marginRight = value;
    break;

  case "margin-bottom":
    element.style.marginBottom = value;
    elementStyleObject.marginBottom = value;
    break;

  case "margin-left":
    element.style.marginLeft = value;
    elementStyleObject.marginLeft = value;
    break;

  case "padding":
    element.style.padding = value;
    elementStyleObject.padding = value;
    break;

  case "padding-top":
    element.style.paddingTop = value;
    elementStyleObject.paddingTop = value;
    break;

  case "padding-right":
    element.style.paddingRight = value;
    elementStyleObject.paddingRight = value;
    break;

  case "padding-bottom":
    element.style.paddingBottom = value;
    elementStyleObject.paddingBottom = value;
    break;

  case "padding-left":
    element.style.paddingLeft = value;
    elementStyleObject.paddingLeft = value;
    break;

  case "color":
    element.style.color = value;
    elementStyleObject.color = value;
    break;

  case "font-size":
    element.style.fontSize = value;
    elementStyleObject.fontSize = value;
    break;

  case "font-family":
    element.style.fontFamily = value;
    elementStyleObject.fontFamily = value;
    break;

  case "font-style":
    element.style.fontStyle = value;
    elementStyleObject.fontStyle = value;
    break;

  case "font-weight":
    element.style.fontWeight = value;
    elementStyleObject.fontWeight = value;
    break;

  case "background":
    element.style.background = value;
    elementStyleObject.background = value;
    break;

  case "background-color":
    element.style.backgroundColor = value;
    elementStyleObject.backgroundColor = value;
    break;

  case "background-url":
    element.style.backgroundImage = `url('${value}')`;
    elementStyleObject.backgroundUrl = value;
    break;

  case "background-position":
    element.style.backgroundPosition = value;
    elementStyleObject.backgroundPosition = value;
    break;

  case "background-size":
    element.style.backgroundSize = value;
    elementStyleObject.backgroundSize = value;
    break;

  case "background-repeat":
    element.style.backgroundRepeat = value;
    elementStyleObject.backgroundRepeat = value;
    break;

  case "background-image":
    element.style.backgroundImage = value;
    elementStyleObject.backgroundImage = value;
    break;

  case "border":
    element.style.border = value;
    elementStyleObject.border = value;
    break;

  case "border-color":
    element.style.borderColor = value;
    elementStyleObject.borderColor = value;
    break;

  case "border-width":
    element.style.borderWidth = value;
    elementStyleObject.borderWidth = value;
    break;

  case "border-style":
    element.style.borderStyle = value;
    elementStyleObject.borderStyle = value;
    break;

  case "border-radius":
    element.style.borderRadius = value;
    elementStyleObject.borderRadius = value;
    break;

  case "border-top":
    element.style.borderTop = value;
    elementStyleObject.borderTop = value;
    break;

  case "border-right":
    element.style.borderRight = value;
    elementStyleObject.borderRight = value;
    break;

  case "border-bottom":
    element.style.borderBottom = value;
    elementStyleObject.borderBottom = value;
    break;

  case "border-left":
    element.style.borderLeft = value;
    elementStyleObject.borderLeft = value;
    break;

  case "text-align":
    element.style.textAlign = value;
    elementStyleObject.textAlign = value;
    break;

  case "vertical-align":
    element.style.verticalAlign = value;
    elementStyleObject.verticalAlign = value;
    break;

  case "text-decoration":
    element.style.textDecoration = value;
    elementStyleObject.textDecoration = value;
    break;

  case "text-decoration-line":
    element.style.textDecorationLine = value;
    elementStyleObject.textDecorationLine = value;
    break;

  case "text-decoration-style":
    element.style.textDecorationStyle = value;
    elementStyleObject.textDecorationStyle = value;
    break;

  case "text-decoration-color":
    element.style.textDecorationColor = value;
    elementStyleObject.textDecorationColor = value;
    break;

  case "text-shadow":
    element.style.textShadow = value;
    elementStyleObject.textShadow = value;
    break;

  case "text-indent":
    element.style.textIndent = value;
    elementStyleObject.textIndent = value;
    break;

  case "letter-spacing":
    element.style.letterSpacing = value;
    elementStyleObject.letterSpacing = value;
    break;

  case "line-height":
    element.style.lineHeight = value;
    elementStyleObject.lineHeight = value;
    break;

  case "text-transform":
    element.style.textTransform = value;
    elementStyleObject.textTransform = value;
    break;

  case "-webkit-line-clamp":
    element.style["-webkit-line-clamp"] = value;
    element.style.webkitLineClamp = value;
    elementStyleObject.webkitLineClamp = value;
    break;

  case "-webkit-box-orient":
    element.style["-webkit-box-orient"] = value;
    element.style.webkitBoxOrient = value;
    elementStyleObject.webkitBoxOrient = value;
    break;

  case "transform":
    element.style.transform = value;
    elementStyleObject.transform = value;
    break;

  case "opacity":
    element.style.opacity = value;
    elementStyleObject.opacity = value;
    break;

  case "box-shadow":
    element.style.boxShadow = value;
    elementStyleObject.boxShadow = value;
    break;

  case "mix-blend-mode":
    element.style["mix-blend-mode"] = value;
    elementStyleObject.mixBlendMode = value;
    break;

  case "transform-translate":
    let transformArray = element.style.transform.split(" ");

    for (let i = 0; i < transformArray.length; i++) {
      let transformProperty = transformArray[i].split("(");
      if (transformProperty[0] === "translate") {
        transformProperty[1] = value + ")";
      }
    }

    element.style.transform = transformArray.join(" ");
    elementStyleObject.transformTranslate = value;
    break;

  case "image-width":
    element.style.width = value;
    elementStyleObject.imageWidth = value;
    break;

  case "image-height":
    element.style.height = value;
    elementStyleObject.imageHeight = value;
    break;

  case "image-ratio":
    // Calculate image ratio and apply necessary styles
    break;

  case "image-source":
    element.style.src = value;
    elementStyleObject.imageSource = value;
    break;
		   default:
			   console.log(value);
       }
	   

	   if(window.cohoba.selection && !window.cohoba.selection?.layout && window.cohoba.workspace?.stylesheet){
     // console.log(window.cohoba.workspace.stylesheet, window.cohoba.selection)
	   if (Object.keys(window.cohoba.workspace.stylesheet).find(k => k === window.cohoba.selection?.component)){
      Object.keys(window.cohoba.workspace.stylesheet).forEach((key,index) => {
        let s = window.cohoba.workspace.stylesheet[key];
			if (key === window.cohoba.selection?.component) {
				console.log("Editing defined component", element);
				window.cohoba.workspace.stylesheet[key] = {...window.cohoba.workspace.stylesheet[key], ...elementStyleObject, edited: true};;
			}
		})
	   }
		}

    if (window.cohoba.selection?.layout) {
      window.cohoba.selection.attributes.style = window.cohoba.selection.attributes.style ? {...window.cohoba.selection.attributes.style, ...elementStyleObject} : {...elementStyleObject};
      //window.cohoba.setSelection(window.cohoba.selection);
      // window.cohoba.updateWorkspace({});
     // window.cohoba.updateDesignWindow(window.cohoba.selection);
     //});
    } else {

      window.cohoba.selection = {...window.cohoba.selection, ...elementStyleObject, edited: true};
     // window.cohoba.setSelection(window.cohoba.selection);
      //window.cohoba.updateDesignWindow(window.cohoba.selection);
     

      // Update source markup
    if (window.cohoba.workspace.layouts[window.cohoba.frame]?.markup) {
   
      // window.cohoba.workspace.layouts[window.cohoba.frame] = {...window.cohoba.workspace.layouts[window.cohoba.frame], markup: cohoba.workspaceElement.innerHTML};
      
      updateMarkup(cohoba.workspaceElement.innerHTML, cohoba.workspace.assets);

      // Update source layout
    } else if (window.cohoba.workspace.layouts[window.cohoba.frame]?.cn){
      window.cohoba.workspace.render = cohoba.workspaceElement.innerHTML;
    }
    //  window.cohoba.updateWorkspace({});
    // window.cohoba.updateWorkspace({preview: cohoba.workspaceElement.innerHTML});
    // window.cohoba.updateWorkspace({stylesheet: window.cohoba.workspace.stylesheet});
 //   refreshWorkspace(window.cohoba.workspace);
  }

    
     
    }

    export default styler;