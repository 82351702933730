const getFinishTime = () => {
    try{
        if (!window.render_params.finishTime){
            return -1;
        }
      const startTime = window.render_params.startTime;
      const finishTime = window.render_params.finishTime;
      const ellapsedTime = finishTime - startTime;
      return ellapsedTime/1000;
    }catch(e){
      return -1
    }
  }

  export default getFinishTime;