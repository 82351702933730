import * as React from 'react';
import styled from 'styled-components';
import Header from './Header';

const WindowFooter = styled(Header).attrs({className:"window-footer"})`
  position: relative;
  align-items: center;
  font-size: 11px;
  text-align: center;
  padding: 5px;
  
  .window.overflowing & {
    box-shadow: 0 -7px 8px -5px rgba(0, 0, 0, 0.25);
  }
  
  .window & {
    display: flex;
    border-radius: 0 0 4px 4px;
    justify-content: space-around;
    position: relative;
    border-width: 2px;
    padding: 4px;
    line-height: 1;
    min-height: 24px;

    .property-value-input {
      padding: 0;
    }
}

`

export default WindowFooter;