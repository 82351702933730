import * as React from 'react';
import './windowbody.css';
import styled from 'styled-components';


// Styled-Components
const WindowBodyDiv = styled.div`
  display: block;
  height: 100%;
  margin: 0px;
  box-sizing: border-box;
  overflow: hidden;
  position: relative;
  flex-grow: 1;
  &:first-child {
    max-height: calc(100% - 0px);
  }

  &.scrollable-up .scroll-gradient-up  {
    opacity: 1;
  }

  &.scrollable-down .scroll-gradient-down {
    opacity: 1;
  }

  .window-header ~ & {
    max-height: calc(100% - 30px);
  }

  @media (max-width: 480px) {
    max-height: 100%;
    overflow: auto;
  }

`;

const WindowBodyScroller = styled.div`
  display: block;
  width: 100%;
  height: 100%;
  max-height: calc(100% - 0);
  overflow-x: hidden;
  overflow-y: auto;
  margin: 0px;
  padding: 0;
  box-sizing: border-box;


&::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
}

&::-webkit-scrollbar
{
	width: 6px;
  background-color: #F5F5F5;
  cursor: pointer;
}

&::-webkit-scrollbar-thumb
{
	background-color: #888;
}

`;


const ScrollGradientUp = styled.div.attrs({className:"scroll-gradient-up"})`
left:0px;
border-bottom-left-radius: 6px;
border-bottom-right-radius: 6px;
content:"";
display:block;
position:absolute;
width:100%;
height:24px;
background-image: linear-gradient(to bottom, rgba(0,0,0,0.5),rgba(0,0,0,0));
top:0;
//mix-blend-mode: multiply;
opacity: 0;
pointer-events: none;
transition: opacity 0.5s ease-in-out;
z-index: 3;
`;
const ScrollGradientDown = styled.div.attrs({className:"scroll-gradient-down"})`
left:0px;
bottom:0px;
content:"";
display:block;
position:absolute;
width:100%;
height:24px;
background-image:linear-gradient(to top, rgba(0,0,0,0.5),rgba(0,0,0,0));
//mix-blend-mode: multiply;
opacity: 0;
pointer-events: none;
transition: opacity 0.5s ease-in-out;
z-index: 3;
`;



export default function WindowBody(props) {
  
  const bodyRef = React.useRef();
  const scrollerRef = React.useRef();

  React.useEffect(()=>{
    const scroller = scrollerRef.current;
    const body = bodyRef.current;
    if(scroller.scrollTop > 0){ 
      body.classList.add("scrollable-up")
    }else if(body.scrollTop <= 1){
      body.classList.remove("scrollable-up")
    }

    if(scroller.scrollHeight > scroller.clientHeight && (scroller.scrollTop+body.clientHeight) !== scroller.scrollHeight){ body.classList.add("scrollable-down")}else if((scroller.scrollTop+scroller.clientHeight) === scroller.scrollHeight){
      body.classList.remove("scrollable-down");
    }

    scroller.onscroll = function(event){
      body.classList.add("scrolling");

     // setTimeout(()=>{body.classList.remove("scrolling");}, 100)

      if(scroller.scrollTop > 0){ body.classList.add("scrollable-up")}else if(scroller.scrollTop <= 1){
        body.classList.remove("scrollable-up")
      }

      if(scroller.scrollHeight > scroller.clientHeight && (scroller.scrollTop+scroller.clientHeight) !== scroller.scrollHeight){ body.classList.add("scrollable-down")}else if((scroller.scrollTop+scroller.clientHeight) === scroller.scrollHeight){
        body.classList.remove("scrollable-down");
      }
      body.classList.remove("scrolling");
    }

  },[])


    return(      
        
      <WindowBodyDiv
        ref={bodyRef}
        style={props.style}
        className={props.className}
        className="window-body"
        >
          <ScrollGradientUp/>
          <WindowBodyScroller ref={scrollerRef} className="window-body-scroller">
          {props.children}
          </WindowBodyScroller>
          <ScrollGradientDown/>
      </WindowBodyDiv>
      );
}
