import replaceRegex from '../workspace/replaceRegex.js';

/*
====================
What does this do and where are we using it?
Does this write to the database whatever code 
is in the code editor? OR, does it update the code
editor with whatever is in the database?
====================
*/


export default function updateSource(element){
  if(window.cohoba.workspace.mode === "edit"){

  //Hotfix for decoding characters
  let htmlCode = window.cohoba.workspaceElement.innerHTML;
  htmlCode.replace(/cohoba-selector/g, "");

  var txt = document.createElement("textarea");
  txt.innerHTML = htmlCode;
  txt.value = txt.value.replace(/cohoba-selector/g, "");
  var template  = replaceRegex(txt.value, window.cohoba.workspace);
 
  window.cohoba.workspace.code = template;
  window.cohoba.workspace.template = template;
  window.cohoba.workspaceArray[window.cohoba.currentIndex].template = template;
  window.cohoba.updateCodeEditor(template);

   }else if(window.cohoba.workspace.mode === "preview"){
  //   var render = window.cohoba.workspaceElement.innerHTML;
  //   var txt = document.createElement("textarea");
  // txt.innerHTML = render;
  //       window.cohoba.workspace.render = txt.value;
  //        window.cohoba.updateCodeEditor(render);
  //        if(document.querySelector(".cohoba-selector") !== null){
  //     selector(document.querySelector(".cohoba-selector"))
  //   }
   }

}

