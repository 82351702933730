import getRenderUrls from '../getRenderUrls';

const getSspid = () => {
  const url = new URL(getRenderUrls().at(0));
  let sspId;
  if (url) {
      sspId = url.searchParams.get('sspid')
  }
  return sspId
};

export default getSspid;