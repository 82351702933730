import * as React from 'react';
import {styled} from 'goober';
import {createGlobalStyles} from 'goober/global';

const GeneralStyling = createGlobalStyles`

${props => props.removeMargin && `
  body{margin: 0;}
`}
`;

const Container = styled('div')`
  position: relative;
  display: flex;
  text-align: left;
  flex-flow: ${(props) => ((props.orientation === 'portrait' || props.mobileFirst) ? 'column' : 'row')} nowrap;
  width: ${(props) => {
    if (props.width && !isNaN(props.width)) {
      return (props.width+"px");
     } if (props.width){
      return props.width
     }
    return '100%';
  }};
  height: ${(props) => {
    if (props.height && !isNaN(props.height)) {
     return props.height +"px";
    } else if (props.height){
      return props.height
     }
    return 'auto';
  }};
  align-items: center;
  max-width: 100%;
  max-height: 100%;
  max-width: 100vw;
  max-height: 100vh;
  margin: 0 auto;
  padding: 0;
  white-space: normal;
  background: #fff;
  color: #000;
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  overflow: hidden;
  box-sizing: border-box;
  cursor: pointer;
  font-family: system-ui, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialised;
  &.hidden * {
    visibility: hidden;
  }

  ${props => props.fitToViewport && `
    width: 100vh;
    height: 100vh;
  `}

`;

const Parent = (props) => {
  return (<><GeneralStyling removeMargin={true}/><Container {...props}   data-fp={props['data-fp']} className={`${props.identifier}_container${props.className ? " "+props.className : ''}`} data-component="container"></Container></>)
}

export default Parent;
