import SetupModule from "../../setupModule";
import LayoutModule from "..";

const calculateMediaAvailableSpace = () => {
    const adContainer = SetupModule.getAdContainer();
    const adMedia = LayoutModule.getMediaContainer();
    const adContent = LayoutModule.getAdContent();
    const adHeight = adContainer?.getBoundingClientRect().height;
    const mediaHeight = adMedia?.getBoundingClientRect().height;
    const adElements = Array.from(adContent?.children);
    let contentHeight = 0;
    if (adElements.length > 0){
      adElements?.forEach(element => {
        contentHeight += element.getBoundingClientRect().height;
      });
    }
    return adHeight - contentHeight;
}

export default calculateMediaAvailableSpace;