import createClickParameters from "../createClickParameters";
import SetupModule from "../../setupModule";
import renderPixel from "../../../utilities/renderPixel";
import renderTag from "../../../utilities/renderTag";

const renderClickTrackers = async (event, node) => {
    if (!node) {
        node = document.body;
    }

    const render_params = window.render_params;
    
  if (window.advertible_impression) { 
    // This should be redundant, as getCtaUrl already checks for advertible_impression
    if (SetupModule.getCtaUrls()) {
      const ctaUrls = SetupModule.getCtaUrls();
      ctaUrls.forEach((url:string) => {
        if (url !== ''){
          const clickEventUrl = new URL(url);
          const extString = createClickParameters(event);
          clickEventUrl.searchParams.set("ext", extString);
          // Review if we truly need to append, and consider implementing directly into renderPixel
          node.appendChild(renderPixel(clickEventUrl.href));
        }
      })
    }
  }

  if (render_params && render_params?.native && render_params?.eventtrackers && render_params?.eventtrackers.length > 0) {
    render_params?.eventtrackers.forEach((et) => {
        if (et.event === 2) {
          if (et.method === 1) {
            node.appendChild(renderPixel(et.url));
          } else if (et.method === 2) {
            node.appendChild(renderTag(et.url));
          }
        }
      });
    }
    
}

export default renderClickTrackers;