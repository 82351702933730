import SetupModule from "../setupModule";
import AnalyticsModule from "../analyticsModule";
import createImpressionParameters from "../analyticsModule/createImpressionParameters";

const errorLimit = 10;
const errorMaxLength = 1000;
const errorTimeout = 2000;
const errorExpire = 60000;

const ErrorModule = (function () {
    let ERROR_ENDPOINT = window.advertible_impression?.error_url || null;
    if (process.env.NODE_ENV === 'development'){
     //   ERROR_ENDPOINT = 'test.com';
    }
    return {
        emergencyStop: () =>{
            // If condition is met, stop all intervals, and all logic
            
            /*
                Always keep track of all intervals, and/or avoid using intervals

            */
        },

        /*

        Error Reporting Logic

        Collect errors inside an array
        Set a cap on how many errors can be collected, but add an error count
        Consider only sending a "too many errors" event if it exceeds a certain number
        Start a timeout to send errors after render
        Send all errors up to a number when ad renders succesfully
        Stop timer if ad rendered
        Consider ahving a second timer to send errors up to a minute after ad has rendered
        If ad exceed a certain number of errors, don't send any more errors

        If ad exceeds a certain number of events, don't send any more events

        // Create an ad lifecycle status that is "stale" so it stops all timers and etc
         Stale can mean that the user hasn't engaged after a while, the ad is not viewable

        

        */

        errorReport: {
            errors: [],
            count: 0,
            env: createImpressionParameters(),
            expired: false,
            sent: false,
        },

        errorTimer: null,
        errorExpire: setTimeout(()=>{
            if (ErrorModule.errorReport.sent === false){
                ErrorModule.report();
                ErrorModule.errorReport.expired = true;
            } 
        }, errorExpire),

        capture: (error) => {

            if (ErrorModule.errorTimer) {
                clearTimeout(ErrorModule.errorTimer);
            } 

            console.error(error);

            if (error.error) {
                error = error.error;
            }

            // If error count reaches errorLimit in less than 2s, then close session because there's a bigger issue.
            if (ErrorModule.errorReport.count >= errorLimit) {
                ErrorModule.report();
                ErrorModule.errorReport.expired = true;
                return;
            }

            // This will collect errors with a 2s separation and send an initial report.
            ErrorModule.errorTimer = setTimeout(()=>{
                if (ErrorModule.errorReport.sent === false){
                    ErrorModule.report();
                }
            }, errorTimeout);
            
            
            const errorMessage = {
                code: error.code,
                stack: error.stack,
                message: error.message,
                count: 1
            }

            const existingError = ErrorModule.errorReport.errors.find(error => {
                return (error.code === errorMessage.code 
                    && error.stack === errorMessage.stack
                    && error.message === errorMessage.message)
            });

            if (existingError) {
                existingError.count += 1;
            } else {
                ErrorModule.errorReport.errors.push(errorMessage);
            }

            ErrorModule.errorReport.count += 1;

            if (window.render_params) {
                window.render_params.errorReport = ErrorModule.errorReport;
            }

            //Add errors until it reaches the error limit.
            // If an error already exists don't add, or replace it. Increase count value.
            // If more errors than limit are reported, keep the first 5, and add to the last 5
            // Reset the timeout everytime an error comes through
            // If timeout expires, submit errors
            // If add successfully renders, and there's errors, clear timeouts and send
            // Wait for errors up to 30-60 seconds after the ad has been rendered, to capture any issues with engagement or video plays
            // Only send env parameters once per error report
        },

        report: () =>{
            console.log(ErrorModule.errorReport);

            // Errors will continue to be captured for debugging, but never sent after the expiration period
            if (!SetupModule.errorReportingEnabled() || ErrorModule.errorReport.expired) {
                return false;
            }

            if (ErrorModule.errorReport.errors.length > errorLimit) {
                const topFifty = ErrorModule.errorReport.errors.slice(0,4);
                const bottomFifty = ErrorModule.errorReport.errors.slice(0,-5);
                ErrorModule.errorReport.errors = [...topFifty, ...bottomFifty];
            } else if (ErrorModule.errorReport.errors.length === 0) {
                return false;
            }

      //      const errorMessage = `${error.code ? error.code : ''} ${error.stack ? error.stack : ''} ${error.message ? error.message : ''} ${AnalyticsModule.createImpressionParameters()}`;

            // Cleanup errorReport object
            const report = {
                c: ErrorModule.errorReport.count,
                e: ErrorModule.errorReport.errors.map(error => {
                    error.stack = error.stack ? error.stack.split("\n").slice(0,5).join("\n") : null;
                    error.s = error.stack;
                    delete error.stack;

                    if (!error.stack) {
                        delete error.stack;
                    }
                    if (!error.message) {
                        delete error.message;
                    }
                    if (!error.code) {
                        delete error.code;
                    }
                    error.c = error.count;
                    delete error.count;

                    return error
                }),
                x: ErrorModule.errorReport.expired ? 1 : 0
            }

            const errorString = `c:${report.c}|x:${report.x}|e:${report.e.map(e=>{return `[${e.c}]${e.code ? e.code : ''}${e.cause ? e.cause : ''}${e.s ? e.s : ''}${e.message ? e.message : ''}`}).join(';')}`;

            const errorObj = {
                stack: errorString.substring(0, errorMaxLength),
                sspid: SetupModule.getSspId(),
                renderId: SetupModule.getRenderId()
                };

            // if (window.render_params && window.render_params.errors) {
            //     window.render_params.errors.push(errorObj);
            // }

            if (!ERROR_ENDPOINT) {
                ErrorModule.errorReport.sent = true;
                return
            }

            fetch(ERROR_ENDPOINT, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(errorObj)
            })
            .then(response => {
                console.log("reported status: " + response.status);
                // If a report was sent before session expired, clear the report for new errors
                ErrorModule.errorReport.sent = true;
                ErrorModule.errorReport.errors = [];
                ErrorModule.errorReport.count = 0;
             //  ErrorModule.errorReport.env = createImpressionParameters();
            })
            .catch(networkError => {
                console.error('Network error:', networkError)
            });
        
        }
    }
})();
      
export default ErrorModule;