const getViewTime = () => {
try{
    const startTime = window.render_params.timestamp*1000; // Convert to milliseconds
    const nowTime = Date.now();
    const ellapsedTime = nowTime - startTime;
    return ellapsedTime/1000;
    }catch(e){
    return -1
    }
}

export default getViewTime;