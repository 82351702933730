import MRAIDModule from "../../mraidModule";

const getPlacementType = () => {
if (MRAIDModule.mraid && MRAIDModule.getPlacementType) {
    return MRAIDModule.getPlacementType()
} else {
    return 'unknown'
}
}

export default getPlacementType;