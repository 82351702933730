import MRAIDModule from "../../mraidModule";

export default function checkViewabilityOptions(){
        let viewabilityTracking = "untrackable";

        if (MRAIDModule.getMraid() 
          && ((MRAIDModule.mraid.getVersion() !== 0 
          && typeof MRAIDModule.mraid.isViewable !== "undefined"))
          ){
          viewabilityTracking = "mraid"
        } else if (window.$sf) {
          viewabilityTracking = 'safeframe'
        } else if (window.top && window.frameElement) {
          viewabilityTracking = "friendly"
        }

        return viewabilityTracking;
      }