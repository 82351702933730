import React, { useRef, useState, useEffect } from "react";
import { PropertyLabel } from "./PropertyLabel";
import { PropertyInput, ActionButton } from '@components';
import styled from 'styled-components';
import { HexColorPicker } from "react-colorful";
import { Flex } from 'rebass';

const ColorPreview = styled.div`
  display: inline-block;
  width: 24px;
  height: 18px;
  border: 2px solid #fff;
  vertical-align: middle;
  background: ${props => props.color || 'red'};
  box-sizing: border-box;
`;

const ColorMixerField = styled(PropertyInput)`
  padding-left: 40px !important;
`;

const ColorPickerContainer = styled(Flex)`
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 10;
  justify-content: center;
  background: #fff;
  padding: 8px;
  border-radius: 9px;
  box-shadow: 0 2px 4px 0px rgb(0 0 0 / 30%);
  flex-direction: column;
  
  .react-colorful {
    width: 100%;
  }

  .color-code-input {
    margin-bottom: 12px;
    padding: 0px;
    font-size: 14px;
   
  }

  .opacity-slider {
    -webkit-appearance: none;
    appearance: none;
    width: 100%;
    height: 10px;
    background: linear-gradient(to right, ${props => props.color}00, ${props => props.color});
    border-radius: 5px;
    outline: none;
    opacity: 0.7;
    transition: opacity .2s;

    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      appearance: none;
      width: 20px;
      height: 20px;
      background: #fff;
      cursor: pointer;
      border: 1px solid #000;
      border-radius: 50%;
    }

    &::-moz-range-thumb {
      width: 20px;
      height: 20px;
      background: #fff;
      cursor: pointer;
      border: 1px solid #000;
      border-radius: 50%;
    }

    &:hover {
      opacity: 1;
    }
  }

  .buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
  }

  .confirm-button {
    background: var(--primaryColor);
    color: white;
  }
`;

const ScreenPickerPreview = styled.div`
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  border: 1px solid #ccc;
  transform: translate(-50%, -50%);
  background: transparent;
  z-index: 999999999999;
  pointer-events: none;
  overflow: hidden;

  &::before, &::after, & > div {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 1px solid #ccc;
  }

  &::before {
    width: 5px;
    height: 5px;
  }

  &::after {
    width: 100%;
    height: 1px;
  }

  & > div {
    width: 1px;
    height: 100%;
  }

  & .text-input {
    font-size: 11px;
  }
`;

const ColorMixer = ({ value = '', label, action, id, unit, preview, sublabel, style, name, input }) => {
  const [toggled, setToggled] = useState(false);
  const [currentColor, setCurrentColor] = useState(value);
  const [opacity, setOpacity] = useState(1); // New state for opacity
  const pickerNode = useRef(null);
  const inputRef = useRef(null);
  const originalColor = useRef(value);

  useEffect(() => {
    if (!window.colorPickerPalette) {
      window.colorPickerPalette = [{ color: "#fff" }, { color: "#000" }];
    }
  }, []);

  useEffect(() => {
    // Update opacity when color changes
    if (currentColor.length === 9) {
      const alpha = parseInt(currentColor.slice(7), 16) / 255;
      setOpacity(alpha);
    } else {
      setOpacity(1); // Default to full opacity if not 8-digit hex
    }
  }, [currentColor]);

  const updateValue = (color) => {
    setCurrentColor(color);
    if (action) {
      action(color);
    }
  };

  const handleColorCodeInput = (enteredColor) => {
    if (/^#[0-9A-Fa-f]{6}$/.test(enteredColor) || /^#[0-9A-Fa-f]{8}$/.test(enteredColor)) {
      updateValue(enteredColor);
    }
  };

  const handleOpacityChange = (e) => {
    const newOpacity = e.target.value;
    setOpacity(newOpacity);
    
    // Convert current color to RGB and append new opacity
    let colorPart = currentColor.length === 9 ? currentColor.slice(1, 7) : currentColor.slice(1);
    updateValue(`#${colorPart}${Math.round(newOpacity * 255).toString(16).padStart(2, '0')}`);
  };

  const confirmColor = () => {
    action(currentColor);
    setToggled(false);
  };

  const cancel = () => {
    setCurrentColor(originalColor.current);  // Reset to original color
    action(originalColor.current);
    setToggled(false);
  };

  return (
    <div className="property-field" style={style}>
      {label && <PropertyLabel>{label}</PropertyLabel>}
      <div style={{position:"relative",width:"fit-content"}}>
        <ColorPreview 
          ref={pickerNode}
          className="color-input-preview"
          color={currentColor}
          onClick={() => {
            originalColor.current = currentColor;
            setToggled(!toggled);
          }}
        />
        {unit && <span className="cohoba-property-unit">{unit}</span>}
        {/* <ScreenPickerPreview /> */}
        {input && <ColorMixerField 
          ref={inputRef}
          className="color-input"
          type="static"
          name={name}
          value={currentColor}
          action={(value) => updateValue(value)}
        />}
      </div>
      {sublabel && <div style={{textAlign:"right"}} className="cohoba-property-sublabel">{sublabel}</div>}
      {toggled && (
        <ColorPickerContainer color={currentColor}>
         
          <HexColorPicker 
            alpha={true} 
            color={currentColor} 
            onChange={updateValue} 
          />
          <input 
            type="range" 
            min="0" 
            max="1" 
            step="0.01" 
            value={opacity} 
            onChange={handleOpacityChange} 
            className="opacity-slider"
          />
            <PropertyInput 
           type='static'
            className="color-code-input" 
            placeholder="#color-code"
            value={currentColor}
            action={handleColorCodeInput}
          />
          <div className="buttons">
            <ActionButton action={cancel}>Cancel</ActionButton>
            <ActionButton action={confirmColor} className="confirm-button">Confirm</ActionButton>
          </div>
        </ColorPickerContainer>
      )}
    </div>
  );
};

export default ColorMixer;