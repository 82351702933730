/**
 * The purpose of this function is to expand ad containers in different environments
 * to achieve an in-feed look, or maximize ad space
 * @param width - container width
 * @param height - container height
 */
export default function resizeAdContainer(width: number, height: number): void {
  // If Friendly Iframe use DOM API
  if (window.frameElement) {
    // TODO: fix typings, as now styles attr exist in frameElement ts
    (window.frameElement as any).style.width = `${width}px`;
    (window.frameElement as any).style.height = `${height}px`;
    (window.frameElement as any).style.maxWidth = "100%";
    (window.frameElement as any).style.maxHeight = "100%";

    if (window.frameElement?.parentNode 
      && window.frameElement?.parentNode?.tagName === "DIV"
      && window.frameElement?.parentNode?.getAttribute("id")
    && window.frameElement?.parentNode?.getAttribute("id").includes("google_ads_iframe")) {
      let rootContainer = window.frameElement?.parentNode;
      rootContainer.style.width ="";
      rootContainer.style.height ="";
    }

    if (window.frameElement.ownerDocument?.defaultView?.frameElement) {
      let parent = window.frameElement?.ownerDocument?.defaultView?.frameElement;
      parent.style.width = `${width}px`;
      parent.style.height = `${height}px`;
      parent.style.maxWidth = "100%";
      parent.style.maxHeight = "100%";
      

      if (window.frameElement.ownerDocument?.defaultView?.frameElement?.parentNode
        && window.frameElement.ownerDocument?.defaultView?.frameElement?.parentNode?.tagName === "DIV"
        && window.frameElement.ownerDocument?.defaultView?.frameElement?.parentNode.getAttribute("id")
        && window.frameElement.ownerDocument?.defaultView?.frameElement?.parentNode.getAttribute("id").includes("google_ads_iframe")) {
        let rootContainer = window.frameElement?.ownerDocument?.defaultView?.frameElement?.parentNode;
        rootContainer.style.width ="";
        rootContainer.style.height ="";
      }
    }
  }
  // If Safeframe use Safeframe API
  // If webview use MRAID API
}