import {loremIpsum} from 'lorem-ipsum';

const saganWords = [
    "cosmos", "stars", "universe", "galaxy", "astronomy", "science", "astronomers", "astronaut", "planetary", "space",
    "time", "infinity", "eternity", "cosmic", "stellar", "astrophysics", "quantum", "physics", "mathematics", "philosophy",
    "life", "earth", "earthling", "planet", "planets", "solar", "system", "orbit", "celestial", "sky",
    "light", "year", "light-year", "gravity", "gravitational", "radiation", "nuclear", "fusion", "nebula", "supernova",
    "black hole", "wormhole", "pulsar", "quasar", "comet", "asteroid", "meteor", "meteorite", "dust", "cosmic dust",
    "interstellar", "intergalactic", "extraterrestrial", "alien", "Martian", "Jovian", "Saturnine", "Uranian", "Neptunian",
    "Venusian", "Mercurian", "Plutonian", "Kuiper Belt", "Oort Cloud", "exoplanet", "exobiology", "astrobiology", "SETI",
    "radio", "signal", "contact", "civilization", "intelligence", "technological", "evolution", "origin", "Big Bang", "cosmology",
    "dark matter", "dark energy", "antimatter", "neutrino", "photon", "electron", "proton", "atom", "molecule", "element",
    "chemical", "biochemistry", "genetics", "DNA", "RNA", "amino acid", "protein", "cell", "organism", "species",
    "evolutionary", "natural", "selection", "adaptation", "mutation", "genetic", "code", "fossil", "geology", "geophysics",
    "meteorology", "climate", "atmosphere", "biosphere", "ecology", "environment", "pollution", "conservation", "sustainability",
    "exploration", "voyager", "pioneer", "mission", "probe", "telescope", "observatory", "Hubble", "Kepler", "Webb",
    "astronomical", "zenith", "nadir", "horizon", "eclipse", "transit", "occultation", "conjunction", "opposition", "celestial event",
    "constellation", "zodiac", "astrology", "pseudoscience", "rational", "skepticism", "inquiry", "hypothesis", "theory", "experiment",
    "observation", "data", "information", "knowledge", "wisdom", "understanding", "discovery", "breakthrough", "innovation", "technology",
    "communication", "education", "learning", "teaching", "public", "outreach", "Carl Sagan", "Ann Druyan", "Cosmos", "Pale Blue Dot",
    "Contact", "The Demon-Haunted World", "Billions and Billions", "Broca's Brain", "Dragon's of Eden", "Astrology", "Wonder",
    "Curiosity", "Imagination", "Vision", "Future", "Past", "History", "Legacy", "Humanity", "Culture", "Language",
    "Art", "Music", "Literature", "Poetry", "Film", "Documentary", "Cosmic Connection", "Universe", "Galactic", "Starlight",
    "Twinkle", "Twilight", "Dawn", "Dusk", "Midnight", "Noon", "Season", "Solstice", "Equinox", "Northern",
    "Southern", "Hemisphere", "Polar", "Tropics", "Arctic", "Antarctic", "Aurora", "Borealis", "Australis", "Magnetosphere",
    "Ionosphere", "Stratosphere", "Troposphere", "Exosphere", "Thermosphere", "Mesosphere", "Ozone", "Greenhouse", "Effect", "Global",
    "Warming", "Climate Change", "Sustain", "Preserve", "Protect", "Advocate", "Science Communicator", "Educator", "Philosopher",
    "Thinker", "Visionary", "Innovator", "Explorer", "Discoverer", "Astronomer", "Cosmologist", "Astrophysicist", "Biologist",
    "Geologist", "Meteorologist", "Chemist", "Physicist", "Mathematics", "Mathematician", "Theoretical", "Applied", "Science",
    "Research", "Study", "Investigate", "Analyze", "Synthesize", "Interpret", "Predict", "Model", "Simulation", "Measurement",
    "Instrument", "Lab", "Laboratory", "Field", "Work", "Observation", "Observational", "Theoretical", "Computational", "Data",
    "Analysis", "Visualization", "Chart", "Graph", "Spectrum", "Spectroscopy", "Radio Astronomy", "Infrared", "Ultraviolet",
    "Visible Light", "X-ray", "Gamma Ray", "Wave", "Particle", "Quantum Mechanics", "Relativity", "Einstein", "Hawking",
    "Newton", "Galileo", "Copernicus", "Kepler", "Hubble", "Sagan", "Pioneer", "Voyager", "New Horizons", "Cassini","astrophile", "astronomer", "astrometry", "astrogeology", "astrophotography", "astronavigation",
    "celestial body", "celestial mechanics", "comet tail", "cosmic background", 
    "cosmic dust", "cosmic evolution", "cosmic radiation", "cosmic ray", "cosmogony", 
    "crater", "cratering", "dark energy", "dark matter halo", "debris disk",
    "ecliptic", "exosphere", "extrasolar", "galactic center", "galactic disk",
    "galactic halo", "galactic plane", "galactic spiral", "galactic tide", 
    "gamma ray burst", "giant impact", "gravitational lensing", "gravitational wave",
    "heliopause", "heliosphere", "Hubble's law", "interplanetary", "interstellar gas",
    "interstellar medium", "ion tail", "Kardashev scale", "Kuiper belt object",
    "lunar", "meteor shower", "Milky Way", "nebulae", "nebulosity",
    "Nova", "observational cosmology", "Oort cloud comet", "orbital resonance",
    "parallax", "planetarium", "planetology", "pulsar wind", "quantum foam",
    "red dwarf", "red giant", "retrograde motion", "ring system",
    "satellite", "scale of the universe", "solar flare", "solar nebula",
    "solar wind", "space dust", "space probe", "space weather",
    "spectral line", "spectroscopy", "star cluster", "star formation",
    "star system", "stellar evolution", "stellar nursery", "supercluster",
    "supernova remnant", "telescope array", "transit of planet", "variable star",
    "white dwarf", "X-ray binary", "Zodiacal light", "astrometry",
    "atmospheric escape", "auroral substorm", "axial tilt", "barycenter",
    "biogenic", "binary star system", "blazar", "brown dwarf",
    "carbon star", "cataclysmic variable", "Chandrasekhar limit", "circumbinary",
    "circumstellar disk", "collapsar", "color magnitude diagram", "cosmic microwave background",
    "cosmic string", "crystalline structure", "dark fluid", "degenerate matter",
    "deuterium burning", "diffuse interstellar bands", "dwarf galaxy", 
    "ecliptic plane", "emission nebula", "ephemeris", "escape velocity",
    "exobiology", "exoplanetary", "extragalactic", "fermi paradox",
    "first light", "galactic bulge", "galactic cannibalism", "galactic collision",
    "galactic rotation", "gamma ray astronomy", "gas giant", "gravitational collapse",
    "habitable zone", "heliocentric", "helioseismology", "hypernova",
    "hydrodynamic escape", "inflation theory", "interstellar extinction", 
    "interstellar travel", "iron core collapse", "isothermal core",
    "jeans instability", "kelvin-helmholtz mechanism", "lithosphere", 
    "local group", "molecular cloud", "neutrino flux", "neutron star",
    "novae", "nucleosynthesis", "occult", "olivine", "opacity source",
    "pan-galactic", "photon sphere", "planetesimal", "planetary differentiation",
    "planetary nebula", "plasma tail", "pre-main sequence", "primordial black hole",
    "protoplanetary disk", "pulsar timing", "quantum tunneling", "radiation pressure",
    "radiative transfer", "redshift", "relativistic jet", "resonant relaxation",
    "Roche lobe", "Schwarzschild radius", "secular variation", "sedimentation",
    "solar luminosity", "solar mass", "spacetime", "spiral density wave",
    "starquakes", "stellarator", "stellar mass black hole", "stellar remnant",
    "sublimation", "super-earth", "superluminal", "synchronous rotation",
    "synchrotron emission", "thermal radiation", "Tidal disruption event",
    "transient", "transit method", "triple star system", "ultraviolet astronomy",
    "Van Allen radiation belt", "virial theorem", "void", "WIMPs",
    "Wolf-Rayet star", "X-ray astronomy", "Young stellar object", "ZAMS",
    "Zero Age Main Sequence", "Zwicky's work", "Astrophysical plasma",
    "Intergalactic medium", "Kuiper Cliff", "Magnetar", "Microquasar",
    "Open cluster", "Photometry", "Pulsar wind nebula", "Reionization",
    "Singularity", "Space-time curvature", "Synchrotron radiation", "Tachyon",
    "Thermonuclear fusion", "Tidal locking", "Universe expansion",
    "Virgo Cluster", "X-ray binary", "Zero-point energy","awe", "wonder", "mystery", "cosmic perspective", "pale blue dot",
    "we are made of star stuff", "billions and billions", "the cosmos is within us",
    "to make some one else's life better", "the universe is not required to be",
    "logical", "understanding is a kind of ecstasy", "science as a candle in the dark",
    "imagination will often carry us to worlds", "that never were", "but without it we go nowhere",
    "the Earth is a very small stage", "in a vast cosmic arena", "think about how big the universe is",
    "and how small we are", "knowledge is power", "science is a way of thinking",
    "the universe is under no obligation", "to make sense to you", "the beauty of a living thing",
    "is not the atoms that go into it", "but the way those atoms are put together",
    "if you want to make an apple pie from scratch", "you must first create the universe",
    "somewhere, something incredible is waiting to be known", "our planet is a lonely speck",
    "in the great enveloping cosmic dark", "the nitrogen in our DNA", "the calcium in our teeth",
    "the iron in our blood", "the carbon in our apple pies", "were made in the interiors of collapsing stars",
    "we are stardust", "in the cosmic perspective", "it's quite easy", "to understand why religious people",
    "hold on to their beliefs", "for it's the only thing they have", "in the vastness of the universe",
    "we are not the center", "our planet is a mote of dust", "suspended in a sunbeam",
    "to see the Earth as it truly is", "small and blue and beautiful", "in our obscurity",
    "in all this vastness", "there is no hint that help", "will come from elsewhere",
    "to save us from ourselves", "the significance of our lives", "and our fragile biosphere",
    "is then determined only by our own wisdom", "and courage", "we must cherish the time",
    "we have here", "to understand the nature", "of the world", "to foster peace",
    "and to live the lives", "that are our own to lead", "our thoughts are free",
    "to wander anywhere", "free to soar into an infinite universe", "the sky calls to us",
    "if we do not destroy ourselves", "we will one day venture to the stars", "a still more glorious dawn",
    "awaits", "not in the east", "but in the west", "where the sun of science",
    "sets on one civilization", "only to rise again", "in the future", "on another",
    "that's here", "that's home", "that's us", "on a mote of dust", "suspended in a sunbeam",
    "the Earth is where we make our stand", "the only world known so far to harbor life",
    "there is perhaps no better demonstration", "of the folly of human conceits",
    "than this distant image of our tiny world", "to me, it underscores our responsibility",
    "to deal more kindly with one another", "and to preserve and cherish the pale blue dot",
    "the only home we've ever known", "look again at that dot", "that's here, that's home",
    "that's us", "on it everyone you love", "everyone you know", "everyone you ever heard of",
    "every human being who ever was", "lived out their lives", "the aggregate of our joy and suffering",
    "thousands of confident religions", "ideologies", "and economic doctrines", "every hunter and forager",
    "every hero and coward", "every creator and destroyer of civilization", "every king and peasant",
    "every young couple in love", "every mother and father", "hopeful child", "inventor and explorer",
    "every teacher of morals", "every corrupt politician", "every 'superstar', every 'supreme leader'",
    "every saint and sinner", "on this dot", "the Earth is a very small stage", "in a vast cosmic arena",
    "think of the endless cruelties", "visited by the inhabitants of one corner of this pixel",
    "on the scarcely distinguishable inhabitants", "of some other corner", "how frequent their misunderstandings",
    "how eager they are to kill one another", "how fervent their hatreds", "our posturings",
    "our imagined self-importance", "the delusion that we have some privileged position in the Universe",
    "are challenged by this point of pale light", "our planet", "our home", "our civilization",
    "all that we love", "all that we fear", "all our triumphs", "our tragedies",
    "are in this one pixel", "this is our reality", "this is where we start", "in our thinking",
    "about the significance of human life", "or any life for that matter", "in a universe so vast",
    "where we are but a tiny, insignificant part", "yet, in this vastness", "we find our meaning",
    "we find our purpose", "not in conquering the stars", "but in understanding them",
    "in appreciating the beauty", "in safeguarding our fragile world", "in seeking knowledge",
    "in striving for peace", "in nurturing our collective consciousness", "we are one species",
    "on one planet", "with one chance", "to get it right", "this realization should humble us",
    "should inspire us", "to care for each other", "to protect our shared home",
    "and to explore the cosmos not just with our technology", "but with our minds and hearts",
    "the universe is vast", "and we are small", "but our potential is limitless", "if we choose",
    "to look up", "to wonder", "to explore", "to care", "for we are made of star stuff",
    "and to the stars", "we shall return", "in spirit and in dreams", "carrying with us",
    "the legacy of Carl Sagan", "who taught us to embrace", "the cosmic perspective", "and to cherish",
    "the pale blue dot","Three Laws", "robotics", "psychohistory", "Foundation", "Trantor",
    "Galactic Empire", "Mule", "Second Foundation", "Hari Seldon", "Asenion",
    "positronic brain", "R. Daneel Olivaw", "Elijah Baley", "Spacer", "Aurora",
    "Solaria", "Caves of Steel", "Naked Sun", "Robots of Dawn", "robot-human relations",
    "First Law", "Second Law", "Third Law", "Zeroth Law", "robot ethics",
    "multivac", "supercomputer", "future history", "galactic civilization", "Seldon Crisis",
    "Encyclopedia Galactica", "psychohistorical predictions", "galactic council", "Foundation Trilogy",
    "I, Robot", "C/Fe", "robot detective", "humaniform robots", "robot rights",
    "galactic trade", "Foundation and Earth", "Gaia", "Galactic Library", "mentalics",
    "mentor", "Trantor's Vault", "Plan of Seldon", "Terminus", "Vault Speakers",
    "Foundation's Edge", "preemptive", "Foundation and Chaos", "Foundation's Triumph", "psychohistorian",
    "hyperwave", "Fission", "Fusion", "nuclear power", "atomic transmutation",
    "invisible space", "wormholes", "time travel", "hyperdrive", "jump drive",
    "atomic motors", "gravitics", "force field", "personal shield", "energy weapons",
    "nanotechnology", "micronization", "miniaturization", "Fantastic Voyage", 
    "microscopic journey", "cellular level", "antibodies", "biological processes", "DNA surgery",
    "subatomic", "quark", "atomic structure", "particle physics", "quantum mechanics",
    "gravitational waves", "black holes", "neutron stars", "pulsars", "red giants",
    "white dwarfs", "supernova", "cosmic rays", "dark matter", "string theory",
    "multiverse", "parallel universes", "alternate timelines", "time dilation", "relativity",
    "cosmology", "astronomical distances", "light years", "galactic center", "galactic spiral",
    "exobiology", "astrobiology", "xenobiology", "alien life", "extraterrestrial intelligence",
    "First Contact", "interstellar communication", "space colonization", "terraforming", "space habitats",
    "asteroid mining", "space elevator", "orbital ring", "laser propulsion", "antimatter engines",
    "magnetic confinement", "inertial confinement", "fusion reactors", "cold fusion", "superconductivity",
    "holography", "holovision", "telepresence", "virtual reality", "cybernetics",
    "cybernetic organism", "cyborg", "artificial intelligence", "AI research", "AI ethics",
    "sentience", "machine consciousness", "android", "gynoid", "robot psychology",
    "robotic rebellion", "technological singularity", "technological evolution", "transhumanism", "post-human",
    "space opera", "science fiction", "hard sci-fi", "golden age", "Campbellian",
    "Hugo Award", "Nebula Award", "science education", "popular science", "science writing",
    "Foundation Series", "Robot Series", "Empire Series", "Lucky Starr", "The Gods Themselves",
    "Nightfall", "The Last Question", "Bicentennial Man", "The End of Eternity", "Caves of Steel",
    "The Currents of Space", "Pebble in the Sky", "The Stars, Like Dust", "Nemesis", "Gold",
    "The Martian Way", "The New Teachers", "The Ugly Little Boy", "The Dead Past", "The Dying Night",
    "The Feeling of Power", "The Last Answer", "The Last Trump", "The Machine That Won the War",
    "The Talking Stone", "The Watery Place", "What If-", "The Bicentennial Man", "Sally",
    "Liar!", "Reason", "Runaround", "Catch That Rabbit", "Escape!",
    "Evidence", "Little Lost Robot", "The Evitable Conflict", "Robot Dreams", "Robot Visions",
    "The Caves of Steel", "The Naked Sun", "The Robots of Dawn", "Robots and Empire", "Mirror Image",
    "Light Verse", "Lenny", "Galley Slave", "The Billiard Ball", "First Law",
    "Franchise", "Lest We Remember", "Satisfaction Guaranteed", "Risk", "The Tercentenary Incident",
    "True Love", "Think!", "The Immortal Bard", "The Last Question", "The Dead Past",
    "The Foundation of S.F. Success", "The Relativity of Wrong", "The Tyranny of the Rocket Equation",
    "The Sun Shines Bright", "The Exploding Universe", "Quasars, Pulsars, and Black Holes",
    "The Left Hand of the Electron", "The Collapsing Universe", "Of Time and Space and Other Things",
    "The Neutrino", "The Genetic Code", "The Wellsprings of Life", "The Human Body",
    "The Human Brain", "The Biological Time Bomb", "Races of Mankind", "The Kingdom of the Sun",
    "The World of Carbon", "The Universe", "The Beginning and the End", "The Intelligent Man's Guide",
    "to Science", "Realm of Numbers", "The History of Physics", "The History of Astronomy",
    "The History of Chemistry", "The History of Biology", "The History of Mathematics",
    "The History of Medicine", "The History of Earth", "Science Past, Science Future",
    "The Future of Science", "Asimov's Guide to Shakespeare", "Asimov's Guide to the Bible",
    "Asimov on Numbers", "Asimov on Chemistry", "Asimov's New Guide to Science",
    "Asimov's Biographical Encyclopedia of Science and Technology", "Science Fiction by Asimov",
    "Asimov's Science Fiction", "Magazine of Fantasy and Science Fiction", "Black Widowers",
    "Tales of the Black Widowers", "The Union Club Mysteries", "Casebook of the Black Widowers",
    "Puzzles of the Black Widowers", "The Return of the Black Widowers", "Banquet Night",
    "Murder at the ABA", "The Edge of Tomorrow", "Extraterrestrial Civilizations",
    "The Road to Infinity", "Our Angry Earth", "The Secret of the Universe",
    "Atom", "Kingdom of the Sun", "Life and Energy", "The Wellsprings of Life",
    "In Memory Yet Green", "In Joy Still Felt", "I. Asimov", "It's Been a Good Life",
    "Asimov's Guide to Science Fiction", "Isaac Asimov's Treasury of Humor",
    "Isaac Asimov Presents The Great SF Stories", "The Annotated Gulliver's Travels",
    "The Annotated Paradise Lost", "The Annotated Wizard of Oz", "The Annotated Dracula",
    "The Annotated Sherlock Holmes", "The Annotated H.G. Wells", "The Annotated Verne",
    "The Annotated Poe", "The Annotated Alice", "The Annotated Don Quixote",
    "The Annotated Brothers Grimm", "The Annotated Aesop", "The Annotated Mother Goose",
    "The Annotated Hans Christian Andersen", "The Annotated Myths", "The Annotated Legends",
    "The Annotated Science", "The Annotated Fantasy", "The Annotated History",
    "Asimov Laughs Again", "Magic", "Isaac Asimov's Book of Facts", "The Sensuous Dirty Old Man",
    "The Stars in Their Courses", "The Roving Mind", "Asimov's Chronology of Science and Discovery",
    "Asimov's Chronology of the World", "Asimov's Chronology of the Bible", "How Did We Find Out?",
    "The Science Fiction Weight Loss Book", "The March of the Millennia",
    "The Tragedy of the Moon", "The Clock We Live On", "The Sun Shines Bright",
    "The Stars in Their Courses", "The Exploding Universe", "The Neutrino",
    "Catching Up with Isaac Asimov", "Isaac Asimov's Book of Science and Nature Quotations",
    "Isaac Asimov's Treasury of Humor", "Isaac Asimov's Science Fiction Magazine",
    "Isaac Asimov's SF", "The Hugo Winners", "The Best Science Fiction of",
    "The Great SF Stories", "Before the Golden Age", "The Early Asimov",
    "Earth: Our Crowded Spaceship", "The Science Fictional Solar System",
    "Intergalactic Empires", "The Future in Space", "The Future of Humanity",
    "Far as Human Eye Could See", "The Tragedy of the Moon", "The Clock We Live On",
    "The Sun Shines Bright", "The Exploding Universe", "The Collapsing Universe",
    "Of Time and Space and Other Things", "The Neutrino", "The Genetic Code",
    "The Wellsprings of Life", "The Human Body", "The Human Brain",
    "The Biological Time Bomb", "Races of Mankind", "The Kingdom of the Sun",
    "The World of Carbon", "The Universe", "The Beginning and the End"
];

export default function generateSaganIpsum(count, units = 'sentences', format = 'plain') {
    return loremIpsum({
        count: count,
        units: units,
        format: format,
        words: saganWords
    });
}
