import * as React from 'react';
import styled from 'styled-components';
import {Box, Flex} from 'rebass';
import { useNavigate } from 'react-router-dom';
import {NavLink} from 'react-router-dom';

export const StyledHeader = styled(Flex)`
position: relative;
top: 0;
white-space: nowrap;
text-overflow: ellipsis;
& * {
//   white-space: nowrap;
// text-overflow: ellipsis;
}
width: 100%;
display: flex;
align-items: center;
  background: ${props => props.theme?.header?.background ? props.theme.header.background : '#ddd'};
  color: ${props => props.theme?.header?.color ? props.theme.header.color : '#ddd'};
  color: ${props => props.theme?.header?.padding ? props.theme.header.padding : '8px 16px'};

box-shadow: 0 0 12px 4px rgba(0,0,0,0.1);
  font-size: 14px;
  padding: 2px;
 
    border: 4px solid #fff;
    border-radius: 4px;
  & .action-button{font-size: 12px; font-weight: 500; }
  & .action-button > div{font-size: 20px; margin-bottom: 8px;}
  @media screen and (max-width:550px){
   & .action-button{font-size: 9px; padding: 0;}
   & .action-button > div{font-size: 16px; margin: 0 0 8px;}
  }
  transform-origin: left center;
  transition: all 0.25s ease-in-out;

  .interactive-mode &&& {
   width: fit-content;
   margin: 0 0 0 auto;
   position: fixed;
   right: 0;
  }


  

  
`;



export const HeaderLogo = styled(Box)`
  background: url('${props => props.logo ? props.logo : ''}') no-repeat left/contain;
  width: 80px;
  height: 40px;
`;

export const HeaderContent = styled(Box)`
  flex-grow: 1;
  font-size: 12px;
  border-left: 1px solid rgba(200,200,200,0.1);
  text-align: center;
`;

export const Navigation = styled(Box)`
  flex-grow: 2;

  & a {color: #707070; text-decoration: none; margin: 0 8px; transition: all .3s ease-in-out; font-weight: 500;}
  & a.active{border-bottom: 2px solid #fff; color: #fff; padding-bottom: 4px;}
`;

const NavigationItem = styled(NavLink)`
text-decoration: none;
  cursor: pointer;
  &&&{margin: 4px 8px;}
  font-weight: 600;
  &&&.active {color: #a72fc8; font-weight: bold;}
  color: ${props => props.theme?.header?.color ? props.theme.header.color : '#666'};
`;

export default function Header({id, style, options, children, className}){
    const [activeItem, setActiveItem] = React.useState(0);

    let NavigationItems = options?.map((ni, index) => {
        return <NavigationItem key={ni.label} path={ni.path} to={ni.path} onClick={() =>{
          setActiveItem(index);
          if (ni.action) {
            ni.action();
          }  
        }} className={(activeItem === index) ? `${ni.name ? ni.name : ''} active` : `${ni.name ? ni.name : ''}`}>{ni.label}</NavigationItem>
    }) || null;

    return <StyledHeader id={id} style={style} className={className ? className+ " header" : 'header'}>
        {NavigationItems}
        {children}
    </StyledHeader>
}