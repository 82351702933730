  const getStylesheetId = () => {
    try{
      if (typeof window.render_params?.stylesheet === "object" &&  window.render_params?.stylesheet?.id) {
        return window.render_params?.stylesheet?.id;
      } else {
        const adContainer = document.querySelector('#native-workspace');
        const stylesheetId = adContainer?.getAttribute('data-stylesheet');
        return stylesheetId
      }
    }catch(e){
      return 'none'
    }
  }

  export default getStylesheetId;