import cssStringFromObject from "./cssStringFromObject";
export function MapStylesheet(stylesheet, identifier){
    if (typeof stylesheet === "undefined") {
      return [];
    }
  
    if (typeof identifier === "undefined") {
      identifier = "cohoba";
    }
  
     const  compiledStylesheet = Object.keys(stylesheet).map(ok => {
          if (["id","css","name","fonts","pub_id","pub_url"].includes(ok)) {
              return "";
          } 
          
          if (ok === 'css') {
              return stylesheet["css"];
          }
          
         
          let css = cssStringFromObject(stylesheet[ok]);
          
          if(stylesheet[ok]?.css) {
              css += stylesheet[ok].css;
          }
          
          if (ok.includes('$')){
            //It's a pseudo element or state
            if (ok.includes('$hover')){
              ok = ok.replace(/\$/g,':')
            } else if (ok.includes('$after') || ok.includes('$before')){
              ok = ok.replace(/\$/g,'::')
            }
          }
          
          let selector = `.${identifier}_${ok}.${identifier}_${ok}.${identifier}_${ok}`;

          
          return `${selector}{${css}}`;
      });

      if (stylesheet.css) {
        // Check for stylesheet integrity
        
        compiledStylesheet.unshift(stylesheet.css);
      }

      
      return compiledStylesheet;
  }

  export default MapStylesheet;