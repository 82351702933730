import * as React from 'react';
import styled from 'styled-components';
import FaderInput from '../../components/FaderInput';
import OptionGroup from '../../components/OptionGroup';
import ColorMixer from '../../components/ColorMixer';
import {Flex, Box} from 'rebass';
import PropertyInput from '../../components/PropertyInput';
import ActionButton from '../../components/ActionButton';
import { FixedSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import OptionList from '../../components/OptionList';
import { PropertyLabel } from '../../components/PropertyLabel';
import Switch from '../../components/Switch';

export const TextAlign = ({value, action}) => {
   return <OptionGroup
    style={{ width: "calc(40%)", display: "inline-block" }}
    label="Align"
    value={value}
    columns={4}
    options={[
      {
        name: "",
        value: "left",
        icon: <i className="fas fa-align-left"></i>,
      },
      {
        name: "",
        value: "center",
        icon: <i className="fas fa-align-center"></i>,
      },
      {
        name: "",
        value: "right",
        icon: <i className="fas fa-align-right"></i>,
      },
    ]}
    action={function (value) {
      action(value)
    }}
  />
}

export const FontWeight = ({value, action}) => {
    return  <OptionList
    label="Weight"
    style={{ width: "100%" , display:"inline-block"}}
    value={value}
    options={[
        { name: "100", value: "100", icon: <span style={{fontWeight: 100}}>Thin</span> },
        { name: "200", value: "200", icon: <span style={{fontWeight: 200}}>Lighter</span> },
        { name: "300", value: "300", icon: <span style={{fontWeight: 300}}>Light</span> },
        { name: "400", value: "400", icon: <span style={{fontWeight: 400}}>Regular</span> },
        { name: "500", value: "500", icon: <span style={{fontWeight: 500}}>Medium</span> },
        { name: "600", value: "600", icon: <span style={{fontWeight: 600}}>Bold</span> },
        { name: "700", value: "700", icon: <span style={{fontWeight: 700}}>Bolder</span> },
        { name: "900", value: "900", icon: <span style={{fontWeight: 900}}>Black</span> },
    ]}
    action={function (value) {
       action(value);
    }}
    />
}



export const FillColor = ({stylesheet, setStylesheet, action, component, value, label}) => {

    const [background, setBackground] = React.useState(() => {
        if (stylesheet && component && stylesheet[component]) {
            if (!stylesheet[component].background && stylesheet[component].backgroundImage) {
                return  stylesheet[component]?.backgroundImage
            }

            if (stylesheet[component].background && stylesheet[component].background !== '') {
                return stylesheet[component].background;
            }

            if (stylesheet[component].backgroundColor && stylesheet[component].backgroundColor !== ''
                && (!stylesheet[component].background || stylesheet[component].background === '')
                && (!stylesheet[component].backgroundImage || stylesheet[component].backgroundImage === '')
                ) {
                return stylesheet[component].backgroundColor;
            }
          
        }

        if (value) {
            return value;
        }
        return "#fff";
    });

    const [startColor, setStartColor] = React.useState(()=>{
        if (background.includes("gradient")) {
           return background.split("gradient")[1].split("),")[0].split(",").slice(1).join(",")+")";
        } else {
            return background;
        }
    });
    const [endColor, setEndColor] = React.useState(()=>{
        if (background.includes("gradient")) {
           return background.split("gradient")[1].split("),")[1].slice(0,-1).trim();
        } else {
            return background;
        }
    });
    const [fillType, setFillType] = React.useState(()=>{
        if (background.includes("gradient")) {
           return background.split("gradient")[0] + "gradient";
        } else {
            return "solid";
        }
    });
    const [direction, setDirection] = React.useState(()=>{
        if (background.includes("gradient")) {
           return background.split("gradient")[1].split(",")[0].slice(1).trim()
        } else {
            return background;
        }
    });
    const [init, setInit] = React.useState(false);

    /*
    background.includes("gradient");
    background.split("gradient")[1].split(",")[0].slice(1)
    background.split("gradient")[1].split(",")[1].slice(1)
    background.split("gradient")[1].split(",")[2].slice(-1)

    else
    background
    */

    React.useEffect(()=>{
        if (init) {
            console.log(`${fillType}(${direction}, ${startColor}, ${endColor})`);
            if (fillType === "solid") {
                if (setStylesheet) {
                    setStylesheet(ss => ({...ss, [component]: {...ss[component], backgroundColor: startColor, background: startColor, backgroundImage: ''}}));
                }

                if (action) {
                    action(`${startColor}`);
                }
            } else if (fillType === "linear-gradient") {
                if (setStylesheet) {
                    setStylesheet(ss => ({...ss, [component]: {...ss[component], backgroundColor: startColor, background: startColor, backgroundImage: `${fillType}(${direction}, ${startColor}, ${endColor})`}}));
                }

                if (action) {
                action(`${fillType}(${direction}, ${startColor}, ${endColor})`);
                }
            } else if (fillType === "radial-gradient") {
                if (setStylesheet) {
                    setStylesheet(ss => ({...ss, [component]: {...ss[component], backgroundColor: startColor, background: startColor, backgroundImage: `${fillType}(center, ${startColor}, ${endColor})`}}));
                }
                if (action) {
                action(`${fillType}(center, ${startColor}, ${endColor})`);
                }
            }
        } else {
            setInit(true);
        }
    }, [fillType, direction, startColor, endColor]);

    return <>{label && <PropertyLabel>{label}</PropertyLabel>}<Flex>
        <OptionList key="backround-type" label="Type" value={fillType} options={[
            {name:"solid", value:"solid"},
            {name:"linear", value:"linear-gradient"},
          //  {name:"radial", value:"radial-gradient"}
            ]} action={(value) => {
                setFillType(value);
            }}/>
        {fillType !== "solid" && <OptionList key="backround-direction" style={{alignSelf:"flex-end", marginLeft:"8px"}} label="Direction" value={direction} options={[
            {name:"to bottom", value:"to bottom"},
            {name:"to right", value:"to right"},
            {name:"angle", value:"angle"}
            ]}  action={(value) => {
                setDirection(value);
            }}/>}

            <ColorMixer key="backround-start-color" label="Start" style={{alignSelf:"flex-end", marginLeft:"8px"}} preview={true} value={startColor} action={(value) =>{ setStartColor(value)}}/>

           {fillType !== "solid" && <ColorMixer key="backround-end-color" label="End" style={{alignSelf:"flex-end", marginLeft:"8px"}} preview={true} value={endColor} action={(value) =>{setEndColor(value)}}/>}
    </Flex></>
}


export function FontList({fontFamilies, onFontSelect, sampleText, action}) {
    const [nameQuery, setNameQuery] = React.useState("");
    const [sortBy, setSortBy] = React.useState("popularity");
    const [filterBy, setFilterBy] = React.useState(['display', 'serif', 'sans-serif', 'monospace', 'handwriting']);
    const [fontList, setFontList] = React.useState(fontFamilies);
    const [filteredFontList, setFilteredList] = React.useState([]);

    const API_KEY = "AIzaSyBMC1nT0mzLkctwuvoXyeH6TORSIRvR1VY";


//     React.useEffect(()=>{
//         // Fetch the list of available Google Fonts
// fetch("https://www.googleapis.com/webfonts/v1/webfonts?sort=popularity&key="+API_KEY)
// .then((response) => response.json())
// .then((data) => {
//     if (data.error) {return};
//     console.log(data.items);
//     window.cohoba.fontList = data.items;
//     setFontList(data.items);
//     setFilteredList(data.items);
// })
// .catch((error) => {
//   console.error("Error fetching fonts: " + error);
// });
//     },[])

    React.useEffect(()=>{
        let filteredFonts = fontList.filter(font => filterBy.includes(font.category));

        const regex = new RegExp(nameQuery, "i");

       filteredFonts = filteredFonts.filter(font => regex.test(font.family));
       setFilteredList(filteredFonts);
    },[nameQuery, filterBy]);

    // React.useEffect(()=>{
    //     fetch("https://www.googleapis.com/webfonts/v1/webfonts?sort="+sortBy+"&key="+API_KEY)
    //     .then((response) => response.json())
    //     .then((data) => {
    //         console.log(data.items);
    //         setFontList(data.items);
    //     })
    //     .catch((error) => {
    //       console.error("Error fetching fonts: " + error);
    //     });
    // },[sortBy]);

    
    // Inside your component
const Row = ({ index, style }) => {
    const fontFamily = filteredFontList[index] ? filteredFontList[index].family : '';

    return <Flex style={style} onClick={()=>{onFontSelect(fontFamily)}}>
        <Box flexGrow={1}>{fontFamily}</Box>
      {<div>
      <iframe style={{height: "50px", pointerEvents:"none"}} frameBorder={0} onLoad={e =>{
        e.currentTarget.contentDocument.head.innerHTML = `<style>@import url('https://fonts.googleapis.com/css?family=${fontFamily.replace(/ /g, "+")}');body{background:#fff; font-family: ${fontFamily};}</style>`;
        e.currentTarget.contentDocument.body.innerHTML = sampleText || `Sample Text`;
      }} srcDoc={`<style>@import url('https://fonts.googleapis.com/css?family=${fontFamily.replace(/ /g, "+")}');body{background:#fff; font-family: ${fontFamily};}</style> Testing Copy`}>
       
      </iframe>
      </div>}
    </Flex>
};

    return (
        <Box>
            <Flex>
            <PropertyInput type="static" placeholder="Font Family" style={{flexGrow:1, padding: "0 8px 0 0"}} label="Name" value={nameQuery} action={setNameQuery} onReturnAction={(value)=>{if(action){action(value);}}}/>
            {/* <OptionList name="SORT" label="Sort" value={sortBy} action={setSortBy} options={[
                {name: "Style", value:"style"},
                {name: "Popularity", value:"popularity"},
                {name: "Trending", value:"trending"},
            ]}/> */}
            <OptionList name="CATEOGRY" value={filterBy} action={setFilterBy}  options={[
                {name: "Display", value:"display"},
                {name: "Serif", value:"serif"},
                {name: "Sans-serif", value:"sans-serif"},
                {name: "Monospace", value:"monospace"},
                {name: "Handwriting", value:"handwriting"},
            ]}/>
            </Flex>
      <AutoSizer style={{height:"200px"}} id="font-list-container">
        {({ height, width }) => (
            <List
            height={height}
            itemCount={filteredFontList?.length || 0}
            itemSize={50} // Adjust based on your item size
            width={width}
            >
            {Row}
          </List>
        )}
      </AutoSizer>
        </Box>
    );
  }


const BorderStyleContainer = styled(Box)`
  &&&{position: relative;
    width: 200px;
    height: 120px;
    margin: 0;}
`

const BorderStyleBox = styled(Box)`
position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
width: 80%;
height: 70%;
border: 2px solid #fff;
`

const BorderStyleLinkedInput = styled(PropertyInput)`

width: 65px;

`

export const BorderStyle = ({setStylesheet, component, action, value}) => {

    const [init, setInit] = React.useState(false);

    const [option, setOption] = React.useState("none");
    const [linkedValues, setLinkedValues] = React.useState(true);
    const [borderValue, setBorderValue] = React.useState(value || "0px");

    const [topBorderValue, setTopBorderValue] = React.useState(value?.split(" ")?.at(0) || "0px");
    const [rightBorderValue, setRightBorderValue] = React.useState(value?.split(" ")?.at(1) || "0px");
    const [bottomBorderValue, setBottomBorderValue] = React.useState(value?.split(" ")?.at(2) || "0px");
    const [leftBorderValue, setLeftBorderValue] = React.useState(value?.split(" ")?.at(3) || "0px");


    React.useEffect(()=> {
        if (init) {
            console.log(value);
    }
}
    ,[value]);

    React.useEffect(()=> {
        if (init) {

            if (linkedValues) {
                setTopBorderValue(borderValue);
                setRightBorderValue(borderValue);
                setBottomBorderValue(borderValue);
                setLeftBorderValue(borderValue);
                action(`${borderValue}`);

            } else {
                action(`${topBorderValue} ${rightBorderValue} ${bottomBorderValue} ${leftBorderValue}`)
            }

        } else {
            setInit(true);
        }
    }
    ,[borderValue, topBorderValue, rightBorderValue, bottomBorderValue, leftBorderValue]);

    React.useEffect(()=> {
       if (linkedValues) {
        setTopBorderValue(borderValue);
        setRightBorderValue(borderValue);
        setBottomBorderValue(borderValue);
        setLeftBorderValue(borderValue);
       } else {
        setBorderValue(leftBorderValue);
       }
    }
    ,[linkedValues]);

    const linkIcon = linkedValues ? <i className="fas fa-unlink"/> : <i className="fas fa-link"/>;


  return  <Flex alignItems={"center"}>
    <BorderStyleContainer>
        {!linkedValues &&<> <PropertyInput disabled={linkedValues} value={topBorderValue} key="top-border" style={{position:"absolute", width:"65px", top: 0, left: "50%", transform:"translateX(-50%)", zIndex: 1}} action={(value) => {
            setTopBorderValue(value)
        }}/>
        <PropertyInput disabled={linkedValues} value={rightBorderValue} key="right-border" style={{position:"absolute", width:"65px",top: "50%", right: 0, transform:"translateY(-50%)", zIndex: 1}} action={(value) => {
            setRightBorderValue(value)
        }}/>
        <PropertyInput disabled={linkedValues} value={bottomBorderValue} key="bottom-border" style={{position:"absolute", width:"65px",bottom: 0, left: "50%", transform:"translateX(-50%)", zIndex: 1}} action={(value) => {
            setBottomBorderValue(value)
        }}/>
        <PropertyInput disabled={linkedValues} value={leftBorderValue} key="left-border" style={{position:"absolute", width:"65px",top: "50%", left: 0, transform:"translateY(-50%)", zIndex: 1}} action={(value) => {
            setLeftBorderValue(value)
        }}/></>}

        <BorderStyleBox style={{
            // borderTopWidth: topBorderValue,
            // borderRightWidth: rightBorderValue,
            // borderBottomWidth: bottomBorderValue,
            // borderLeftWidth: leftBorderValue,
            }}>
            <Flex style={{position:"absolute", width:"80%", top:"50%", left:"50%", transform:"translate(-50%,-50%)", width:"fit-content", alignItems:"center", justifyContent:"center"}}>
            {linkedValues && <BorderStyleLinkedInput value={borderValue} action={(value) => {
                setBorderValue(value);
            }}/>}
        <ActionButton key="unlink-button" style={{background:"transparent", color:"#c068d9"}} action={()=>{
            setLinkedValues(!linkedValues)
        }}>
            {<span key={Math.random()}>{linkIcon}</span>}
        </ActionButton>
        
        </Flex>
        </BorderStyleBox>
    </BorderStyleContainer>
    <OptionGroup options={[
        {name:"None", icon: <div style={{margin:"4px auto",width:"16px", height:"16px",border:"1px dotted #ccc", borderWidth:"1px"}}/>, value:"none"},
        {name:"Top-Bottom", icon: <div style={{margin:"4px auto",width:"16px", height:"16px",border:"1px solid #ccc", borderWidth:"1px 0"}}/>, value:"top-bottom"},
        {name:"All Light", icon: <div style={{margin:"4px auto",width:"16px", height:"16px",border:"1px solid #ccc", borderWidth:"1px"}}/>, value:"all-light"},
        {name:"All Heavy", icon: <div style={{margin:"4px auto",width:"16px", height:"16px",border:"2px solid #ccc", borderWidth:"2px"}}/>, value:"all-heavy"},
        {name:"All Heavier", icon: <div style={{margin:"4px auto",width:"16px", height:"16px",border:"4px solid #ccc", borderWidth:"4px"}}/>, value:"all-heavier"},
       
    ]}
    action={(value)=>{
      
        if (value === 'none') {
            setStylesheet(stylesheet => {
               stylesheet[component] = {...stylesheet[component], borderStyle:"none", borderWidth:"0"};
               return stylesheet;
            });
            setLinkedValues(true);
            setBorderValue("0px");

        }

        if (value === 'top-bottom') {
            setStylesheet(stylesheet => {
               stylesheet[component] = {...stylesheet[component], borderStyle:"solid", borderWidth:"1px 0"};
               return stylesheet;
            });
            setLinkedValues(false);
            setTopBorderValue("1px");
            setRightBorderValue("0px");
            setBottomBorderValue("1px");
            setLeftBorderValue("0px");
        }
        if (value === 'all-light') {
            setStylesheet(stylesheet => {
                stylesheet[component] = {...stylesheet[component], borderStyle:"solid", borderWidth:"1px"};
                return stylesheet;
             });
             setLinkedValues(true);
             setBorderValue("1px");
        }
        if (value === 'all-heavy') {
            setStylesheet(stylesheet => {
                stylesheet[component] = {...stylesheet[component], borderStyle:"solid", borderWidth:"2px"};
                return stylesheet;
             });
             setLinkedValues(true);
             setBorderValue("2px");
        }

        if (value === 'all-heavier') {
            setStylesheet(stylesheet => {
                stylesheet[component] = {...stylesheet[component], borderStyle:"solid", borderWidth:"4px"};
                return stylesheet;
             });
             setLinkedValues(true);
             setBorderValue("4px");
        }

    }}
    />
    </Flex>
    
}

export const BoxShadow = ({value, action, label, stylesheet, setStylesheet, component}) => {

    const [shadow, setShadow] = React.useState(value);
    const [shadowEnabled, setShadowEnabled] = React.useState(false);
    const [xOffset, setXOffset] = React.useState(shadow.split(" ")[0] || 0);
    const [yOffset, setYOffset] = React.useState(shadow.split(" ")[1] || 0);
    const [size, setSize] = React.useState(shadow.split(" ")[2] || 0);
    const [blur, setBlur] = React.useState(shadow.split(" ")[3] || 0);
    const [color, setColor] = React.useState(shadow.split(" ")[4] || 'black');

    const [init, setInit] = React.useState(false);

    React.useEffect(()=>{
        // setStylesheet(stylesheet => {
        //     stylesheet[component] = {...stylesheet[component], textShadow: shadow};
        //     return stylesheet;
        // });
       if (init) {
        if (action) {
            action(`${xOffset} ${yOffset} ${size} ${blur} ${color}`);
        }
        if (setStylesheet && component) {
            setStylesheet(ss => ({...ss, [component] : {...ss[component], boxShadow: `${xOffset} ${yOffset} ${size} ${blur} ${color}`}}))
        }
       } else {
        setInit(true);
       }
    }, [xOffset, yOffset, size, blur, color])

    React.useEffect(()=>{
        // setStylesheet(stylesheet => {
        //     stylesheet[component] = {...stylesheet[component], textShadow: shadow};
        //     return stylesheet;
        // });
        if (init && shadowEnabled === false) {
            setStylesheet(ss => ({...ss, [component] : {...ss[component], boxShadow: ""}}))
        } else if (init){
            setStylesheet(ss => ({...ss, [component] : {...ss[component], boxShadow: `${xOffset} ${yOffset} ${size} ${blur} ${color}`}}))
        }
    }, [shadowEnabled]);

    // let xOffset = stylesheet[component]?.textShadow?.split(" ")[0] || 0;
    // let yOffset = stylesheet[component]?.textShadow?.split(" ")[1] || 0;
    // let blur = stylesheet[component]?.textShadow?.split(" ")[2] || 0;
    // let color = stylesheet[component]?.textShadow?.split(" ")[3] || 'black';



    return <Box>
        {label && <PropertyLabel>{label}</PropertyLabel>}
        <Switch  value={shadowEnabled} action={(value) =>{
            setShadowEnabled(value)
        }}/>
        {shadowEnabled && <Flex>
        <PropertyInput value={xOffset} sublabel="X" unit={"px"} action={(value) => {
            setXOffset(value)
           // setShadow(`${value} ${yOffset} ${size} ${blur} ${color}`)
           // setStylesheet(stylesheet => {
               //     stylesheet[component] = {...stylesheet[component], textShadow: `${value} ${yOffset} ${blur} ${color}`};
               //     return stylesheet;
               // });
            }}/>
        <PropertyInput  value={yOffset}  sublabel="Y" unit={"px"} action={(value) => {
            
            setYOffset(value)
            //  setShadow(`${xOffset} ${value} ${size} ${blur} ${color}`)
            // setStylesheet(stylesheet => {
                //     stylesheet[component] = {...stylesheet[component], textShadow: `${xOffset} ${value} ${blur} ${color}`};
                //     return stylesheet;
                // });
            }}/>
          <PropertyInput  value={size}  sublabel="SIZE" unit={"px"} action={(value) => {
              setSize(value)
              //  setShadow(`${xOffset} ${yOffset} ${value} ${blur} ${color}`)
              // setStylesheet(stylesheet => {
                  //     stylesheet[component] = {...stylesheet[component], textShadow: `${xOffset} ${yOffset} ${value} ${color}`};
                  //     return stylesheet;
                  // });
                }}/>
        <PropertyInput  value={blur}  sublabel="BLUR" unit={"px"} action={(value) => {
            setBlur(value)
            // setShadow(`${xOffset} ${yOffset} ${size} ${value} ${color}`)
            // setStylesheet(stylesheet => {
                //     stylesheet[component] = {...stylesheet[component], textShadow: `${xOffset} ${yOffset} ${value} ${color}`};
                //     return stylesheet;
                // });
            }}/>
        <ColorMixer  preview={true}  sublabel="COLOR" value={color} action={(value) => {
            setColor(value)
            // setShadow(`${xOffset} ${yOffset} ${size} ${blur} ${value}`)
            // setStylesheet(stylesheet => {
                //     stylesheet[component] = {...stylesheet[component], textShadow: `${xOffset} ${yOffset} ${blur} ${value}`};
                //     return stylesheet;
                // });
        }}/>
    </Flex>}
</Box>
}

export const TextShadow = ({value, action, label, stylesheet, component, setStylesheet}) => {
    
    const [shadow, setShadow] = React.useState(value);
    const [xOffset, setXOffset] = React.useState(shadow.split(" ")[0] || 0);
    const [yOffset, setYOffset] = React.useState(shadow.split(" ")[1] || 0);
    const [blur, setBlur] = React.useState(shadow.split(" ")[2] || 0);
    const [color, setColor] = React.useState(shadow.split(" ")[3] || 'black');
    const [shadowEnabled, setShadowEnabled] = React.useState(false);


    const [init, setInit] = React.useState(false);

    React.useEffect(()=>{
        // setStylesheet(stylesheet => {
        //     stylesheet[component] = {...stylesheet[component], textShadow: shadow};
        //     return stylesheet;
        // });
        if (init && shadowEnabled === false) {
            setStylesheet(ss => ({...ss, [component] : {...ss[component], textShadow: ""}}))
        } else if (init){
            setStylesheet(ss => ({...ss, [component] : {...ss[component], textShadow: `${xOffset} ${yOffset} ${blur} ${color}`}}))
        }
    }, [shadowEnabled]);

    React.useEffect(()=>{
        // setStylesheet(stylesheet => {
        //     stylesheet[component] = {...stylesheet[component], textShadow: shadow};
        //     return stylesheet;
        // });
        if (init) {
            if (action) {
                action(`${xOffset} ${yOffset} ${blur} ${color}`)
            }
            if (setStylesheet && component) {
                setStylesheet(ss => ({...ss, [component] : {...ss[component], textShadow: `${xOffset} ${yOffset} ${blur} ${color}`}}))
            }
        } else {
            setInit(true);
        }
    }, [xOffset, yOffset,blur,color])

    // let xOffset = stylesheet[component]?.textShadow?.split(" ")[0] || 0;
    // let yOffset = stylesheet[component]?.textShadow?.split(" ")[1] || 0;
    // let blur = stylesheet[component]?.textShadow?.split(" ")[2] || 0;
    // let color = stylesheet[component]?.textShadow?.split(" ")[3] || 'black';

    // const xOffset = shadow.split(" ")[0] || 0;
    // const yOffset = shadow.split(" ")[1] || 0;
    // const blur = shadow.split(" ")[2] || 0;
    // const color = shadow.split(" ")[3] || 'black';



    return <Box>
        {label && <PropertyLabel>{label}</PropertyLabel>}
        <Switch  value={shadowEnabled} action={(value) =>{
            setShadowEnabled(value)
        }}/>
        {shadowEnabled && <Flex flexWrap={'wrap'}>
        <PropertyInput value={xOffset} sublabel="X" unit="px" action={(value) => {
            setXOffset(value)
           // setShadow(`${value} ${yOffset} ${blur} ${color}`)
            // setStylesheet(stylesheet => {
            //     stylesheet[component] = {...stylesheet[component], textShadow: `${value} ${yOffset} ${blur} ${color}`};
            //     return stylesheet;
            // });
        }}/>
        <PropertyInput  value={yOffset}  sublabel="Y" unit="px" action={(value) => {
            setYOffset(value)
           // setShadow(`${xOffset} ${value} ${blur} ${color}`)
            // setStylesheet(stylesheet => {
            //     stylesheet[component] = {...stylesheet[component], textShadow: `${xOffset} ${value} ${blur} ${color}`};
            //     return stylesheet;
            // });
        }}/>
        <PropertyInput  value={blur}  sublabel="SIZE" unit="px" action={(value) => {
            setBlur(value)
           // setShadow(`${xOffset} ${yOffset} ${value} ${color}`)
            // setStylesheet(stylesheet => {
            //     stylesheet[component] = {...stylesheet[component], textShadow: `${xOffset} ${yOffset} ${value} ${color}`};
            //     return stylesheet;
            // });
        }}/>
        <ColorMixer  preview={true}  sublabel="COLOR" value={color} action={(value) => {
            setColor(value)
           // setShadow(`${xOffset} ${yOffset} ${blur} ${value}`)
            // setStylesheet(stylesheet => {
            //     stylesheet[component] = {...stylesheet[component], textShadow: `${xOffset} ${yOffset} ${blur} ${value}`};
            //     return stylesheet;
            // });
        }}/>
    </Flex>}
    </Box>
}

export const ComponentBorderShape = ({stylesheet, setStylesheet, component, action}) => {
    const [custom, setCustom] = React.useState(false);
    return <>
                    <OptionGroup label="Border Shape" options={[
                        {name:"Square", value:"0px"},
                        {name:"Beveled", value:"3px"},
                        {name:"Rounded", value:"8px"},
                        {name:"Pill", value:"100px"},
                        {name:"Circle", value:"50%"},
                        {name:"Custom", value:"custom"},
                    ]} 
                    value={stylesheet[component]?.borderRadius}
                    action={(value) => {
                        if (value === "custom") {
                            setCustom(true);
                        } else {
                            setCustom(false);
                        }
                
                        if (value !== "custom") {
                            // setStylesheet(ss => {
                            //     ss[component] = {...ss[component], borderRadius: value};
                            //     console.log(ss[component].borderRadius);
                            //     return ss;
                            // });
                           
                            setStylesheet(ss => ({...ss, [component]: {...ss[component], borderRadius: value}}));
                            console.log(window.cohoba.workspace.stylesheet[component]?.borderRadius);
                            if (action) {
                                action(value);
                            }
                        }
                    }}
                    />
                    {custom && <FaderInput label="Border Radius" value={stylesheet[component]?.borderRadius} max="100" min="0" action={(value)=>{
                        setStylesheet(ss => ({...ss, [component]:{...ss[component], borderRadius: value+'px'}}));

                        if (action) {
                            action(value);
                        }
                    }} />}
                    </>
}

export const BorderShape = props => {
    const [custom, setCustom] = React.useState(false);
    return <>
    <OptionGroup options={[
        {name:"Square", value:"0px"},
        {name:"Beveled", value:"3px"},
        {name:"Rounded", value:"8px"},
        {name:"Pill", value:"100px"},
        {name:"Circle", value:"50%"},
        {name:"Custom", value:"custom"},
    ]} 
    action={(value) => {
        if (value === "custom") {
            setCustom(true);
        } else {
            setCustom(false);
        }

        if (value !== "custom") {
            props.setStylesheet(stylesheet => ({...stylesheet, cta:{...stylesheet['cta'], borderRadius: value}}))
        }
    }}
    />
    {custom && <FaderInput label="Border Radius" max="100" min="0" action={(value)=>{
        props.setStylesheet(stylesheet => ({...stylesheet, cta:{...stylesheet['cta'], borderRadius: value+'px'}}))
    }} value="0"/>}
    </>
}