import * as React from 'react';
import styled from 'styled-components';

const StyledSpinner = styled.span`
    font-size: 18px;
    margin-left: 8px;
`;

function Spinner({loading}) {
    return <StyledSpinner>
        {<i className="fas fa-spinner fa-spin"></i>}
        </StyledSpinner>
}

export default Spinner;