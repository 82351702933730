import getRenderUrls from "../getRenderUrls";

const getRenderId = () => {
  const url = new URL(getRenderUrls().at(0));
  let renderId;
  if (url) {
      renderId = url.searchParams.get('renderid')
  }
  return renderId
};

export default getRenderId;