

function downloadFile(filename, data, type) {
  var element = document.createElement('a');
  if(!type){
      type = "data:text/html;charset=utf-8,";
  }else if(type === "data"){
      type = "data:application/octet-stream;charset=utf-8,"
  }else if(type === "png"){
      type = "data:image/png;charset=utf-8,"
  }else if(type === "csv"){
    type = "data:text/csv;charset=utf-8;,"
}
  element.setAttribute('href', type + encodeURIComponent(data));
  element.setAttribute('download', filename);

  element.style.display = 'none';
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
}

export default downloadFile;