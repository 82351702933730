import React from "react";
import styled from 'styled-components';
import {Flex} from 'rebass';

const WindowModuleContainer = styled(Flex)`
  max-height: 100%;
  flex-direction: column;
`


export default function WindowModule (props) {
  
    return (
      <WindowModuleContainer
        style={props.style}
        className={"window-module window-" + props.name + "-module " + props.className}
      >
        {props.children}
      </WindowModuleContainer>
    );
}