/*
This function could accept a callback because some of what happens
depends on runtime and things might need to execute asynchronously.
*/

//import getCreative from "../creative/getCreative";
//import processDCR from "../creative/processDCR";

/*
===========================
Identifies elements of a template
Used in many places throughout cohoba
===========================
*/

function inspect(object) {
  let inspectObject = {};

  /*
  We can only inspect rendered code, not templates with macros.
  We're checking for that here, but this function should be made pure.
  */

  // Should we be "inspecting" proven templates, HDX & mapped templates?


  /*
  A guard clause
  If there's nothing to inspect, return (do nothing)
  Sarah chose a random way to check...we could use a million different ways
  */
  if (!window.cohoba.workspace.title) {
    return
  } 

  if (
    typeof window.cohoba.workspace !== "undefined" &&
    typeof htmlCode === "undefined"
  ) {
    if (typeof window.cohoba.workspace.creative === "undefined" && window.cohoba.workspace.placement) {
      let workspace = window.cohoba.workspace;
      // getCreative(workspace.placement.inv_code, workspace.placement.tactic, function (dcr) {
      //   workspace.creative = processDCR(dcr);
      //   window.cohoba.updateWorkspace(workspace);
      // });
    }
  }

  if (typeof object === "string") {
    // This only works on a window.document (browser)
    inspectObject.content = document.createElement("div");
    inspectObject.content.innerHTML = object;
  } else if (typeof object === "object") {
    inspectObject.content = object;
  } else if (typeof object === "undefined") {

    // TODO: We could remove this from here and do it outside
    // (inspect(window.cohoba.WorkspaceElement))
    // console.log("Finding content to inspect")
    if (window.cohoba.workspace.type === "div" || window.cohoba.workspace.type === "component") {
      inspectObject.content = document.querySelector("#cohoba-workspace");
    } else if (window.cohoba.workspace.type === "iframe") {
      inspectObject.content = window.cohoba.workspaceContainer.querySelector(
        "iframe"
      );
      if (inspectObject.content !== null) {
        inspectObject.content = inspectObject.content.contentDocument.body;
      }
    }

    if (
      // window.cohoba.workspace.placement.inv_code &&
      window.cohoba.workspace.title &&
      isNaN(window.cohoba.workspace.title[0]) &&
      inspectObject.content !== null && typeof inspectObject.content !== "undefined"
    ) {
        if (window.cohoba.workspace.workspaceType === "placement") {
          
          if ( isNaN(parseInt(window.cohoba.workspace.placement.inv_code.charAt(0))) === false ) {
            // console.log("inv code has number as first charater. Add tl to classname." , parseInt(window.cohoba.workspace.placement.inv_code.charAt(0)) )
            inspectObject.ad = inspectObject.content.querySelector(".tl-" + window.cohoba.workspace.placement.inv_code);          
          } else {
            // console.log("inv code does not begin with a number")
            inspectObject.ad = inspectObject.content.querySelector("." + window.cohoba.workspace.placement.inv_code);          
          }



        }
         else if (window.cohoba.workspace.workspaceType === "template") {
          /*
          Default template names have spaces and : (Gah! WHY????)
          that need to be replaced to be in sync with class names
          */
          inspectObject.ad = inspectObject.content.querySelector("." + window.cohoba.workspace.title.replace(/ /g,"_").replace(":", "-"));
        }
        

        if (inspectObject.ad === null) {
          inspectObject.ad = inspectObject.content.querySelector(".tlod");
        }
    }
  }

  if (inspectObject.content === null || typeof inspectObject.content === "undefined") {
    return inspectObject;
  }

  inspectObject.container = inspectObject.content.querySelector("[id$='_container']:not([id*='media']),[class*='_container']:not([class*='_media'])");

  if(inspectObject.ad === null && inspectObject.container){
    inspectObject.ad = inspectObject.container;
  }


  //containerLeft, aka media_container
  inspectObject.containerLeft = inspectObject.content.querySelector(
    "[id$='_container_left'], [class*='_container_left'], [class*='_media_container']"
  );
   
  //containerRight, aka textcontent on UT
  inspectObject.containerRight = inspectObject.content.querySelector(
    "[id$='_container_right'], [class*='_textcontent'], [id$='_textcontent']"
  );

  inspectObject.textContent = inspectObject.content.querySelector(
    "[id$='_textcontent'],[class*='_textcontent']"
  );

  inspectObject.containerTop = inspectObject.content.querySelector(
    "[id$='_container_top'], [id$='_container_right1'], [class*='_creative_copy'],[class*='_textcontent_creative_copy']"
  );

  inspectObject.containerBottom = inspectObject.content.querySelector(
    "[id$='_container_bottom'], [id$='_container_right2'],[class*='_textcontent_cta'],[class*='_cta_container'],  [class*='_footer']"
  );

  inspectObject.footer = inspectObject.content.querySelector(
    "[id$='_container_bottom'], [id$='_container_right2'],[class*='_textcontent_cta'],[class*='_cta_container'],  [class*='_footer']"
  );

  inspectObject.media = inspectObject.content.querySelector(
    "[id$='_image'], [class*='_media']:not([class*='_container']):not([class*='_link']),[class*='_image'],.tl-video, img"
  );

  inspectObject.heading = inspectObject.content.querySelector(
    "[class*='_flexrules'] [class*='_heading_link'], [id$='heading'],[class*='_heading'],[id*='_heading'],[class*='_heading']"
  );

  inspectObject.headingLink = inspectObject.content.querySelector(
    "[id$='_heading'] a,[class*='_heading'] a,[id$='_heading'] a, .tl-heading, [class*='_heading'] a"
  );

  inspectObject.sponsor = inspectObject.content.querySelector(
    "[class*='_flexrules'] [class*='_sponsor_link'], [id$='_sponsor'], [class*='_sponsor']:not([class*='_tag'])"
  );

  inspectObject.sponsorName = inspectObject.content.querySelector(
    "[id$='_sponsor_name'] span, [class*='_sponsor_name'] span,[id$='_sponsor_name'], [class*='_sponsor_name']"
  );

  inspectObject.caption = inspectObject.content.querySelector(
    "[class*='_flexrules'] [class*='_caption_link'], [id$='_caption'], [class*='_caption'], .tl-caption,[class*='_caption']"
  );

  inspectObject.cta = inspectObject.content.querySelector(
    "a[class*='_cta'], [id$='_cta'], [class*='_cta']:not([class*='_container']),[id$='-cta'],[class*='-cta']"
  );

  inspectObject.disclosure = inspectObject.content.querySelector(
    "[id$='disclaimer'], [class*='disclaimer'],[id$='-disclaimer'],[class*='-disclaimer'],[class*='_disclosure'],[class*='_sponsor_tag']"
  );

  inspectObject.adChoices = inspectObject.content.querySelector(
    ".tl-adchoices"
  );

  inspectObject.closeButton = inspectObject.content.querySelector(
    "[id$='_close'], [class*='_close'],[id$='-close'],[class*='-close']"
  );

  inspectObject.clickthroughs = inspectObject.content.querySelectorAll("a");

  inspectObject.styleBlock = inspectObject.content.querySelector("style");

  inspectObject.scripts = inspectObject.content.querySelectorAll("script");

  inspectObject.tlWrap = inspectObject.content.querySelector(".tl-csr");

  let workspaceElement = object;

  if (window.cohoba.workspaceElement && typeof workspaceElement !== "object") {
    if (window.cohoba.workspaceElement.constructor.name !== "cohobaWorkspace") {
      workspaceElement = window.cohoba.workspaceElement;
    }
  }

  if (
    typeof workspaceElement === "object" &&
    workspaceElement.getBoundingClientRect
  ) {
    inspectObject.workspaceWidth = workspaceElement.getBoundingClientRect().width;
    inspectObject.workspaceHeight = workspaceElement.getBoundingClientRect().height;
    inspectObject.workspaceTop = workspaceElement.getBoundingClientRect().top;
    inspectObject.workspaceLeft = workspaceElement.getBoundingClientRect().left;
  }

  /*
  Find the Stylesheet object for the workspace style block
  (We are asuming there's only one styleblock for now.)
  */
  inspectObject.styleSheet = null;

  for (let i = 0; i < window.document.styleSheets.length; i++) {
    if (window.document.styleSheets[i].ownerNode === inspectObject.styleBlock) {
      inspectObject.styleSheet = window.document.styleSheets[i];
    }
  }
  return inspectObject;
}

export default inspect;
