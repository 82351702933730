import checkWebview from "../checkWebview";
import checkViewability from '../checkViewability';
import MRAIDModule from "../../mraidModule";
import insertResponsiveTag from "../insertResponsiveTag";
import renderTag from "../../../utilities/renderTag";
import isTouchDevice from '../../analyticsModule/isTouchDevice';
import ErrorModule from "../../errorModule";
import tagParent from "../tagParent";
import SetupModule from "..";

const renderNode = document.currentScript;

const flexCss = `
.cohoba_parent > div {display: block !important; width: 100% !important; height: auto !important; min-width: unset !important; min-height: unset !important; max-width: 100% !important; max-height: 100% !important;}
.cohoba_parent > div iframe[id*='google']{display: block !important; width: 100% !important; height: auto !important; min-width: unset !important; min-height: unset !important; max-width: 100% !important; max-height: 100% !important;}
`;

export default async function setupRenderEnvironment(layout){
        const render_params = window.render_params;

        if (document.head.contains(renderNode)) {
          document.body.appendChild(renderNode);
        }

        insertResponsiveTag();
        // If we're inside a friendly iframe, make sure there's no frameBorder
        if (window.frameElement) {
          window.frameElement.setAttribute('frameborder', '0');
          document.body.style.margin = "0";
        }

        // POC: Catch all errors from window
        window.addEventListener("error", (e:ErrorEvent)=>{
          if (e.error) {
            e = {...e.error, cause: e.cause ? e.cause : e.error.cause, message: e.message ? e.message : e.error.message}; 
          }
          ErrorModule.capture({...e, stack: "Window : "+e.stack})
        })

       // MRAIDModule.insert();

        if ((render_params?.app || checkWebview()) && !MRAIDModule.mraid) {
          // Add AdMob library
          document.body.appendChild(renderTag("https://www.gstatic.com/afma/api/v1/google_mobile_app_ads.js"));
         const mraidInitialized = await MRAIDModule.initialize();
        } else if (isTouchDevice() && !MRAIDModule.mraid) {
          document.body.appendChild(renderTag("https://www.gstatic.com/afma/api/v1/google_mobile_app_ads.js"));
          const mraidInitialized = await MRAIDModule.initialize();
        }

        if (render_params) {
            checkViewability();
        }

        if (layout && layout?.tagParent) {
          console.log(SetupModule.getAdContainer());
          tagParent(window.frameElement, layout.tagParent.level, layout.tagParent.parentStyle)
        }

        if (layout?.attributes?.flex) {
          tagParent(window.frameElement, 2, flexCss)

        }

      }