import React from 'react';
import styled, {css} from 'styled-components';

// Styled-Components
const StyledActionButton = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  box-sizing: border-box;
  line-height: 30px;
  min-height: 30px;
  cursor: pointer;
  margin: 0px 4px 0 0;
  font-size: ${props => props.theme.fontSize || "12px"};
  font-weight: 500;
  background: #fff;
  color: #000;
  border-radius: 2px;
  padding: 0 10px;
  transition: all 0.15s ease-in-out;

  &:hover {
    background-color: ${props => props.theme.darkGray || "var(--dark-gray)"};
    color: #fff;
  }

  &:last-child {
    margin-right: 0;
  }

  ${props => props.primary && css`
  color: #fff;
  background: ${props => props.theme.primaryColor || '#fff'};
  font-weight: 600;
  `}

  ${props => props.transparent && css`
    background: transparent;
    color: ${props => props.theme.primary || "rgba(255,255,255,1)"};
    text-decoration: underline transparent solid;

    &:hover {
      background: transparent;
      color: ${props => props.theme.black || "rgba(255,255,255,0.75)"};
      text-decoration-color: ${props => props.theme.darkGray || "rgba(255,255,255,0.75)"};
    }
  `}

  ${props => props.besideField && css`
     border-radius: 0 4px 4px 0;
     margin-left: 6px;
   `}

  #code-module .smart-features & {
    margin-bottom: 10px;
  }

  ${props => props.inMenu && css`
    background: transparent;
    color: ${props => props.theme.darkGray || "var(--dark-gray)"};
    padding: 10px 0 5px;
    transition: all 0.25s ease-out;
    cursor: pointer;
    text-align: center;
    margin: 8px 4px;
    border-radius: 70px;
    display: block;
    position: relative;
    height: 60px;
    line-height: 1;
    align-items: unset;

    &:hover {
      background: transparent;
    }

    &.toggled:after{
      content: "▲";
      color: #ffffff;
      transform: rotate(90deg) translate(-50%, -50%);
      position: absolute;
      display: block;
      top: 50%;
      left: -18px;
      font-size: 18px;

    }


  `}
  ${props => props.knockedOut && css`
    background: none;
    line-height: 30px;
    font-size: 12px;
    padding: 4px;
    height: auto;
    min-height: auto;
    border: 1px solid #fff;
    color: #fff;
    color: #fff;
    line-height: 1;
    padding: 8px;
  `}
 
`;

const ActionButtonLabel = styled("div")`
`;

export default (props) => {

    let className = "action-button";
    if(typeof(props.name) !== "undefined"){
      className += " "+props.name
    }
    if(typeof(props.className) !== "undefined"){
      className += " "+props.className
    }

      return (
        <StyledActionButton
      //    {...props}
          id={props.id}
          className={className}
          style={props.style}
          theme={props.theme}
          onMouseEnter={function(event){event.currentTarget.classList.add("hover")}}
          onMouseLeave={function(event){event.currentTarget.classList.remove("hover")}}
          onMouseDown={function(event){event.currentTarget.classList.add("active")}}
          onMouseUp={function(event){event.currentTarget.classList.remove("active")}}
          onTouchStart={function(event){event.currentTarget.classList.add("active")}}
          onTouchEnd={function(event){event.currentTarget.classList.remove("active")}}
          onClick={props.action}
         >

        
         {props.children}

        </StyledActionButton>

      )

}