import React from "react";
import ActionButton from "./ActionButton";
import styled, { useTheme }  from 'styled-components';
import Header from './Header';
/*
=======================
This is the navigation menu that appears
on the right side of the screen (bottom on mobile)
View the array of menu items (menuItems) in App.js
=======================
*/

const MenuContainer = styled(Header)`
  background: ${props => {
    return props.theme?.menu?.background ? props.theme.menu?.background : '#fff'
  }};
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 0;
  left: 0;
  transition: all 0.5s ease-out;
  width: 36px;
  max-width: 36px;
  min-width: 36x;
  min-height: 85px;
  height: 100%;
  z-index: 9999999999999;
  box-sizing: border-box;
  list-style: none;
  text-transform: center;
  overflow-y: auto;
  overflow-x: hidden;
  border-radius: 0;
  border: 1px solid rgba(0, 0, 0, 0.025);
  max-height: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.13);
  box-shadow: ${props => props.theme.menu?.boxShadow || '0 0 12px 4px rgba(0,0,0,0.1)'};
  transition: all 0.25s ease-in-out;
  transform-origin: top center;
  transform: ${props => props.menuHidden ? "translateX(100%) !important" : "none"};
  
  & .menu-item {
    visibility: visible!important;
    cursor: pointer;
    flex-direction: column;
    margin: 0;
    padding: 8px 0;
    line-height: 1;
  }

  & .menu-item:hover {
    visibility: visible!important;
    cursor: pointer;
    flex-direction: column;
    margin: 0;
    padding: 8px 0;
    line-height: 1;
    background: transparent;
    
  }

  @media (max-height: 650px) {
  justify-content: normal;
  }

  &.moving {
    transition: none !important;
    transform: translateY(-50%) scale(1) !important;
  }

  &:hover {
    box-shadow: 0 8px 7px -6px rgba(0, 0, 0, 0.3);
  }

 &.collapsed {
   max-height: 86px;
 }

 &.hidden {
  transform: translateX(100%) !important;
}

 .interactive-mode &{
  z-index: 9;
    padding: 4px;
    width: fit-content;
    right: 0;
    left: unset;
    align-self: flex-end;
    margin-left: auto;
 }

`

export const MenuIcon = styled.div`
margin: 0 auto;
background: linear-gradient(120deg, rgba(102, 102, 102, 0), transparent);
color: #45c4d4;
font-weight: 100;
text-align: center;
cursor: pointer;
font-size: 14px;
border-radius: 50%;
transition: 0.25s all ease-in-out;
transition-delay: 0.1s;

  & svg {
   margin: 0;

  }
  .toggled &{
    color: var(--complementaryColor); 
  }

  div:hover > & {
    color: ${props => props.theme.menu.active_icon_color || 'var(--accentColor)'};
  }
`

export const MenuLabel = styled.div`
color: #fff;
font-size: 7px !important;
padding: 5px 0;
text-transform: uppercase;
letter-spacing: normal;
font-weight: 600;
opacity: 0.5;
transition: 0.25s ease-in-out all;
transition-delay: 0.4s;
margin: 0;
  color: ${props => {
    return props.theme?.menu?.label_color ? props.theme.menu?.label_color : '#000'
  }};
  .toggled &{
    color: var(--primaryColor); 
    opacity: 1;
  }
`

const MenuGroup = styled.div`
max-height: fit-content;
flex: 0 0 100%;
justify-content: center;
display: flex;
flex-direction: column;

  @media screen and (max-width:480px){
    & {flex-direction:row;}
  }
`;


export default function Menu ({menuArray, orientation, hidden}) {

  const menuNode = React.useRef();

  const theme = useTheme();
    // The array of menu items which will populate the menu.
    // Each item will appear as an ActionButton component within
    // the menu.
    const menuItemArray = menuArray.map((item) => {  

      return (
        <ActionButton
          // inMenu is a styled-component prop
          inMenu
          key={item.name}
          name={"menu-item " + item.name}
          className={"menu-item " + item.name}
          icon={item.icon}
          label={item.label}
          action={ (event) => {
            document.querySelectorAll("#menu .menu-item").forEach((b)=>{
              if (b === event.currentTarget) {
                b.classList.add("toggled");
              } else {
                b.classList.remove("toggled");
              }
            })
            //event.currentTarget.classList.toggle("toggled");
            if (item.action) {
              item.action(event.currentTarget.classList.contains('toggled'));
              return;
            }
            if (item.window && typeof item.window.toggle !== "undefined"){
              item.window.toggle();
            } else if (item.window && item.window.window && item.window.window.current.tagName === "DIV") {
              item.window.window.current.toggle();
            } else if (item.window && item.window.window && item.window.window.current){
              item.window.window.current.toggle();
            }else if (item.window && item.window.current ){
              item.window.current.toggle();
            }
            
          }}
        >
          <MenuIcon
            className="menu-icon"
          >{item.icon}</MenuIcon>

          <MenuLabel
            className="menu-label"
          >{item.label}</MenuLabel>
        </ActionButton>
      );
    });

    return (
      <MenuContainer
        id="menu"
        theme={theme?.menu}
        ref={menuNode}
        menuHidden={hidden}
        >
        <MenuGroup>{menuItemArray}</MenuGroup>
      </MenuContainer>
    );
  
}
