export default function findByFingerprint(layout, fingerprint) {
    let element;

    layout.cn.forEach(ly => {
        if (ly.fingerprint === fingerprint) {
            element = ly
        } else if (ly.cn) {
            element = findByFingerprint(ly, fingerprint)
        }
    });

    return element;
    
}