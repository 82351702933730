import styled from 'styled-components';


// How to use import for this:
// import { PropertyLabel }  from "../components/PropertyLabel.js"

const PropertyLabel = styled.span.attrs({className:"property-label"})`
  font-weight: 600;
  font-size: 12px;
  text-align: left;
  padding-bottom: 5px;
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  vertical-align: middle;
  line-height: 1;
  margin-top: 10px;
  margin-left:4px;
`
const PropertySubLabel = styled(PropertyLabel)`
  position: absolute;
  padding: 0;
  padding-bottom: 10px;
  bottom: -30px;
  right: 5px;
  opacity: .7;
`

const PropertyLabelSide = styled(PropertyLabel)`
  margin-top: unset;
  display: inline;
`


export {
	PropertyLabel,
	PropertySubLabel,
  PropertyLabelSide
}