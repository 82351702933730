import * as React from "react";
import styled from "styled-components";
import Interactive from '../interactive';
import replacePatterns from "../interactive/setupModule/replacePatterns";
import ReplaceMacrosInLayout from "../interactive/setupModule/replaceMacros";
const StyledWorkspace = styled("div")`

  position: relative;
  width: 100%;
  height: 100%;
  border: none;
  transition: opacity 1s ease-out;
  transform-origin: top left;
  opacity: 1;
  visibility: visible;
  position: relative;
  overflow: visible;
  background: #fff;
  box-shadow: 0px 36px 16px -16px rgb(0 0 0 / 30%);
  transition: all .3s ease-in;

  &:after{
    content:'';
    display: block;
    position: absolute;
    top: 0;
    left:0;
    width: 100%;
    height: 100%;
    outline: 1px solid #999;
    z-index: var(--topIndex);
    pointer-events: none;
  }

&[data-placed='true'] {
  box-shadow: none !important;
  outline: none !important;
}

#workspace-container & {
  // position: absolute;
  width: 100%;
  height: 100%;
  max-width: 100%;
  top: 0;
  left: 0;
  max-height: 100%;
}

.interactive-mode & {
  outline: 0px solid #999;
  box-shadow: 0 4px 4px 0px rgb(0 0 0 / 30%);
}

${props => props.mode === 'stack' ? `* {position:absolute;}` : ''}
${props => props.mode === 'flow' ? `* {box-sizing: border-box;}` : ''}
`;

const  Workspace = React.memo(({workspace, updateWorkspace, frame}) => {

  const workspaceRef = React.useRef();
  const workspaceScrollerRef = React.useRef();

React.useEffect(() => {
  workspaceScrollerRef.current = document.querySelector(".workspace-scroller");
}, []);

// Then use workspaceScrollerRef.current instead of querying again
  
  React.useEffect(() => {
    window.cohoba.workspaceElement = workspaceRef.current;
    if (workspace.type === "flow") {
      cohoba.workspace.render = workspaceRef.current.innerHTML;
    } else {
      // Hack to get macros working with HTML for now
      workspace.render = ReplaceMacrosInLayout(workspace.layouts[frame],workspace.assets);
      workspaceRef.current.innerHTML = workspace.render;
      if (cohoba.workspace){
        cohoba.workspace.render = workspace.render;
      }
    }
  }, [workspace]);

  const positionMapper = {
    bottom: () => ({ x: workspace.x, y: cohoba.workspaceContainer.clientHeight - workspace.size[1] }),
    top: () => ({ x: workspace.x, y: 0 }),
    left: () => ({ x: 0, y: workspace.y }),
    right: () => ({ x: cohoba.workspaceContainer.clientWidth - workspace.size[0], y: workspace.y }),
    center: () => {
      const x = (cohoba.workspaceContainer.clientWidth - workspace.size[0]) / 2;
      const y = (cohoba.workspaceContainer.clientHeight - workspace.size[1]) / 2;
      return { x, y };
    }
  };
  
  const positionWorkspace = (position) => {
    const { x, y } = positionMapper[position]?.() || { x: workspace.x, y: workspace.y };
    return {x,y};
    updateWorkspace({ x, y });
  }


  let styles = {
    width: workspace.width === "auto" ? "100%" : `${workspace.size[0]}px`,
    height: workspace.height === "auto" ? "100%" : `${workspace.size[1]}px`,
    top: `${workspace.y}px`,
    left: `${workspace.x}px`
  };
  
  // If autoCenter is true and we have workspaceScrollerRef
  if (workspace.autoCenter && workspaceScrollerRef.current) {
    const { width: containerWidth, height: containerHeight } = workspaceScrollerRef.current.getBoundingClientRect();
    styles = {
      ...styles,
      top: `${(containerHeight - workspace.size[1]) / 2}px`,
      left: `${(containerWidth - workspace.size[0]) / 2}px`
    };
  }

   // replacePatterns(workspace.layouts[cohoba.frame], workspace.layouts)
      return  <>
        {workspace ? <StyledWorkspace
      ref={workspaceRef}
      style={{...styles}}
      key="workspace"
      data-type={workspace.type}
      id="workspace"
      mode={workspace.mode}
      className={`workspace`}>
          <Interactive 
          key={'interactive'}
          layout={workspace.layouts[frame]} 
          components={workspace.components}
          stylesheet={workspace.stylesheet} 
          assets={workspace.assets}/>
      </StyledWorkspace> : null}
      </>
      
});

export default Workspace;
