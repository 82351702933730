export default function createBlobFromXML(xmlString) {
  let blob;

  try {
    // Modern browsers with Blob constructor
    blob = new Blob([xmlString], { type: 'application/xml' });
  } catch (e) {
    // Fallback for older browsers using BlobBuilder
    if (window.BlobBuilder) {
      const blobBuilder = new BlobBuilder();
      blobBuilder.append(xmlString);
      blob = blobBuilder.getBlob('application/xml');
    } else {
      // Blob constructor and BlobBuilder not supported
      console.error('Blob creation not supported in this browser.');
      return null;
    }
  }

  return blob;
}