  const getRenderTime = () => {
    try{
      const startTime = window.render_params.startTime;
      const renderTime = window.render_params.renderTime;
      const ellapsedTime = renderTime - startTime;
      return ellapsedTime/1000;
    }catch(e){
      return -1
    }
  }

  export default getRenderTime;