import * as React from 'react';
import {createRoot} from 'react-dom/client';
import Platform from "./views/Platform";
import { BrowserRouter } from 'react-router-dom';

window.cohoba = {}
window.cohoba.container = document.createElement("div");
window.cohoba.container.id = "cohoba-designer";
document.body.appendChild(window.cohoba.container);

let root = createRoot(window.cohoba.container);

root.render(<BrowserRouter><Platform /></BrowserRouter>);
