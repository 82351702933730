import React from "react";
import styled from 'styled-components';
import {Box, Flex} from 'rebass';
import WindowNavButton from "../components/WindowNavButton";
import Window from "../components/Window";
import WindowHeader from "../components/WindowHeader";
import WindowBody from "../components/WindowBody";
import WindowModule from "../components/WindowModule";
import InfoModule from "./InfoModule";
import WorkspaceModule from "./WorkspaceModule";
import IssuesModule from './IssuesModule';
import "./project.css";

import WindowFooter from '../components/WindowFooter';
import ActionButton from '../components/ActionButton';
import Accordion from "../components/Accordion";

const StyledWindow = styled(Window)`

`;

export default function ProjectWindow ({windowRef, workspace}) {

return <StyledWindow
        ref={windowRef}
        name="file"
        type="panel"
      >
       


<WindowBody>
  

            {/* <WindowModule
            name="issues"
            className="issues-module"
            style={{display:"none", padding:"16px", boxSizing:"border-box",position:"absolute", top: 0, left: 0, width:"100%", height:"100%", backgroundImage:"linear-gradient(to top, rgb(41, 12, 50), rgb(71, 20, 86))"}}
            >  <ActionButton style={{position: "relative", top: 0, left: 0}} action={() => {
              document.querySelector(".issues-module").style.display = "none";
              setPublishers(publishers);
             }}>BACK</ActionButton>
              <h3>Issues</h3>
          <IssuesModule
              publishers={this.props.publishers}
              setPublishers={this.props.setPublishers}
              workspace={this.props.workspace}
              currentPublisher={this.state.currentPublisher}
              stylesheets={this.props.stylesheets}
              setStylesheets={this.props.setStylesheets}
              />
            </WindowModule> */}


<WindowModule  name="workspace"
            className="workspace-module" >
<WorkspaceModule workspace={workspace}/>
</WindowModule>

        
        </WindowBody>
        
      </StyledWindow>
}
