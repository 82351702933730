export function flexParents(currentNode, parentLevel){
    let parent = currentNode;
    for(let i=0; i<parentLevel; i++) {
        parent = parent.parentNode;
        parent.style.width = "100%";
        parent.style.height = "auto";
    }
}

export function tagParent(currentNode, parentLevel, parentStyle){
    let parent = currentNode;

    if (parent === null) {
        return false;
    }

    if (parent?.ownerDocument?.defaultView?.frameElement) {
        parent = parent.ownerDocument.defaultView.frameElement;
    }

    for(let i=0; i<parentLevel; i++) {
        parent = parent.parentNode;
    }

    parent.classList.add("cohoba_parent");

    let styleNode = document.createElement("style");
    styleNode.innerHTML = `${parentStyle}`;
    currentNode.parentNode.appendChild(styleNode);
}

export default tagParent;