import convertHex from '../utilities/convertHex';

function selector(selectorElement) {
    if(selectorElement === null){
      return false;
    }

        var selectorStyle = window.getComputedStyle(selectorElement);
        var boundingBox = selectorElement.getBoundingClientRect();

        let selection = {
          /*OBJECT PROPERTIES */
          fingerprint: selectorElement.getAttribute('data-fp') || Math.random(),
          object: selectorElement,
          component : selectorElement.dataset["component"],
          id : selectorElement.getAttribute("id"),
          class : selectorElement.getAttribute("class"),
          box : boundingBox,

          


            /*POSITION AND SIZE*/
            display: selectorStyle['display'],
            visibility: selectorStyle['visibility'],
            flex: selectorStyle['flex'],
            flexDirection: selectorStyle['flex-direction'],
            flexFlow: selectorStyle['flex-flow'],
            flexGrow: selectorStyle['flex-grow'],
            flexWrap: selectorStyle['flex-wrap'],
            order: selectorStyle['order'],
            alignItems: selectorStyle['align-items'],
            alignContent: selectorStyle['align-content'],
            justifyContent: selectorStyle['justify-content'],

            position: selectorStyle.getPropertyValue('position'),
            left: selectorStyle.getPropertyValue('left'),
            top: selectorStyle.getPropertyValue('top'),
            right: selectorStyle.getPropertyValue('right'),
            bottom: selectorStyle.getPropertyValue('bottom'),
            width: selectorStyle.getPropertyValue('width'),
            height: selectorStyle.getPropertyValue('height'),
            maxWidth: selectorStyle.getPropertyValue('max-width'),
            maxHeight: selectorStyle.getPropertyValue('max-height'),
            minWidth: selectorStyle.getPropertyValue('min-width'),
            minHeight: selectorStyle.getPropertyValue('min-height'),
            float: selectorStyle.getPropertyValue('float'),

            /*OBJECT STYLING*/
            boxSizing: selectorStyle.getPropertyValue('box-sizing'),
            
            margin: selectorStyle.getPropertyValue('margin'),
            marginTop: selectorStyle.getPropertyValue('margin-top'),
            marginRight: selectorStyle.getPropertyValue('margin-right'),
            marginBottom: selectorStyle.getPropertyValue('margin-bottom'),
            marginLeft: selectorStyle.getPropertyValue('margin-left'),           
            
            padding: selectorStyle.getPropertyValue('padding'),
            paddingTop: selectorStyle.getPropertyValue('padding-top'),
            paddingRight: selectorStyle.getPropertyValue('padding-right'),
            paddingLeft: selectorStyle.getPropertyValue('padding-left'),
            paddingBottom: selectorStyle.getPropertyValue('padding-bottom'),

            overflow: selectorStyle.getPropertyValue('overflow'),

            backgroundImage: selectorStyle.getPropertyValue('background-image'),
            backgroundColor: selectorStyle.getPropertyValue('background-color'),
            backgroundSize: selectorStyle.getPropertyValue('background-size'),
            backgroundRepeat: selectorStyle.getPropertyValue('background-repeat'),
            backgroundUrl: selectorStyle.getPropertyValue('background-url'),
            backgroundPosition: selectorStyle.getPropertyValue('background-position'),
            
            //BORDER
            border: selectorStyle.getPropertyValue('border'),
            borderColor: selectorStyle.getPropertyValue('border-color'),
            borderWidth: selectorStyle.getPropertyValue('border-width'),
            borderStyle: selectorStyle.getPropertyValue('border-style'),
            borderRadius: selectorStyle.getPropertyValue('border-radius'),
            
            borderTop: selectorStyle.getPropertyValue('border-top'),
            borderTopColor: selectorStyle.getPropertyValue('border-top-color'),
            borderTopWidth: selectorStyle.getPropertyValue('border-top-width'),
            borderTopStyle: selectorStyle.getPropertyValue('border-top-style'),
            
            borderRight: selectorStyle.getPropertyValue('border-right'),
            borderRightColor: selectorStyle.getPropertyValue('border-right-color'),
            borderRightWidth: selectorStyle.getPropertyValue('border-right-width'),
            borderRightStyle: selectorStyle.getPropertyValue('border-right-style'),
            
            borderBottom: selectorStyle.getPropertyValue('border-bottom'),
            borderBottomColor: selectorStyle.getPropertyValue('border-bottom-color'),
            borderBottomWidth: selectorStyle.getPropertyValue('border-bottom-width'),
            borderBottomStyle: selectorStyle.getPropertyValue('border-bottom-style'),
            
            borderLeft: selectorStyle.getPropertyValue('border-left'),
            borderLeftColor: selectorStyle.getPropertyValue('border-left-color'),
            borderLeftWidth: selectorStyle.getPropertyValue('border-left-width'),
            borderLeftStyle: selectorStyle.getPropertyValue('border-left-style'),
                     
            boxShadow: selectorStyle.getPropertyValue('box-shadow'),
            
            opacity: selectorStyle.getPropertyValue('opacity'),
            
            mixBlendMode: selectorStyle.getPropertyValue('mix-blend-mode'),
            blending: selectorStyle.getPropertyValue('mix-blend-mode'),

            /*TYPE SETTING*/
            fontFamily: selectorStyle.getPropertyValue('font-family'),
            fontSize: selectorStyle.getPropertyValue('font-size'),
            fontWeight: selectorStyle.getPropertyValue('font-weight'),
            fontStyle: selectorStyle.getPropertyValue('font-style'),
            color: convertHex(selectorStyle.getPropertyValue('color')),
            fontStretch: selectorStyle.getPropertyValue('font-stretch'),

            textAlign: selectorStyle.getPropertyValue('text-align'),
            wordSpacing: selectorStyle.getPropertyValue('word-spacing'),
            letterSpacing: selectorStyle.getPropertyValue('letter-spacing'),
            lineHeight: selectorStyle.getPropertyValue('line-height'),
            verticalAlign: selectorStyle.getPropertyValue('vertical-align'),

            textDecoration: selectorStyle.getPropertyValue('text-decoration'),
            textDecorationLine: selectorStyle.getPropertyValue('text-decoration-line'),
            textDecorationStyle: selectorStyle.getPropertyValue('text-decoration-style'),
            textDecorationColor: selectorStyle.getPropertyValue('text-decoration-color'),

            textTransform: selectorStyle.getPropertyValue('text-transform'),
            textShadow: selectorStyle.getPropertyValue('text-shadow'),
            textIndent: selectorStyle.getPropertyValue('text-indent'),

            //WEBKIT
            webkitBoxOrient : selectorStyle['-webkit-box-orient'],
            webkitLineClamp : selectorStyle['-webkit-line-clamp'],
            webkitFontSmoothing : selectorStyle['-webkit-font-smoothing'],

            /*TRANSFORMATIONS*/
            transform: selectorStyle.getPropertyValue('transform'),
            
            /*ANIMATIONS*/
            animation: selectorStyle.getPropertyValue("animation"),

            /*FILTERS*/

            /*CONTENT*/
            textContent: selectorElement.textContent.trim(),

            innerHTML : selectorElement.innerHTML,
            
            /*HTML*/
            
            /*ANCHOR*/
            href : selectorElement.getAttribute("href"),

            target : selectorElement.getAttribute("target")

        }

        if(selection.textContent === ""){
          selection.textContent = "Sample Text";
        }

        if (typeof(selection.component) === "undefined" || selection.component == null) {
            selection.component = selection.object.tagName;
         }

        if(typeof(window.cohoba.workspace) === "undefined"){
            return selection;
        }

         
        return selection;
      
    }
export default selector;