import { openDB } from 'idb';

export async function createWorkspacePost(workspace) {
  const data = {
    title: 'test',
    status: 'publish', // Set to 'draft' for a draft post
    meta: {
      stylesheet: {
        cta: {
          css: '', // Update with your actual CSS code
        },
      },
      layouts: [], // Update with layout data if needed
      assets: [], // Update with asset data if needed
    },
  };

  try {
    const response = await fetch(`${window.location.origin}/wp-json/wp/v2/workspace`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      //  Authorization: `Bearer ${YOUR_API_KEY}`, // Replace with your actual API key
      },
      body: JSON.stringify(data),
    });

    if (response.ok) {
      const newPost = await response.json();
      console.log('New workspace post created:', newPost);
    } else {
      console.error('Error creating post:', await response.text());
    }
  } catch (error) {
    console.error('Error:', error);
  }
}



// Function to initialize the database
export async function initDB() {
  const db = await openDB('workspaces-db', 1, {
    upgrade(db) {
      // Create a store named 'workspaces' with an 'id' keypath
      if (!db.objectStoreNames.contains('workspaces')) {
        db.createObjectStore('workspaces', { keyPath: 'title' });
      }
    },
  });
  return db;
}

// Function to add a workspace to the database
export async function addWorkspace(workspace) {
  const db = await initDB();
  const tx = db.transaction('workspaces', 'readwrite');
  const store = tx.objectStore('workspaces');
  await store.add(workspace);
  await tx.done;
}

// Function to update an existing workspace in the database
export async function refreshWorkspace(updatedWorkspace, callback) {
  if (!updatedWorkspace) { return}
  const db = await initDB();
  const tx = db.transaction('workspaces', 'readwrite');
  const store = tx.objectStore('workspaces');
  const existingWorkspace = await store.get(updatedWorkspace.title);

  if (existingWorkspace) {
    await store.put(updatedWorkspace);
    console.log(`Workspace with title: ${updatedWorkspace.title} updated successfully!`);
    if (callback){

      callback(true);
    }
  } else {
    await addWorkspace(updatedWorkspace);
    console.error(`Workspace with title: ${updatedWorkspace.title} not found!`);
    if (callback) {
      callback(false)
    }
  }

  await tx.done;
}


// Function to get all workspaces from the database
export async function getWorkspaces() {
  const db = await initDB();
  const tx = db.transaction('workspaces', 'readonly');
  const store = tx.objectStore('workspaces');
  const allWorkspaces = await store.getAll();
  await tx.done;
  return allWorkspaces;
}

// // Example usage to retrieve all workspaces
// getWorkspaces()
//   .then((workspaces) => {
//     console.log('All workspaces:', workspaces);
//   })
//   .catch((error) => {
//     console.error('Error retrieving workspaces:', error);
//   });
