const getLoadTime = () => {
  try{
    const serverTime = window.render_params.timestamp*1000; // Convert to milliseconds
    const startTime = window.render_params.startTime;
    const ellapsedTime = startTime - serverTime;
    return ellapsedTime/1000;
  }catch(e){
    return -1
  }
}

export default getLoadTime;