import selector from "../../../../template/selector";
import findByFingerprint from "../../../../template/findElementByFingerprint";
import insertTextNode from "./insertTextNode";

const handleMouseDown = (e) => {
    if (e.target?.classList?.contains('knob') || document.querySelector(".selector-box")?.contains(e.target)) {
        // Prevent default actions if clicking on knobs or selector box
        return;
    }

    if (cursor === 'select') {
        cohoba.areaSelection = document.querySelector(".area-selection");
        cohoba.areaSelection.style.visibility = 'visible';
        cohoba.areaSelection.style.left = `${e.clientX}px`;
        cohoba.areaSelection.style.top = `${e.clientY}px`;
        cohoba.areaSelection.style.width = '1px';
        cohoba.areaSelection.style.height = '1px';
        cohoba.areaSelection.style.opacity = 1;
        cohoba.areaSelection.dataset.startPosition = JSON.stringify({x: e.clientX, y: e.clientY});
    }

    // Add mousemove listener for area selection
    e.currentTarget.addEventListener('mousemove', areaSelectionMoveHandler, { capture: true });
};

const handleMouseUp = (e) => {
    e.currentTarget.removeEventListener('mousemove', areaSelectionMoveHandler, true);

    if (e.target.classList.contains('knob')) {
        e.stopPropagation();
        return false;
    }

    const areaSelection = document.querySelector(".area-selection");
    if (areaSelection) {
        areaSelection.style.opacity = 0;
        cohoba.areaSelection = null;
    }
};

const handleMouseOut = (e) => {
    e.currentTarget.removeEventListener('mousemove', areaSelectionMoveHandler, true);
    // Note: You already have removed the mousemove event listener above, so no need to remove it again here.
};

const handleMouseMove = (e) => {

        window.cohoba.areaSelection = document.querySelector(".area-selection");
       // console.log(cohoba.areaSelection);
       if (cursor === 'select' && document.querySelector(".area-selection")) {
         let startPosition = JSON.parse(cohoba.areaSelection.dataset['startPosition']);
         let offsetX = e.clientX - startPosition.x;
         let offsetY = e.clientY - startPosition.y;
         if (offsetX < 0) {
           cohoba.areaSelection.style.left = e.clientX +'px';
           cohoba.areaSelection.style.width = offsetX*-1 +'px';
         } else {
           cohoba.areaSelection.style.width = offsetX +'px';
       //    cohoba.areaSelection.style.height = offsetY +'px';
         }
         if (offsetY < 0) {
           cohoba.areaSelection.style.top = e.clientY +'px';
           cohoba.areaSelection.style.height = offsetY*-1 +'px';
         } else {
          // cohoba.areaSelection.style.width = offsetX +'px';
           cohoba.areaSelection.style.height = offsetY +'px';
         }
         
         cohoba.areaSelection.dataset['endPosition'] = JSON.stringify({x: e.clientX, y: e.clientY});
       }
      
};

export const handleClick = (e) => {
    console.log(e.target);
    if (document.querySelector("#side-panel")?.contains(e.target) || e.target.classList.contains('knob')) {
        return;
    }

    if (cohoba.cursor === 'type') {
        if (cohoba.workspaceElement.contains(e.target)) {
            if (cohoba.selection?.object !== e.target) {
                cohoba.setSelection(selector(e.target));
            }
            e.target.focus(0);
        }
        if (e.target === cohoba.workspaceElement || e.target.id === 'preview-panel'){
            // Focus on object
       //     e.target.focus();
            insertTextNode(e);
        } 
        return;
        if (!cohoba.selection && !cohoba.workspaceElement.contains(e.target)) {
          // Simply insert
        } else if (!cohoba.selection && cohoba.workspaceElement.contains(e.target)) {

        } else if (cohoba.selection.object === e.target){
            // Focus on object
        } else {
            // Deselect element and insert
        }
    }
    

    if (document.querySelector("#workspace")?.contains(e.target)) {
       
     //   e.preventDefault();
        handleWorkspaceClick(e);
    } else if (e.target === cohoba.selectorFrame || cohoba.selectorFrame.contains(e.target)) {
        console.log("selectorFrame")
    }else if (e.target === cohoba.workspaceElement || e.target.id === 'preview-panel' || e.target.id === 'workspace-container') {
        console.log('background');
        cohoba.setSelection(null);
    }
};


function handleWorkspaceClick(e) {


    if (e.target.classList.contains('knob') || e.target.classList.contains('selector-box')) {
        return false;
    }

    let element = document.elementFromPoint(e.clientX, e.clientY);

    if (cohoba.selectorFrame) {
        cohoba.selectorFrame.style.pointerEvents = 'none';
         element = document.elementFromPoint(e.clientX, e.clientY);
        cohoba.selectorFrame.style.pointerEvents = 'all';
    } else {

    }
   
   
        if (cohoba.selection?.object === element) {
           
        } else if (!element?.classList.contains('select-item') && element !== cohoba.workspaceElement){
            cohoba.updateSelector(element);
            cohoba.setSelection(selector(element));

            // Skip finding layout elements for now: Using HTML
           // return;
        }

        // If it's a dynamic workspace
        if (element.hasAttribute('data-fp')) {
            const fingerprint = element.getAttribute('data-fp');
            cohoba.updateSelector(element);
            let navigatorItem = document.querySelector(`#layout-navigator [data-fingerprint='${fingerprint}']`);
            if (navigatorItem) {
                navigatorItem.querySelector(".navigator-item-tag-name").click();
                return;
            } else {
                const layoutElement = findByFingerprint(window.cohoba.workspace.layouts[cohoba.frame], parseInt(fingerprint));
                if (layoutElement) {
                    cohoba.setSelection(layoutElement);
                }
            }
        } else if(element !== cohoba.workspaceElement){
            cohoba.setSelection(selector(element));
        } else {
            cohoba.setSelection(null);
        }
    
}