import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const RulerContainer = styled.div`
  position: relative;
  width: 100%;
  height: 15px;
  overflow: hidden;
  background: rgba(255,255,255,0.8);
  user-select: none;
`;

const RulerMark = styled.div`
  position: absolute;
  top: 0;
  height: 6px;
  border-left: 1px solid #000;
  width: 1px;
  font-size: 6px;
  text-align: center;
  color: #000;
  border-color: rgba(0,0,0,0.1);

  &.major {
    height: 50%;
    font-size: 0px;
    padding-top: 6px;
    border-color: rgba(0,0,0,0.2);
  }
  
  .canvas-indicator &{
    border-color: rgba(0,0,0,0.4);
  }

  .canvas-indicator &.major {
    font-size: 8px;
    height: 100%;
    border-color: rgba(0,0,0,0.5);
  }

  span {
    position: relative;
    top: 0;
    left: 2px;
    width: 20px;
    text-align: center;
  }
`;

const CanvasIndicator = styled.div`
  position: absolute;
  height: 16px;
  background-color: var(--primaryColor);
  pointer-events: none;
`;

const MouseIndicator = styled.div`
  position: absolute;
  top: 0;
  height: 16px;
  width: 1px;
  background-color: var(--accentColor);
  pointer-events: none;
`;

const MarksContainer = styled.div``;

const HorizontalRuler = ({style, width, scale = 1, canvasWidth = 550 , indicator = false}) => {
  const [mouseX, setMouseX] = useState(0);

  useEffect(() => {
    if (!indicator){

      const handleMouseMove = (e) => {
        const rect = e.currentTarget.getBoundingClientRect();
        setMouseX((e.clientX - rect.left) * scale);
      };
      
      const rulerElement = document.querySelector('#frame-content');
      if (rulerElement) {
        rulerElement.addEventListener('mousemove', handleMouseMove);
        return () => rulerElement.removeEventListener('mousemove', handleMouseMove);
      }
    }
  }, [scale]);

  const marks = [];

  for (let i = 0; i < width * scale; i += 5) {
    let isMajor = i % 100 === 0;
    marks.push(
      <RulerMark 
        key={i} 
        style={{ left: `${i}px` }} 
        className={isMajor ? 'major' : ''}
      >
        {isMajor && <span>{i}</span>}
      </RulerMark>
    );
  }

  const canvasStart = (width * scale - canvasWidth * scale) / 2;
  const canvasEnd = canvasStart + canvasWidth * scale;

  return (
    <RulerContainer className="frame-content-ruler" style={{ transform: `scaleX(${1/scale})` }} style={style}>
      <MarksContainer className="marks-container">
        {marks}
        {canvasWidth !== 0 && <CanvasIndicator className='canvas-indicator' style={{ left: `${canvasStart-1}px`, width: `${canvasWidth * scale}px` }}>
          <HorizontalRuler  indicator={true} width={canvasWidth} canvasWidth={0} />
          </CanvasIndicator>}
       {!indicator && <MouseIndicator style={{ left: `${mouseX}px` }} />}
      </MarksContainer>
      
      {!indicator && <div style={{ position: 'absolute', top: '0', left: `${canvasStart-1}px` , fontSize:'8px', borderLeft:"1px solid var(--primaryColor)", height:"100%", lineHeight:'22px', verticalAlign:"bottom"}}></div>}
     {!indicator && <div style={{ position: 'absolute', top: '0', left: `${canvasEnd-1}px` , fontSize:'8px', borderLeft:"1px solid var(--primaryColor)", height:"100%", lineHeight:'22px', verticalAlign:"bottom"}}>{canvasWidth * scale}</div>}
    </RulerContainer>
  );
};

export default HorizontalRuler;