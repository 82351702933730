import * as React from 'react';
import {styled, css, keyframes} from 'goober';
import Box from '../../Box';
import Flex from '../../Flex';
import Scrubber from '../Scrubber';
import MuteButton from '../MuteButton';
//import {runWaterfall} from '../../../../mol-video-ad-sdk/src';
import {runWaterfall} from '@mailonline/video-ad-sdk';
import renderPixel from '../../../../utilities/renderPixel';
import SetupModule from '../../../../interactive/setupModule';
import LayoutModule from '../../../../interactive/layoutModule';
import VideoModule from '../../../../interactive/videoModule';
import ErrorModule from '../../../../interactive/errorModule';
import AnalyticsModule from '../../../../interactive/analyticsModule';

import loader_icon from '../../../../images/icons/loader.png';
import link_icon from '../../../../images/icons/link.png';
import replay_icon from '../../../../images/icons/replay.png';
import play_icon from '../../../../images/icons/play.png';


const VideoPlayerContainer = styled('div')`
position: relative;
height:100%;
width: 100%;

&.vertical {
  border-right: 1px solid #222;
}

}
`;



const Video = styled('video', React.forwardRef)`
    width: 100%;
    height: 100%;
    min-height: 100%;
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    position: relative;
    z-index: 2;
    opacity: 1;
    transition: all 0.25s ease-in-out;
    &.hidden {
      opacity: 0;
    }
`;

const VideoBackground = styled('video', React.forwardRef)`
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    object-fit: cover;
    object-position: center;
    filter: blur(5px);
    top: 0;
    left: 0;
    transform: scale(1.1);
    background-color: #000;
    opacity: 1;
    transition: all 0.25s ease-in-out;
    &.hidden {
      opacity: 0;
    }
`;
const AdContainer = styled('div', React.forwardRef)`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    pointer-events: none;
`;

//const ForwardedAdContainer = React.forwardRef(AdContainer);

const LoadScreen = styled('div')`
display: flex;
position: absolute;
top: 0;
left: 0;
z-index: 3;
opacity: 1;
width: 100%;
height: 100%;
transition: opacity .3s ease-in-out;
background: rgba(0,0,0,0.7);
flex-direction: column;
justify-content: center;
align-items: center;
color: #fff;
&.hidden {
  opacity: 0;
  pointer-events: none;
}

${props => props.layoutWidth <= 320 && props.layoutHeight <= 100 && css`
  font-size: 12px;
`}
`;

const spinningAnimation = keyframes`
  from{transform: rotate(0deg);}
  to{transform: rotate(360deg);}
`;

const Icon = styled('div')`
width: 1em;
height: 1em;
filter: invert(1);
background-repeat: no-repeat;
background-size: 0.7em;
background-position: center;
background-image: url('${props => props.src}');
pointer-events: none;

`;
const LoaderIcon = styled(Icon)`
  width: 45px;
  height: 45px;
  filter: invert(1);
  transform: rotate(0deg);
  animation: ${spinningAnimation} 1s infinite;
`;
const LoadingMessage = styled('div')`

`;

const PlaceholderImage = styled('div')`
  display: flex;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: url('${props => props.src}') no-repeat center / cover;
  opacity: 0.5;
`;
const EndScreen = styled('div')`
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 4;
  opacity: 1;
  transition: opacity .3s ease-in-out;
  background: rgba(0,0,0,1);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
  &.hidden {
    opacity: 0;
    pointer-events: none;
  }

  ${props => props.layoutWidth <= 320 && props.layoutHeight <= 100 && css`
  font-size: 12px;
`}
`;
const PlayButton = styled('div')`display: flex; position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); z-index: 9; padding:  8px; align-items: center;`;
const ReplayButton = styled('div')`display: flex; position: relative; padding:  8px; align-items: center; margin-bottom: 1em !important;`;
const ClickthroughButton = styled('div')`display: flex; position: relative; align-items: center;`;
const VideoControls = styled('div')`display: flex; `;

const MediaSpacer = styled('div')`
display: flex; 
  width: 100%;
  height: auto;
  padding-top: 100%;
`;

let adUnit;

const onPlay = (event, videoElement, videoBackground) => {
  window.render_params.videoStartTime = Date.now();
  videoBackground.src = videoElement.src;
  videoBackground.currentTime = videoElement.currentTime;

  // Hack to keep both synchronized;
  setTimeout(()=>{
    videoBackground.currentTime = videoElement.currentTime;
  },200)
  setTimeout(()=>{
    videoBackground.currentTime = videoElement.currentTime;
  },500)
  setTimeout(()=>{
    videoBackground.currentTime = videoElement.currentTime;
  },1000)
  setTimeout(()=>{
    videoBackground.currentTime = videoElement.currentTime;
  },2000);
  setTimeout(()=>{
    videoBackground.currentTime = videoElement.currentTime;
  },3000);
  document.querySelector('.end-screen')?.classList.add("hidden");
  document.querySelector('.load-screen')?.classList.add("hidden");
  videoElement.classList.remove('hidden');
  videoBackground.classList.remove('hidden');
}

const onEnd = (event) => {
  
}

const onTimeUpdate = (event, setProgress) => {
  const videoElement: HTMLVideoElement = event.currentTarget;
  const videoDuration = videoElement.duration;
  const { currentTime } = videoElement;
  const videoProgress = (currentTime / videoDuration) * 100;
  SetupModule.getAdContainer()?.querySelector('.load-screen')?.classList.add('hidden');
  setProgress(videoProgress);
  
  //const videoCountdown = parseInt(videoDuration - currentTime);
  //setVideoTimer(videoCountdown);
}

// Align with onCanPlay
const onLoadedMetadata = (event) => {
  try{
  const videoElement = event.currentTarget;
 // videoElement.pause();
  
  const videoRatio = VideoModule.getVideoRatio();
  const layoutOrientation = LayoutModule.getLayoutOrientation();
  const formatContainer = LayoutModule.getFormatContainer();
  const mediaContainer = LayoutModule.getMediaContainer();
  const loadScreen = LayoutModule.getLoadScreen();
  const videoContainer = LayoutModule.getVideoContainer();
  const adRatio = LayoutModule.getAdRatio();

  if (layoutOrientation === 'landscape') {
    // Fit format container to video width
    const formatWidth = (formatContainer?.getBoundingClientRect().height * videoRatio);
    formatContainer.style.width = formatWidth + 'px';
  } else if (layoutOrientation === 'portrait'){
    if (adRatio > 1) {
      // Ad is wider than taller
      if (videoRatio < 1) {
        // Video is vertical
        LayoutModule.transformLayout('landscape');
        const formatWidth = (formatContainer?.getBoundingClientRect().height * videoRatio);
        formatContainer.style.width = formatWidth + 'px';
        mediaContainer.style.minWidth = 'fit-content';

        LayoutModule.transformAdContent('portrait');
        videoContainer?.classList.add('vertical');
      } else {
        const formatHeight = (formatContainer?.getBoundingClientRect().width / videoRatio);
        // If video is vertical fit to size
        if (LayoutModule.calculateMediaAvailableSpace() >= formatHeight) {
          mediaContainer.style.height = formatHeight+'px';
          mediaContainer.style.maxHeight = formatHeight+'px';
        } else {
          mediaContainer.style.height = LayoutModule.calculateMediaAvailableSpace()+'px';
          mediaContainer.style.maxHeight = LayoutModule.calculateMediaAvailableSpace()+'px';
        }
      }
    } else {
      // Ad is taller than it's wide
      if (videoRatio < 1) {
        const formatHeight = (formatContainer?.getBoundingClientRect().width / videoRatio);
        // If video is vertical fit to size
        if (LayoutModule.calculateMediaAvailableSpace() >= formatHeight) {
          mediaContainer.style.height = formatHeight+'px';
          mediaContainer.style.maxHeight = formatHeight+'px';
        } else {
          mediaContainer.style.height = LayoutModule.calculateMediaAvailableSpace()+'px';
          mediaContainer.style.maxHeight = LayoutModule.calculateMediaAvailableSpace()+'px';
        }
      }
    }
  }

  // if (adBox?.height > 300 && adRatio < 0) {
  //   //   if (adRatio > 0) {
  //   // videoRef.current.style.width = (adBox.height/videoRatio) + "px";
  //   // videoRef.current.style.height = adBox.height + "px";
  //   // } else {
  //   // videoRef.current.style.width = "100%";
  //   // videoRef.current.style.height = "auto";
  //   // eslint-disable-next-line no-unsafe-optional-chaining
  //   if (adBox.width / videoRatio < adBox?.height * 0.6) {
  //     mediaContainer.style.maxHeight = `${adBox.width / videoRatio}px`;
  //     mediaContainer.style.height = `${adBox.width / videoRatio}px`;
  //     // eslint-disable-next-line no-unsafe-optional-chaining
  //   } else if (adBox.width / videoRatio > adBox?.height * 0.6) {
  //     mediaContainer.style.maxHeight = `${adBox.height * 0.5}px`;
  //     mediaContainer.style.height = `${adBox.height * 0.5}px`;
  //   }
  //   //   }
  // }
} catch(e){
  ErrorModule.capture({...e, stack:"Player.onLoadedMetada"+e.stack})
}
}

const onCanPlay = async (event, setClickToPlay) => {
  try {

  // POC: Video tracking signals
  if (window.render_params) {
    window.render_params.videoLoadTime = Date.now();
  }
  const videoElement = event.currentTarget;
  //videoElement.pause();
  
  const videoRatio = VideoModule.getVideoRatio();
  const layoutOrientation = LayoutModule.getLayoutOrientation();
  const formatContainer = LayoutModule.getFormatContainer();
  const mediaContainer = LayoutModule.getMediaContainer();
  const loadScreen = LayoutModule.getLoadScreen();
  const videoContainer = LayoutModule.getVideoContainer();
  const videoBackground = LayoutModule.getVideoBackground();
  const adRatio = LayoutModule.getAdRatio();

  if (layoutOrientation === 'landscape') {
    // Fit format container to video width
    const formatWidth = (formatContainer?.getBoundingClientRect().height * videoRatio);
    formatContainer.style.width = formatWidth + 'px';
  } else if (layoutOrientation === 'portrait'){
    if (adRatio > 1) {
      // Ad is wider than taller
      if (videoRatio < 1) {
        // Video is vertical
        LayoutModule.transformLayout('landscape');
        const formatWidth = (formatContainer?.getBoundingClientRect().height * videoRatio);
        formatContainer.style.width = formatWidth + 'px';
        mediaContainer.style.minWidth = 'fit-content';

        LayoutModule.transformAdContent('portrait');
        videoContainer?.classList.add('vertical');
      } else {
        const formatHeight = (formatContainer?.getBoundingClientRect().width / videoRatio);
        // If video is vertical fit to size
        if (LayoutModule.calculateMediaAvailableSpace() >= formatHeight) {
          mediaContainer.style.height = formatHeight+'px';
          mediaContainer.style.maxHeight = formatHeight+'px';
        } else {
          mediaContainer.style.height = LayoutModule.calculateMediaAvailableSpace()+'px';
          mediaContainer.style.maxHeight = LayoutModule.calculateMediaAvailableSpace()+'px';
        }
      }
    } else {
      const formatHeight = (formatContainer?.getBoundingClientRect().width / videoRatio);
      // Ad is taller than it's wide
      if (videoRatio < 1) {
        // If video is vertical fit to size
        if (LayoutModule.calculateMediaAvailableSpace() >= formatHeight) {
          mediaContainer.style.height = formatHeight+'px';
          mediaContainer.style.maxHeight = formatHeight+'px';
        } else {
          mediaContainer.style.height = LayoutModule.calculateMediaAvailableSpace()+'px';
          mediaContainer.style.maxHeight = LayoutModule.calculateMediaAvailableSpace()+'px';
        }
      } else {
        // If video is landscape fit to size
        if (LayoutModule.calculateMediaAvailableSpace() >= formatHeight) {
          mediaContainer.style.height = formatHeight+'px';
          mediaContainer.style.maxHeight = formatHeight+'px';
        } else {
          mediaContainer.style.height = LayoutModule.calculateMediaAvailableSpace()+'px';
          mediaContainer.style.maxHeight = LayoutModule.calculateMediaAvailableSpace()+'px';
        }
      }
    }
  }

  videoElement.pause();
  videoBackground.pause();

  const viewable = await AnalyticsModule.isViewable();
    if (videoElement.paused && (viewable === true || viewable === 'untrackable')) {
     // videoElement.load();

      // setTimeout(()=>{
        if (videoElement.paused) {
        videoElement.play().then(r => {
          loadScreen.classList.add('hidden');
          videoElement.classList.remove('hidden');
          videoBackground.classList.remove('hidden');
        }).catch((e) => {
        //  setClickToPlay(true);
          ErrorModule.capture({...e, stack: "Player.play : "+e.stack});
        });

        videoBackground.play();
      }
      // },200);
     // POC: Video tracking signals  
   // videoBackgroundRef.play();
   }
} catch(e){
  ErrorModule.capture({ ...e, stack:"Player.onCanPlay : "+e.stack})
}
}

export default function Player({src, video, playerStyle, style}){

    const [progress, setProgress] = React.useState(0);
    const [muted, setMuted] = React.useState(true);
    const [clickToPlay, setClickToPlay] = React.useState(false);
    const videoRef = React.useRef();
    const videoBackgroundRef = React.useRef();
    const containerRef = React.useRef();

    React.useEffect(()=>{
      
      requestAdRun();
 
      videoRef.current.addEventListener("stalled", (event) => {document.querySelector('.load-screen')?.classList.remove('hidden')})
      videoRef.current.addEventListener("waiting", (event) => {document.querySelector('.load-screen')?.classList.remove('hidden')})
      videoRef.current.addEventListener("play", (event) => {onPlay(event, videoRef.current, videoBackgroundRef.current)})
      videoRef.current.addEventListener("pause", (e) => {
        videoBackgroundRef.current.pause();
      })
      videoRef.current.addEventListener("end", onEnd)
      videoRef.current.addEventListener("timeupdate", (event) => {onTimeUpdate(event, setProgress);})
      videoRef.current.addEventListener('loadedmetadata', onLoadedMetadata);
      videoRef.current.addEventListener('canplay', (event) => {onCanPlay(event, setClickToPlay)});
      
      // Move disclosure badge from mute position for 300x250
      if (document.querySelector(".cohoba_media .cohoba_disclosure")) {
        document.querySelector('.cohoba_disclosure').style.bottom = "unset";
        document.querySelector('.cohoba_disclosure').style.top = "8px";
      }

    },[]);

    const requestAdRun = () => {
    
        let adTag = video.vasturl;
        const videoAdContainer = containerRef.current;
        const videoElement = videoRef.current;
       
        const onAdReady = (newAdUnit) => {
            adUnit = newAdUnit;
            window.render_params.video = adUnit;
            const evtHandler = (evt) => {
              console.log(`### ${evt.type}`, evt.adUnit)

              try {
              if (evt.type === 'start') {
                renderPixel(VideoModule.generateVideoTrackingUrl('start'));
                VideoModule.addVideoTrackingParameters();
                AnalyticsModule.renderImpressionTrackers(SetupModule.getAdContainer());
              }
              //What is the difference between finish and complete?
              if (evt.type === 'finish') {
               document.querySelector('.end-screen')?.classList.remove('hidden')
              }
              if (evt.type === 'firstQuartile') {
                renderPixel(VideoModule.generateVideoTrackingUrl('firstQuartile'));
              }
              if (evt.type === 'midpoint') {
                renderPixel(VideoModule.generateVideoTrackingUrl('midpoint'));
              }
              if (evt.type === 'thirdQuartile') {
                renderPixel(VideoModule.generateVideoTrackingUrl('thirdQuartile'));
              }
              if (evt.type === 'complete') {
                renderPixel(VideoModule.generateVideoTrackingUrl('end'));
                document.querySelector(".end-card")?.classList.remove("hidden");
              }
            } catch(e){
              ErrorModule.capture({...e, stack:"Player.onAdReady : "+e.stack})
            }
            };
        
            [
              'pause',
              'resume',
              'finish',
              'impression',
              'start',
              'skip',
              'firstQuartile',
              'midpoint',
              'thirdQuartile',
              'complete'
            ].forEach((evtType) => {
              adUnit.on(evtType, evtHandler);
            });

            console.log('### onAdReady', adUnit, adUnit.assetUri);

 
          };

        const resumeContent = () => {
         // videoElement.play();
          videoElement.removeEventListener('contentloadedmetadata', resumeContent);
          videoElement.removeEventListener('canplay', resumeContent);
          videoBackgroundRef.current.src = adUnit.assetUri;
        };

        const onError = (error) => {
          console.log('### onError', error);
            ErrorModule.capture({...error, stack: "Player Error : "+ error.stack});

            if (error === 'No next ad to request'){}
        };

        const onRunFinish = (event) => {
          console.log('### onRunFinish', event);
          document.querySelector(".end-card")?.classList.remove("hidden");
          document.querySelector(".load-card")?.classList.add("hidden");
        };
    
        videoAdContainer.classList.add('active');
       // videoElement.pause();
      //  videoElement.currentTime = 0;
    
        runWaterfall(adTag, videoAdContainer, {
          onAdReady: onAdReady,
          onError: onError,
          onRunFinish: onRunFinish,
          videoElement: videoElement,
          onAdStart: ()=>{},
          useAdBuffet: true,
          responsive: true,
          viewability: true,
          track: (error) => {
            ErrorModule.capture({...error, stack: "VideoPlayer.track :"+error.stack})
          }
        });
      };

    return <><VideoPlayerContainer style={style} data-component='video-container' className="video-player-container">
      {clickToPlay && <PlayButton data-component='play-button' onClick={(event) => {
        try{
          videoRef.current.load();
        }catch(e){
          ErrorModule.capture(e);
        }
        }}><Icon src={play_icon} style={{fontSize:"2em", marginLeft:"0.3em", border: "1px solid rgba(0,0,0,0.5)", padding:"0.25em", borderRadius:"50px"}}/></PlayButton>}
        <Video ref={videoRef} data-component='video-element' className="video-element hidden" autoPlay="true" playsInline="true" muted={muted}></Video>
        {!src && <MediaSpacer data-component='media-spacer'/>}
        <VideoBackground ref={videoBackgroundRef} data-component='video-background' className="video-background-element hidden" autoPlay={true} playsInline={true} muted={true}/>
        <AdContainer ref={containerRef} data-component='video-ad-container' className="ad-container"/>
          

      {/* <SkipButton onClick={() => {}} /> */}
     
      {/* <Timer countDown videoTimer={videoTimer} template={template} data-component="timer" key="timer"  playerStyle={playerStyle}/> */}
        <LoadScreen data-component='load-screen' className="load-screen hidden" layoutWidth={video.layoutWidth} layoutHeight={video.layoutHeight}>
                <LoaderIcon src={loader_icon} style={{backgroundSize:"contain"}}/>
                <LoadingMessage>Buffering...</LoadingMessage>
        </LoadScreen>

        <EndScreen data-component='end-screen' className="end-screen hidden" layoutWidth={video.layoutWidth} layoutHeight={video.layoutHeight}>
                {src && <PlaceholderImage src={src} data-component='placeholder-image'/>}
                <ReplayButton data-component="replay-button" className="replay-button" onClick={()=>{
                  setMuted(false); 
                  videoRef.current.load();
                  videoRef.current.autoPlay = false;
                //  videoRef.current.currentTime = 0;
                  }}>Replay <Icon src={replay_icon} style={{fontSize:"2em", marginLeft:"0.3em", border: "1px solid rgba(0,0,0,0.5)", padding:"0.25em", borderRadius:"50px"}}/></ReplayButton>
                <ClickthroughButton data-component="link-button" className="link-button" onClick={()=>{}}>Visit Site <Icon src={link_icon} style={{fontSize:"2em", marginLeft:"0.3em", border: "1px solid rgba(0,0,0,0.5)", padding:"0.25em",borderRadius:"50px"}}/></ClickthroughButton>
        </EndScreen>

    </VideoPlayerContainer><VideoControls>
        <MuteButton
          key="mute-button"
          data-component="mute-button"
          muted={muted}
          playerStyle={playerStyle}
          onClick={(e) => {
            const isVideoMuted = videoRef.current?.muted;
            setMuted(!isVideoMuted);
          }}
        />
         <Scrubber key="scrubber-element" progress={progress} playerStyle={playerStyle} style={{position:"absolute", width:"100%", height:"4px", bottom: 0, left: 0, zIndex: 3}}/>
         </VideoControls></>
}