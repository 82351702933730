import LayoutModule from "..";

const transformAdContent = (orientation) => {
    if (orientation === 'portrait') {
        LayoutModule.getFooter()!.style.flexDirection = "column";
        LayoutModule.getCaption()!.style.lineClamp = "8";
        LayoutModule.getCaption()!.style['-webkit-line-clamp'] = "8";
        LayoutModule.getCta()!.style.width = "100%"
    }
}

export default transformAdContent;