export default function insertMraid() {      
        return new Promise((resolve, reject) =>{
          const mraidTag = document.createElement('script');
          mraidTag.setAttribute('src', 'mraid.js');
          mraidTag.setAttribute('type', 'text/javascript');
          mraidTag.addEventListener('error', (e) => {
            // Add small delay to allow app to load library
            setTimeout(()=>{
              if (window.mraid){
                resolve(true);
              } else {
               resolve(false)
              }
            }, 200);
          
            
          });
          mraidTag.addEventListener('load', (e) => {
            setTimeout(()=>{
              if (window.mraid){
                resolve(true);
              } else {
               resolve(false)
              }
            }, 200);
          });
          if (window.mraid) {
            resolve(true);
            return;
          }
          document.body.appendChild(mraidTag);
        });
    }