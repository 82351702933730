function checkWebview(){
    try {
      var standalone = window.navigator.standalone,
        userAgent = window.navigator.userAgent.toLowerCase(),
        safari = /safari/.test(userAgent),
        ios = /iphone|ipod|ipad/.test(userAgent);
    
      if (ios) {
        if (!standalone && safari) {
          // Safari
        } else if (!standalone && !safari) {
          // iOS webview
          return true;
        }
      } else {
        if (userAgent.includes('wv')) {
          // Android webview
          return true;
        } else {
          // Chrome
        }
      }
    } catch (e) {
     return false;
    }
  }

export default checkWebview;