import * as React from "react";
import {Box, Flex} from 'rebass';
import styled from 'styled-components';
import {PropertyInput} from "@components";
//import resizeWindow from "../components/resizeWindow";
import highlight from './highlight.js';
import selector from './selector.js';
import findByFingerprint from "./findElementByFingerprint";
import { useWorkspace } from "../views/Platform/WorkspaceContext";
const NavigatorItemContainer = styled(Box)`
position: relative;
  white-space: nowrap; 
  max-width: 100%;  
  position: relative; 
  padding-left:8px; 
  box-sizing: border-box;  
  line-height: 30px; 
  overflow: hidden;
  display: ${props => props.tagName === "TEXT" ? 'flex' : 'block'}; 
  align-items:center; 
  max-height: 30px;    
  border-left: 1px solid rgba(255,255,255,0.1); 
  
  box-sizing: border-box; 
  transition:all .5s ease-in-out;

  white-space: nowrap;
    max-width: 100%;
    position: relative;
    padding-left: 0px;
    box-sizing: border-box;
    line-height: 24px;
    overflow: hidden;
    display: block;
    align-items: center;
    max-height: 30px;
    border-left: 1px solid rgba(255,255,255,0.1);
    box-sizing: border-box;
    transition: all .3s ease-in-out;

    &:hover {
      background: rgb(69 196 212 / 9%);
      outline: rgb(69 196 212 / 0%) 1px solid;
      & {background: rgb(69 196 212 / 9%);}
    }

  &.toggled {
    max-height: 1200px;
    padding; 4px 0 0;
  }

  &.selected {
    background: rgba(0,0,0,0.1);
    outline: 1px solid rgb(69 196 212);
    background: rgb(69 196 212 / 15%);
  }

  &.sandbox {
    border:2px solid rgba(255,0,0,0.5)
  }
  &.text {
    border-left: 1px solid #e7e7e7;
  }
  &.text.emtpy {
    font-style: italic;
  }
`;
const NavigatorItemTagName = styled(Box)`
display: inline-block;
width: 30px;
min-width: fit-content!important;
font-weight: 900;
font-size: 14px;
text-align: center;
padding: 0;
box-sizing: border-box;
vertical-align: middle;
color: ${props => props.theme.colors.accent || '#000'};
color: #314a61;
color: rgba(0,0,0,0.4);
  text-transform: uppercase;

  .text &{
    color: #99999973;
    width: 23px;
    margin-left: 19px;
    margin-right: 6px !important;
  }
`;
const NavigatorItemIdentifier = styled(Box)`
display: inline-block;
font-size: 11px;
margin-left: 0px!important;
font-weight: 500;
text-transform: capitalize;
color: rgba(0,0,0,0.75);

  .selected &{
    // background: linear-gradient(to right,#8e2aab,#c168d9);
    // padding: 0 10px;
    // box-sizing: border-box;
    // font-weight: 600;
    // border-radius: 4px;
  }

  .text &{
    display: inline-block;
    font-size: 11px;
    margin-left: 0px!important;
    font-weight: 400;
    color: #314a61;
    font-weight: 400;
    color: rgba(0,0,0,0.3);
    font-style: italic;
  }
`;
const NavigatorItemExpandIcon = styled(Box)`
display: inline-block;
    width: 16px;
    text-align: center;
    top: 0;
    left: 0;
    height: 100%;
    color: rgb(198 198 198);
    font-size: 11px;
    vertical-align: middle;
    transform-origin: center;

.toggled > & {
  transform: rotate(180deg);
}
`;

const NavigatorItemFingerprint = styled(Box)`
position: absolute;
    right: 0;
    font-weight: 400;
    font-size: 9px;
    color: #0000005e;
    font-family: monospace;
    top: 0;

    .selected & {
      color: #314a61;
    }
`;

export default function NavigatorItem(props) {

  const itemRef = React.useRef();
  const {workspace} = useWorkspace();

  const selection = function (event) {
    //event.preventDefault();
    let object = {...props.object};
    if (props.object?.tagName) {
      // Is an html doc
      object = props.object;
    
    } else {
      object.layout = true;
      // Is a serialized workspace
    }
    
    if (event.type === "click") {
    //  event.stopPropagation();
    }
    //let layoutObject = findByFingerprint(workspace.layouts[cohoba.frame], object.fingerprint);
   // if (layoutObject) {object = layoutObject};

    let toggledItem = document.querySelector(
      ".navigator-item.selected"
    );

    if (toggledItem) {
      toggledItem.classList.remove("selected");
    }

    itemRef.current.classList.add("selected");

  //  let selection = selector(object);
    //if (!object.layout) {
    //  object = {...object, layout : true}
    // object.layout = true;
    // }

    if (object.fingerprint &&  document.querySelector(`[data-fp='${object.fingerprint}']`)) {
     object.object =  document.querySelector(`[data-fp='${object.fingerprint}']`);
    cohoba.updateSelector(document.querySelector(`[data-fp='${object.fingerprint}']`));
  } else if (document.body.contains(object)) {
    let selection = selector(object);
    window.cohoba.selection = selection;
    window.cohoba.setSelection(selection);
    cohoba.updateSelector(object);
  }

  // window.cohoba.selection = object;
  // window.cohoba.setSelection(object);

  };

  if (props.object === "undefined") {
 //   return null;
  }
  let toggledState;

  if (window.cohoba.selection && window.cohoba.selection.object) {
      if (window.cohoba.selection.object === props.object) {
        toggledState = " toggled";
      }
  }

  

  return <NavigatorItemContainer draggable="true" onDragOver={(e)=>{
    if (e.clientY > e.currentTarget.getBoundingClientRect().top+e.currentTarget.getBoundingClientRect().height/2){
      // Insert at top
      e.currentTarget.style.borderTop = "0px solid var(--accentColor)";
      e.currentTarget.style.borderBottom = "2px solid var(--accentColor)";
    } else {
      // Insert at bottom
      e.currentTarget.style.borderBottom = "2px solid var(--accentColor)";
      e.currentTarget.style.borderTop = "0px solid var(--accentColor)";
    }
  }} onDragLeave={(e)=>{
    e.currentTarget.style.borderBottom = "0px solid var(--accentColor)";
    e.currentTarget.style.borderTop = "0px solid var(--accentColor)";
  }} tagName={props.tagName} ref={itemRef}  onClick={(event)=>{selection(props); console.log(props)}} data-fingerprint={props.fingerprint} className={`navigator-item${props.selected ? ' selected' : ''}${props.toggled ? ' toggled' : ''}${props.object === "" ? ' empty' : ''}${typeof props.object === "string" ? ' text' : ''}`}>
  {props.children && <NavigatorItemExpandIcon className="navigator-item-expand-icon" onClick={(e) => {
          e.currentTarget.parentNode.classList.toggle("toggled");

          let windowComponent = e.currentTarget;
          while (!windowComponent.classList.contains("window")) {
            windowComponent = windowComponent.parentNode;
          }
         // resizeWindow(windowComponent);
        }}><i className="fas fa-angle-down"></i></NavigatorItemExpandIcon>}
  <NavigatorItemTagName className="navigator-item-tag-name" >{props.tagName}</NavigatorItemTagName>
 {<NavigatorItemIdentifier className={`navigator-item-identifier${toggledState ? toggledState : ''}`}>
   
   {props.component}
   <NavigatorItemFingerprint>~ {props.fingerprint}</NavigatorItemFingerprint>
 
 </NavigatorItemIdentifier>}
 {typeof props.object === 'none' && <PropertyInput type="static" style={{padding:"0 2px 0 16px", flexGrow:1}} value={props.object.textContent} action={(value) => {
   //props.object = value;
 //  window.cohoba.selection.textContent = value;
  let parentFingerPrint = itemRef.current.parentNode.dataset['fingerprint'];
  let layoutObject = findByFingerprint(workspace.layouts[cohoba.frame],parentFingerPrint);
  if (layoutObject) {
    if (layoutObject.layout && typeof layoutObject.cn[0] === "string") {
      layoutObject.cn[0] = value;
      window.cohoba.updateWorkspace();
    }
  }
  document.querySelector(`[data-fp='${parentFingerPrint}']`).textContent = value;
 }}/>}
  {props.children}
  </NavigatorItemContainer>
}
