import renderTag from "../../utilities/renderTag";
import DebugModule from "../debugModule";
import ErrorModule from "../errorModule";
import SetupModule from "../setupModule";
import getMraid from "./getMraid";
import getPlacementType from "./getPlacementType";
import getState from "./getState";
import getVersion from "./getVersion";
import insertMraid from "./insertMraid";
import isReady from "./isReady";

const CLOSE_WAIT_TIME = 5;

const MRAIDModule = (function () {
  // Find the mraid library in the window or parents when inside a nested iframe;
  let mraid = window.mraid;
  try{
    if (!window.mraid && window.parent?.mraid) {
      mraid = window.parent.mraid;
    }
  }catch(e){}

  // Public interface
  return {
    mraid: mraid,
    getMraid: getMraid,
    insert: insertMraid,
    isReady: isReady,
    getVersion: getVersion,
    getState: getState,
    getPlacementType: getPlacementType,
    addListeners: () =>{
      const mraidVersion = MRAIDModule.getVersion();
      if (mraidVersion === '2.0') {
        try {
        window.render_params.viewable = MRAIDModule.mraid?.isViewable();
        MRAIDModule.mraid.addEventListener('viewableChange', (viewable) => {
          window.render_params.viewable = viewable;
        });
      } catch(e){
        ErrorModule.capture({...e, stack:"MraidModule: 2.0 viewability : "+e.stack});
      }
      } else if (mraidVersion === '3.0') {
        try {   
          MRAIDModule.mraid.addEventListener('exposureChange', function(exposedPercentage) {
            if (exposedPercentage >= 50) {
              window.render_params.viewable = true;
            } else {
              window.render_params.viewable = false
            }
          });
        }catch(e){
          ErrorModule.capture({...e, stack:"MraidModule: 3.0 viewability : "+e.stack});
        }
      } else if (mraidVersion === '1.0'){
        window.render_params.viewable = MRAIDModule.mraid?.isViewable();
        window.render_params.viewabilityTracking = 'mraid';
      } else {
        window.render_params.viewable = MRAIDModule.mraid?.isViewable();
        window.render_params.viewabilityTracking = 'mraid';
      }
    },
    canCheckViewability: async () =>{
      return new Promise((resolve, reject) =>{
        const waitForIsViewable = setInterval(()=>{
          if (typeof MRAIDModule.mraid.isViewable !== 'undefined' 
          && (MRAIDModule.mraid.isViewable() === true
          || MRAIDModule.mraid.isViewable() === false)){
            clearInterval(waitForIsViewable);
            resolve(true);
          }
        },100);
      })
    },
    initialize: async function() {
       const mraidEnabled = await MRAIDModule.insert();
       
       if (!mraidEnabled) {
           return new Promise((resolve, reject) =>{resolve(false)});
        }

        // Find mraid in window or parent
        MRAIDModule.getMraid();
      
        MRAIDModule.mraid.addEventListener("error", (e) => {ErrorModule.capture(e)});
        
        // We are waiting for getState to be default or anything other than loading, rather than 'ready' event to go out. This means that mraid
        // could be ready to be used, but state loading. 
        const mraidIsReady = await MRAIDModule.isReady();

     
      return new Promise((resolve, reject) =>{
        window.render_params.mraidIsReady = mraidIsReady;
        const waitForMraid = setInterval(()=>{
          // We should not rely on this signal to check if mraid is available, from within the mraid module itself because it should be available trough other means.
          if (window.render_params?.viewabilityTracking === 'untrackable'){
            resolve(false);
          }

          // mraidVersion === 0 means that no mraid is available
          if (mraidVersion === '0') {
            clearInterval(waitForMraid);
            resolve(false);
          }

          if (MRAIDModule.mraid.getState() === 'default'){
            clearInterval(waitForMraid);
            resolve(true);
          }

          // This is inconvenient, because isViewable is available before mraid.getState is default
          if (typeof MRAIDModule.mraid.isViewable !== 'undefined') {
            clearInterval(waitForMraid);
            resolve(true);
          }

        },100);
      })
      },

    expand: function () {
      // Logic for expanding the ad
    },

    resize: function () {
      // Logic for expanding the ad
    },

    close: function () {
      // Logic for closing the ad
    },

    addCustomClose: function (){


        const closeButton = document.querySelector(".close-button");
        const closeSymbol = "✕";
        if (closeButton) {
          MRAIDModule.mraid.useCustomClose(true);
          
          closeButton.addEventListener("click", () => {
            MRAIDModule.mraid.close();
          });
          closeButton.parentNode.addEventListener("click", () => {
            MRAIDModule.mraid.close();
          });
          
          let COUNTDOWN = 5;
          closeButton.style.display = "flex";
          closeButton.style.opacity = 1;
          closeButton.textContent = COUNTDOWN;
          closeButton.parentNode.style.display = "flex";
          const interstitialCloseTimer = setInterval(()=>{
            if (COUNTDOWN <= 0) {
              clearInterval(interstitialCloseTimer);
              closeButton.style.opacity = 1;
              closeButton.style.fontSize = "20px";
              closeButton.style.pointerEvents = "all";
              closeButton.textContent = closeSymbol;
              return;
            }
            COUNTDOWN -= 1;
            closeButton.textContent = COUNTDOWN;
          },1000);
          
          // setTimeout(()=>{
            //   closeButton.style.opacity = 1;
            //   closeButton.style.pointerEvents = "all";
            // }, CLOSE_WAIT_TIME);
          }

    }
  };
})();

export default MRAIDModule;