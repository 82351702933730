import "./components.css";
import React, {useState, useEffect, useRef} from "react";
import styled from 'styled-components';
import {Flex} from 'rebass';


const StyledDropdown = styled.div`
  position: relative;
  display: inline-block;
  z-index: 999;
  text-align: left;


&.toggled {
  z-index: 9999999;
}

& button {
  display: block !important;
  line-height: 32px !important;
  float: none !important;
}

& button span {
  margin: 0px 6px 0px 10px;
  text-transform: uppercase;
  font-size: 11px;
}

& ul {
  display: none;
  position: absolute;
  top:30px;
  left: 20px;
  width: fit-content;
  height: auto;
  margin: 0;
  background: #fff;
  border-bottom: 2px solid rgba(255, 255, 255, 0.1);
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  padding: 0;
  text-align: left;
  list-style-position: inside;
}

& ul li {
  font-size: 12px;
  padding: 0 15px;
  display: block;
  background: rgba(0, 0, 0, 0.05);
  margin: 0;
  text-align: left;
  cursor: pointer;
  min-height: 30px;
  line-height: 30px;
}

& ul li:hover {
  background: rgba(0, 0, 0, 0.1);
}

&.toggled ul {
  display: block;
  overflow: auto;
  max-height: 350px;
  min-width:250px;
  box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
}

& svg {
  width: 20px;
  transform-origin: center;
  transition: all 0.25s ease-out;
}

&.toggled svg {
  transform: scale(0.7);
}


& ul::-webkit-scrollbar {
  width: 4.5px;
  background-color: #F5F5F5;
  cursor: pointer;
}

& ul::-webkit-scrollbar-thumb {
  background-color: #888;
}

& ul::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 30%);
  background-color: #F5F5F5;
}



`

const DropdownList = styled.ul`

  position: absolute;
  top:30px;
  left: 0;
  width: fit-content;
  height: auto;
  margin: 0;
  background: #fff;
  border-bottom: 2px solid rgba(255, 255, 255, 0.1);
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  padding: 0;
  text-align: left;
  list-style-position: inside;
  z-index: 9999999999999;


& li {
  font-size: 12px;
  padding: 0 15px;
  display: block;
  background: rgba(0, 0, 0, 0.05);
  margin: 0;
  text-align: left;
  cursor: pointer;
  min-height: 30px;
  line-height: 30px;
}

& li:hover {
  background: rgba(0, 0, 0, 0.1);
}


  display: block;
  overflow: auto;
  max-height: 150px;
  min-width:fit-content;
  box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);

&::-webkit-scrollbar {
  width: 4.5px;
  background-color: #F5F5F5;
  cursor: pointer;
}

&::-webkit-scrollbar-thumb {
  background-color: #888;
}

&::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 30%);
  background-color: #F5F5F5;
}
`;

const ActionListButton = styled(Flex)`
  font-size: 11px;
  border: 1px solid #fff;
  padding: 6px;
  line-height: 1;
  align-items: center;

  & .action-list-label {margin-right: 8px;}
`;



export default function ActionList(props){

  const [toggled, setToggled] = useState(false);

  const listNode = useRef();
  const buttonNode = useRef();
    

  const toggleGroup = (state)=>{

    if(listNode.current.getBoundingClientRect().bottom > window.innerHeight){
      listNode.current.style.bottom = "10px";
    }else{
      listNode.current.style.bottom = "unset";
    }
   
    setToggled(state);

    if(state){
      window.document.body.addEventListener("click", trackBlur);
      cohoba.container.appendChild(listNode.current);
    }else{
      window.document.body.removeEventListener("click", trackBlur);
      buttonNode.current.parentNode.appendChild(listNode.current);
    }

  }

  const trackBlur = (e) =>{
    if(listNode.current && buttonNode.current && !listNode.current.contains(e.target) && e.target !== buttonNode.current && !buttonNode.current.contains(e.target)){
      console.log("Tracking window clicks")
      toggleGroup(false);
      window.document.body.removeEventListener("click", trackBlur);
    }
  }

  useEffect(()=>{
    if(toggled){     

      listNode.current.style.left = buttonNode.current.getBoundingClientRect().left+"px";
      if(listNode.current.getBoundingClientRect().right > window.document.querySelector("#menu").getBoundingClientRect().left){
        listNode.current.style.left = buttonNode.current.getBoundingClientRect().right - listNode.current.getBoundingClientRect().width +"px";
      }
      listNode.current.style.top = buttonNode.current.getBoundingClientRect().top+buttonNode.current.clientHeight+2+"px";

      listNode.current.style.maxHeight = "unset";

      if(listNode.current.getBoundingClientRect().bottom > window.innerHeight){
        listNode.current.style.bottom = "10px";
      }else{
        listNode.current.style.bottom = "unset";
      }
    }
  }, [toggled])

  // When implementing this component, The user will
  // create an array of "actions" and pass that array
  // to the component as a prop. Here, the array is
  // mapped over, and turned into the list of actions




    const actionsArray = props.actions.map((action, index) =>

      <li
        key={index}
        data-index={index}
        data-snippet={action.name}
        onClick={function (event) {
          toggleGroup(false);
          action.action(event);
        }}
      >
      {action.name}
      </li>
    );

    // The classNames of the parent div are here.
    // If the button has a toggled state, then the className toggled
    // appears in the list of classes.
    let classNames = props.className + " action-list cohoba-dropdown";

    if (toggled) {
      classNames += " toggled"
    } 

      return (

        <StyledDropdown
          className={classNames}
          style={props.style}
          className="action-list-container"
          >
          <ActionListButton className="action-list-button" ref={buttonNode}
            onClick={ function (event) {
              toggleGroup(!toggled);
            }}
          >

            <span  className="action-list-label">
              {props.label}
            </span>
            {props.icon ? props.icon : <i className="fas fa-caret-down"></i>}
        </ActionListButton>

          <DropdownList ref={listNode} toggled={toggled}>{actionsArray}</DropdownList>
        </StyledDropdown>

      );
  
}
