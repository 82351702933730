import * as React from 'react';
import styled, {css} from 'styled-components';
import {Box, Flex} from 'rebass';
import cohoba_logo from '../../../images/cohoba_logo.svg';

import LoginComponent from '../../../user/LoginComponent';
import ActionButton from '../../../components/ActionButton';
import PropertyInput from '../../../components/PropertyInput';
import OptionGroup from '../../../components/OptionGroup';
import Window from '../../../components/Window';
import  WindowFooter  from '../../../components/WindowFooter';
import { useNavigate } from 'react-router-dom';
import { WindowBody, WindowHeader } from '../../../components';

export const Backdrop = styled(Flex)`
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: radial-gradient(ellipse at top, #48c3d6, #fff),radial-gradient(ellipse at bottom, #eb237b, #f8bf10);
    z-index: 999;
    justify-content: center;
    align-items: center;
    transition: all ease-in-out 0.5s;
    z-index: var(--topIndex);
   
    &.hidden{
       
        pointer-events: none;
    }
`;
export const HomeModal = styled(Window)`
&&& {
    position: relative;
    background: linear-gradient(to right, hsl(187 20% 95% / 1), hsl(334 20% 95% / 1));
    width: 550px;
    height: 400px;
    color: #33495f;
    flex-direction: column;
    padding: 16px 16px 12px;
    box-shadow: 0 8px 12px -6px rgba(0,0,0,0.5);
    box-sizing: border-box;
    border-radius: 4px;
}
`;

const PlatformLogo = styled(Box)`
width: 66%;
height: 275px;
background: url('${cohoba_logo}') no-repeat center / contain;
filter: drop-shadow(2px 4px 6px rgba);
`;

const LoginContainer = styled(Box)``;
const RequestAccessTitle = styled(Box)``;
const EmailInput = styled(Box)``;
const ReachOutButton = styled(Box)``;
const Spinner = styled(Box)``;
const Disclosures = styled(Box)`
    font-size: 11px;
`;

const QuickStartContainer = styled(Box)``;

const RecentProjects = styled(Flex)`
    flex-wrap: no-wrap;
    white-space: no-wrap;
    max-width: 100%;
    overflow: auto;
    padding: 8px;
`;



const QuickStartOptions = styled(Flex)`
    flex-wrap: wrap;
`;

const QuickStartItem = styled(Box)`
    width: 50%;
    height: 180px;
    border-radius: 2px;

    padding: 8px;

    & .quick-start-item-card {
        position: relative;
        width: 100%;
        background: #8d29ab;
        font-size: 14px;
        font-weight: 600;
        padding: 8px;
        min-height: 100%;
        border-radius: 3px;
        overflow: hidden;
        box-shadow: 0 4px 8px -4px rgba(0,0,0,0.5);
        cursor: pointer;
    }
`;

const StartOptions = styled(Flex)``;

const ProjectBox = styled(Flex)`
 width: 100px;
 height: 100px;
 border-radius: 2px;
 padding: 4px;
 margin: 0 8px 32px 0px !important;
 min-width: 100px !important;
 cursor: pointer;
`;

const ProjectCard = styled(Flex)`
    width: 100%;
    min-height: 100%;
    background: #5f1b72;
    border-radius: 3px;
    box-shadow: rgba(0, 0, 0, 0.5) 0px 4px 8px -4px;
    position: relative;

`;

const ProjectName = styled(Box)`
position: absolute;
width: 100%;
bottom: -18px;
font-size: 12px;
font-weight: bold;
max-width: 100%;
text-overflow: ellipsis;
overflow: hidden;
`;
const ProjectDate = styled(Box)`
position: absolute;
    width: 100%;
    bottom: -33px;
    font-size: 12px;
    font-weight: 400;
    font-style: italic;
    opacity: 0.7;
`;
const ProjectThumbnail = styled(Box)`
position: absolute;
width: 100%;
height: 100%;
left:0;
top: 0;
background: url('${props => props.src}') no-repeat center 27px / 85%;
filter: sepia(1) hue-rotate(-121deg) saturate(2);
`;
const ProjectType = styled(Box)`
position: absolute;
    width: 100%;
    height: 21px;
    background: rgba(0, 0, 0, 0.5);
    color: rgb(255, 255, 255);
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 3px 3px 0 0;
`;

const QuickStartItemImage = styled(Box)`
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 80%;
    background: rgba(0,0,0,0.7) url('${props => props.src}') no-repeat center center / 50%;
    filter: sepia(1) hue-rotate(-121deg) saturate(2);
`;

const CloseButton = styled(Box)`
    position: absolute;
    left: 8px;
    top: 8px;
    color: #fff;
    font-size: 36px;
    line-height: 17px;
    height: fit-content;
    margin: 0;
    opacity: 0.5;

    &:hover{
        opacity: 1;
    }
`;

const ProductBackground = styled.div``;
const ProductName = styled.div``;
const ProductLogo = styled.div``;
const ProductBanner = styled.div``;

export default function HomeScreen(props) {

    const navigate = useNavigate();
    const [userStatus, setUserStatus] = React.useState('guest');

  return  <Backdrop id="home-screen">
        <HomeModal>
            <WindowHeader>
         
            <i className="fa-solid fa-house-chimney-user"></i>

            
            <Flex>

            <i className="fa-solid fa-chalkboard"></i> Learn
            <i className="fa-solid fa-laptop-code"></i> Developer
            <i className="fa-solid fa-mug-saucer"></i> Community
            <i className="fa-regular fa-newspaper"></i> Blog
            </Flex>
            <i className="fa-solid fa-sliders"></i>
        <CloseButton className="close-button" onClick={()=>{
                props.setShowHomeScreen(false);
            }}>
            ×
            </CloseButton>
            </WindowHeader>
            <WindowBody>
                <WindowBody>
                <QuickStartContainer>
            
     
            
            <Box fontWeight="600" fontSize="16px">Quick Start</Box>
            <QuickStartOptions>

            <QuickStartItem>
                <Box className="quick-start-item-card" onClick={()=>{
                document.querySelector("#home-screen")?.classList.add("hidden");
                navigate('/design/stylesheet')
            }}>• Design Stylesheet
                <QuickStartItemImage src={''}/>
                </Box>
                </QuickStartItem>
            <QuickStartItem>
            <Box className="quick-start-item-card" onClick={()=>{
                document.querySelector("#home-screen")?.classList.add("hidden");
                document.querySelector(".header .preview").click();
                setTimeout(()=>{props.setCreativeWizardMode(true);},200)
                
            }}>• Creative Preview <QuickStartItemImage src={''}/></Box>
                </QuickStartItem>
            <QuickStartItem>
            <Box className="quick-start-item-card" onClick={()=>{
               document.querySelector("#home-screen")?.classList.add("hidden");
                document.querySelector(".header .report").click();
            }}>• New Report <QuickStartItemImage src={''}/></Box>
                </QuickStartItem>
            <QuickStartItem>
            <Box className="quick-start-item-card" onClick={()=>{
               document.querySelector("#home-screen")?.classList.add("hidden");
                navigate('/manage/placements')
            }}>• Placement Mapping <QuickStartItemImage src={''}/></Box>
                </QuickStartItem>
            </QuickStartOptions>

            <Box fontWeight="600" fontSize="16px" marginTop="8px">Recent</Box>
            <RecentProjects>
                {[
                    {name: "nypost", type: "stylesheet", date:"A min ago", thumbnail: ''},
                    {name: "cohoba_demo", type: "mockup", date:"3 days ago", thumbnail: ''},
                    {name: "linkedin_interstitial", type: "layout", date:"1 month", thumbnail: ''},
                    {name: "accuweather_leaderboard_top", type: "placement", date:"1 year ago", thumbnail: ''},
                ].map(recent => {
                    return <ProjectBox>
                    <ProjectCard>
                        <ProjectThumbnail src={recent.thumbnail}></ProjectThumbnail>
                        <ProjectType>{recent.type}</ProjectType>
                        <ProjectName>{recent.name}</ProjectName>
                        <ProjectDate>{recent.date}</ProjectDate>
                    </ProjectCard>
                </ProjectBox>
                })}
              
             
            </RecentProjects>
          </QuickStartContainer>
                </WindowBody>
        
          <Flex flexGrow="1" maxHeight="556px">
            <ProductBanner>
                <ProductLogo margin="0 auto"/>
                <PlatformLogo margin="0 auto"/>
                <ProductName margin="0 auto"/>
                <ProductBackground margin="0 auto"/>
            </ProductBanner>
          <Box minWidth="50%" padding="0 16px 0 0" marginRight="16px" style={{borderRight:"1px solid rgba(255,255,255,0.1)"}}>
                
               
                <Flex fontSize="21px" fontWeight="400" textTransform="capitalize" margin="32px auto 16px" width="fit-content">Welcome {props.user.username || 'Guest'} !</Flex>


               
                <Flex flexDirection="column" fontWeight="bold" margin="20px auto 32px" lineHeight="1.6" fontSize="14px" textAlign="center">
                    <Box>• Getting Started</Box>
                    <Box>• New Features</Box>
                    <Box>• Resources</Box>
                    <Box>• Concierge Service</Box>
                    
                    </Flex> 

                     
            </Box>
            <Flex flexGrow="1" className="home-screen-interactions" flexDirection="column" justifyContent="center">
                <StartOptions>
                   <OptionGroup options={[
                    {name:"Login", value: 'guest'},
                    {name:"Request Access", value: 'new'},
                   ]}
                   action={()=>{
                    setUserStatus(value);
                   }}
                   />
                </StartOptions>
            <LoginContainer>
        
                <LoginComponent user={props.user} setUser={props.setUser}/>
              
              
                <Box>
                    <PropertyInput label="Email" placeholder="name@company.xyz" style={{margin:"0 0 0"}}/>
                    <PropertyInput label="Company" placeholder="Company Name" style={{margin:"0 0 16px"}}/>
                </Box>
              
            </LoginContainer>
            <Box>{props.user.token && <ActionButton action={()=>{
                    props.setUser(user => ({...user, token: null}));
                }}>LOG-OUT</ActionButton>}</Box>
          
          </Flex>
          </Flex>
          </WindowBody>
          <WindowFooter alignItems="flex-end">
          <Disclosures flexGrow="1">Cohoba & Bohio.js are registered trademarkes under US Code 12345. Cohoba Co. holds and reserves all rights of patents and inventions, as of the publishing of this agreementm and there after;
          <Box>• Terms and Conditions</Box>
          <Box>• Privacy Policy</Box>
          </Disclosures>
            <Flex  className="home-screen-status" fontSize="12px" alignItems="center" maxHeight="14px">
                Loading resources...
                {/* <img src={loadingGif} style={{width:"35px", height:"35px", objectFit:"contain", objectPosition:"center"}}/> */}
                </Flex> 
          </WindowFooter>
       
        </HomeModal>
      </Backdrop>
}