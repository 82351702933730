const renderTag = (url: string) => {
  const tag: HTMLScriptElement = document.createElement('script');
  const srcUrl = new URL(url);
  srcUrl.searchParams.set("cb", Math.random().toString().slice(2,18));
  tag.setAttribute('type', 'text/javascript');
  tag.setAttribute('src', srcUrl.href);
  tag.addEventListener('load', () => {
  
  });
  return tag;
};

export default renderTag;
