import * as React from 'react';
import { Window, Knob } from '../../../components';
import styled, {createGlobalStyle} from 'styled-components';
import { useWorkspace } from '../WorkspaceContext';
import selectTrigger from '../../../template/selectTrigger';
import {Box} from 'rebass';
import '../../../workspace/textures.css';
import createImageFromClipboard from '../../../workspace/createImageFromClipboard';
import { handleClick } from './mouseEventHandlers';
import selector from '../../../template/selector';

const StyledPanel = styled('div')`
cursor: default;
padding: 20vh 0 0 2vh;
&&&{
    position: relative;  
    display: flex;
    background: transparent;
    width: 100% !important;
    height: 100% !important;
    justify-content: center; 
    align-items: center; 
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    overflow: auto;
   
}
&.active {
    z-index: 2 !important;
    outline: 1px solid var(--primaryColor);
}
`;

const GlobalStyles = createGlobalStyle`
${props => props.theme.fontImport}
body * {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  margin: 0;
}
${props => {
    return '';
}}
`;

const PreviewSizer = styled.div`
position: absolute;
top: 0;
left: 0;
width: 120%;
height: 120%;
`;

const PreviewPanel = React.memo((props) =>{


    const {workspace, setWorkspace} = useWorkspace();
    const PreviewPanelRef = React.useRef();

    React.useEffect(()=>{
      PreviewPanelRef.current = document.querySelector("#preview-panel");
      
      PreviewPanelRef.current.addEventListener("mousedown", (e)=>{
       
        if (cohoba.cursor === 'insert') {
          const workspaceRect = document.querySelector("#workspace")?.getBoundingClientRect();
          const newElement = document.createElement('div');
          newElement.className = `new-box-${Math.random().toString().replace('.','')}`;
          newElement.style.cssText = `
              position: fixed;
              top: ${e.clientY - workspaceRect.top + 16}px;
              left: ${e.clientX - workspaceRect.left}px;
              font-size: 16px;
              font-weight: bold;
              padding: 0px;
              box-sizing: border-box;
              display: flex;
              flex-direction: column;
              background: hsl(${360*Math.random()}deg ${100*Math.random()}% ${100*Math.random()}%);
              `;
              newElement.dataset.startPosition = JSON.stringify({x: e.clientX, y: e.clientY});

          if (cohoba.selection){
            cohoba.selection.object.appendChild(newElement);
          } else {
            document.querySelector("#workspace").appendChild(newElement);
          }
            window.cohoba.setSelection(selector(newElement));

            function handleDrawMove(e){
                let startPosition = JSON.parse(newElement.dataset['startPosition']);
                newElement.removeAttribute('data-start-position');
                let offsetX = e.clientX - startPosition.x;
                let offsetY = e.clientY - startPosition.y;
                if (offsetX < 0) {
                  newElement.style.left = e.clientX +'px';
                  newElement.style.width = offsetX*-1 +'px';
                } else {
                  newElement.style.width = offsetX +'px';
              //    cohoba.areaSelection.style.height = offsetY +'px';
                }
                if (offsetY < 0) {
                  newElement.style.top = e.clientY +'px';
                  newElement.style.height = offsetY*-1 +'px';
                } else {
                 // cohoba.areaSelection.style.width = offsetX +'px';
                 newElement.style.height = offsetY +'px';
                }
                
                newElement.dataset['endPosition'] = JSON.stringify({x: e.clientX, y: e.clientY});
            }

            function handleMouseUp(e){
              delete newElement.dataset['endPosition'];
              window.removeEventListener('mouseup', handleMouseUp);
              window.removeEventListener('mousemove', handleDrawMove);
            }
            window.addEventListener('mouseup', handleMouseUp);
            window.addEventListener('mousemove', handleDrawMove);
          
        }
      }, true);
      PreviewPanelRef.current.addEventListener("click", handleClick, true);
      PreviewPanelRef.current.addEventListener("drop", (e) => {
        let emoji = e.dataTransfer.getData("emoji");
        let elementHtml = e.dataTransfer.getData("text");
       
        
        let element = document.createElement('span' );
        if (emoji) {
          elementHtml = emoji;
          element.style.fontSize = '24px';
        }
        element.innerHTML = elementHtml;
        element.style.position = 'absolute';
        element.style.left = `${e.clientX - 46 - cohoba.workspaceElement.getBoundingClientRect().left}px`;
        element.style.top = `${e.clientY - 36 - cohoba.workspaceElement.getBoundingClientRect().top}px`;
       
        cohoba.workspaceElement.appendChild(element);
      }, true);
      PreviewPanelRef.current.addEventListener("dragover", (e) => {e.preventDefault();});
   

          // trackIntersections(window.cohoba.workspaceElement, window.cohoba.areaSelection, (entry) => {
      //   entry.target.classList.add("selected-element")
      // })
    },[]);

    return <StyledPanel
                id="preview-panel"
                onMouseMove={(ev) => {
                          if (!cohoba.selection) {
                              if (document.querySelector(".area-selection")) {
                                    cohoba.areaSelection = document.querySelector(".area-selection");
                                  document.querySelector(".width-height :nth-child(1) .text-input").value = cohoba.areaSelection?.getBoundingClientRect().width+'px';
                                  document.querySelector(".width-height :nth-child(2) .text-input").value = cohoba.areaSelection?.getBoundingClientRect().height+'px';
                                }

                            document.querySelector(".left-top :nth-child(1) .text-input").value = ev.clientX+'px';
                            document.querySelector(".left-top :nth-child(2) .text-input").value = ev.clientY+'px';
                          } else if (cohoba.selection?.object){
                            document.querySelector(".width-height :nth-child(1) .text-input").value = cohoba.selection.object.getBoundingClientRect().width+'px';
                            document.querySelector(".width-height :nth-child(2) .text-input").value = cohoba.selection.object.getBoundingClientRect().height+'px';

                            document.querySelector(".left-top :nth-child(1) .text-input").value = cohoba.selection.object.getBoundingClientRect().left+'px';
                            document.querySelector(".left-top :nth-child(2) .text-input").value = cohoba.selection.object.getBoundingClientRect().top+'px';
                          };
                }}
            >
                <GlobalStyles/>
                {/* <PreviewSizer style={{
                    
                }}/> */}
                {props.children}
<div style={{position:"absolute", left:0,top:0, width:'200vw', height:'200vh', pointerEvents:'none'}}></div>
       
                         {/* <Flex>
              <Box>MODE</Box>
              <Box>DEVICE</Box>
              <Box>BACKGROUND</Box>
              <Box>ZOOM</Box>
              <Box>SNAP TO GRID</Box>
            </Flex> */}
       


            </StyledPanel>
});

export default PreviewPanel;