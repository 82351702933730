import * as React from 'react';
import moveSelection from "../template/moveSelection";
import {ActionButton, Switch} from '../components';
import {Flex, Box} from 'rebass';
import styled from 'styled-components';

const MoverContainer = styled(Flex)`
    
    & .action-button {
        flex-direction: column;
        font-size: 10px;
        margin: 0;
        background: transparent;
    }
`;
const SmallText = styled(Box)`
    font-size: 8px !important;
    line-height: 1;
`;
export default function SelectionMover(){
  const [pluck, setPluck] = React.useState(false);
  return <MoverContainer justifyContent="space-between" className="mover-container">
  <Switch
  value={pluck}
  toggled={pluck}
  toggledLabel='ON'
  label="OFF"
  insideLabel={true}
  action={()=>{setPluck(!pluck)}}
/>

<ActionButton
name="moveOut"
  action={function () {
    if (pluck) {
      let element = window.cohoba.selection.object;
      let parentElement = element.paretNode;
      element.parentNode.parentNode.insertBefore(element, parentElement);
    //  cohoba.updateWorkspace({render:cohoba.workspaceElement.innerHTML})
    } else {
      moveSelection("out");
    }
  }}
>
  <i className="fas fa-level-up-alt" />
  <SmallText className="action-button-label">OUT</SmallText>
</ActionButton>
<ActionButton
 name="moveIn"
  action={function () {
    if (pluck) {
      let element = window.cohoba.selection.object;
      let nextElementSibling = element.nextElementSibling;
      nextElementSibling.appendChild(element);
    //  cohoba.updateWorkspace({render:cohoba.workspaceElement.innerHTML})
    } else {
      moveSelection("in");
    }
  }}
>
  <i className="fas fa-level-down-alt" />
  <SmallText className="action-button-label">IN</SmallText>
</ActionButton>

<ActionButton
 name="movePrev"
  action={function () {
    if (pluck) {
      let element = window.cohoba.selection.object;
      let prevSibling = element.previousElementSibling;
      element.parentNode.insertBefore(element, prevSibling);
    //  cohoba.updateWorkspace({render:cohoba.workspaceElement.innerHTML})
    } else {
      moveSelection("prev");
    }
  }}
>
  <i className="fas fa-long-arrow-alt-left" />
  <SmallText className="action-button-label">PREV</SmallText>
</ActionButton>
<ActionButton
 name="moveNext"
  action={function () {
    if (pluck) {
      let element = window.cohoba.selection.object;
      let nextSibling = element.nextElementSibling;
      nextSibling.insertAdjacentElement('afterend', element);
     // element.parentNode.insertBefore(nextSibling, element);
    //  cohoba.updateWorkspace({render:cohoba.workspaceElement.innerHTML})
    } else {
      moveSelection("next");
    }
  }}
>
  <i className="fas fa-long-arrow-alt-right" />
  <SmallText className="action-button-label">NEXT</SmallText>
</ActionButton>   
  </MoverContainer>
}
         
           