import SetupModule from "../../setupModule";
import LayoutModule from "..";

const transformLayout = (orientation : string) => {
    const adContainer = SetupModule.getAdContainer();
    const mediaContainer = LayoutModule.getMediaContainer();
    const formatContainer = LayoutModule.getFormatContainer();
    const adContent = LayoutModule.getAdContent();

    if (orientation === 'landscape') {
      adContainer.style.flexDirection = "row";
      mediaContainer.style.borderRight = "1px solid #666";
      mediaContainer.style.width = "fit-content";
      mediaContainer.style.height = "100%";
      mediaContainer.style.maxHeight = "100%";
      formatContainer.style.height = "100%";
      formatContainer.style.maxHeight = "100%";
      adContent.style.height = "100%";
    }
  }

export default transformLayout;