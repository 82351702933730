import * as React from 'react';
import styled from 'styled-components';
import {OptionList, OptionGroup} from '@components';
import { useWorkspace } from './Platform/WorkspaceContext';

const DeviceOptionList = styled(OptionGroup)`
color: inherit;
font-size: inherit;
& .option-list-trigger {
  color: inherit;
  font-size: inherit;
  .option-list-trigger-label {
    color: inherit;
    font-size: inherit;
    display: none;
  }
}
`;

export default function DeviceOptions(){

  const {workspace, setWorkspace, updateWorkspace} = useWorkspace();

return <DeviceOptionList
              value={workspace?.target}
              label="Target"
            triggerStyle={{
            backgroundColor:"transparent", 
            border:"none"}}
              options={[
                {
                  name: "tv",
                  icon: <i className="fas fa-tv"></i>,
                  value: "tv",
                },
                {
                  name: "desktop",
                  icon: <i className="fas fa-desktop"></i>,
                  value: "desktop",
                },
                {
                  name: "laptop",
                  icon: <i className="fas fa-laptop"></i>,
                  value: "laptop",
                },
               
                {
                  name: "tablet",
                  icon: <i className="fas fa-tablet-alt"></i>,
                  value: "tablet",
                },
               
                {
                  name: "phone",
                  icon: <i className="fas fa-mobile-alt"></i>,
                  value: "phone",
                },
                {
                  name: "responsive",
                  icon: <i className="fas fa-expand"></i>,
                  value: "none",
                },
              ]}
              action={function (value) {
                setWorkspace(workspace => ({...workspace, target: value}));
              }}
            />
          }