import * as React from 'react';
import styled from 'styled-components';
import {Box, Flex} from 'rebass';
import ComponentInjector from '../ComponentInjector';
import { useWorkspace } from '../WorkspaceContext';
import { ActionButton, FileDrop, Switch } from '@components';
import { useSelection } from '../SelectionContext';
import SelectionMover from '../../../design/SelectionMover';
import generateSaganIpsum from '../../../utilities/saganIpsum';
export const FloatingMenu = styled(Flex)`
position: fixed;
top: 46px;
display: flex;
align-items: center;
justify-content: center;
width: fit-content;
left: 0;
top:0;
flex-direction: column;
background: none;
padding: 0;
visibility: hidden;
/* background: #fff; */
/* border-radius: 8px; */
font-size: 12px;
z-index: 999999999;
perspective: 13px;
    perspective-origin: center;
 
& * {
  transition: none;
}
    .contextual-item{
      display: flex;
      background: #fff;
      /* position: absolute; */
      align-items: center;
      justify-content: center;
      padding: 4px;
      transform-origin: center top;
      /* opacity: 0; */
    
      border-radius: 8px;
      box-shadow: inset 0 0px 3px 1px rgb(0 0 0 / 20%);
      overflow: hidden;
      background: #fff;
      min-width: fit-content;
      outline: 1px solid #fdbc11;

       

        .action-button { 
          font-size: 8px;
          line-height: 24px;
          min-height: 24px;
          background: transparent;
        }
    }

    .contextual-item.toggled {
      position: relative;
      transform: rotateX(0);
      background: #fff;
      opacity: 1;
      pointer-events: all;
      box-shadow: inset 0 -2px 2px 0px rgb(0 0 0 / 18%);
    }
`;

export default function ContextualMenu ({cursor, setComponentInjection, componentInjection, components}) {

  let {workspace, setWorkspace,  updateWorkspace} = useWorkspace();
  let {selection} = useSelection();
  const[pluck, setPluck] = React.useState(false);
  const contextMenu = React.useRef();

  const clearMenu = (e) => {
    if (e.target === cohoba.contextualMenu || cohoba.contextualMenu.contains(e.target)){
      
    } else {

      contextMenu.current.style.visibility = 'hidden';
      contextMenu.current.style.pointerEvents = 'none';
      window.removeEventListener('click', clearMenu);
    }
  }

  React.useEffect(()=>{
    cohoba.contextualMenu = contextMenu.current;
    document.querySelector("#frame-content").addEventListener('contextmenu', (e)=>{
      e.preventDefault();
      contextMenu.current.style.left = e.clientX + 'px';
      contextMenu.current.style.top = e.clientY + 20 + 'px';

      if (contextMenu.current.getBoundingClientRect().right > document.querySelector("#side-panel")?.getBoundingClientRect().left) {
        contextMenu.current.style.left = e.clientX - contextMenu.current.getBoundingClientRect().width - 10 + 'px';
      }

      if (contextMenu.current.getBoundingClientRect().bottom > window.innerHeight) {
        contextMenu.current.style.top = e.clientY - contextMenu.current.getBoundingClientRect().height - 10 + 'px';
      }

      contextMenu.current.style.visibility = 'visible';
      contextMenu.current.style.pointerEvents = 'all';

      window.addEventListener('click', clearMenu);
    });
  },[]);

   return  <FloatingMenu className="contextual-menu" ref={contextMenu}>

              {/* <Box className="contextual-item pan">
                <ActionButton action={()=>{}}>VIEW</ActionButton>
                <ActionButton action={()=>{}}>OFFSET</ActionButton>
                <ActionButton action={()=>{}}>SCROLL</ActionButton>
              </Box> */}

             {cursor === 'select' && <Box className="contextual-item selection">
               <SelectionMover />
                {
                  /*
                  // Area selection (allows drag area selection)
                  // Laso selection (allows drawing shapes to select visible elements inside)
                  // Select top parents
                  // Select unlocked elements only
                  // Multiple selection (by clicking to toggle) 
                  // Center selection
                  // Select layout element vs DOM node (for prototyping and non permanent changes)
                  // 
                  */
                }
             
              
                
              </Box>}

              {cursor === 'type' && <Box className="contextual-item text">
              <ActionButton action={()=>{
                cohoba.selection.object.textContent = generateSaganIpsum(1);
              }}>GENERATE</ActionButton>
              <ActionButton action={()=>{
                let text = cohoba.selection.object.textContent;
                
                for(let i =0; i< text.length; i++){
                  cohoba.selection.object.innerHTML = `<span className="char">${text[i]}</span>`;
                }
              }}>SPLIT CHARS</ActionButton>
              <ActionButton action={()=>{
                let text = cohoba.selection.object.textContent;
                let words = text.split(' ');
                words.forEach(word => {
                  cohoba.selection.object.innerHTML = `<span className="word">${word}</span>`;
                })
                
              }}>SPLIT WORDS</ActionButton>
              <ActionButton action={()=>{
                 let text = cohoba.selection.object.textContent;
                 let lines = text.split('\n');
                 lines.forEach(line => {
                   cohoba.selection.object.innerHTML = `<div className="line">${line}</div>`;
                 })
              }}>BREAK LINES</ActionButton>
              </Box>}

              {cursor === 'draw' && <Box className="contextual-item injection">
                <ComponentInjector components={components} setComponentInjection={setComponentInjection} componentInjection={componentInjection}/>
              </Box>}

              {cursor === 'transform' && <Box className="contextual-item transform">
                <ActionButton action={()=>{}}>SCALE</ActionButton>
                <ActionButton action={()=>{}}>ROTATE</ActionButton>
                <ActionButton action={()=>{}}>TRANSLATE</ActionButton>
                <ActionButton action={()=>{}}>SKEW</ActionButton>
              </Box>}

             

             {cursor === 'action' &&  <Box className="contextual-item action">
              
                <ActionButton action={()=>{}}>CUSTOM 2</ActionButton>
                <ActionButton action={()=>{}}>CUSTOM 3</ActionButton>
                <ActionButton action={()=>{}}>CUSTOM 4</ActionButton>
                <ActionButton action={()=>{}}>CUSTOM 5</ActionButton>
              </Box>}

             

              
            </FloatingMenu>
}