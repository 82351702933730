import MRAIDModule from "../../mraidModule";
import AnalyticsModule from "../../analyticsModule";
import ErrorModule from "../../errorModule";
import SetupModule from "..";
export default async function onRender(node){
        if (typeof node === "string") {
          return false;
        }



        window.render_params.renderTime = Date.now();

        // Hack to fit ad to container
        window.resizeTemplate = setInterval(() =>{
          SetupModule.resizeTemplate(node)
        },500);
        
        window.addEventListener("resize", (e) => {
          //   e.querySelector(".cohoba_container .cohoba_media").style.width = window.innerWidth +"px";
        });
        

        //MRAIDModule.initialize should be async and wait for mraid to be ready before continuing with onRender
        // if (MRAIDModule.mraid) {
        // } else {
        //   MRAIDModule.insert();
        //   MRAIDModule.initialize();
        // }

        
        try {
          //  const mraidIsReady = await MRAIDModule.initialize();
            AnalyticsModule.initialize();
            if (MRAIDModule.mraid && MRAIDModule.mraid.getPlacementType() === 'interstital' && MRAIDModule.mraid.getState() === "default") {
              MRAIDModule.addCustomClose();
            }
        } catch (e) {
            ErrorModule.capture({...e, stack: "ErrorBoundary : "+e.stack});
          //  AnalyticsModule.initialize();
        }
       // TimelineModule.runRichMediaInteractions();
      }