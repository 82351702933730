import parseNativeToAssets from '../parseNativeToAssets';

export default function getRenderParameters() {
        const render_params = window.render_params;
        let identifier = "advertible";
        let assets = render_params?.assets;
        let layout = render_params?.layout;
        let stylesheet = render_params?.stylesheet;

        if (typeof layout?.at !== "undefined") {
          layout = layout.at(0)?.content;
        }

        // Parse native assets to render assets
        if (render_params?.native) {
          assets = parseNativeToAssets(render_params.native);
          render_params.assets = assets;
          // Should change this to something more proper
          if (assets.find(a => a.name === "eventtrackers")) {
            render_params.eventtrackers = assets.find(a => a.name === "eventtrackers").data;
          }
        }
      
        // POC: Parse encoded characters in vasttag XML
        if (assets.find(a => a.name === "video")) {
          let textParser = document.createElement("textarea");
          textParser.innerHTML = assets.find(a => a.name === "video").data;
        }

        // Get advertible impression URLs

        if (window.advertible_impression && window.advertible_impression['render-url']) {
             window.advertible_impression = {
              ... window.advertible_impression,
                render_url: window.advertible_impression['render-url'] ?? null,
                cta_url: window.advertible_impression['cta-url'] ?? null,
                cta_url3p: window.advertible_impression['cta-url3p'] ?? null,
                error_url: window.advertible_impression['error-url'] ?? null,
                video_url: window.advertible_impression['video-url'] ?? null
            }
        }

        return {identifier, assets, layout, stylesheet};
      }