import * as React from 'react';
import SetupModule from './setupModule';
import ErrorBoundary from '../components/ErrorBoundary';
import { extractCss } from 'goober';

const Interactive = ({layout, components, stylesheet, assets, style, identifier, reportFunction, onRender}) =>{
  const node = React.useRef();
  React.useEffect(()=>{

    window.player = {
      rt: Date.now(),
      ts: "{{}}",
      init: () => {
        player.ticker = setInterval(()=>{

        },40);
      },
      title: "",
      content: "",
   
    }

    if (onRender) {
      onRender(node.current);
    }
  },[]);

  // if (!layout || !assets) {
  //   ErrorModule.capture({stack:"No layout or assets were provided for render"})
  //   return "No layout or assets were provided for render";
  // }

    if (!identifier) {
      identifier = 'chb';
    }
   // layout = SetupModule.replaceMacros(layout, assets, identifier);

    return  <ErrorBoundary reportFunction={reportFunction}>
              <div
                style={{maxWidth:"100%", maxHeight:"100%",...style}}
                data-stylesheet={stylesheet?.id}
                data-version={process.env.version}
                id={identifier}
                data-component="interactive"
                data-name={layout?.name}
                ref={node}
                >
                <style id="_chb">
                  {extractCss()}
                  {stylesheet ? SetupModule.mapStylesheet(stylesheet, identifier).join("") : ''}
                  {layout.stylesheet ? SetupModule.mapStylesheet(layout.stylesheet, identifier).join("") : ''}
                </style>
                {layout.type === 'stack' ? <span key={'render-container'} className='render-container' dangerouslySetInnerHTML={{__html: SetupModule.replaceMacros(layout.markup, assets)}}></span> 
                : SetupModule.mapLayout(layout, identifier)}
              </div> 
            </ErrorBoundary>
}

export default Interactive;