import * as React from "react";
import styled from 'styled-components';

/*==========================
The Knobs are the little thingies that appear
in the corners the windows to allow the user
to move & resize windows.

When implementing, use these props:

scale={true}
move={true}
==========================*/

// Styled-Components
const StyledKnob = styled.div`
position: fixed;
transform-origin: 50% 50%;
width: 8px;
height: 8px;
color: #fff;
background: ${props => props.theme?.colors.primary};
border-left: 5px #fff solid;
border-radius: 25px;
border-width: 0;
line-height: 23px;
opacity: 1;
font-size: 10px;
vertical-align: middle;
-webkit-font-smoothing: antialiased;
-webkit-user-select: none;
-moz-user-select: none;
-ms-user-select: none;
user-select: none;
z-index: 999;
box-sizing: border-box;
transition: none .3s ease-in-out;
border-width: 0;
color: #0CA59D;
z-index: 9999999999999999999;
transform: translate(calc(-50% - 0px), calc(-50% - 0px));
&#move-knob {cursor: move;
top:-15px !important;
left:-15px !important;
bottom: auto !important;
right: auto !important;
}
&#scale-knob {cursor: nwse-resize;
  top:auto !important;
  left:auto!important;
  bottom: -15px !important;
  right: -15px !important;
}

& .cohoba-knob-property-label {
position: absolute;
width: 70px;
font-weight: 500;
line-height: 1;
border-radius: 4px;
padding: 4px;
opacity: 0;
font-size: 7px;
transition: all .25s ease-in-out;
pointer-events: none;
text-align: center;
color: #000;

}

&.moving .cohoba-knob-property-label {
opacity: 1;
}

@media screen and (min-width:1280px) {
& {
  // width: 35px;
  // height: 35px;
  // font-size: 13px;
}
}

&.moving {
transition: none;
}

&  svg {
pointer-events: none;
user-select: none;
}

.workspace-container &  {
opacity: 1 !important;
}

.interactive-mode &  {
visibility: hidden !important;
opacity: 0 !important;
}

&:hover {
line-height: 24px;
font-size: 12px;
/* opacity: .5 !important; */
 background: ${props => props.theme?.colors.accent};
}

&:active {
float:left;
line-height: 20px;
font-size: 12px;
color: #fff;
background: transparent;
transition: none;
opacity: 1;
background: ${props => props.theme?.colors.complimentary};
}
`




export default function Knob({className,style, position = [0,0,0,0], type = null, attachedTo, onClear, onMove, onTrigger, name, attachedToTriggering, freeMode, group, linked, id}){
  const [knobPosition, setPosition] = React.useState([0,0,0,0] || position);
  const [anchor, setAttachedTo] = React.useState(attachedTo);


  const knobNode = React.useRef();
  // Not required if we use the knob node
  const labelRef = React.useRef();

  React.useEffect(()=>{

    // if (!attachedTo) {
       const parent = knobNode.current?.parentNode;
       setAttachedTo((anchor) ?? parent)
    // }
    //positionKnob();

  },[])


  const positionKnob = (position) => {
    if (anchor) {
      let {top, right, bottom, left} = anchor.getBoundingClientRect();
      setPosition([left,top, right, bottom])
    }
  }

  const trigger = (position, element) => {
    window.activeKnob = element;

    anchor.classList.add("interacting");

    element.xStart = position.x;
    element.yStart = position.y;
    element.initialTop = parseInt(element.style.top);
    element.initialLeft = parseInt(element.style.left);
    element.initialWidth = anchor?.getBoundingClientRect().width;
    element.initialHeight = anchor?.getBoundingClientRect().height;
    element.initialAttachedTop = anchor?.getBoundingClientRect().top;
    element.initialAttachedLeft = anchor?.getBoundingClientRect().left;

    if (typeof onTrigger !== "undefined") {
      onTrigger(position, element);
    }

    window.addEventListener("mousemove", knobInteract, false);
    window.addEventListener("mouseup", knobInteract, false);
    window.addEventListener("mouseout", knobInteract, false);

    document.body.addEventListener("touchmove", knobInteract, false);
    document.body.addEventListener("touchend", knobInteract, false);
    document.body.addEventListener("touchcancel", knobInteract, false);

    if (type === "move") {
      //  workspaceModule.WorkspaceContainer.style.touchAction = "manipulation";
      //  workspaceModule.WorkspaceContainer.style.overflow = "visible";

      element.initialAttachedLeft = parseInt(anchor.style.left);
      element.initialAttachedTop = parseInt(anchor.style.top);

      if (isNaN(element.initialAttachedLeft)) {
        element.initialAttachedLeft = 0;
      }

      if (isNaN(element.initialAttachedTop)) {
        element.initialAttachedTop = 0;
      }
    }

    if (anchor && element.type === "scale") {
      anchor.style.width = element.initialWidth + "px";
      anchor.style.height = element.initialHeight + "px";
    }

    if (anchor && type === "maximize") {
      if (!anchor.maximized) {
        let initialWidth = anchor.getBoundingClientRect().width;
        let initialHeight = anchor.getBoundingClientRect().height;
        anchor.initialWidth = initialWidth;
        anchor.initialHeight = initialHeight;
        anchor.style.width = window.innerWidth - 70 + "px";
        anchor.style.height = window.innerHeight + "px";
        anchor.style.top = 0;
        anchor.style.left = 0;
        knob.innerHTML = '<i class="fas fa-compress-arrows-alt"></i>';
      } else {
        anchor.style.top = anchor.initialAttachedTop + "px";
        anchor.style.left = anchor.initialAttachedLeft + "px";
        anchor.style.width = anchor.initialWidth + "px";
        anchor.style.height = anchor.initialHeight + "px";
        knob.innerHTML = '<i class="fas fa-expand-arrows-alt"></i>';
      }

      if (typeof anchor.maximized !== "undefined") {
        if (anchor.maximized) {
          anchor.maximized = false;
        } else {
          anchor.maximized = true;
        }
      } else {
        anchor.maximized = true;
      }
    }
  }

  const moveInBounds = (element, boundary) => {
    if (!element) {
      element = boundary
    }
 
    if (element && attachedToTriggering) {
      
      element.style.left = parseInt(element.initialLeft) + element.xOffset - 0 + "px";
      element.style.top = parseInt(element.initialTop) + element.yOffset - 0 + "px";
    } else if (type !== 'sticky'){
      
        element.style.left = element.xStart + element.xOffset - 0 + "px";
        element.style.top = element.yStart + element.yOffset - 0 + "px";
      
      
    } else {
      
      element.style.left = element.xStart + element.xOffset - 0 + "px";
      element.style.top = element.yStart + element.yOffset - 0 + "px";
    }


      // Prevent moving over menu
      if (!freeMode) {

        if(anchor && (anchor.getBoundingClientRect().left) <= document.querySelector("#app-frame #menu").getBoundingClientRect().right){
          anchor.style.left =
          (document.querySelector("#app-frame #menu").getBoundingClientRect().right) + "px";
        }
        
      }
      

      // Prevent moving outside window bottom
      if((element.getBoundingClientRect().bottom) >= window.innerHeight){
        element.style.top =
        (window.innerHeight - anchor.getBoundingClientRect().height) + "px";
      }

       // Prevent moving outside window bottom
       if((element.getBoundingClientRect().top) <= 0){
        element.style.top = "0px";
       }

       // Prevent moving outside window left
       if((element.getBoundingClientRect().left) <= 0){
        element.style.left = "0px";
       }

        // Prevent moving outside window right
        if((element.getBoundingClientRect().right) >= window.innerWidth){
          element.style.left =  (window.innerWidth - element.getBoundingClientRect().width) + "px";
         }
         if (attachedToTriggering) {
          if((element.getBoundingClientRect().bottom) >= element.parentNode.getBoundingClientRect().bottom){
            element.style.top =
             (element.parentNode.clientHeight - element.getBoundingClientRect().height) + "px";
           }
           if(element.getBoundingClientRect().right > element.parentNode.getBoundingClientRect().right){
            element.style.left =  (element.parentNode.getBoundingClientRect().width - element.getBoundingClientRect().width) + "px";
           }
           if((element.getBoundingClientRect().top) < element.parentNode.getBoundingClientRect().top){
            element.style.top =  "0px";
           }
           if((element.getBoundingClientRect().left) < element.parentNode.getBoundingClientRect().left){
            element.style.left =  "0px";
           }
         }

       //Prevent overlapping other windows
       let visibleWindows = document.querySelectorAll(".window:not(.windowHidden)");
       let attachedToBox = element.getBoundingClientRect();
       visibleWindows.forEach(window =>{
         let  windowBox = window.getBoundingClientRect();
    
         //Touching on the right
         if((windowBox.right >= attachedToBox.left && windowBox.bottom >= attachedToBox.top) || (windowBox.right >= attachedToBox.left && windowBox.top <= attachedToBox.bottom)){
        
         }
         //Touching on the left
         if(windowBox.left >= attachedToBox.right){}
         //Touching on the bottom
         if(windowBox.top >= attachedToBox.left){}
         //Touching on the top
         if(windowBox.bottom >= attachedToBox.left){}
       })

      labelRef.current.textContent =
      element.initialAttachedLeft +
      element.xOffset +
      " , " +
      (element.initialAttachedTop + element.yOffset);
  }

  const move = (position, element) => {
    
    if (!anchor) { 
      // return;
       }
    element.xOffset = position.x - element.xStart;
    element.yOffset = position.y - element.yStart;

    if (typeof onMove !== "undefined") {
      onMove(position, element);
    }
    

    //General move knobs
    if (type === "move" && anchor) {
      // if ((element.xOffset) % 4 !== 0) {return}
      // if ((element.yOffset) % 4 !== 0) {return}
      moveInBounds(anchor)
    } else if (type === 'sticky'){
      // if ((element.xOffset) % 4 !== 0) {return}
      // if ((element.yOffset) % 4 !== 0) {return}
      moveInBounds(knobNode.current)
    }

    if (type === "scale") {
      let scaleProportion = 1;
      anchor.style.width =
      element.initialWidth + element.xOffset / scaleProportion + "px";
      anchor.style.height =
      element.initialHeight + element.yOffset / scaleProportion + "px";

      labelRef.current.textContent =
        parseInt(element.initialWidth +
          element.xOffset / scaleProportion) +
        " x " +
        parseInt(element.initialHeight + element.yOffset / scaleProportion);

      // setState({
      //   x: knob.initialWidth + knob.xOffset / scaleProportion - 20,
      //   y: knob.initialHeight + knob.yOffset / scaleProportion - 20,
      // });
    }

    // Hack to detect deselection while click event bubbles up faster than 50ms
   // setTimeout(()=>{
      anchor.classList.add("moving");
      knobNode.current.classList.add("moving");
   // },500);
  }

  const knobInteract = (event, knob) => {
  
    if (knob === window) {
     // knob = event.currentTarget;
    } else {
      knob = knobNode.current;
    }

    knob.attachedTo = anchor;

    if (event.type === "touchstart") {
      trigger(
        {
          x: event.changedTouches[0].clientX,
          y: event.changedTouches[0].clientY,
        },
        knob
      );
    }

    if (event.type === "touchmove") {
      // move({
      //   x: event.changedTouches[0].clientX,
      //   y: event.changedTouches[0].clientY,
      // });
      // knob = { attachedTo: attachedTo };


     // if (knob === window) {
        knob = {
          ...knob,
          attachedTo: anchor,
          xStart: window.activeKnob.xStart,
          yStart: window.activeKnob.yStart,
          initialAttachedLeft: window.activeKnob.initialAttachedLeft,
          initialAttachedTop: window.activeKnob.initialAttachedTop,
          initialWidth: window.activeKnob.initialWidth,
          initialHeight: window.activeKnob.initialHeight,
          initialTop: window.activeKnob.initialTop,
          initialLeft: window.activeKnob.initialLeft,
          knobNode: event.currentTarget,
        };

      //  move({ x: event.changedTouches[0].clientX, y: event.changedTouches[0].clientY }, knob);
     // } else {
        move({ x: event.changedTouches[0].clientX, y: event.changedTouches[0].clientY }, knob);
     // }

    }

    if (event.type === "contextmenu") {
      event.preventDefault();
    }

    if (event.type === "mousedown") {
      
      if (event.button === 0) {
        trigger({ x: event.clientX, y: event.clientY }, knob);
      }

      if (event.button === 2) {
        event.preventDefault();
      }
    }
    if (event.type === "mousemove") {
    //  console.log("Dragged knob",event, knob)
      if (knob === window) {
        console.log("moving without reference?")
        knob = {
          ...knob,
          attachedTo: attachedTo,
          xStart: window.activeKnob.xStart,
          yStart: window.activeKnob.yStart,
          initialAttachedLeft: window.activeKnob.initialAttachedLeft,
          initialAttachedTop: window.activeKnob.initialAttachedTop,
          initialWidth: window.activeKnob.initialWidth,
          initialHeight: window.activeKnob.initialHeight,
          initialTop: window.activeKnob.initialTop,
          initialLeft: window.activeKnob.initialLeft,
          knobNode: event.currentTarget,
        };

        move({ x: event.clientX, y: event.clientY }, knob);
      } else {
        move({ x: event.clientX, y: event.clientY }, knob);
      }
    }

    if (event.type === "mouseout") {
      //  ev.preventDefault();
      //  ev.stopPropagation();
    }

    if (
      event.type === "touchend" ||
      event.type === "touchcancel" ||
      event.type === "mouseup" ||
      event.type === "mouseleave"
    ) {
      //knob = ev.target;
      knobClear({ x: event.clientX, y: event.clientY }, knob);
        }
  }

  const knobClear = (position, knob) => {
    if (typeof onClear !== "undefined") {
      onClear(position, knob);
    }
    anchor.classList.remove("interacting");

    window.removeEventListener("mouseup", knobInteract, false);
    window.removeEventListener("mouseout", knobInteract, false);
    window.removeEventListener("mousemove", knobInteract, false);
    document.body.removeEventListener("touchmove", knobInteract, false);
    document.body.removeEventListener("touchend", knobInteract, false);
    document.body.removeEventListener("touchcancel", knobInteract, false);

    //workspaceModule.WorkspaceContainer.style.overflow = "";
    //workspaceModule.workspaceElement.style.transition = "";

    anchor.style.pointerEvents = "auto";
    anchor.classList.remove("moving");
    if(knobNode.current){
      knobNode.current.classList.remove("moving");
    }


    if (anchor.getBoundingClientRect().bottom > window.innerHeight) {
      anchor.style.top =
        window.clientHeight -
        15 -
        anchor.getBoundingClientRect().height +
        "px";
    }

    if (anchor.getBoundingClientRect().top <= 15) {
      anchor.style.top = "15px";
    }

    positionKnob();
  }

    if (attachedTo === null) {
    //  return null;
    }

  

    var propertyLabel = null;
    var label = null;

    if (label) {
      label = (<div className={label} />)
    }
    
    var propertyText = anchor ? (parseInt(anchor?.getBoundingClientRect().left) +
      " , " +
      parseInt(anchor?.getBoundingClientRect().top)) : '';

    if (type === "scale") {
      propertyText = anchor ? 
      (parseInt(anchor?.getBoundingClientRect().width) +
        " x " +
        parseInt(anchor?.getBoundingClientRect().height)) : '';
    }

    propertyLabel = (
      <div
        ref={labelRef}
        className="cohoba-knob-property-label"
      >
      {propertyText}
      </div>
    )

    let attachedToRender = anchor;


    if (attachedToTriggering && attachedToRender) {
      attachedToRender.addEventListener('mousedown', function (ev) {
        if (ev.target !== attachedToRender){
          return
        }
        ev.currentTarget.attachedTo = attachedToRender;
        let eventTarget = ev.currentTarget;
       
        if (ev.button !== 2) {

          window.knobTimeout = setTimeout(()=> {
            eventTarget.style.pointerEvents = "none";
         
            knobInteract(ev);
          },500)
        }
      }, false);
      document.body.onmouseup = function (ev) {

        clearTimeout(window.knobTimeout);
        attachedToRender.style.pointerEvents = "all";
      }
      attachedToRender.ontouchstart = function (ev) {
        ev.currentTarget.attachedTo = attachedToRender;
      //  knobInteract(ev, ev.currentTarget);
      };
    }

    return <StyledKnob
    ref={knobNode}
    type={type}
    className={className+" knob " + name}
    data-name={id}
    data-group={group}
    data-linked={linked}
    style={style ? {
      left: position[0] + "px",
      top: position[1] + "px",
      ...style
    } : {
      left: position[0] + "px",
      top: position[1] + "px",
      ...style
    }}
    onMouseDown={function (ev) {
      ev.currentTarget.attachedTo = attachedToRender;
     
      knobInteract(ev);
    }}
    onTouchStart={function (ev) {
      ev.currentTarget.attached = attachedToRender;
      knobInteract(ev);
    }}
  >
  {label}
  {propertyLabel}
  </StyledKnob>;
  
}
