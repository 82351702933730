import updateWorkspaceLayout from "./updateWorkspaceLayout";
import selector from "../../../../template/selector";
export default function (e){
    const workspaceRect = document.querySelector("#workspace")?.getBoundingClientRect();
    if (workspaceRect) {
        const newElement = document.createElement('div');
        newElement.contentEditable = true;
        newElement.className = `text-${Math.random().toString().replace('.','')}`;
        newElement.style.cssText = `
        top: ${e.clientY - workspaceRect.top}px;
        left: ${e.clientX - workspaceRect.left}px;
        font-size: ${cohoba.selection?.fontSize || '16px'};
        font-weight: ${cohoba.selection?.fontSize || 'bold'};
        padding: 0px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        `;
        //  color: hsl(${360*Math.random()}deg ${100*Math.random()}% ${100*Math.random()}%);
        document.querySelector("#workspace").appendChild(newElement);
        window.cohoba.setSelection(selector(newElement));
        newElement.addEventListener('blur',() =>{
            
        });
        newElement.focus();
        updateWorkspaceLayout(newElement);
    }
}