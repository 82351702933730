import * as React from 'react';
import styled from "styled-components";
import Workspace from '../../../workspace/Workspace';
import HorizontalRuler from './HorizontalRuler';
import VerticalRuler from './VerticalRuler';
import ZoomControl from '../../ZoomControl';
import { useWorkspace } from '../WorkspaceContext';
import DeviceOptions from '../../DeviceOptions';
import WorkspaceAlign from './WorkspaceAlign';
export const FrameContainer = styled.div.attrs({id: 'frame-content'})`
    position: relative;
    z-index: 1;
    padding: 0;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: 100%;
    overflow: auto;
    padding: 0;
    transition: all 1s ease-in-out;
    background-size: 8px;
    background-position: center;

    .interactive-mode &  {
        margin-top: -40px;
        background-size: 16px !important;
        background-position: center;
    }

    & .frame-content-ruler{
        position: absolute;
    }
   
`;

const FrameContent = (props) =>{

    const [width, setWidth] = React.useState(window.innerWidth);
    const [height, setHeight] = React.useState(window.innerHeight);
    const {workspace, updateWorkspace} = useWorkspace();

    React.useEffect(()=>{
        window.addEventListener('resize', () =>{
            setWidth(window.innerWidth);
            setHeight(window.innerHeight);
        })
    },[]);

    return <FrameContainer {...props}>
        <HorizontalRuler width={width} canvasWidth={workspace?.size[0]}/>
        <VerticalRuler  height={height} canvasHeight={workspace?.size[1]}/>
        <ZoomControl workspace={workspace} updateWorkspace={updateWorkspace}/>
        {/* <DeviceOptions workspace={workspace} updateWorkspace={updateWorkspace}/> */}
        <WorkspaceAlign />
        {props.children}
    </FrameContainer>
}

export default FrameContent;

