import MRAIDModule from "..";

export default function getMraid(){
      MRAIDModule.mraid = window.mraid;
      try{
        if (!window.mraid && window.parent?.mraid) {
          MRAIDModule.mraid = window.parent.mraid;
        }
      }catch(e){

      }
      return MRAIDModule.mraid;
    }