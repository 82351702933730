import styled from 'styled-components';

const Overlay = styled('div').attrs({id: 'overlay-container'})`

    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: none;
    width: 100%;
    height: 100%;
    maxWidth: 100%;
    maxHeight: 100%;
    position: fixed;
    padding: 66px 0 0 70px;
    z-index: var(--topIndex);
    right: 0;
    top: 0;
`;

export default Overlay;