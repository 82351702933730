import React from "react";
import styled from 'styled-components';
import {Box, Flex} from 'rebass';
import { PropertyLabel } from "./PropertyLabel";

const StyledSwitch = styled.span`
* {
  transition: all .3s ease-in-out;
}
width: 100%;
position: relative;
display: flex;
overflow: hidden;
cursor: normal;
justify-content: center;
min-width: fit-content;
min-height: 20px;
align-items: center;
margin: 0;
background: hsl(0deg 0% 96%);
border-color: rgba(0,0,0,0.05);
box-shadow: inset 0 2px 1px 0px rgb(0 0 0 / 14%);
border-top: 1px solid rgba(0,0,0,0.2);
border-bottom: 1px solid #fff;
border-width: 2px;
border-style: solid;
border-radius: 16px;
margin: 0px;
border: 1px solid rgb(0 0 0 / 15%);
border-bottom: 1px solid #47c3d457;
font-size: 8px;
font-weight: 600;
outline: 1px solid rgb(0 0 0 / 15%);
padding: 0 8px 0 28px;
color: var(--primaryColor);
min-width: fit-content !important;
&.toggled {
  padding: 0 28px 0 8px;
  background: var(--primaryColor);
  color: #fff;
}

.switch-button-label {
  transform: none;
  pointer-events: none;
  
}
&.toggled .switch-button-label{
  transform: skewX(180deg);
}


`

const SwitchKnob = styled(Box)`
width: 24px;
height: 20px;
position: absolute;
top: 0;
left: 0;
text-align: center;
color: var(--primary);
color: #49c1d1;
border-color: rgba(0,0,0,0.1);
padding: 0px;
background: #fff;
box-shadow: 0 2px 1px -1px rgb(0 0 0 / 14%);
transition: all .3s ease-in-out;
border-radius: 16px;
border: 1px solid #cccccc82;
box-sizing: border-box;
border-width: 1px 1px 2px;
display: flex;
justify-content: center;
align-items: center;
font-size: 10px;
color: var(--primaryColor);
svg {
  pointer-events: none;
  transition: all .3s ease-in-out;
}

  .toggled & {
    left: calc(100% - 24px);
    svg {
      transform: rotateY(180deg);
    }
  }
`;

const OptionLabel = styled(Box)`
  display: inline-block;
  font-size: 10px;
  font-weight: bold;
  font-style: italic;
  cursor: pointer;
`;

export default function Switchbutton({id, style, value, action, label, defaultLabel, toggledLabel, icon, toggledIcon, insideLabel}) {

  const [toggled, setToggled] = React.useState(value || false);


  React.useEffect(()=>{
    setToggled(value);
  },[value])

  React.useEffect(()=>{
   
  },[toggled])

    return(

      <Box className="switch-button-container" minWidth={"fit-content"}>{label && !insideLabel && <PropertyLabel style={{minWidth:"fit-content"}}>{label}</PropertyLabel>}
      <Flex alignItems="center" padding={"0 0 0 6px"} minWidth={"76px"}>
      {defaultLabel && insideLabel && <OptionLabel onClick={()=>{
        setToggled(false);
        if (action) {
          action(toggled);
        }
      }}>{defaultLabel}</OptionLabel>}
      <StyledSwitch
      key="switch-button"
        id={id}
        style={style}
        className={`switch-button${toggled ? ' toggled' : ''}`}
        onClick={ () => {
          setToggled(!toggled);
          if (action) {
            action(toggled);
          }
        }}
        >
         <span className="switch-button-label">{toggled ? toggledLabel : label}</span>
      <SwitchKnob
        key="switch-button-knob"
        className="switch-button-knob"
        style={{
           textAlign:"center",
           }}
      >
        {/* {toggled ? toggledIcon : icon} */}
        <i className="fa fa-caret-right"/>
      </SwitchKnob>
     
    </StyledSwitch>
    {toggledLabel && !insideLabel && <OptionLabel onClick={()=>{
        setToggled(true);
      }}>{toggledLabel}</OptionLabel>}
</Flex></Box>
    )
}


