import * as React from 'react';
import styled from 'styled-components';
import {OptionGroup} from '@components';

const StyledOptionGroup = styled(OptionGroup)`
    background: #fff;
    color: var(--primaryColor, #000);
`;

export default function ComponentInjector({components, componentInjection, setComponentInjection}) {
    return <StyledOptionGroup 
    value={componentInjection}
    triggerStyle={{
        backgroundColor:"transparent", 
        border:"none"}}
        
        action={(value)=>{
            setComponentInjection(value)
        }} options={Object.keys(components).map(key => {
            return {name: key, value: key}
        })}/>
        
}