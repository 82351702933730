import MRAIDModule from "../../mraidModule";

const isViewable = () =>{
  return new Promise((resolve, reject) => {
    const viewableInterval = setInterval(()=>{

      if (window.render_params.viewable === true
        ) {
        clearInterval(viewableInterval);
        window.render_params.viewable = true;
        resolve(true);
      } 

      if (window.admob && window.admob.opener && window.admob.opener.isOverlayShowing()){
        clearInterval(viewableInterval);
        window.render_params.viewable = true;
        window.render_params.viewabilityTracking = "admob";
        resolve(true);
      }

      if (window.render_params.viewabilityTracking === "untrackable"
          || window.render_params.viewabilityTracking === "safeframe"
          || window.render_params.viewabilityTracking === "friendly"
      ) {
        clearInterval(viewableInterval);
        window.render_params.viewable = 'untrackable';
        resolve("untrackable")
      }

      if (window.render_params.viewabilityTracking === "mraid" && MRAIDModule.mraid.isViewable()){
        clearInterval(viewableInterval);
        window.render_params.viewable = true;
        resolve(true);
      }

    }, 100);
  });
}

export default isViewable;