function loadText(file, callback) {
     
  var reader = new FileReader();
  
  if(file){reader.readAsText(file);}
  else{return false}
    
  reader.addEventListener("load", function() {
    var dataSource = reader.result;
    var extension = file.name.split('.').pop().toLowerCase()

    var loadedData = {
      source : dataSource,
      type : extension,
      name : file.name.split(".")[0]
    }
    
    callback(loadedData);
    return loadedData;
  }, false);  
    
}
export default loadText;