export default function createWorkspace(title, options){

    const workspace = {
        title: title || 'untitled',
        id: null, // Reserved for server id for now
        type: "fixed", // fixed or flex (Size)
        mode: 'interactive', // interactive or static 
        target: "none", // phone, tablet, laptop, desktop, tv
        frame: 0, // No frame changing for now
        size: [550,400], // Flash default size
        assets: [],
        layouts: [
        {
            name: "Root",
            id: Math.random(),
            type: 'stack', // flow or stack
            attributes: {},
            markup:""
        }
    ],
        rotation: 0,
        scale: 1,
        translate: 0,
        stylesheet: {},
        components: [],
        actions: [],
        time: [],
        ...options
  }
  
  if (options) {
      // Merge with each..
  }
  return workspace
}