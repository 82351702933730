import React from 'react';
import styled, {css} from 'styled-components';
import './optionlist.css';
import { PropertyLabel } from "./PropertyLabel";
import {Flex, Box} from 'rebass';


const OptionListContainer = styled('div',{
  shouldForwardProp: (prop) => prop !== 'toggled', // Don't forward dataTestId
})`
  position: relative;
  display: inline-block;
  text-align: left;
  font-size: 14px;
  vertical-align: middle;
  background: #666;
  color: #fff;
  border: 1px solid ${props => props.theme.lightGray ||  "var(--light-gray)"};
  border-radius: 3px;

  ${props => !props.toggled && css`  
  &  ${OptionListContent} {
    display: none;
    }
  `}

  
`


const OptionListTrigger = styled(Flex)`
 align-items: center;
 justify-content: space-between;
  width: 100%;
  height: 24px;
  padding: 0 10px;
  font-size: ${props => props.theme.fontSize ||  "12px"};
  font-weight: 400;
  font-family: inherit;
  transition: all .25s ease-out;
  cursor: pointer;
  line-height: 24px;
  position: relative;

  & > span {
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    flex-grow: 1;
  }

  text-align: center;
  max-width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  outline: none;
  overflow: hidden;
  box-sizing: border-box;
  
  &:last-child {
    margin: 0;
  }
`
const OptionListTriggerSpan = styled.span`
  padding: 0 10px 0 0px;
`
const OptionListItem = styled.div`
  font-size: 12px;
  padding: 0 8px;
  margin: 4px 0;
  display: block;
  background: rgba(0, 0, 0, 0.05);
  margin: 0;
  text-align: center;
  cursor: pointer;
  min-height: 30px;
  line-height: 30px;
  color: #fff;
  font-family: sans-serif;
  white-space: nowrap;
  &:hover {
    background: rgba(0, 0, 0, 0.1);
  }

  .option-list-item-icon {
    margin-right: 8px;
  }
`
const OptionListContent = styled.div`
  display: none;
  position: absolute;
  top: 25px;
  width: 100%;
  min-width: fit-content;
  height: auto;
  margin: 0;
  background: #666;
  border-bottom: 2px solid rgba(255, 255, 255, 0.1);
  border-radius: 6px;
  padding: 0;
  text-align: left;
  z-index: 9999999;

 .option-list.toggled & {
   display: block;
   max-width: 100%;
 }
`




export default class OptionList extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      toggled: false,
      valueLabel: this.props.label,
      value: this.props.value,
      options: this.props.options,
      icon: null
    }
    
    this.toggleGroup = this.toggleGroup.bind(this);
    this.selectOption = this.selectOption.bind(this);
    this.node = React.createRef();
    this.optionListContentRef = React.createRef();
    this.value = this.props.value;
  }

  componentDidUpdate() {
    let toggleGroup = this.toggleGroup;
    const props = this.props;
    //const setState = this.setState;
    if (this.state.value !== this.props.value /*&& this.state.toggled == false*/) {
      this.props.options.forEach(opt => {
        if (opt.value === props.value) {
         this.setState({
            value: opt.value,
            valueLabel: opt.value,
            icon: opt.icon
          });
        }
      })
    }

   

    if(this.state.toggled){
      
     

    } else {

    
    }
    this.value = this.props.value;
  }

  componentDidMount() {
    if (typeof(this.props.options) == "object") {

    }

    for (let i = 0; i < this.props.options.length; i++) {
      if (this.props.options[i].value === this.state.value) {

        this.setState({
          value: this.props.options[i].value,
          valueLabel: this.props.options[i].name,
          icon:  this.props.options[i].icon,
        });

      }
    }
  }

  toggleGroup(status) {
    let optionGroupComponent = this.node.current;
    let optionContainer = this.optionListContentRef.current;
    let toggleGroupFn = this.toggleGroup;

    if (status === false) {
      this.setState({
        toggled: false
       });
       return;
    }
   

    const handleOutsideClick = (e) =>{
      if (e.target !== optionContainer && e.target !== optionGroupComponent && !optionGroupComponent.contains(e.target) && !e.target.parentNode.classList.contains("option-list-item")) {
        document.body.removeEventListener("click", handleOutsideClick);
        toggleGroupFn(false);
      }
    }

    if (this.state.toggled) {
   //   optionGroupComponent.appendChild(optionContainer);
   this.setState({
     toggled: false
    });
    document.body.removeEventListener("click", handleOutsideClick, false)
      //window.removeEventListener("click", collapseOnBlur, true);
    } else{
     // document.body.appendChild(optionContainer);
      let left = optionGroupComponent.getBoundingClientRect().left +"px";
      let top = optionGroupComponent.getBoundingClientRect().top + "px";
     
      // optionContainer.style.width = optionGroupComponent.getBoundingClientRect().width + "px";
      // optionContainer.style.minWidth = "fit-content";
      // optionContainer.style.height = "auto";
      // optionContainer.style.left = left;
      // optionContainer.style.top = top;
     
     this.setState({
       toggled: true
      });
      document.body.addEventListener("click", handleOutsideClick, false)
    }
  }

  selectOption(option, action) {
    // this.props.value = option.value;

    this.setState({
      value: option.value,
      valueLabel: option.name,
      icon: option.icon,
      toggled: false
    });
    if (typeof(action) !== "undefined") {
      action(option.value);
    }
  }



  render() {
    const toggleFunction = this.toggleGroup;
    const selectOption = this.selectOption;
    const action = this.props.action;

    const optionsArray = this.props.options.map(function(option, index) {
      if (option == null || typeof(option) === "undefined") {
        return null;
      }

      if (option.group) {
        return(
          <OptionListItem
            key={index}
            className="option-list-item"
          >
           <Flex alignContent={"center"} justifyContent={"center"}> - {option.group} - </Flex> 
          </OptionListItem>  
        )
      }

      return(
        <OptionListItem
          key={index}
          className={"option-list-item "}
          data-value={option.value}
          onClick={ function(event) {
            console.log("selected option")
            selectOption(option, action);
           // toggleFunction();
        }}
        >
         <Flex alignContent={"center"} justifyContent={"space-between"}>{option.icon && <Box className="option-list-item-icon" style={{fontSize:"16px"}}>{option.icon}</Box>}{option.label ? option.label : option.name}</Flex> 
        </OptionListItem>  
      )
    });

    let classes = `${this.props.className}${this.state.toggled ? " toggled" : ''}${this.props.name ? (" "+this.props.name) : ''} option-list`;

      return (
<Box style={this.props.style} className={this.props.className+" option-list-container"}>{this.props.label &&  <PropertyLabel>{this.props.label}</PropertyLabel>}
        <OptionListContainer 
          value={this.state.value}
          className={classes}
          style={this.props.triggerStyle}
          ref={this.node}
          toggled={this.state.toggled}
        >
          

          <OptionListTrigger
            className="option-list-trigger"
            onClick={event => {
              //event.currentTarget.parentNode.classList.toggle("toggled");
              toggleFunction();
            }}
            onBlur={ e => {
              //toggleFunction(false);
            }}>
            <OptionListTriggerSpan>
              <Box className="option-list-trigger-icon" key="trigger-icon">{this.state.icon}</Box>
            <span className='option-list-trigger-label'>
              {this.state.valueLabel}
              </span>
              </OptionListTriggerSpan> 
              <i className= "fas fa-caret-down"/>
            </OptionListTrigger>
            <OptionListContent
          key="content"
          className="option-list-content"
          ref={this.optionListContentRef}
        >
        {optionsArray}
        </OptionListContent>

        </OptionListContainer>
        </Box>
      );
  }
};