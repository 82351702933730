import * as React from "react";
import styled from 'styled-components';
import Window from "../components/Window";
import WindowHeader from "../components/WindowHeader";
import WindowBody from "../components/WindowBody";
import WindowModule from "../components/WindowModule";
import WindowFooter from "../components/WindowFooter";
import ActionButton from "../components/ActionButton";
import WindowNavButton from "../components/WindowNavButton";
import Switch from "../components/Switch";
import attach from "./attach";
import Navigator from "./Navigator";
import SelectionMover from '../design/SelectionMover';
import buildCSS from "../design/buildCSS";
import copyStringToClipboard from "../components/copyStringToClipboard";
import layoutLibrary from "../interactive/layoutLibrary";
//import patternLibrary from "../components/interactive/patternLibrary";

import OptionList from "../components/OptionList";

import { useWorkspace } from "../views/Platform/WorkspaceContext";
import findElementByFingerprint from './findElementByFingerprint'; 
import insertElement from "../layouts/insertElement";
import generateElement from "../layouts/generateElement";
import { useSelection } from "../views/Platform/SelectionContext";

const StyledWindow = styled(Window)`
top: 46px !important;
transform: none !important;
left: 48px !important;
border-radius: 0 !important;
bottom: 0 !important;
height: auto !important;
max-height: unset !important;
width: 240px !important;
min-width: 240px !important;
`;

export default function TemplateWindow ({frame, windowRef, components}) {

  const {selection} = useSelection();
  const {workspace} = useWorkspace();
  const [pluck, setPluck] = React.useState(false);
  const [object, setObject] = React.useState(selection?.object);



  React.useEffect(()=>{
    setTimeout(()=>{
      setObject(document.querySelector('#workspace'));
    },10);
  },[workspace]);

  React.useEffect(()=>{
    if (selection?.object) {
      setTimeout(()=>{
        setObject(document.querySelector('#workspace'));
      },20);
    }
  },[selection]);

  const exportStylesheet = (stylesheet) => {
    let text = buildCSS(this.state.stylesheetObject);
    copyStringToClipboard(text);
  }

  const exportAllStylesheets = () =>{
    let stylesheets = Array.from(window.document.styleSheets);

    console.log(stylesheets);
    let AllCSS = "";
    stylesheets.forEach((stylesheet) => {
      if (stylesheet.ownerNode.tagName === "STYLE") {
          let text = buildCSS(stylesheet);
          AllCSS += text;
      }
    });

    console.log("AllCSS", AllCSS);

    copyStringToClipboard(AllCSS);
  }




  // addStyle(selection) {
  //   if(typeof selection === "undefined" && window.cohoba.selection){
  //    // selection = window.cohoba.selection
  //   }
  //   let newStyles = workspace.styles;
  //   if(this.state.useGlobal){newStyles = this.state.styles}
  
    
  //   if(typeof newStyles === "undefined"){
  //     newStyles = [];
  //   }

  //   newStyles.forEach((style,index)=>{
  //     if(style.component && style.component === selection.component && ["cta", "heading", "caption", "sponsor", "sponsorName", "container"].includes(selection.component)){
  //       newStyles[index] = selection;
  //     }
  //   });

  //   if(newStyles.filter(style=>{return style.component === selection.component ? true : false}).length === 0  || !["cta", "heading", "caption", "sponsor", "sponsorName", "container"].includes(selection.component)){
  //     newStyles.push(selection);
  //   }
   

  //   if(this.state.useGlobal){
  //     this.setState({ styles: newStyles });
  //   }else{
  //     window.cohoba.workspace.styles = newStyles;
  //    // window.cohoba.updateWorkspace(window.cohoba.workspace);
  //   }
   
    
  // }





    return (
     <StyledWindow
        ref={windowRef}
        name="template"
        move={false}
        type="panel"
        size={[300, 400]}
      >
        <WindowHeader>
          <ActionButton className='duplicate' action={()=>{
            if (cohoba.selection?.object) {
              let duplicate = cohoba.selection?.object.cloneNode(true);
              cohoba.selection.object.style.top = cohoba.selection?.object.getBoundingClientRect().top + cohoba.selection?.object.getBoundingClientRect().height - cohoba.workspaceElement.getBoundingClientRect().top + 'px';
              cohoba.selection?.object.parentNode.appendChild(duplicate);
            }
          }}>DUPLICATE</ActionButton>
        {/* <WindowNavButton

className="toggled"
           id="styles-nav-button"
           action={function (event) {
            
            }}><i style={{color:"#fdbc11"}} className="fas fa-pencil-ruler"></i> LAYOUT</WindowNavButton>
            <WindowNavButton

className=""
           id="styles-nav-button"
           action={function (event) {
          
            }}><i style={{color:"#ec077d"}} className="fas fa-tools"></i> STYLESHEET</WindowNavButton> */}
        </WindowHeader>

        <WindowBody style={{padding:"0 8px"}}>
  
             
          
          <WindowModule>
           {workspace?.type === 'fixed' && <Navigator workspace={workspace} type={"component"} object={object} />}
           {workspace?.type === 'flow' && <Navigator workspace={workspace} type={"component"} layout={workspace?.layouts?.at(frame)} />}
          </WindowModule>
        </WindowBody>

        <WindowFooter>
      
       
<SelectionMover pluck={pluck} selection={selection}/>
       
        </WindowFooter>
      </StyledWindow>
    );
  
}