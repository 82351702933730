import React, { useState } from "react";
import ActionButton from "./ActionButton";
import styled from 'styled-components';

const MessageBarStyle = styled.div`
display: flex;
justify-content: space-between;
position: relative;
    align-items: center;
    padding: 6px;
    min-width: 200px;
    background: linear-gradient(to right, rgb(70 196 213), rgb(70 196 213 / 28%));
    color: #fff;
    border-radius: 6px;
    border: 1px solid #00e1ff;
    border-width: 1px 1px 0;
    margin-bottom: 16px;
    box-shadow: inset 0px 0px 16px 0px var(--primaryColor), 0 7px 16px -4px rgba(0,0,0,0.5);

& .messageContent {
  font-size: 12px;
  font-weight: 400;
  color: var(--textColor, #333);
}

& .messageTitle {
  color:#fff;
  font-weight: 600;
}

& .messageIcon {
  font-size: 16px;
}

`

export const MessageContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: auto;
  max-height: 100%;
  min-height: 0;
  width: 300px;
  z-index: var(--topIndex, 999);
  padding: 24px;
`;



export function Message(props) {
  let icon = null;
  let acceptText = "X" || props.acceptMessage;
  const node = React.useRef();

  const [closed, setClosed] = useState(false);

  const acceptMessage = function () {
    setClosed(true);
    // props.setOverlay({overlay:null});
    //node.current.remove();
  };

  if (props.autoClose) {
    setTimeout(() => {
      setClosed(true);
      //props.setOverlay({overlay:null});
    }, 5000);
  }

  if (props.type === "error") {
    icon = (
      <i
        className="fas fa-exclamation-circle"
        style={{ marginRight: "16px", color: "red" }}
      ></i>
    );
  } else if (props.type === "warning") {
    icon = (
      <i
        className="fas fa-exclamation-triangle"
        style={{ marginRight: "16px", color: "orange" }}
      ></i>
    );
  } else if (props.type === "info") {
    icon = (
      <i
        className="fas fa-info-circle"
        style={{ marginRight: "16px", color: "#23aea8" }}
      ></i>
    );
  } else if (props.type === "success") {
    icon = (
      <i
        className="fas fa-check-circle"
        style={{ marginRight: "16px", color: "#008000" }}
      ></i>
    );
  }


  if (closed) {
    return null;
  }
  console.log(node);

  return (
    <MessageBarStyle ref={node}>
        <div className="messageIcon">  {icon}</div>
      <div className="messageContent">
       
        <div className="messageTitle">
        
          {props.title}
        </div>
        <div className="messageText">
          {[props.children] || null}
        </div>
      </div>
      <div className="messagefooter">
        {(props.type !== "success" || props.autoClose !== true) && <ActionButton
          style={{ background: "transparent", color: "#fff",  }}
          action={() => {
            acceptMessage();
          }}
        >
          {acceptText}
        </ActionButton>}
      </div>
    </MessageBarStyle>
  );
}

export default Message;