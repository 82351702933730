interface Asset {
    id: number;
    name: string;
    type: string;
    data: string;
}

export default function reconstructSourceHTML(renderedHTML: string, assets: Asset[]): string {
    // Create a copy of the rendered HTML so we don't modify the original
    let sourceHTML: string = renderedHTML;

    // Loop through each asset
    assets.forEach((asset: Asset) => {
        // Construct the macro for this asset
        const macro: string = `{{ASSET_${asset.id}}}`;
        
        // Replace all occurrences of the asset's data with its macro
        const regex: RegExp = new RegExp(asset.data.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'), 'g');
        sourceHTML = sourceHTML.replace(regex, macro);
    });

    return sourceHTML;
}