import React from "react";
import {PropertyInput} from '@components';
import { PropertyLabel }  from "./PropertyLabel.js"
import styled from 'styled-components';


// =========================
// A slider for gradual value adjustments
// Currently used in:
// 1) /settings/ > workspace (to zoom in and out)
// =========================

const StyledFaderInput = styled.div`

  margin:20px 0px;

  & input {
    width: 100%;
    cursor: pointer;
  }

  $ PropertyInput {
    width: 100%;
    marginLeft: 10px;
  }

`


export default function FaderInput({value, step, minValue, maxValue, unit, label, action, style, className, textInput}) {

    return(
      <StyledFaderInput style={style} className={className}>
        {label && <PropertyLabel>{label}</PropertyLabel>}
        <input
          type="range"
          min={minValue}
          max={maxValue}
          step={step}
          onChange={ function (event) {
            
            action(event.currentTarget.value);
          }}
        />
       
      {textInput &&  <PropertyInput key="text-input"
        value={value}
        unit="%"
        action={ function (value) {
          
          action(parseInt(value));
        }}/>}
      </StyledFaderInput>
    )
  
}
