import React, { useState } from 'react';
import styled from 'styled-components';
import Modal from 'react-modal';
import { useTranslation } from 'react-i18next';
import { FileDrop, OptionGroup, Switch, Window, WindowHeader, WindowCard, WindowFooter } from '../../components';
import uploadAsset from '../../network/assets/uploadAsset';
import updateUserMeta from './updateUserMeta';

interface Passenger {
    name: string;
    age: string;
    gender: string;
}

const TextInput = styled.input`
  display: block;
  width: 100%;
  border: none;
  background: rgba(0, 0, 0, 0.05);
  box-sizing: border-box;
  color: #000;
  &::placeholder {
    color: red;
  }
  padding: 16px;
  margin-bottom: 16px;

  &.error {
    border: 1px solid red;
  }
`;

const SelectInput = styled.select`
  display: block;
  width: 100%;
  border: none;
  background: rgba(0, 0, 0, 0.05);
  box-sizing: border-box;
  color: #000;
  padding: 16px;
  margin-bottom: 16px;
`;

const SubmitButton = styled.button`
  background-color: #e3342f;
  color: white;
  width: 100%;
  padding: 16px;
  border: none;
  cursor: pointer;
  font-size: 16px;
  margin-top: 16px;
  &:hover {
    background-color: #cc1f1a;
  }
`;

const RangeInput = styled.input`
  width: 100%;
  margin-bottom: 16px;
`;

const TicketContainer = styled.div`
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
`;

const QRCodePlaceholder = styled.div`
  width: 100px;
  height: 100px;
  background: #eee;
  margin: 20px auto;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: #666;
`;

const experienceLabels = [
    "Principiante",
    "Aprendiendo",
    "Puliendo",
    "Intermedio",
    "Atletico",
    "Competitivo"
];



const divisiones = {
   'atv':{
       'buggy':[
        {name:'2 Pasajeros',value:'2p'},
        {name:'4 Pasajeros',value:'4p'},
       ],
       '4w':[
        {name:'2x4',value:'2x4'},
        {name:'4x4',value:'4x4'},
       ]
   },
    'moto': {
        'enduro':[
            {name:'A',value:'a'},
        {name:'B',value:'b'},
        {name:'C',value:'c'},
        {name:'D',value:'d'},
        ],
        'dual':[
            {name:'Adventure',value:'adventure'},
            {name:'Dual Enduro',value:'enduro'},
        ]
    },
    'suv': [
        {name:'Paseo',value:'paseo'},
        {name:'Offroad',value:'offroad'},
        {name:'Offroad Extremo',value:'offroad-extremo'},
    ],
    'otro': [],
}

const RegistrationView: React.FC = () => {
    const { t } = useTranslation('es');
    const [passengers, setPassengers] = useState<Passenger[]>([{ name: '', age: '', gender: '' }]);
    const [experience, setExperience] = useState<number>(0);
    const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
    const [user, setUser] = useState({});

    const addPassenger = () => {
        if (passengers.length < 3) {
            setPassengers([...passengers, { name: '', age: '', gender: '' }]);
        }
    };

    const handlePassengerChange = (index: number, field: keyof Passenger, value: string) => {
        const newPassengers = [...passengers];
        newPassengers[index][field] = value;
        setPassengers(newPassengers);
    };

    //import cohoba from '../..';

const uploadAsset = (file, callback) => {
    if (!user.token) {return};
    const formData = new FormData();
    formData.append('file', file); // Append the file from the input

    fetch(process.env.API_ENDPOINT+'/media', {
        method: "POST",
        headers: {
       //    'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${user.token}`
        },
        body: formData
    }).then(response => response.json())
    .then(data => {console.log('Uploaded image:', data); callback(data)})
    .catch(error => console.error('Error uploading image:', error));
};


    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();

        if (!user.token){
            let email = document.querySelector('#registration-container .email').value;
            let first_name = document.querySelector('#registration-container .first-name').value;
            let last_name = document.querySelector('#registration-container .last-name').value;
            
            fetch('https://rallyfrontera.do/?rest_route=/login/users&email='+email,{method:'POST', body:JSON.stringify({
                email:email,
                first_name: first_name,
                last_name: last_name,
                description: '',
            })}).then(r => r.json()).then(d => {
                setUser(user => ({...user, id: d.id, token: d.jwt}))
                setModalIsOpen(true);
            }).catch((e)=>{alert(e.error)});
            setModalIsOpen(false);
        } else {
            updateUserMeta(user.id, {
                division: user.division,
                category: user.category,
                experience: user.experience,
                first_name: user.first_name,
                last_name: user.last_name,
                address: user.address,
                address: user.address,
              //  id: user.id,
              //  phone_number: user.phone_number,
            })
        }
    };

    return (
        <Window className="max-w-4xl mx-auto p-4">
            <WindowHeader>•••••••</WindowHeader>
            <h1 className="text-2xl md:text-4xl font-bold mb-8">{t('Motorbike Rally Registration')}</h1>
            <form className="space-y-6" onSubmit={handleSubmit}>

            <WindowCard name="biker-information">
            <h2 className="text-xl font-semibold mb-4">{t('Biker Information')}</h2>
        
                    <TextInput className="email" type="text" placeholder={t('Email')} />
                    <TextInput className="first-name" type="text" placeholder={t('First Name')} />
                    <TextInput className="last-name" type="text" placeholder={t('Last Name')} />
                    <TextInput type="number" placeholder={t('Mobile Phone #')} />
          
                    <OptionGroup label="Division" value={user.division} options={[
                        {name:'Moto',value:'moto'},
                        {name:'ATV',value:'atv'},
                        {name:'SUV',value:'suv'},
                        {name:'Otro',value:'otro'},
                    ]} action={(value)=>{
                        setUser(user => ({...user, division: value}))
                    }}/>

{user.division === 'atv' && <OptionGroup label="Category" value={user.category} options={[{name:'4W', value:'4w'}, {name:'Buggy', value:'buggy'}]} action={(value)=>{
    setUser(user => ({...user, category: value}))
}}/>}

{user.division === 'moto' && <OptionGroup label="Category" value={user.category} options={[{name:'Enduro', value:'enduro'}, {name:'Dual', value:'dual'}]} action={(value)=>{
    setUser(user => ({...user, category: value}))
}}/>}

{user.division === 'suv' && <OptionGroup label="Category" value={user.category} options={divisiones['suv']} action={(value)=>{
    setUser(user => ({...user, category: value}))
}}/>}

{user.division !== 'suv' && user.category && <OptionGroup label="Category" value={user.category} options={divisiones[user.division][user.category]} action={(value)=>{
    setUser(user => ({...user, category: value}))
}}/>}



<label className="block text-sm font-medium text-gray-700 mb-2">
                            {t('Experience')}: {t(experienceLabels[experience])}
                        </label>
                        <RangeInput
                            type="range"
                            min="0"
                            max="5"
                            step="1"
                            value={user.experience}
                            onChange={(e) => {
                                setUser(user => ({...user, experience: e.target.value}))
                                //  setExperience(Number(e.target.value))
                            }}
                            />

                            </WindowCard>
        
                    <WindowCard name="private-information">
                    <h2 className="text-xl font-semibold mb-4">{t('Private Information')}</h2>
                    <TextInput className="dob" type="date" placeholder={t('DoB')}  onChange={(e)=>{
                        setUser(user => ({...user, dob: e.target.value}))
                    }}/>
                    <SelectInput onChange={(e) =>{
                        setUser(user => ({...user, gender: e.target.value}))
                    }}>
                        <option value="">{t('Gender')}</option>
                        <option value="male">{t('Male')}</option>
                        <option value="female">{t('Female')}</option>
                    </SelectInput>
                    <TextInput type="text" placeholder={t('Address')} onChange={(e)=>{
                        setUser(user => ({...user, address: e.target.value}))
                    }}/>
                    <TextInput type="text" placeholder={t('ID Number')} onChange={(e)=>{
                        setUser(user => ({...user, identification: {type:'cedula', number: e.target.value}}))
                    }}/>
                    <FileDrop label="Foto Licencia" type="data" action={(data)=>{
                        uploadAsset(data, (json) => {
                            setUser(user => ({...user, identification: {...user.identification, image_url: json.link}}))
                        });
                    }}/>
                    </WindowCard>
                    
              
                    <WindowCard name="emergency-information">
                    
                    <h2>Emergency Information</h2>
                    <OptionGroup label="Blood Type" options={[
                        {name:'A', value:'a'},
                        {name:'B', value:'b'},
                        {name:'C', value:'ab'},
                        {name:'O', value:'o'},
                    ]} 
                    value={user.blood_type?.at(0)}
                    action={(value)=>{
                        setUser(user => ({...user, blood_type: [value]}))
                    }}/>s
                         <OptionGroup options={[
                             {name:'-', value:'negative'},
                             {name:'+', value:'positive'},
                            ]}   value={user.blood_type?.at(1)} action={(value)=>{
                                setUser(user => ({...user, blood_type: [user.blood_type.at(0), value]}))
                            }}/>
                        <h3>Allergies?</h3>
                        <Switch label="NO" toggledLabel="YES" insideLabel={true}/>
                        <h3>Prescriptions?</h3>
                        <Switch label="NO" toggledLabel="YES" insideLabel={true}/>

                    {/* <TextInput type="text" placeholder={t('Alergias')} /> */}
                    {/* <TextInput type="text" placeholder={t('Prescripciones')} />
                    <TextInput type="text" placeholder={t('Contraindicaciones')} /> */}
                    <hr/>
                    <TextInput type="text" placeholder={t('Nombre Contacto')} />
                    <TextInput type="number" placeholder={t('Mobile Phone #')} />
                    <TextInput type="number" placeholder={t('Email (Optional)')} />
                    <TextInput type="text" placeholder={t('Address')} />
                  
                    <p className="text-sm text-gray-600">{t('Your information is safe and secure.')}</p>
                    </WindowCard>
                

                    <WindowCard name="passenger-information">
                    <h2 className="text-xl font-semibold mb-4">{t('Passengers')}</h2>
                    {passengers.map((passenger, index) => (
                        <div key={index} className="space-y-2 mb-4">
                            <TextInput
                                type="text"
                                placeholder={`${t('Passenger')} ${index + 1} ${t('Name')}`}
                                value={passenger.name}
                                onChange={(e) => handlePassengerChange(index, 'name', e.target.value)}
                                />
                            <TextInput
                                type="number"
                                placeholder={`${t('Passenger')} ${index + 1} ${t('Age')}`}
                                value={passenger.age}
                                onChange={(e) => handlePassengerChange(index, 'age', e.target.value)}
                                />
                            <SelectInput
                                value={passenger.gender}
                                onChange={(e) => handlePassengerChange(index, 'gender', e.target.value)}
                                >
                                <option value="">{t('Gender')}</option>
                                <option value="male">{t('Male')}</option>
                                <option value="female">{t('Female')}</option>
                                <option value="other">{t('Other')}</option>
                            </SelectInput>
                        </div>
                    ))}
                    {passengers.length < 3 && (
                        <SubmitButton type="button" onClick={addPassenger}>
                            {t('Add Passenger')}
                        </SubmitButton>
                    )}
                
                    </WindowCard>

                    <WindowCard name="event-information">
                            <TextInput type="text" placeholder={t('Jersey Size')} />
                            <TextInput type="text" placeholder={t('Jersey Number')} />
                            <TextInput type="text" placeholder={t('Helmet Size')} />
                        </WindowCard>

<WindowFooter>

                <SubmitButton type="submit">
                    {t('Submit Registration')}
                </SubmitButton>
</WindowFooter>
            </form>

            <Modal
                isOpen={modalIsOpen}
                onRequestClose={() => setModalIsOpen(false)}
                contentLabel={t('Registration Confirmation')}
                style={{
                    content: {
                        top: '50%',
                        left: '50%',
                        right: 'auto',
                        bottom: 'auto',
                        marginRight: '-50%',
                        transform: 'translate(-50%, -50%)',
                        width: '80%',
                        maxWidth: '500px',
                    },
                }}
            >
                <TicketContainer>
                    <h2 className="text-xl font-bold mb-4">{t('Registration Confirmation')}</h2>
                    <p>{t('Thank you for registering for the Motorbike Rally!')}</p>
                    <QRCodePlaceholder>QR Code</QRCodePlaceholder>
                    <p className="text-sm text-gray-600">{t('Copy and save your QR code for event entry.')}</p>
                    <SubmitButton onClick={() => setModalIsOpen(false)}>{t('Close')}</SubmitButton>
                </TicketContainer>
            </Modal>
        </Window>
    );
};

export default RegistrationView; 