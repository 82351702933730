import * as React from 'react';
import layoutLibrary from '../layoutLibrary';
import Render from '../';
import checkWebview from "./checkWebview";
import findMaxParentSize from "./findMaxParentSize";
import flexParents from "./flexParents";
import getLayoutSize from './getLayoutSize';
import mapLayout from './mapLayout';
import mapStylesheet from './mapStylesheet';
import parseNativeToAssets from './parseNativeToAssets';
import parseVastTag from "./parseVastTag";
import replaceMacros from "./replaceMacros";
import replacePatters from './replacePatterns';
import resizeAdContainer from './resizeAdContainer';
import resizeTemplate from './resizeTemplate';
import tagParent from "./tagParent";
import insertResponsiveTag from "./insertResponsiveTag";
import checkViewabilityOptions from "./checkViewabilityOptions";
import AnalyticsModule from '../analyticsModule';
import MRAIDModule from '../mraidModule';
import ErrorModule from '../errorModule';

import { renderParameters, advertibleImpressionObject,  } from '../../entities/advertibleInterfaces';

import getRenderUrls from './getRenderUrls';
import getAdContainer from './getAdContainer';
import getRenderId from './getRenderId';
import getSspId from './getSspId';
import getErrorUrl from './getErrorUrl';
import getCtaUrls from './getCtaUrls';
import getVideoEventsUrl from './getVideoEventsUrl';
import createRenderRoot from './createRenderRoot';
import setupRenderEnvironment from './setupRenderEnvironment';
import checkViewability from './checkViewability';
import getRenderParameters from './getRenderParameters';
import onRender from './onRender';

// POC: Default layout minimal rendering
import defaultLayout from '../../layouts/base/default.json';
import stylesheetLibrary from '../stylesheetLibrary';
import getViewportSize from '../analyticsModule/getViewportSize';

import DebugModule from '../debugModule';


declare global{
  interface Window {
    mraid: any;
    render_params: renderParameters | undefined;
    advertible_impression?: advertibleImpressionObject | undefined;
    renderInfo: any;
  } 
}

const SetupModule = (function () {
    const renderNode = document.currentScript;
    // Public interface
    return {
      getRenderUrls: getRenderUrls,
      getErrorUrl: getErrorUrl,
      getCtaUrls: getCtaUrls,
      getVideoEventsUrl: getVideoEventsUrl,
      getRenderId: getRenderId,
      getSspId: getSspId,
      insertResponsiveTag: insertResponsiveTag,
      findMaxParentSize: findMaxParentSize,
      flexParents: flexParents,
      getLayoutSize: getLayoutSize,
      mapLayout: mapLayout,
      mapStylesheet: mapStylesheet,
      parseNativeToAssets: parseNativeToAssets,
      parseVastTag: parseVastTag,
      replaceMacros: replaceMacros,
      replacePatters: replacePatters,
      resizeAdContainer: resizeAdContainer,
      resizeTemplate: resizeTemplate,
      tagParent: tagParent,
      checkWebview: checkWebview,
      checkViewabilityOptions: checkViewabilityOptions,
      getAdContainer: getAdContainer,
      checkViewability: checkViewability,
      onRender: onRender,
      getRenderParameters : getRenderParameters,
      errorReportingEnabled : ()=>{
        return window.render_params?.error_report ? window.render_params?.error_report : false;
      },
      setupRenderEnvironment: setupRenderEnvironment,
      createRenderRoot: createRenderRoot,
      initialize: async function() {
        try{
       
          const renderNode : HTMLOrSVGElement|null = document.currentScript;
          const render_params : renderParameters|undefined = window.render_params;
          let {identifier, assets, layout, stylesheet} = SetupModule.getRenderParameters();
          const root = SetupModule.createRenderRoot(renderNode);

          if (typeof layout === "undefined" || layout === '') {
            try {
              // layout = defaultLayout;
              layout = getLayoutSize(window.render_params?.frame?.width, window.render_params?.frame?.height);
              // let layoutStylesheet = getLayoutSize(window.render_params?.frame?.width, window.render_params?.frame?.height);
              // layout.cn.at(0).attributes.orientation = layoutStylesheet.orientation;
              // layout.stylesheet = stylesheetLibrary[layoutStylesheet.stylesheet];
            } catch (e) {
              console.error(e);
              ErrorModule.capture({...e, stack:"SetupModule.getLayoutSize : "+e.stack});
            }
          }
          // Make this async, add mraid while setting up environment, and wait for mraid to be ready/loading before saying that viewabilityTracking=mraid or untrackable
         try{
           const renderEnvironamentReady = await SetupModule.setupRenderEnvironment(layout);
         }catch(e){
          ErrorModule.capture({...e, stack:"SetupModule.setupRenderEnvironment : "+ e.stack})
         }

         // Expand interstitials before selecting biggest layout size
         try{       
          const viewportSize = getViewportSize();
       
          if (viewportSize.w === 0 && viewportSize.h === 0){
            const mraidReady = await MRAIDModule.isReady();
          } else {
            console.log("Waiting for mraid");
            const mraidReady = await MRAIDModule.isReady();
            console.log("MRaid ready");
            
          }
           if (MRAIDModule.mraid && MRAIDModule.mraid?.getVersion() && MRAIDModule.mraid.getMaxSize && MRAIDModule.mraid.getMaxSize()) {
         
             const mraidPlacementType = MRAIDModule.mraid.getPlacementType();
             // Find device screen size
             const mraidMaxSize = MRAIDModule.mraid.getMaxSize();
             // If it's a mobile interstitial use viewport size
         
             if (mraidPlacementType === 'interstitial') {
              
           //   MRAIDModule.mraid.setExpandProperties({...mraidMaxSize, useCustomClose: true});
              //MRAIDModule.mraid.useCustomClose(true);
            //  MRAIDModule.mraid.expand();
          //  MRAIDModule.mraid.useCustomClose(true);
           //   MRAIDModule.addCustomClose();
               if (MRAIDModule.mraid.supports('expand')){
          
                 MRAIDModule.mraid.setExpandProperties({...mraidMaxSize});
                 //MRAIDModule.mraid.useCustomClose(true);
                 MRAIDModule.mraid.expand();
                
     
                } else {
                //  MRAIDModule.mraid.setExpandProperties({...mraidMaxSize, useCustomClose: true});
             
                //  MRAIDModule.mraid.expand();
                }
              } else {
                if (MRAIDModule.mraid.supports('resize')){
                  MRAIDModule.mraid.setResizeProperties(mraidMaxSize);
                  MRAIDModule.mraid.resize();
                }
              }
            } 
          }catch(e){
            ErrorModule.capture({...e, stack:"SetupModule.expandOrResizeWithMraid : "+ e.stack})
         }
            
         

          /* try{     
            if (window.frameElement){
              // Try expanding the width first
              let parentSize;
              try{
               parentSize = findMaxParentSize(window.frameElement);
              }catch(e){
                ErrorModule.capture({...e, stack: "SetupModule.findMaxParentSize"+e.stack})
              }
              // Expand iframe if parent is bigger than container
              if (layout && parentSize && (parentSize.width > layout.attributes?.width || parentSize.height > layout.attributes?.height)) {
                // Disabled resizing for now
             //   resizeAdContainer(parentSize.width, parentSize.height);

                // Find container size for layout again, using parent size
                layout = getLayoutSize(parentSize.width, parentSize.height);
              }
            }
          }catch(e){
            ErrorModule.capture({...e, stack: "SetupModule.resizeAdContainer"+JSON.stringify(e)})
          } */
            
          // if (window.ucTagData) {
          //   containerSize = window.ucTagData.size;
          // }

          if (typeof layout === "undefined" || layout === '') {
            try {
              // layout = defaultLayout;
              layout = getLayoutSize(window.render_params?.frame?.width, window.render_params?.frame?.height);
              // let layoutStylesheet = getLayoutSize(window.render_params?.frame?.width, window.render_params?.frame?.height);
              // layout.cn.at(0).attributes.orientation = layoutStylesheet.orientation;
              // layout.stylesheet = stylesheetLibrary[layoutStylesheet.stylesheet];
            } catch (e) {
              console.error(e);
              ErrorModule.capture({...e, stack:"SetupModule.getLayoutSize : "+e.stack});
            }
          }

          // Fit to Container
          const fitThreshold = 0.98;
          if (layout 
            && (layout.attributes.width <= window.innerWidth * fitThreshold
            || layout.attributes.height <= window.innerHeight * fitThreshold)
          ) {
            try{
              layout.attributes.width = window.innerWidth;
              layout.attributes.height = window.innerHeight;
            }catch(e){
              ErrorModule.capture({...e, stack:"SetupModule.fitLayout : "+e.stack});
            }
          }

      
          //Should consolidate render_params as much as possible, and build a framework to create and extend it.
          window.render_params = {
            ...render_params,
            ver: process.env.version,
            layoutName: layout.name,
            startTime: Date.now(),
          // loadTime : AnalyticsModule.getLoadTime(),
          // renderTime: AnalyticsModule.getRenderTime(),
          }

          /* RENDER TEMPLATE */
          root.render(<Render 
                        assets={assets} 
                        layout={layout} 
                        stylesheet={stylesheet} 
                        ref={(e) => {SetupModule.onRender(e.base)}} 
                        reportFunction={ErrorModule.capture}
                      />);
        }catch(e){
          ErrorModule.capture({...e, stack:"SetupModule.initialize : "+ e.stack});
        };
      },
    };
  })();
  
  export default SetupModule;