import React from "react";
import NavigatorItem from "./NavigatorItem";

function MapChildrenObjects(children) {
  var childrenObjects = children.map(function (child, index) {
    var identifier = child.id;
    var tagName = child.tagName || child.component;
    if (typeof identifier == "undefined") {
      identifier = child.className;
    }
    if (typeof identifier === "undefined") {
     // return null;
    } else {
      identifier = "" + child.id + " " + child.className;
    }

    if (child.tagName === "SCRIPT") {
      // return null
    }
    if (child.tagName === "STYLE") {
      // return null
    }

    //  if(child.tagName === "LINK" || child.tagName === "SCRIPT" || child.tagName === "BR"){return}

    if (typeof child.children !== "undefined" && child.children.length > 0) {
      var grandchildren = child.children;

      grandchildren = Array.from(grandchildren);

      var grandchildrenObjects = MapChildrenObjects(grandchildren);

      return React.createElement(
        NavigatorItem,
        {
          key: index,
          toggled: true,
          identifier: identifier,
          tagName: tagName,
          object: child,
          component: identifier,
          selected: cohoba.selection?.object === child ? true : false
        },
        grandchildrenObjects
      );
    } else {
      return React.createElement(NavigatorItem, {
        key: index,
        identifier: identifier,
        tagName: tagName,
        object: child,
        component: identifier,
        selected: cohoba.selection?.object === child ? true : false
      });
    }
  });

  return childrenObjects;
}

export default MapChildrenObjects;
