import * as React from "react";
import styled, {css} from 'styled-components'
import StylerModule from "../../../design/styler-module/ComponentsModule";
import AttributesModule from "../../../design/styler-module/AttributesModule";
import Window from "../../../components/Window";
import WindowHeader from "../../../components/WindowHeader";
import WindowBody from "../../../components/WindowBody";
import WindowFooter from "../../../components/WindowFooter";
import WindowCard from "../../../components/WindowCard";
import ActionButton from "../../../components/ActionButton";
import { Switch } from "../../../components";
import {Flex} from 'rebass';
import {PropertyInput, OptionList} from "@components";
import styler from "../../../design/styler";
import Visualizer from "../../../template/Visualizer";
import Accordion from "../../../components/Accordion";
import BackgroundProperties from "../../../design/styler-module/BackgroundProperties";
import ComponentProperties from "../../../design/ComponentProperties";

//import NewStyleModal from "./NewStyleModal";
//import cleanStylesheet from "../library/cleanStylesheet";
// import { kebabToCamel } from "../utilities/convertStringCase";
// import StylesheetWizard from "./wizard/StylesheetWizard";
//import Modal from "../components/Modal";
//import StylesheetList from '../views/Manage/StylesheetsModule/StylesheetList';

const StyledWindow = styled(Window)`
max-height: 100%;
width: 100%;
height: 100% !important;
${props => props.type === "panel" && css`
&&&{
  width: 250px !important;
  min-width: 250px !important;

  height: 100%;



  .property-field input[type='text']{
    background: transparent;
    border-bottom: 1px solid rgba(255,255,255,0.7);
    color: #fff;
  }
}
`}

& .property-input{

  .property-value-change-arrows {
    position: absolute;
    top: 3px;
    left: 0px;
    height: 18px;
    width: 16px;
    text-align: center;
    position: absolute;
    top: 6px;
    left: 0px;
    height: 16px;
    width: 18px;
    text-align: center;
    font-size: 8px;
    color: var(--primaryColor);
    color: rgba(0,0,0,0.2);
  }
  &:hover {
    .property-value-change-arrows {
      color: var(--primaryColor);
    }
  }
  
  .property-sublabel {
    color: #a5a5a5;
    font-size: 8px;
    padding: 0;
    display: block;
    text-overflow: ellipsis;
    text-align: right;
    overflow: hidden;
    vertical-align: middle;
    padding-bottom: 10px;
    bottom: -28px;
    left: 8px;
    opacity: .7;
    margin: 4px 0px 0px 4px;
    text-transform: uppercase;
  }
  span {padding: 2px;}
  input {

    color: #666!important;
    margin: 0;
    width: 100%;
    font-size: 12px;
    padding: 4px 15px 4px;
    box-sizing: border-box;
    border-radius: 3px;
    border: 0;
    text-align: left;
    font-family: inherit;
    background: hsl(0deg 0% 96%);
    border: 1px solid var(--light-gray);
    border: none;
    box-sizing: border-box;
    transition: all 0.25s ease-in-out;
    border-color: rgba(0,0,0,0.05);
    box-shadow: inset 0 0 4px 0px rgb(0 0 0 / 15%);
    border-color: rgba(0,0,0,0.05);
    box-shadow: inset 0 2px 1px 0px rgb(0 0 0 / 14%);
    border-top: 1px solid rgba(0,0,0,0.2);
    border-bottom: 1px solid #fff;
    border-width: 2px;
    border-style: solid;
    border-radius: 4px;
    margin: 0px;
    border: 1px solid rgb(0 0 0 / 15%);
    border-bottom: 1px solid #47c3d421;
  }
  

  }

  .window-header {
    flex-direction: column;
  }

  .navigation-buttons {
    border-bottom: 1px solid whitesmoke;
    padding: 4px 0;
  }
  .property-value-input {
    display: flex;
    width: 100%;
    flex-grow: 1;
    justify-content: space-around;
    font-size: 11px;
    font-family: monospace;
    white-space: nowrap;
    vertical-align: middle;
    align-items: center;
    padding: 4px;
    box-sizing: border-box;
    border-bottom: 1px solid whitesmoke;

    .property-input {
      flex-grow: 1;
    padding: 0 0 0 8px;
    }
  }

  & .window-quick-color-module {
    flex-direction: row;
    align-items: center;
    .property-field {
      display: flex;
      align-items: center;
      .property-label {
        margin: 0;
      }
    }
  }

  & .sketch-picker{
    position: fixed;
    line-height: 1;
    background: #f3f2f3;
    z-index: 999999;
    border-radius: 4px;
    top: 6px;
    padding: 6px 6px 0;
  }
  `;
  
const SelectionIdentifier = styled.div`
display: inline-block;
    margin-left: 8px;
    font-weight: 400;
    background: #f2f3f4;
    padding: 4px 8px;
    border-radius: 3px;
    flex-grow: 1;
    border: 1px solid #49c1d1;
    width: 119px;
`;

  export default function PropertiesWindow ({theme, workspace, stylesheets, selection, id, updateStyles, type, className, setOverlay, windowRef}) {

 // const windowRef = React.useRef();
 const [cssProperty, setCssProperty] = React.useState('');
 const [cssValue, setCssValue] = React.useState('');

  // React.useEffect(()=>{
  //  // window.cohoba.updateDesignWindow = refresh;
  // },[])

  // React.useEffect(()=>{
  //   // if (selection?.fingerprint !== this.state.selection?.fingerprint) {
  //   //     this.setState({selection: selection})
  //   //   }
  // },[id, selection])



  const addStyle = () => {
    let newStyles = {...workspace.stylesheet};

    //let newStylesheet = {};
    //newStylesheet[selection.component] = selection;

    let updateStyles = (stylesheet) => {
      stylesheet = cleanStylesheet(stylesheet);
      window.cohoba.updateWorkspace({stylesheet: {...workspace.stylesheet, ...stylesheet}});
     // window.cohoba.workspaceArray[window.cohoba.currentIndex] = {...workspace, stylesheet: {...workspace.stylesheet, ...stylesheet}};
    };

    setOverlay([<NewStyleModal workspace={workspace} selection={selection} stylesheet={newStyles} updateStyles={updateStyles} />]);
  }

    
    return(
     <StyledWindow
        name="design"
        key="design-window"
        ref= {windowRef}
        move= {"true"}
        scale= {"true"}
        type={type}
        visible={true}
        className={className}
        type="panel"
        size= {[360, 480]}>

      <WindowHeader>
       {/* <WindowNavButton
          className="toggled"
          id="attributes-nav-button"
          action={function (event) {
              if(event.currentTarget.classList.contains("toggled")){
               }else{
                // style the current button with .toggled
                event.currentTarget.classList.add("toggled");
                // remove the style .toggled from other buttons
                document.querySelector("#styles-nav-button").classList.remove("toggled");
                document.querySelector("#library-nav-button").classList.remove("toggled");
                // show the correct module view
                document.querySelector(".window-attributes-card").style.display = 'block';
                document.querySelector(".window-attributes-card").classList.remove("hidden");
                // hide the other module views
                document.querySelector(".window-styles-card").style.display = 'none';
                document.querySelector(".window-library-card").style.display = 'none';
                }
            }}>ATTRIBUTES</WindowNavButton> */}
       

        <Visualizer selection={selection} style={{}}/>

        </WindowHeader>

      <WindowBody>
      
       <WindowCard name="styles" key="styles">
        
       <StylerModule key="styler-module"
             workspace={workspace}
             selection={selection}
           />
       </WindowCard>

       <WindowCard name="attributes">
         <AttributesModule  key="styler-module"
             workspace={workspace}
             selection={selection}
             />
       </WindowCard>

       <WindowCard name="background">
       <Accordion name="background" icon={<i key='icon' className="fas fa-fill"></i>} title={<Flex className="accordion-title-label">Background<ActionButton action={e => {
      e.currentTarget.classList.toggle('toggled');
    }}name="maximize-button"><i className="far fa-square"></i><i className="fas fa-square"></i></ActionButton></Flex>}>
      <BackgroundProperties workspace={workspace}
             selection={selection}/>
      </Accordion>
       </WindowCard>

       <WindowCard name="background">
       <Accordion name="background" icon={<i key='icon' className="far fa-clock"></i>} title={<Flex className="accordion-title-label">Events<ActionButton action={e => {
      e.currentTarget.classList.toggle('toggled');
    }}name="maximize-button"><i className="far fa-square"></i><i className="fas fa-square"></i></ActionButton></Flex>}>
      
      </Accordion>
       </WindowCard>

       <WindowCard name="appeareance">
       <Accordion name="appeareance" icon={<i key='icon' className="fas fa-paint-brush"></i>} title={<Flex className="accordion-title-label">Appeareance<ActionButton action={e => {
      e.currentTarget.classList.toggle('toggled');
    }}name="maximize-button"><i className="far fa-square"></i><i className="fas fa-square"></i></ActionButton></Flex>}>
      
      <div>
          <OptionList
            options={`mix-blend-mode: normal;
            mix-blend-mode: multiply;
            mix-blend-mode: screen;
            mix-blend-mode: overlay;
            mix-blend-mode: darken;
            mix-blend-mode: lighten;
            mix-blend-mode: color-dodge;
            mix-blend-mode: color-burn;
            mix-blend-mode: hard-light;
            mix-blend-mode: soft-light;
            mix-blend-mode: difference;
            mix-blend-mode: exclusion;
            mix-blend-mode: hue;
            mix-blend-mode: saturation;
            mix-blend-mode: color;
            mix-blend-mode: luminosity;
            mix-blend-mode: plus-darker;
            mix-blend-mode: plus-lighter;`.split("mix-blend-mode: ").map(mbm => {return {
              name: mbm.replace(/;/g,''), value: mbm.replace(/;/g,'')
            }})}
            value="normal"
            action={function (value) {
              styler("mix-blend-mode", value);
            }}
          />
          <OptionList 
            options={[
              { name: "Visible", value: "visible" },
              { name: "Hidden", value: "hidden" },
              { name: "Scroll", value: "scroll" },
              { name: "Auto", value: "auto" },
            ]}
            value="normal"
            action={function (value) {
              styler("overflow", value);
            }}
          />
        <OptionList
          label="Overflow"
          options={[
            { name: "Hidden", value: "hidden" },
            { name: "Auto", value: "auto" },
            { name: "Scroll", value: "scroll" },
          ]}
          action={function (value) {
            styler("overflow", value);
            // updateValue({overflow : value});
          }}
          value={selection?.overflow}
        />
          </div>
      </Accordion>
       </WindowCard>

       <WindowCard name="Transform">
       <Accordion name="Transform" icon={<i key='icon' className="fas fa-cube"></i>} title={<Flex className="accordion-title-label">Transform<ActionButton action={e => {
      e.currentTarget.classList.toggle('toggled');
    }}name="maximize-button"><i className="far fa-square"></i><i className="fas fa-square"></i></ActionButton></Flex>}></Accordion>
       </WindowCard>

       <WindowCard name="Animation">
       <Accordion name="Animation" icon={<i key='icon' className="fas fa-hourglass-start"></i>} title={<Flex className="accordion-title-label">Animation<ActionButton action={e => {
      e.currentTarget.classList.toggle('toggled');
    }}name="maximize-button"><i className="far fa-square"></i><i className="fas fa-square"></i></ActionButton></Flex>}></Accordion>
       </WindowCard>

       <WindowCard name="Behavior">
       <Accordion name="Behavior" icon={<i key='icon' className="far fa-hand-pointer"></i>} title={<Flex className="accordion-title-label">Behavior<ActionButton action={e => {
      e.currentTarget.classList.toggle('toggled');
    }}name="maximize-button"><i className="far fa-square"></i><i className="fas fa-square"></i></ActionButton></Flex>}></Accordion>
       </WindowCard>

       <WindowCard name="Logic">
       <Accordion name="Logic" icon={<i key='icon' className="fas fa-toolbox"></i>} title={<Flex className="accordion-title-label">Component<ActionButton action={e => {
      e.currentTarget.classList.toggle('toggled');
    }}name="maximize-button"><i className="far fa-square"></i><i className="fas fa-square"></i></ActionButton></Flex>}>
      <ComponentProperties />
      </Accordion>
       </WindowCard>

       <WindowCard name="Logic">
       <Accordion name="Logic" icon={<i key='icon' className="fas fa-robot"></i>} title={<Flex className="accordion-title-label">Logic<ActionButton action={e => {
      e.currentTarget.classList.toggle('toggled');
    }}name="maximize-button"><i className="far fa-square"></i><i className="fas fa-square"></i></ActionButton></Flex>}></Accordion>
       </WindowCard>

       <WindowCard name="Dataset">
       <Accordion name="Dataset" icon={<i className="fas fa-table"></i>} title={<Flex className="accordion-title-label">Dataset<ActionButton action={e => {
      e.currentTarget.classList.toggle('toggled');
    }}name="maximize-button"><i className="far fa-square"></i><i className="fas fa-square"></i></ActionButton></Flex>}></Accordion>
       </WindowCard>



       {/* 

        attributes
        - id
        - class
        - component
        - 

        - Backgound
    - Border
    - Appareance
        - opacity
        - mix blend modes
        - box-shadow
        - filters
        - z-index
    - Transform
    - Behavior
        - line clamp, text overflow and other nice stuff, cursor...pointer events
    - Custom stuff per component in the theme. Themeable props
    - 
       */}

       
      </WindowBody>
      <WindowFooter style={{display:"flex", justifyContent:"space-around"}}>
      
          <Flex className="property-value-input">{cssProperty+" : "}<PropertyInput value={cssValue} returnAction={(value,e) => {
            if (cssProperty === ''){
              setCssProperty(value);
              setCssValue('');
            } else if (cssValue !== ''){
              setCssValue('')
              setCssProperty('');

              // Add to stylesheet
            }
          }} action={(value,e) => {
            setCssValue(value)
            if (cssProperty !== '' && window.cohoba.selection) {
              styler(cssProperty, value,window.cohoba.selection);
            }
          }}/></Flex>

          <Switch insideLabel={true} action={()=>{

          }} toggledLabel="ATTRS" label={"STYLES"} icon={<i key="1"  style={{color:"#fdbc11"}} className="fas fa-pencil-ruler"></i>} toggledIcon={<i key="2" style={{color:"#ec077d"}} className="fas fa-tools"></i>}/>
      </WindowFooter>
     </StyledWindow>
    )
  
}

