import * as React from 'react';
import {styled} from 'goober';
//import ActionButton from '../../../ActionButton';
import MuteIcon from '../../../../images/icons/no-volume.png';
import UnMuteIcon from '../../../../images/icons/volume.png';

const MuteButton = styled('div')`
  background: url(${(props) => (props.muted ? MuteIcon : UnMuteIcon)}) no-repeat center / 24px;
  borde-radius: 3px;
  padding: 2px;
  width: 42px;
  height: 42px;
  position: absolute;
  bottom: 0px;
  right: 0px;
  z-index: 3;
  transform: translateZ(1px);
  filter: invert(1) drop-shadow(0px 0px 3px black);

  &:hover{
    background-color: transparent;
  }

  ${props => props.playerStyle?.mute && `
    width: ${props.playerStyle?.mute.width};
    height: ${props.playerStyle?.mute.height};
    background-color: ${props.playerStyle?.mute.backgroundColor};
    background-size: ${props.playerStyle?.mute.backgroundSize};
    border-radius: ${props.playerStyle?.mute.borderRadius};
    background-image: ${props.playerStyle?.mute.backgroundImage};
    box-shadow: ${props.playerStyle?.mute.boxShadow};
    filter: ${props.playerStyle?.mute.filter};
  `}

  ${(props) => ['320x50', '320x100'].includes(props.template?.size)
    && `
      width: 12px;
      height: 12px;
      left: 4px;
      bottom: 4px;
    `}
`;

export default MuteButton;