import React from "react";
import mapChildrenObjects from "./mapChildrenObjects";
import mapLayoutObjects from "./mapLayoutObjects";
import mapCssStylesheet from "./mapCssStylesheet";

export default class Navigator extends React.Component {
  constructor(props) {
    super(props);
            
      this.state = {
        object : document.body
      }
  }

      componentDidMount(){
        this.setState({object : this.props.object})
      }
     
       render() {
          var object = this.props.object;
       //  var object = document.querySelector(".cohoba-workspace-scroller");

        if (this.props.layout) {
          object = this.props.layout;
          var childrenObjects = mapLayoutObjects(object.cn);

          return React.createElement("div",
          {id:"layout-navigator", style:{}},
          childrenObjects);
        }

         if (typeof(object) === "undefined" || object === null) {
           return "Object undefined"
          }
          
          if(this.props.type === "component" && typeof(object) !== "undefined" && object !== null){
            if(typeof(object.childNodes) === "undefined"){
              return "Loading items"
            }
            var children = Array.from(object.childNodes);
            var childrenObjects = mapChildrenObjects(children);
          } 

          // else if (this.props.type === "stylesheet"  && typeof(object) !== "undefined" && object !== null){
            //   console.log("Rendering stylesheet navigator")
            //   children = Array.from(object.rules);
            //   childrenObjects = mapCssStylesheet(children);
            //   console.log("Rendering navigator", childrenObjects);
            // }
            
            //var title = object.tagName+object.id+object.className;
            
         return React.createElement("div",
                    {id:"navigator", style:{}},
                    childrenObjects);
                       
       }

}
          
