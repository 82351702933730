import React from 'react';
import styled from 'styled-components';

// ========================
// We use this as navigation buttons
// that appear in the headers of windows
// ========================


// Styled-Components
const WindowNavButtonContainer = styled.div`
  align-items: flex-start;
  text-align: center;
  box-sizing: border-box;
  line-height: 24px;
  cursor: pointer;
  margin-right: 0px;
  font-size: 10px;
  background-color: none;
  width: 100%;
  font-weight: 400;
  text-decoration: none !important;

  svg {
    transition: all .3s ease-in-out;
    transition-delay: box-shadow .3s ease-in-out;
    font-size: 12px;
    color: inherit;
    vertical-align: middle;
    margin: 0 4px 0 0;
    border-radius: 50px;
    border: 1px solid currentColor;
    /* border-bottom-color: hsl(43deg 98% 45%); */
    padding: 4px;
    border-width: 1px 1px 1px;
    filter: drop-shadow(0px 0px 1px #fff) drop-shadow(0px 0px 1px currentColor);
  }

  &.toggled {
    color: #666;
    svg {
      color: #666;
      border-color: currentColor;
      border-width: 1px 1px 1px;
      filter: none;
    }
  }

  background-color: transparent;
  color: #fff;
  font-weight:400;

  &:hover {
    color: #000;
    text-decoration: underline !important;
  }

  &:last-child {
    text-decoration: underline;
    margin: 0;
  }

  &.toggled {
    // background: ${props => props.theme.mediumGray || "var(--medium-gray)"};
    color: ${props => props.theme.primary || "rgb(167, 47, 200)"};
    font-weight:600;
  }

`;


export default class WindowNavButton extends React.Component {
  
   render() {


    let className = "window-nav-button";
    if(typeof(this.props.name) !== "undefined"){
      className += " "+this.props.name
    }
    if(typeof(this.props.className) !== "undefined"){
      className += " "+this.props.className
    }

        return (
          <WindowNavButtonContainer
            id={this.props.id}
            style={this.props.style}
            className={className}
            onClick={this.props.action}
            onMouseEnter={function(event){event.currentTarget.classList.add("hover")}}
            onMouseLeave={function(event){event.currentTarget.classList.remove("hover")}}
            onMouseDown={function(event){event.currentTarget.classList.add("active")}}
            onMouseUp={function(event){event.currentTarget.classList.remove("active")}}
            onTouchStart={function(event){event.currentTarget.classList.add("active")}}
            onTouchEnd={function(event){event.currentTarget.classList.remove("active")}}
           >
             {this.props.children}
          </WindowNavButtonContainer>
        )
   }
};


