import React, { createContext, useContext, useState } from 'react';

// Create the context
const SelectionContext = createContext();

// Create a provider component
export const SelectionProvider = ({selection, setSelection, children }) => {
  // You can initialize this state based on props, API data, or defaults
  //const [selection, setSelection] = useState(null); // replace null with your initial selection value

  return (
    <SelectionContext.Provider value={{ selection, setSelection }}>
      {children}
    </SelectionContext.Provider>
  );
};

// Custom hook to use the selection context
export const useSelection = () => {
  const context = useContext(SelectionContext);
  if (!context) {
    throw new Error('useSelection must be used within a SelectionProvider');
  }
  return context;
};
